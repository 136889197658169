import { ReactElement } from 'react';
import cx from 'classnames';

import styles from './PageTitle.module.scss'

interface Props {
  actionsComponent?: ReactElement;
  subheading?: ReactElement;
  enablePageTitleIcon?: boolean;
  enablePageTitleSubheading?: boolean;
  headerFixed?: boolean;
  icon?: string;
  svg?: ReactElement;
  heading?: ReactElement | string;
  className?: string;
}

export default function PageTitle(props: Props): ReactElement | null {
  const {
    actionsComponent,
    subheading,
    enablePageTitleIcon = true,
    enablePageTitleSubheading,
    headerFixed = false,
    icon,
    svg,
    heading,
    className,
  } = props;

  //
  // Render
  //

  const renderIcon = () => {
    if (svg) {
      return svg;
    }

    return <i className={icon} />;
  };

  return (
    <div className={cx('app-page-title', className, styles.host, { 'position-title-sticky': headerFixed })}>
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className={cx('page-title-icon', { 'd-none': !enablePageTitleIcon })}>{renderIcon()}</div>
          <div>
            {heading}
            <div className={cx('page-title-subheading', { 'd-none': !enablePageTitleSubheading })}>{subheading}</div>
          </div>
        </div>
        <div className="page-title-actions">{actionsComponent}</div>
      </div>
    </div>
  );
}
