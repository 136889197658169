import { getCssCustomProperty } from '@/utils/cssCustomProperties';

export default function ListIcon() {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <rect fill={getCssCustomProperty('--primary-color')} height="24" rx="4" width="24" />
      <path
        d="M9.61857 12.5376C9.31678 12.5376 9.07214 12.2929 9.07214 11.9912C9.07214 11.6894 9.31678 11.4447 9.61857 11.4447H19.4536C19.7554 11.4447 20 11.6894 20 11.9912C20 12.2929 19.7554 12.5376 19.4536 12.5376H9.61857ZM9.61857 16.9087C9.31678 16.9087 9.07214 16.664 9.07214 16.3622C9.07214 16.0605 9.31678 15.8158 9.61857 15.8158H19.4536C19.7554 15.8158 20 16.0605 20 16.3622C20 16.664 19.7554 16.9087 19.4536 16.9087H9.61857ZM9.61857 8.16637C9.31678 8.16637 9.07214 7.92173 9.07214 7.61995C9.07214 7.31816 9.31678 7.07352 9.61857 7.07352H19.4536C19.7554 7.07352 20 7.31816 20 7.61995C20 7.92173 19.7554 8.16637 19.4536 8.16637H9.61857ZM4.56731 7.25678C4.68073 7.14336 4.86463 7.14335 4.97805 7.25678C5.09979 7.37852 5.3001 7.36824 5.40874 7.23468L6.06815 6.424C6.25862 6.18984 6.60286 6.15444 6.83701 6.34493C7.07113 6.53541 7.10651 6.87962 6.91604 7.11374L5.89404 8.36993C5.57051 8.76759 4.97406 8.79817 4.61156 8.43567L4.38634 8.21045C4.17297 7.99708 4.17297 7.65114 4.38633 7.43776L4.56731 7.25678ZM4.5673 11.6279C4.68072 11.5144 4.86464 11.5144 4.97807 11.6279C5.0998 11.7496 5.3001 11.7393 5.40875 11.6058L6.06822 10.7952C6.25867 10.5611 6.60281 10.5257 6.83693 10.7161C7.07109 10.9066 7.1065 11.2508 6.91601 11.4849L5.89405 12.7411C5.57052 13.1387 4.97405 13.1693 4.61155 12.8068L4.38634 12.5816C4.17297 12.3682 4.17296 12.0223 4.38632 11.8089L4.5673 11.6279ZM4.5673 15.999C4.68072 15.8855 4.86464 15.8855 4.97807 15.999C5.0998 16.1207 5.3001 16.1104 5.40875 15.9769L6.06822 15.1663C6.25867 14.9322 6.60281 14.8968 6.83693 15.0872C7.07109 15.2776 7.1065 15.6219 6.91601 15.856L5.89405 17.1121C5.57052 17.5098 4.97405 17.5404 4.61155 17.1779L4.38634 16.9527C4.17297 16.7393 4.17296 16.3934 4.38632 16.18L4.5673 15.999Z"
        fill="white"
      />
    </svg>
  );
}
