import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TokenStorageService } from '@/services';
import { User } from '@/models/entity';

type SliceState = {
  authToken: string | null;
  isUserLoading: boolean;
  user: User | null;
};

const initialState: SliceState = {
  authToken: TokenStorageService.getAuthToken(),
  isUserLoading: true,
  user: null,
};

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string | null>) => {
      state.authToken = action.payload;
    },
    setIsUserLoading: (state, action: PayloadAction<boolean>) => {
      state.isUserLoading = action.payload;
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
});

export const { reducer: profileReducer } = profile;
export const { setAuthToken, setIsUserLoading, setUser } = profile.actions;
