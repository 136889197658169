import cn from 'classnames';
import { useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';

import styles from './AppDropdownDateRangePicker.module.scss';

import { ArrowIcon } from '@/components/icons';

type Props = {
  onChange(value: DateRange | null): void;
  value: DateRange | null;
  placeholder?: string;
  className?: string;
};

export default function AppDropdownDateRangePicker(props: Props) {
  const { onChange, value, placeholder, className } = props;

  //
  // State
  //

  const [open, setOpen] = useState<boolean>(false);

  //
  // Render
  //

  return (
    <div className={cn(styles.wrapper, className)}>
      <DateRangePicker
        className={styles.host}
        format="dd.MM.yyyy"
        locale={{
          today: 'Сегодня',
          yesterday: 'Вчера',
          tomorrow: 'Завтра',
          last7Days: 'Последние 7 дней',
        }}
        onBlur={() => setOpen(false)}
        onChange={(v) => onChange(v)}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        placeholder={placeholder}
        ranges={[
          {
            label: 'today',
            value: [startOfDay(new Date()), endOfDay(new Date())],
          },
          {
            label: 'Завтра',
            value: [startOfDay(addDays(new Date(), +1)), endOfDay(addDays(new Date(), +1))],
          },
          {
            label: 'last7Days',
            value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
          },
        ]}
        value={value}
      />
      <ArrowIcon
        className={styles.arrow}
        direction={open ? 'top' : 'bottom'}
      />
    </div>
  );
}
