type Props = {
  className?: string;
};

export default function SettingsIcon(props: Props) {
  const { className } = props;

  return (
    <svg
      className={className}
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8523 8.18542C12.8823 7.96667 12.8973 7.74062 12.8973 7.5C12.8973 7.26667 12.8823 7.03333 12.8448 6.81458L14.3666 5.6625C14.5015 5.56042 14.539 5.36354 14.4565 5.21771L13.0172 2.79688C12.9273 2.63646 12.7399 2.58542 12.575 2.63646L10.7834 3.33646C10.4085 3.05937 10.0112 2.82604 9.56896 2.65104L9.2991 0.798958C9.26911 0.623958 9.11919 0.5 8.93928 0.5H6.06072C5.88081 0.5 5.73838 0.623958 5.7084 0.798958L5.43853 2.65104C4.99625 2.82604 4.59145 3.06667 4.22414 3.33646L2.43253 2.63646C2.26762 2.57812 2.08021 2.63646 1.99026 2.79688L0.558471 5.21771C0.468516 5.37083 0.498501 5.56042 0.648426 5.6625L2.17016 6.81458C2.13268 7.03333 2.1027 7.27396 2.1027 7.5C2.1027 7.72604 2.11769 7.96667 2.15517 8.18542L0.633433 9.3375C0.498501 9.43958 0.46102 9.63646 0.543479 9.78229L1.98276 12.2031C2.07271 12.3635 2.26012 12.4146 2.42504 12.3635L4.21664 11.6635C4.59145 11.9406 4.98876 12.174 5.43103 12.349L5.7009 14.201C5.73838 14.376 5.88081 14.5 6.06072 14.5H8.93928C9.11919 14.5 9.26912 14.376 9.2916 14.201L9.56147 12.349C10.0037 12.174 10.4085 11.9406 10.7759 11.6635L12.5675 12.3635C12.7324 12.4219 12.9198 12.3635 13.0097 12.2031L14.449 9.78229C14.539 9.62187 14.5015 9.43958 14.3591 9.3375L12.8523 8.18542ZM7.5 10.125C6.01574 10.125 4.80135 8.94375 4.80135 7.5C4.80135 6.05625 6.01574 4.875 7.5 4.875C8.98426 4.875 10.1987 6.05625 10.1987 7.5C10.1987 8.94375 8.98426 10.125 7.5 10.125Z"
        fill="black"
      />
    </svg>
  );
}
