import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import { FormikErrors } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';

import styles from './ClassesPage.module.scss';

import { PageTitle } from '@/layout/DashboardLayout/components';
import { useDocumentTitle, useTableUrlParams } from '@/hooks';
import { AppButton, AppButtonGroup, AppInputField, AppLoader, AppModal, AppSearch, AppTable } from '@/components/app';
import { RootState } from '@/store';
import { SchoolsService, SettingsService } from '@/services';
import { SchoolClass } from '@/models/entity';
import { formatDateToDateAndTime } from '@/utils/date';
import { ClassForm } from './components';
import { FormFields } from './components/ClassForm/ClassForm';
import { Paths } from '@/routes';
import { ClassesIcon } from '@/components/icons';

export default function ClassesPage() {
  const { currentPage: urlCurrentPage, itemsPerPage: urlItemsPerPage } = useTableUrlParams();
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const queryParams = qs.parse(urlParams);

  //
  // State
  //

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [schoolsFilter, setSchoolsFilter] = useState<string[]>(
    queryParams.schoolId && !Array.isArray(queryParams.schoolId) ? [queryParams.schoolId] : [],
  );
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [tableData, setTableData] = useState<SchoolClass[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState<number>(urlItemsPerPage);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [addClassModalOpened, setAddClassModalOpened] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [editClassModal, setEditClassModal] = useState<{
    open: boolean;
    schoolClass: SchoolClass | null;
  }>({
    open: false,
    schoolClass: null,
  });
  const [deleteClassModal, setDeleteClassModal] = useState<{
    open: boolean;
    schoolClass: SchoolClass | null;
  }>({
    open: false,
    schoolClass: null,
  });

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Methods
  //

  const isClassValid = (schoolClass: SchoolClass) => {
    return !!schoolClass.responsible;
  };

  const loadClasses = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data: classesData, count } = await SchoolsService.getSchoolClassesList({
        currentPage,
        itemsPerPage,
        schoolsIds: schoolsFilter,
        search: searchFilter,
      });
      setTableData(classesData);
      setTotalPages(Math.ceil(count / itemsPerPage));
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  }, [currentPage, itemsPerPage, schoolsFilter, searchFilter]);

  const handleCreateClass = async (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    const { liter, mealRequestDeadlineTime, mealRequestTime, number, school } = values;

    if (!school) return;

    try {
      setModalLoading(true);
      await SchoolsService.createNewClass(school, number, liter, mealRequestTime, mealRequestDeadlineTime);
      setAddClassModalOpened(false);
      loadClasses();
    } catch (error) {
      throw error;
    } finally {
      setModalLoading(false);
    }
  };

  const handleEditClass = async (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    const { liter, mealRequestDeadlineTime, mealRequestTime, number, school } = values;

    if (!school || !editClassModal.schoolClass) return;

    try {
      setModalLoading(true);
      await SchoolsService.editSchoolClass(
        editClassModal.schoolClass.id,
        school,
        number,
        liter,
        mealRequestTime,
        mealRequestDeadlineTime,
      );
      setEditClassModal({ open: false, schoolClass: null });
      loadClasses();
    } catch (error) {
      throw error;
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeleteClass = async (schoolClass: SchoolClass | null) => {
    if (!schoolClass) return;

    try {
      setDeleteClassModal({ open: true, schoolClass: deleteClassModal.schoolClass });
      await SettingsService.deleteSchoolClass(schoolClass.id);
      setDeleteClassModal({ open: false, schoolClass: null });
      loadClasses();
    } catch (error) {
      setDeleteClassModal({ open: true, schoolClass: deleteClassModal.schoolClass });
      throw error;
    } finally {
      setModalLoading(false);
    }
  };

  //
  // Effects
  //

  useDocumentTitle('Классы');

  useEffect(() => {
    loadClasses();
  }, [loadClasses]);

  //
  // Render
  //

  const renderActions = (schoolClass: SchoolClass) => {
    return [
      <DropdownItem key="edit" onClick={() => setEditClassModal({ open: true, schoolClass })}>
        <span className="dropdown-icon material-icons">create</span>
        Редактировать
      </DropdownItem>,
      <DropdownItem key="delete" onClick={() => setDeleteClassModal({ open: true, schoolClass })}>
        <span className="dropdown-icon material-icons">delete</span>
        Удалить
      </DropdownItem>,
      <DropdownItem key="list" onClick={() => navigate(Paths.classStudents(schoolClass.school?.id, schoolClass.id))}>
        <span className="dropdown-icon material-icons">list</span>
        Посмотреть список учеников
      </DropdownItem>,
    ];
  };

  return (
    <>
      <PageTitle heading="Классы" svg={<ClassesIcon />} />

      <section className={styles.host}>
        <section className={styles.actions}>
          <div className={styles.actionsLeftSection}>
            <AppButtonGroup
              className={styles.addSchoolBtn}
              leftSlot={<FontAwesomeIcon fixedWidth icon={faPlus} />}
              onClick={() => setAddClassModalOpened(true)}
            >
              Добавить класс
            </AppButtonGroup>

            <AppInputField
              className={styles.schoolsFilter}
              controlType="multi-select"
              onChange={(value) => setSchoolsFilter(value)}
              options={schools.map((s) => ({
                label: s.name,
                value: s.id,
              }))}
              placeholder="Выберите школу"
              value={schoolsFilter}
            />
          </div>

          <AppSearch
            onChange={(value) => setSearchFilter(value)}
            onClickRefreshButton={() => loadClasses()}
            value={searchFilter}
          />
        </section>

        <AppLoader active={tableLoading} />
        <AppTable
          className={styles.table}
          columns={[
            {
              value: (schoolClass) => (
                <div className={cn(styles.schoolCell, styles.linkCell)}>
                  <Link to={Paths.classStudents(schoolClass.school?.id, schoolClass.id)}>
                    {schoolClass.school?.name}
                  </Link>
                </div>
              ),
              className: (schoolClass) => cn({ [styles.invalidRow]: !isClassValid(schoolClass) }),
              header: {
                title: 'Название школы',
                style: { textAlign: 'left', width: 345 },
              },
            },
            {
              value: (schoolClass) => {
                if (!schoolClass.createdAt) return '—';

                const { date, time } = formatDateToDateAndTime(schoolClass.createdAt);

                return (
                  <div style={{ textAlign: 'left' }}>
                    {date}
                    <br />
                    {time}
                  </div>
                );
              },
              className: (schoolClass) => cn({ [styles.invalidRow]: !isClassValid(schoolClass) }),
              header: {
                title: 'Дата и время\nсоздания',
                style: { textAlign: 'left', width: 150 },
              },
            },
            {
              value: (schoolClass) => <div style={{ textAlign: 'left', whiteSpace: 'pre' }}>{schoolClass.name}</div>,
              className: (schoolClass) => cn({ [styles.invalidRow]: !isClassValid(schoolClass) }),
              header: {
                title: 'Класс',
                style: { textAlign: 'left', width: 60 },
              },
            },
            {
              value: (schoolClass) => (
                <div className={styles.linkCell} style={{ textAlign: 'left' }}>
                  <Link to={Paths.classStudents(schoolClass.school?.id, schoolClass.id)}>
                    {schoolClass.studentsCount}
                  </Link>
                </div>
              ),
              className: (schoolClass) => cn({ [styles.invalidRow]: !isClassValid(schoolClass) }),
              header: {
                title: 'Количество\nучеников',
                style: { textAlign: 'left', width: 100 },
              },
            },
            {
              value: (schoolClass) => {
                if (!schoolClass.mealRequestDate) return '—';

                return <div style={{ textAlign: 'left' }}>{schoolClass.mealRequestDate}</div>;
              },
              className: (schoolClass) => cn({ [styles.invalidRow]: !isClassValid(schoolClass) }),
              header: {
                title: 'Создание заявки\nна питание',
                style: { textAlign: 'left', width: 150 },
              },
            },
            {
              value: (schoolClass) => {
                if (!schoolClass.mealRequestDeadlineDate) return '—';

                return <div style={{ textAlign: 'left' }}>{schoolClass.mealRequestDeadlineDate}</div>;
              },
              className: (schoolClass) => cn({ [styles.invalidRow]: !isClassValid(schoolClass) }),
              header: {
                title: 'Дедлайн подачи\nзаявки на питание',
                style: { textAlign: 'left', width: 150 },
              },
            },
            {
              value: (schoolClass) =>
                schoolClass.responsible ? (
                  <div style={{ textAlign: 'left' }}>{schoolClass.responsible.FIO}</div>
                ) : (
                  <div>
                    <Link to={Paths.teachers}>Назначить учителя</Link>
                  </div>
                ),
              className: (schoolClass) =>
                cn('text-left', styles.setupTeacherCell, { [styles.invalidRow]: !isClassValid(schoolClass) }),
              header: {
                title: 'Ответственный по питанию',
                style: { textAlign: 'left' },
              },
            },
            {
              value: (schoolClass) => renderActions(schoolClass),
              type: 'action',
              className: (schoolClass) => cn({ [styles.invalidRow]: !isClassValid(schoolClass) }),
              header: {
                title: 'Действия',
                style: {
                  width: 84,
                },
              },
            },
          ]}
          data={tableData}
          pagination={{
            totalPages,
            currentPage,
            itemsPerPage,
            onChangePage: (newPage: number) => setCurrentPage(newPage),
            onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
          }}
        />
      </section>

      <AppModal isOpen={addClassModalOpened} onClose={() => setAddClassModalOpened(false)}>
        <ClassForm
          defaultValues={{
            school: schoolsFilter.length === 1 ? schoolsFilter[0] : null,
            liter: '',
            number: '',
            mealRequestDeadlineTime: '',
            mealRequestTime: '',
          }}
          handleSubmit={handleCreateClass}
          loading={modalLoading}
          onCancel={() => setAddClassModalOpened(false)}
          subtitle="Для добавления класса, пожалуйста, заполните следующие поля:"
          title="Добавление класса"
        />
      </AppModal>

      <AppModal isOpen={editClassModal.open} onClose={() => setEditClassModal({ open: false, schoolClass: null })}>
        <ClassForm
          defaultValues={{
            school: editClassModal.schoolClass?.school?.id || null,
            liter: editClassModal.schoolClass?.liter || '',
            number: editClassModal.schoolClass?.number?.toString() || '',
            mealRequestDeadlineTime: editClassModal.schoolClass?.mealRequestDeadlineDate
              ? editClassModal.schoolClass.mealRequestDeadlineDate
              : '',
            mealRequestTime: editClassModal.schoolClass?.mealRequestDate
              ? editClassModal.schoolClass.mealRequestDate
              : '',
          }}
          handleSubmit={handleEditClass}
          loading={modalLoading}
          onCancel={() => setEditClassModal({ open: false, schoolClass: null })}
          title="Редактирование класса"
        />
      </AppModal>

      <AppModal isOpen={deleteClassModal.open} onClose={() => setDeleteClassModal({ open: false, schoolClass: null })}>
        <section className={styles.deleteModal}>
          <h2>Вы уверены?</h2>
          <p>
            При удалении класса так же удалятся <br /> все добавленные ранее ученики
          </p>

          <div className={styles.modalActions}>
            <AppButton
              disabled={modalLoading}
              onClick={() => setDeleteClassModal({ open: false, schoolClass: null })}
              outline
            >
              Отмена
            </AppButton>
            <AppButton disabled={modalLoading} onClick={() => handleDeleteClass(deleteClassModal.schoolClass)}>
              Удалить
            </AppButton>
          </div>
        </section>
      </AppModal>
    </>
  );
}
