export default class PaidNutrition {
  public id: string;
  public schoolTitle: string;
  public totalDebt: number;
  public debtAllowed: number;
  public paidNutritionEnabled: boolean;
  public paidBreakfastClasses: number[];
  public paidLunchClasses: number[];
  public paidAfternoonSnackClasses: number[];
  public paidBreakfastPrice: number;
  public paidAfternoonSnackPrice: number;
  public paidLunchPrice: number;

  public constructor(data: Partial<PaidNutrition>) {
    this.id = data.id!;
    this.schoolTitle = data.schoolTitle ?? '';
    this.totalDebt = data.totalDebt ?? 0;
    this.debtAllowed = data.debtAllowed ?? 0;
    this.paidNutritionEnabled = data.paidNutritionEnabled ?? false;
    this.paidBreakfastClasses = data.paidBreakfastClasses ?? [];
    this.paidLunchClasses = data.paidLunchClasses ?? [];
    this.paidAfternoonSnackClasses = data.paidAfternoonSnackClasses ?? [];
    this.paidBreakfastPrice = data.paidBreakfastPrice ?? 0;
    this.paidBreakfastPrice = data.paidBreakfastPrice ?? 0;
    this.paidAfternoonSnackPrice = data.paidAfternoonSnackPrice ?? 0;
    this.paidLunchPrice = data.paidLunchPrice ?? 0;
  }
}
