/* eslint-disable camelcase */
export default class Warehouse {
  public id: string;
  public iikoId: string | null;
  public name: string;

  public constructor(data: Partial<Warehouse>) {
    this.id = data.id!;
    this.iikoId = data.iikoId ?? null;
    this.name = data.name ?? '';
  }
}
