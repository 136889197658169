import { Fragment, useCallback, useEffect, useState } from 'react';
import { Badge, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormikErrors } from 'formik';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import styles from './TeachersTable.module.scss';

import { AppButton, AppButtonGroup, AppInputField, AppLoader, AppSearch, AppTable } from '@/components/app';
import { SchoolClass, TeacherListItem } from '@/models/entity';
import { SchoolsService } from '@/services';
import { deformatPhone, formatPhone } from '@/utils/formatters';
import { useChangeTableUrlParams, useTableUrlParams } from '@/hooks';
import { TeacherModal, TeacherDeleteModal } from '../';
import { getGenderShortName } from '@/constants/genders';
import { config as iikoCardStateConfig } from '@/constants/iikoCardState';
import { Paths } from '@/routes';
import { FormFields } from '../TeacherModal';
import { UserFemaleIcon } from '@/components/icons';
import { notify } from '@/utils/notifications';
import TeacherExistsModal from '../TeacherExistsModal';
import { ServerValidationError } from '@/api/exceptions';
import { RootState } from '@/store';

export default function OrdersTable() {
  const { search: urlSearch, currentPage: urlCurrentPage, itemsPerPage: urlItemsPerPage } = useTableUrlParams();

  //
  // State
  //

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [teachers, setTeachers] = useState<TeacherListItem[]>([]);
  const [schoolsFilter, setSchoolsFilter] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(urlItemsPerPage);
  const [searchFilter, setSearchFilter] = useState<string>(urlSearch || '');
  const [createTeacherModal, setCreateTeacherModal] = useState<{ opened: boolean; loading: boolean }>({
    opened: false,
    loading: false,
  });
  const [editTeacherModal, setEditTeacherModal] = useState<{
    data: TeacherListItem | null;
    opened: boolean;
    loading: boolean;
  }>({
    data: null,
    opened: false,
    loading: false,
  });
  const [deleteModal, setDeleteModal] = useState<{ data: TeacherListItem | null; opened: boolean; loading: boolean }>({
    data: null,
    opened: false,
    loading: false,
  });
  const [teacherExistsModal, setTeacherExistsModal] = useState<{
    data: {
      classes: SchoolClass[];
      formValues: FormFields | null;
      setErrors: ((errors: FormikErrors<FormFields>) => void) | null;
      isEdit: boolean;
        };
    opened: boolean;
        }>({
          data: {
            classes: [],
            formValues: null,
            setErrors: null,
            isEdit: false,
          },
          opened: false,
        });

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Methods
  //

  const loadTeachers = useCallback(async () => {
    const { data: teachersData, count } = await SchoolsService.getAllTeachers({
      search: searchFilter,
      currentPage,
      itemsPerPage,
      schoolsIds: schoolsFilter,
    });

    setTeachers(teachersData);
    setTotalPages(Math.ceil(count / itemsPerPage));
  }, [currentPage, itemsPerPage, schoolsFilter, searchFilter]);

  const loadAllData = useCallback(() => {
    setTableLoading(true);
    Promise.all([loadTeachers()]).finally(() => {
      setTableLoading(false);
    });
  }, [loadTeachers]);

  const handleCloseCreateTeacherModal = () => {
    setCreateTeacherModal({ opened: false, loading: false });
  };

  const handleCloseEditTeacherModal = () => {
    setEditTeacherModal({ data: editTeacherModal.data, opened: false, loading: false });
    setTimeout(() => {
      setEditTeacherModal({ data: null, opened: false, loading: false });
    }, 300);
  };

  const isTextFieldLengthValid = (field: string) => {
    return field.length >= 2;
  };

  const validateFormFields = (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    const { phone, name, surname, middleName } = values;
    const phoneNoFormat = deformatPhone(phone);

    if (phoneNoFormat.length > 0 && !phoneNoFormat.startsWith('9')) {
      setErrors({ phone: ' ' });
      notify('Проверьте корректность введенного номера телефона', 'error', <UserFemaleIcon />);
      return false;
    }

    if (!isTextFieldLengthValid(surname)) {
      setErrors({ surname: ' ' });
      notify('Фамилия должна содержать минимум 2 символа', 'error', <UserFemaleIcon />);
      return false;
    }

    if (!isTextFieldLengthValid(name)) {
      setErrors({ name: ' ' });
      notify('Имя должно содержать минимум 2 символа', 'error', <UserFemaleIcon />);
      return false;
    }

    if (middleName && !isTextFieldLengthValid(middleName)) {
      setErrors({ middleName: ' ' });
      notify('Отчество должна содержать минимум 2 символа', 'error', <UserFemaleIcon />);
      return false;
    }

    return true;
  };

  const handleFormError = (error: unknown) => {
    if (error instanceof ServerValidationError) {
      if (error.fields.phone) notify(error.fields.phone[0], 'error');
    }
  };

  const handleSubmitCreate = async (
    values: FormFields,
    setErrors: (errors: FormikErrors<FormFields>) => void,
    skipTeacherExistsPopup?: boolean,
  ) => {
    const isValid = validateFormFields(values, setErrors);
    const { middleName, name, phone, responsible, surname, school } = values;

    if (!school) {
      notify('Поле "Школа" не заполнено', 'error');
      return;
    }

    if (!isValid) return;

    try {
      const schoolClasses = await SchoolsService.getSchoolClasses(school);
      const schoolClassesHasResponsible = schoolClasses.filter((s) => s.responsible);
      const selectedClassesAlreadyHasTeacher = schoolClassesHasResponsible.filter((sc) => responsible.includes(sc.id));

      if (selectedClassesAlreadyHasTeacher.length > 0 && !skipTeacherExistsPopup) {
        setTeacherExistsModal({
          data: { classes: selectedClassesAlreadyHasTeacher, formValues: values, setErrors: setErrors, isEdit: false },
          opened: true,
        });
        return;
      }

      setCreateTeacherModal({ loading: true, opened: true });
      await SchoolsService.createTeacher(school, name, surname, middleName, responsible, phone);
      notify('Учитель успешно создан', 'success');
      loadAllData();
      setCreateTeacherModal({ loading: false, opened: false });
    } catch (error) {
      setCreateTeacherModal({ loading: false, opened: true });
      handleFormError(error);
      throw error;
    }
  };

  const handleSubmitEdit = async (
    values: FormFields,
    setErrors: (errors: FormikErrors<FormFields>) => void,
    skipTeacherExistsPopup?: boolean,
  ) => {
    const isValid = validateFormFields(values, setErrors);
    const { middleName, name, phone, responsible, surname, school } = values;

    if (!school) {
      notify('Поле "Школа" не заполнено', 'error');
      return;
    }

    if (!isValid || !editTeacherModal.data) return;

    try {
      const schoolClasses = await SchoolsService.getSchoolClasses(school);
      const schoolClassesHasResponsible = schoolClasses.filter((s) => s.responsible);
      const selectedClassesAlreadyHasTeacher = schoolClassesHasResponsible.filter((sc) => responsible.includes(sc.id));

      if (selectedClassesAlreadyHasTeacher.length > 0 && !skipTeacherExistsPopup) {
        setTeacherExistsModal({
          data: { classes: selectedClassesAlreadyHasTeacher, formValues: values, setErrors, isEdit: true },
          opened: true,
        });
        return;
      }

      setEditTeacherModal({ loading: true, data: editTeacherModal.data, opened: true });
      await SchoolsService.editTeacher(editTeacherModal.data.id, school, name, surname, middleName, responsible, phone);
      notify('Учитель успешно отредактирован', 'success');
      loadAllData();
      setEditTeacherModal({ loading: false, data: null, opened: false });
    } catch (error) {
      setEditTeacherModal({ loading: false, data: editTeacherModal.data, opened: true });
      handleFormError(error);
      throw error;
    }
  };

  const handleSubmitDelete = async () => {
    if (!deleteModal.data || !deleteModal.data.school?.id) return;

    try {
      setDeleteModal({ loading: true, data: deleteModal.data, opened: true });
      await SchoolsService.deleteTeacher(deleteModal.data.id, deleteModal.data.school.id);
      notify('Учитель успешно удален', 'success');
      loadAllData();
      setDeleteModal({ loading: false, data: null, opened: false });
    } catch (error) {
      setDeleteModal({ loading: false, data: deleteModal.data, opened: true });
      throw error;
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    loadAllData();
  }, [loadAllData]);

  useChangeTableUrlParams({
    currentPage,
    perPage: itemsPerPage,
    search: searchFilter,
  });

  //
  // Render
  //

  const renderActions = (teacher: TeacherListItem) => {
    return [
      <DropdownItem
        key="edit"
        onClick={() => {
          setEditTeacherModal({
            opened: true,
            data: teacher,
            loading: false,
          });
        }}
      >
        <span className="dropdown-icon material-icons">create</span>
        Редактировать
      </DropdownItem>,
      <DropdownItem
        key="delete"
        onClick={() => {
          setDeleteModal({
            data: teacher,
            opened: true,
            loading: false,
          });
        }}
      >
        <span className="dropdown-icon material-icons">delete</span>
        Удалить
      </DropdownItem>,
    ];
  };

  const renderClassFilter = (
    <section className={styles.host}>
      <section className={styles.topActions}>
        <div className={styles.leftActions}>
          <AppButtonGroup
            className={styles.addTeacherBtn}
            leftSlot={<FontAwesomeIcon fixedWidth icon={faPlus} />}
            onClick={() => setCreateTeacherModal({ opened: true, loading: false })}
          >
            Добавить учителя
          </AppButtonGroup>

          <AppInputField
            className={styles.schoolsFilter}
            controlType="multi-select"
            onChange={(value) => setSchoolsFilter(value)}
            options={schools.map((s) => ({
              label: s.name,
              value: s.id,
            }))}
            placeholder="Выберите школу"
            value={schoolsFilter}
          />
        </div>

        <AppSearch
          onChange={(value) => {
            setSearchFilter(value);
          }}
          onClickRefreshButton={loadAllData}
          value={searchFilter}
        />
      </section>

      <AppTable
        className={styles.table}
        columns={[
          {
            value: (teacher) => <div className={cn('text-left', styles.schoolCell)}>{teacher.school?.name}</div>,
            header: {
              title: 'Название школы',
              style: {
                width: 339,
              },
              className: 'text-left',
            },
          },
          {
            value: (teacher) => teacher.FIO,
            className: 'text-left',
            header: {
              className: 'text-left',
              title: 'Фамилия Имя Отчество',
            },
          },
          {
            value: (teacher) => formatPhone(teacher.phone),
            className: 'text-left',
            header: {
              className: 'text-left',
              title: 'Номер телефона',
              style: {
                width: 250,
              },
            },
          },
          {
            value: (teacher) => (teacher.gender ? getGenderShortName(teacher.gender) : ''),
            className: 'text-left',
            header: {
              title: 'Пол',
              className: 'text-left',
              style: {
                width: 60,
              },
            },
          },
          {
            value: (teacher) => {
              const configValue = teacher.iikoWalletExist
                ? iikoCardStateConfig.created
                : iikoCardStateConfig.notCreated;

              if (!configValue) return '—';

              return (
                <Badge
                  style={{
                    backgroundColor: configValue.backgroundColor,
                    color: configValue.color,
                  }}
                >
                  {configValue.name}
                </Badge>
              );
            },
            className: 'text-left',
            header: {
              title: 'iikoCard',
              style: {
                width: 200,
              },
              className: 'text-left',
            },
          },
          {
            value: (teacher) => (
              <div className="text-left">
                {teacher.assignedClasses.map((schoolClass) => {
                  if (!schoolClass) return undefined;

                  return (
                    <Fragment key={schoolClass.id}>
                      <Link
                        className={styles.classesLink}
                        to={Paths.classStudents(schoolClass.school?.id, schoolClass.id)}
                      >
                        {schoolClass.name}
                      </Link>
                    </Fragment>
                  );
                })}
              </div>
            ),
            header: {
              title: 'Ответственный',
              className: 'text-left',
              style: {
                width: 120,
              },
            },
          },
          {
            value: (teacher) => renderActions(teacher),
            type: 'action',
            header: {
              title: 'Действия',
              style: {
                width: 120,
              },
            },
          },
        ]}
        data={teachers}
        loading={false}
        pagination={{
          totalPages,
          currentPage,
          itemsPerPage,
          onChangePage: (newPage: number) => setCurrentPage(newPage),
          onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
        }}
      />
    </section>
  );

  return (
    <section className={styles.host}>
      <AppLoader active={tableLoading} />
      {renderClassFilter}

      <TeacherModal
        actionsSlot={(values, dirty, isValid) => (
          <section className={styles.actions}>
            <AppButton
              className={styles.actionBtn}
              disabled={createTeacherModal.loading || !dirty || !isValid}
              type="submit"
            >
              Добавить
            </AppButton>
            <AppButton
              className={cn(styles.actionBtn, styles.closeBtn)}
              disabled={createTeacherModal.loading}
              onClick={handleCloseCreateTeacherModal}
              outline
            >
              Отмена
            </AppButton>
          </section>
        )}
        data={null}
        handleSubmit={handleSubmitCreate}
        header="Добавление учителя"
        loading={createTeacherModal.loading}
        onClose={handleCloseCreateTeacherModal}
        open={createTeacherModal.opened}
      />
      <TeacherModal
        actionsSlot={(values, dirty, isValid) => (
          <section className={styles.actions}>
            <AppButton
              className={styles.actionBtn}
              disabled={editTeacherModal.loading || !isValid || !dirty}
              type="submit"
            >
              Сохранить
            </AppButton>
          </section>
        )}
        data={editTeacherModal.data}
        handleSubmit={handleSubmitEdit}
        header="Редактирование учителя"
        loading={editTeacherModal.loading}
        onClose={handleCloseEditTeacherModal}
        open={editTeacherModal.opened}
      />
      <TeacherDeleteModal
        handleSubmit={handleSubmitDelete}
        loading={deleteModal.loading}
        onClose={() => {
          setDeleteModal({ data: null, opened: false, loading: false });
        }}
        open={deleteModal.opened}
      />
      <TeacherExistsModal
        data={teacherExistsModal.data}
        handleSubmit={() => {
          if (!teacherExistsModal.data.formValues || !teacherExistsModal.data.setErrors) return;

          teacherExistsModal.data.isEdit
            ? handleSubmitEdit(teacherExistsModal.data.formValues, teacherExistsModal.data.setErrors, true)
            : handleSubmitCreate(teacherExistsModal.data.formValues, teacherExistsModal.data.setErrors, true);
        }}
        onClose={() => {
          setTeacherExistsModal({
            data: teacherExistsModal.data,
            opened: false,
          });

          setTimeout(() => {
            setTeacherExistsModal({
              data: { classes: [], formValues: null, setErrors: null, isEdit: false },
              opened: false,
            });
          }, 600);
        }}
        open={teacherExistsModal.opened}
      />
    </section>
  );
}
