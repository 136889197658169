import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import styles from './ClassUploadPreviewModal.module.scss';

import { AppButton, AppModal, AppCircularSpinner, AppTable } from '@/components/app';
import { UserIcon, WifiIcon } from '@/components/icons';
import { ImportStudent, Student } from '@/models/entity';
import { Genders, IikoCardState } from '@/constants/';
import { SchoolsService } from '@/services';
import { notify } from '@/utils/notifications';
import { ServerValidationError } from '@/api/exceptions';
import wellKnownErrors from '@/api/wellKnownErrors';
import { formatPhone } from '@/utils/formatters';

type Props = {
  isOpen: boolean;
  onClose(): void;
  file: File | null;
  onContinue(file: File, importStudents: ImportStudent[]): void;
};

export default function ClassUploadPreviewModal(props: Props) {
  const { file, isOpen, onClose, onContinue } = props;

  //
  // State
  //

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [importStudents, setImportStudents] = useState<ImportStudent[]>([]);
  const [excludeStudents, setExcludeStudents] = useState<string[]>([]);

  //
  // Computed
  //

  const students = useMemo(
    () =>
      importStudents.map(
        (s) =>
          new Student({
            balance: 0,
            birthdate: null,
            firstName: s.firstName,
            gender: Genders.notDefined,
            id: s.id.toString(),
            lastName: s.lastName,
            middleName: s.middleName,
            parentFIO: '—',
            parentPhone: s.error ? s.parentPhone : s.parentPhoneClean,
            photoUrl: null,
            iikoCardState: IikoCardState.notCreated,
            parentPhoneValid: s.error.length === 0,
          }),
      ),
    [importStudents],
  ).sort((x, y) => (x.parentPhoneValid === y.parentPhoneValid ? 0 : x.parentPhoneValid ? 1 : -1));
  const isImportInvalid = students.some((s) => !s.parentPhoneValid);
  const paginatedStudents = useMemo(() => {
    const currentPageDivider = currentPage <= 0 ? 0 : currentPage - 1;
    const startIndex = currentPageDivider * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return students.slice(startIndex, endIndex);
  }, [currentPage, students, itemsPerPage]);
  const totalPages = useMemo(() => Math.ceil(students.length / itemsPerPage), [itemsPerPage, students]);

  //
  // Methods
  //

  const handleClose = () => {
    onClose();
    setCurrentPage(1);
    setItemsPerPage(10);
    setLoading(false);
    setTimeout(() => {
      setImportStudents([]);
      setExcludeStudents([]);
    }, 300);
  };

  const parseImportFile = async () => {
    if (!file) return;

    try {
      setLoading(true);
      const importStudentsData = await SchoolsService.parseSchoolClassFile(file);
      setImportStudents(importStudentsData);
    } catch (error) {
      handleClose();
      if (!(error instanceof ServerValidationError)) {
        notify('Произошла ошибка при обработке файла импорта', 'error', <WifiIcon />);
        throw error;
      }

      if (error.code === wellKnownErrors.invalidDataStructureException) {
        notify(
          'Неверная структура данных.\nДанные должны соответствовать формату: Заголовки таблицы, строки с данными учеников',
          'error',
          <UserIcon />,
        );
      }

      throw error;
    } finally {
      setLoading(false);
    }
  };

  const isExistsInExcluded = (student: Student) => {
    return excludeStudents.includes(student.id);
  };

  //
  // Effects
  //

  useEffect(() => {
    parseImportFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  //
  // Render
  //

  return (
    <>
      <AppModal closeOnClick={false} isOpen={isOpen} onClose={onClose} rootClassName={styles.modal}>
        <section className={styles.host}>
          <h2>Превью списка учеников</h2>
          {isImportInvalid ? (
            <section className={styles.invalidSection}>
              <span className={styles.error}>
                Указан некорректный номер телефона родителя. ({students.filter((s) => !s.parentPhoneValid).length})
              </span>
              <ol>
                <li>Нажмите на кнопку "Закрыть";</li>
                <li>
                  Отредактируйте номера телефонов родителей, которые выделены в таблице красным цветом. <br /> Номер
                  должен быть в виде 89ХХХХХХХХХ, либо +79ХХХХХХХХХ, либо 79ХХХХХХХХХ, либо 9ХХХХХХХХХ;
                </li>
                <li>Загрузите новый файл с таблицей.</li>
              </ol>
            </section>
          ) : (
            <p>Убедитесь в корректности заполнения полей и нажмите на кнопку "Продолжить"</p>
          )}

          <div className={cn(styles.tableWrapper, { [styles.tableWrapperLoading]: loading })}>
            {!loading ? (
              <AppTable
                className={cn('animate__animated animate__fadeIn', styles.table)}
                columns={[
                  {
                    value: (student) => student.FIO,
                    className: (student) =>
                      cn('text-left', styles.cell, { [styles.excludeCell]: isExistsInExcluded(student) }),
                    header: {
                      title: 'ФИО',
                      className: 'text-left',
                    },
                  },
                  {
                    value: (student) => formatPhone(student.parentPhone),
                    className: (student) =>
                      cn('text-left', styles.cell, {
                        [styles.invalidCell]: !student.parentPhoneValid && !isExistsInExcluded(student),
                        [styles.excludeCell]: isExistsInExcluded(student),
                      }),
                    header: {
                      title: 'Телефон родителя',
                      style: {
                        width: 250,
                      },
                      className: 'text-left',
                    },
                  },
                  {
                    value: (student) => {
                      if (isExistsInExcluded(student)) {
                        return (
                          <span
                            className={cn('dropdown-icon material-icons', styles.actionIcon)}
                            onClick={() => {
                              setExcludeStudents(excludeStudents.filter((es) => es !== student.id));
                            }}
                            style={{ transform: 'scale(-1, 1) rotate(45deg)' }}
                          >
                            refresh
                          </span>
                        );
                      }

                      return (
                        <span
                          className={cn('dropdown-icon material-icons', styles.actionIcon)}
                          onClick={() => {
                            setExcludeStudents([...excludeStudents, student.id]);
                          }}
                        >
                          delete
                        </span>
                      );
                    },
                    className: (student) =>
                      cn('text-left', styles.cell, { [styles.excludeCell]: isExistsInExcluded(student) }),
                    header: {
                      title: 'Удалить',
                      style: {
                        width: 150,
                      },
                      className: 'text-left',
                    },
                  },
                ]}
                data={paginatedStudents}
                loading={false}
                pagination={{
                  totalPages,
                  currentPage,
                  itemsPerPage,
                  onChangePage: (newPage: number) => setCurrentPage(newPage),
                  onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
                  itemsPerPageList: [10, 50, 100, 200],
                  itemsPerPageListVisible: false,
                }}
              />
            ) : (
              <AppCircularSpinner className="animate__animated animate__fadeIn" radius={50} stroke={3} />
            )}
          </div>

          <section className={styles.actions}>
            <AppButton className={styles.actionBtn} disabled={loading} onClick={handleClose} outline>
              Назад
            </AppButton>
            <AppButton
              className={styles.actionBtn}
              disabled={
                !file ||
                loading ||
                importStudents.filter((s) => !excludeStudents.includes(s.id.toString())).filter((s) => !!s.error)
                  .length > 0
              }
              onClick={() => {
                if (!file) return;
                onContinue(
                  file,
                  importStudents.filter((s) => !excludeStudents.includes(s.id.toString())),
                );
                setTimeout(() => {
                  setImportStudents([]);
                  setExcludeStudents([]);
                }, 300);
              }}
            >
              Продолжить
            </AppButton>
          </section>
        </section>
      </AppModal>
    </>
  );
}
