import { ReactElement, isValidElement } from 'react';

import CellAction from '../CellAction';
import { CellType, CellValue } from '../';

interface Props {
  value: CellValue;
  type?: CellType;
  itemIndex: number;
  className?: string;
}

function renderSimpleCell(value: CellValue, className: string) {
  return <td className={className}>{value}</td>;
}

function renderCellAction(value: CellValue, className: string) {
  const isArrayOfReactElement = Array.isArray(value) && isValidElement(value[0]);

  if (!isArrayOfReactElement) {
    return <td className={className} />;
  }

  return (
    <td className={className}>
      <CellAction dropdownItems={value} />
    </td>
  );
}

export default function Cell(props: Props): ReactElement | null {
  const { type, value, className = '' } = props;

  switch (type) {
    case 'custom':
      return renderSimpleCell(value, className);
    case 'action':
      return renderCellAction(value, className);
    default:
      throw new Error('Type does not exist');
  }
}
