import cn from 'classnames';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './AddStudentModal.module.scss';

import { AppButton, AppModal } from '@/components/app';
import { StudentForm } from '../';
import { FormFields as StudentFormFields } from '../StudentForm';
import { SchoolsService } from '@/services';
import { deformatPhone } from '@/utils/formatters';
import { notify } from '@/utils/notifications';
import { UserIcon } from '@/components/icons';
import { ServerValidationError } from '@/api/exceptions';
import wellKnownErrors from '@/api/wellKnownErrors';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onActionSuccess(): void;
};

export default function AddStudentModal(props: Props) {
  const { isOpen, onActionSuccess, onClose } = props;
  const { classId } = useParams();

  //
  // State
  //

  const [loading, setLoading] = useState<boolean>(false);

  //
  // Methods
  //

  const handleSubmit = async (values: StudentFormFields) => {
    if (!classId || Array.isArray(classId)) return;

    const { firstName, lastName, middleName, parentPhone } = values;
    const phonePurified = deformatPhone(parentPhone);

    try {
      setLoading(true);
      await SchoolsService.createStudent(classId, firstName, lastName, middleName, phonePurified);
      notify('Ученик успешно добавлен', 'success', <UserIcon />);
      onActionSuccess();
    } catch (error) {
      if (error instanceof ServerValidationError) {
        if (error.code === wellKnownErrors.schemaError) notify(error.message, 'error', <UserIcon />);
        if (error.code === wellKnownErrors.parentExist) notify(error.message, 'error', <UserIcon />);
      }

      throw error;
    } finally {
      setLoading(false);
    }
  };

  //
  // Render
  //

  const renderActions = (values: StudentFormFields) => (
    <section className={styles.actions}>
      <AppButton
        className={styles.actionBtn}
        disabled={loading || !values.firstName || !values.lastName || !values.parentPhone}
        type="submit"
      >
        Добавить
      </AppButton>
      <AppButton
        className={cn(styles.actionBtn, styles.closeBtn)}
        disabled={loading}
        onClick={onClose}
        outline
      >
        Назад
      </AppButton>
    </section>
  );

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <section className={styles.host}>
        <h2>Добавление нового ученика</h2>
        <p>
          Для добавления нового ученика, пожалуйста
          <br />
          заполните поля:
        </p>

        <StudentForm
          actionsSection={renderActions}
          onSubmit={handleSubmit}
        />
      </section>
    </AppModal>
  );
}
