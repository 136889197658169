import { ProfileApi } from '@/api/sources';
import { User } from '@/models/entity';

export default class ProfileService {
  public static async getMe(): Promise<User> {
    const dto = await ProfileApi.fetchCurrentUser();
    const user = new User({
      firstName: dto.first_name,
      id: dto.id,
      lastName: dto.last_name,
      roles: dto.roles,
    });

    return user
  }
}
