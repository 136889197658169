/* eslint-disable camelcase */
import { httpClient } from '@/api/httpClient';
import { MarketingCampaignDto, WriteOffStoreDto } from '@/models/dto';

const IikoApi = {
  async fetchWriteOffStores(): Promise<WriteOffStoreDto[]> {
    const { data } = await httpClient.get('/api/v01/iiko/writeoff_stores/');

    return data;
  },

  async fetchMarketingCampaigns(): Promise<MarketingCampaignDto[]> {
    const { data } = await httpClient.get('/api/v01/iiko/marketing_campaigns/');

    return data;
  },
};

export default IikoApi;
