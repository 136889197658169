import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { AuthService, TokenStorageService } from '@/services';
import {
  GatewayTimeout,
  NetworkError,
  ServerError,
  ServerUnavailable,
  ServerValidationError,
  InternalServerError,
  NotFoundError,
} from './exceptions';
import { Sentry } from '@/plugins/sentry';
import { notify } from '@/utils/notifications';
import { WifiIcon } from '@/components/icons';
import { Paths } from '@/routes';

function isAbsoluteURL(url: string) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(url);
}

/**
 * Set Authorization header from storage.
 */
export function requestInterceptor(config: AxiosRequestConfig) {
  if (AuthService.isAuthenticated && !isAbsoluteURL(config.url ?? '') && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Token ${TokenStorageService.getAuthToken()}`;
  }

  return config;
}

export function responseInterceptorSuccess(success: any) {
  return success;
}

export function responseInterceptorError(error: AxiosError) {
  const { response } = error;

  if (axios.isCancel(error)) return Promise.reject(new Error('CANCELED'));

  if (!response) return Promise.reject(new NetworkError(error.message));

  let customError;

  if (!window.navigator.onLine) {
    notify('Проверьте подключение к интернету', 'error', <WifiIcon />);
    throw error;
  }

  switch (response.status) {
    case 401:
      if (!window.location.href.includes(Paths.auth)) window.location.replace(Paths.auth);
      AuthService.logout();
      break;
    case 404:
      customError = new NotFoundError();
      break;
    case 400:
    case 403:
    case 422:
      const { data } = response as any;
      let errorMessage = '';
      const errorCode = data?.error_code ?? '';

      if (typeof data.message === 'string') errorMessage = data.message;
      if (typeof data.message === 'object' && data.message.errors && Array.isArray(data.message.errors))
        errorMessage = data.message.errors[0];

      customError = data?.error_code
        ? new ServerValidationError({ code: errorCode, message: errorMessage, fields: data.errors })
        : new ServerError(response);
      break;
    case 500:
      notify('Проверьте подключение к интернету', 'error', <WifiIcon />);
      Sentry.captureException(new InternalServerError(response), {
        tags: { statusCode: response.status },
      });
      customError = new InternalServerError(response);
      break;
    case 502:
    case 503:
      notify('Проверьте подключение к интернету', 'error', <WifiIcon />);
      Sentry.captureException(new ServerUnavailable(), {
        tags: { statusCode: response.status },
      });
      customError = new ServerUnavailable();
      break;
    case 504:
      notify('Проверьте подключение к интернету', 'error', <WifiIcon />);
      Sentry.captureException(new GatewayTimeout(), {
        tags: { statusCode: response.status },
      });
      customError = new GatewayTimeout();
      break;
    default:
      customError = new ServerError(response);
  }

  return Promise.reject(customError);
}
