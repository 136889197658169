import { AuthApi } from '@/api/sources';
import TokenStorageService from './TokenStorageService';

export default class AuthService {
  public static get isAuthenticated(): boolean {
    return !!TokenStorageService.getAuthToken();
  }

  public static async sendCode(phone: string): Promise<void> {
    return await AuthApi.sendCode(phone);
  }

  public static async authComplete(phone: string, code: string): Promise<void> {
    const { token } = await AuthApi.authComplete(phone, code);
    TokenStorageService.set(token);
  }

  public static async logout(): Promise<void> {
    TokenStorageService.remove();
  }
}
