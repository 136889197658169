/* eslint-disable jsx-a11y/alt-text */
import cn from 'classnames';
import { ReactElement, useState } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import Cropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';
import styles from './AppImageCropper.module.scss';
import dropIcon from '@/assets/img/icons/dropIcon.svg';

import { notify } from '@/utils/notifications';
import AppModal from '../AppModal';
import AppButton from '../AppButton';
import { CloseIcon } from '@/components/icons';

type Props = {
  value: string | null;
  placeholder?: string;
  fileAccept?: string;
  className?: string;
  onFileAccept?: (files: File[], event: DropEvent) => void;
  onFileReject?: (fileRejections: FileRejection[], event: DropEvent) => void;
  onChange: (image: string | null) => void;
  onClear?: () => void;
  aspectRatio?: number;
  dropzoneIcon?: ReactElement;
};

export default function AppImageCropper(props: Props) {
  const {
    fileAccept = 'image/*',
    onFileAccept,
    onFileReject,
    value,
    placeholder,
    onChange,
    className = '',
    aspectRatio = 1,
    dropzoneIcon,
    onClear = () => {},
  } = props;

  //
  // State
  //

  const [cropperOpened, setCropperOpened] = useState<boolean>(false);
  const [tmpCropperImage, setTmpCropperImage] = useState<string | null>(null);
  const [cropperInstance, setCropperInstance] = useState<Cropper | null>(null);

  //
  // Methods
  //

  const handleFileAccept = (files: File[], e: DropEvent) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const image = event.target?.result;
      if (typeof image === 'string') setTmpCropperImage(image);
    };

    reader.readAsDataURL(files[0]);
  };

  const cropImage = () => {
    if (cropperInstance != null) {
      onChange(cropperInstance.getCroppedCanvas().toDataURL());
      setCropperOpened(false);
      setTimeout(() => {
        setTmpCropperImage(null);
      }, 600);
    }
  };

  //
  // Render
  //

  const renderDropzoneIcon = dropzoneIcon || <img alt="Иконка дропзоны" src={dropIcon} />;

  return (
    <>
      <Dropzone
        accept={fileAccept}
        multiple={false}
        onDropAccepted={(files: File[], event: DropEvent) => {
          setCropperOpened(true);
          handleFileAccept(files, event);
          if (onFileAccept) onFileAccept(files, event);
        }}
        onDropRejected={(fileRejections: FileRejection[], event: DropEvent) => {
          notify('Не удалось загрузить изображение', 'error');
          if (onFileReject) onFileReject(fileRejections, event);
        }}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
          <div className={className} {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              className={cn(styles.dropZoneContent, 'dropzone-wrapper', {
                [styles.dragAccepted]: isDragAccept,
                [styles.dragRejected]: isDragReject,
              })}
            >
              {value ? (
                <div className={styles.previewContainer}>
                  <CloseIcon
                    className={cn(styles.clearIcon, 'AppImageCropper__clearIcon')}
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(null);
                      onClear();
                    }}
                  />
                  <img className={styles.previewImage} src={value} />
                </div>
              ) : (
                <>
                  {renderDropzoneIcon}
                  <span>{placeholder}</span>
                </>
              )}
            </div>
          </div>
        )}
      </Dropzone>

      <AppModal
        className={styles.cropper}
        isOpen={cropperOpened}
        onClose={() => setCropperOpened(false)}
        rootClassName={styles.cropperModal}
      >
        <h2>Выбор области изображения акции</h2>

        <div>
          <Cropper
            aspectRatio={aspectRatio}
            autoCropArea={1}
            background={false}
            checkOrientation={false}
            guides={true}
            initialAspectRatio={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            onInitialized={(instance) => {
              setCropperInstance(instance);
            }}
            preview=".img-preview"
            responsive={true}
            src={tmpCropperImage || undefined}
            style={{ maxHeight: 600, width: '100%' }}
            viewMode={1}
            zoomTo={0.5}
          />

          <div className={styles.actions}>
            <AppButton className={styles.cropBtn} onClick={cropImage}>
              Готово
            </AppButton>
          </div>
        </div>
      </AppModal>
    </>
  );
}
