import { useEffect, useMemo, useState } from 'react';
import { isBefore } from 'date-fns';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import cn from 'classnames';

import styles from './EditRequestModal.module.scss';
import defaultUserAvatar from '@/assets/img/defaultUserAvatar.svg';

import { AppButton, AppModal } from '@/components/app';
import { KitchenRequest } from '@/models/entity';
import { QuestionMarkIcon } from '@/components/icons';
import { getCssCustomProperty } from '@/utils/cssCustomProperties';
import { MealSubscription } from '@/constants/mealSubscription';

type Props = {
  open: boolean;
  onClose(): void;
  onSubmit(students: string[]): void;
  kitchenRequest: KitchenRequest | null;
  loading: boolean;
};

export default function EditRequestModal(props: Props) {
  const { kitchenRequest, onClose, onSubmit, open, loading } = props;

  //
  // State
  //

  const [contentDisabled, setContentDisabled] = useState<boolean>(true);
  const [studentsExclude, setStudentsExclude] = useState<string[]>([]);

  //
  // Computed
  //

  const resultStudents = (kitchenRequest?.items || []).filter((cs) => !studentsExclude.includes(cs.pk));
  const isAvailableToEdit = useMemo(() => {
    if (!kitchenRequest?.deadlineDateTime) return false;

    return isBefore(new Date(), kitchenRequest.deadlineDateTime);
  }, [kitchenRequest]);

  //
  // Methods
  //

  const handleMakeRequest = () => {
    onSubmit(resultStudents.map((rs) => rs.pk));
  };

  //
  // Effects
  //

  useEffect(() => {
    if (!open) {
      setContentDisabled(true);
      setStudentsExclude([]);
    } else if (kitchenRequest) {
      setStudentsExclude(kitchenRequest.items.filter((i) => !i.childAttend).map((i) => i.pk));
    }
  }, [kitchenRequest, open]);

  //
  // Render
  //

  const renderCheckbox = (state: 'x-mark' | 'minus' | 'empty' = 'empty') => {
    let icon = null;

    switch (state) {
      case 'x-mark':
        icon = (
          <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="21"
              rx="3.16667"
              stroke={getCssCustomProperty('--primary-color')}
              width="21"
              x="0.5"
              y="0.5"
            />
            <path
              clipRule="evenodd"
              d="M15.6118 16.8543C15.807 17.0496 16.1236 17.0496 16.3189 16.8543L16.8532 16.3199C17.0484 16.1247 17.0484 15.8081 16.8532 15.6129L12.5951 11.3543C12.3999 11.1591 12.3999 10.8425 12.5951 10.6473L16.8534 6.38858C17.0486 6.19332 17.0486 5.87677 16.8534 5.68151L16.3191 5.14715C16.1238 4.95186 15.8072 4.95186 15.6119 5.14715L11.3538 9.40569C11.1585 9.60098 10.8419 9.60098 10.6467 9.40569L6.38788 5.14646C6.19261 4.95118 5.876 4.95118 5.68074 5.14646L5.14643 5.68082C4.95119 5.87608 4.95119 6.19264 5.14643 6.3879L9.40536 10.6473C9.6006 10.8425 9.6006 11.1591 9.40536 11.3543L5.14659 15.6135C4.95136 15.8088 4.95136 16.1254 5.14659 16.3206L5.6809 16.855C5.87617 17.0503 6.19278 17.0503 6.38804 16.855L10.6467 12.5959C10.8419 12.4006 11.1585 12.4006 11.3538 12.5959L15.6118 16.8543Z"
              fill={getCssCustomProperty('--primary-color')}
              fillRule="evenodd"
            />
          </svg>
        );
        break;

      case 'minus':
        icon = (
          <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
            <rect
              height="21"
              rx="3.16667"
              stroke={getCssCustomProperty('--primary-color')}
              width="21"
              x="0.5"
              y="0.5"
            />
            <rect fill={getCssCustomProperty('--primary-color')} height="2" rx="1" width="14" x="4" y="10" />
          </svg>
        );
        break;

      default:
        icon = (
          <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
            <rect height="21" rx="3.16667" stroke="black" width="21" x="0.5" y="0.5" />
          </svg>
        );
        break;
    }

    return <div className={styles.checkbox}>{icon}</div>;
  };

  return (
    <AppModal isOpen={open} onClose={onClose}>
      <section className={styles.host}>
        <div
          className={cn(styles.content, { [styles.contentDisabled]: contentDisabled })}
          style={!isAvailableToEdit ? { paddingBottom: 0 } : {}}
        >
          <h2>Класс {kitchenRequest?.schoolClass?.name}</h2>

          <section className={styles.counters}>
            <div className={styles.counter}>
              <p>
                Всего <br /> учеников
              </p>
              <span>{kitchenRequest?.items.length || 0}</span>
            </div>
            <div className={styles.counter}>
              <p>
                Отсутствует <br /> учеников
              </p>
              <span>{studentsExclude.length}</span>
            </div>
          </section>

          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div>ФИ ученика</div>
              <div>
                Питание
                <UncontrolledDropdown className={styles.mealBadgesLegendDropdown}>
                  <DropdownToggle caret>
                    <QuestionMarkIcon />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <div className={styles.mealBadgesLegend}>
                      <div className={styles.mealBadgesLegendHeader}>
                        Питание <QuestionMarkIcon />
                      </div>

                      <div className={styles.mealBadgesLegendItem}>
                        <span className={cn(styles.mealBadge, styles.breakfastMealBadge)} /> Завтрак
                      </div>
                      <div className={styles.mealBadgesLegendItem}>
                        <span className={cn(styles.mealBadge, styles.lunchMealBadge)} /> Обед
                      </div>
                      <div className={styles.mealBadgesLegendItem}>
                        <span className={cn(styles.mealBadge, styles.afternoonSnackMealBadge)} /> Полдник
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div
                className={styles.checkboxHeaderCell}
                onClick={() => {
                  if (contentDisabled) return;

                  if (studentsExclude.length > 0) setStudentsExclude([]);
                  else setStudentsExclude((kitchenRequest?.items || []).map((cs) => cs.pk));
                }}
              >
                {renderCheckbox(studentsExclude.length > 0 ? 'minus' : 'empty')}
              </div>
            </div>

            <div className={styles.body}>
              {kitchenRequest?.items.map((cs) => (
                <div
                  className={cn(styles.bodyItem, { [styles.excludeBodyItem]: studentsExclude.includes(cs.pk) })}
                  key={cs.pk}
                  onClick={() => {
                    if (contentDisabled) return;

                    if (studentsExclude.includes(cs.pk))
                      setStudentsExclude(studentsExclude.filter((se) => se !== cs.pk));
                    else setStudentsExclude([...studentsExclude, cs.pk]);
                  }}
                >
                  <div>
                    <img alt={cs.FI} src={cs.photo || defaultUserAvatar} />
                    <h3>{cs.FI}</h3>
                  </div>

                  <div className={styles.mealBadges}>
                    {cs.nutritionPlansPositions.find((npp) => npp.kind === MealSubscription.breakfast) ? (
                      <span className={cn(styles.mealBadge, styles.breakfastMealBadge)} />
                    ) : (
                      <span className={styles.mealBadge} />
                    )}
                    {cs.nutritionPlansPositions.find((npp) => npp.kind === MealSubscription.lunch) ? (
                      <span className={cn(styles.mealBadge, styles.lunchMealBadge)} />
                    ) : (
                      <span className={styles.mealBadge} />
                    )}
                    {cs.nutritionPlansPositions.find((npp) => npp.kind === MealSubscription.afternoonSnack) ? (
                      <span className={cn(styles.mealBadge, styles.afternoonSnackMealBadge)} />
                    ) : (
                      <span className={styles.mealBadge} />
                    )}
                  </div>

                  <div>{renderCheckbox(studentsExclude.includes(cs.pk) ? 'x-mark' : 'empty')}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {isAvailableToEdit && (
          <div className={styles.footer}>
            {contentDisabled ? (
              <AppButton onClick={() => setContentDisabled(false)}>Редактировать заявку</AppButton>
            ) : (
              <AppButton disabled={loading} onClick={() => handleMakeRequest()}>
                Подать заявку
              </AppButton>
            )}
          </div>
        )}
      </section>
    </AppModal>
  );
}
