import { DebounceInput } from 'react-debounce-input';

import style from './AppSearch.module.scss';

import { AppRefreshButton } from './components';
import { IAppSearchProps } from '@/components/app/AppSearch/AppSearch.type';


export default function AppSearch(props: IAppSearchProps) {
  const {
    onChange,
    placeholder = 'Поиск...',
    value,
    withRefreshButton = true,
    onClickRefreshButton = () => {},
  } = props;

  //
  // Render
  //

  return (
    <div className={style.host}>
      <DebounceInput
        className="form-control"
        debounceTimeout={420}
        onChange={(event) => onChange(event?.target?.value)}
        placeholder={placeholder}
        value={value}
      />
      {withRefreshButton &&
              <AppRefreshButton
                className={style.refreshBtn}
                onClick={onClickRefreshButton}
              />
      }
    </div>
  );
}
