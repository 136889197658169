import { RefObject, useEffect, useState } from 'react';

const useIsOnScrollEdge = (
  ref: RefObject<HTMLElement> | HTMLElement | null,
  topOffset: number = 0,
  bottomOffset: number = 0,
  leftOffset: number = 0,
  rightOffset: number = 0,
  recountTriggerValue?: any,
): { isOnBottom: boolean; isOnTop: boolean; isOnLeft: boolean; isOnRight: boolean } => {
  const [isOnBottom, setIsOnBottom] = useState(false);
  const [isOnTop, setIsOnTop] = useState(true);
  const [isOnLeft, setIsOnLeft] = useState(false);
  const [isOnRight, setIsOnRight] = useState(false);

  const handleScroll = (element: HTMLElement | null) => {
    if (element) {
      const { scrollTop, scrollHeight, offsetHeight, scrollLeft, scrollWidth, offsetWidth } = element;
      const contentHeight = scrollHeight - offsetHeight;

      setIsOnTop(scrollTop <= topOffset);
      setIsOnBottom(scrollTop + bottomOffset >= contentHeight);
      setIsOnLeft(scrollLeft <= leftOffset);
      setIsOnRight(scrollLeft + offsetWidth + rightOffset >= scrollWidth - 1);
    }
  };

  useEffect(() => {
    let observerRefValue: HTMLElement | null = null;
    const refElement = ref instanceof HTMLElement ? ref : ref?.current;

    if (refElement) {
      observerRefValue = refElement;
      requestAnimationFrame(() => handleScroll(observerRefValue));
      refElement.addEventListener('scroll', () => handleScroll(observerRefValue));
    }

    return () => {
      if (observerRefValue) observerRefValue.removeEventListener('scroll', () => handleScroll(observerRefValue));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, recountTriggerValue]);

  return { isOnBottom, isOnTop, isOnLeft, isOnRight };
};

export default useIsOnScrollEdge;
