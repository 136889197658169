import styles from './SubsParentsPage.module.scss';

import { SubsParentsTable } from './components';
import { PageTitle } from '@/layout/DashboardLayout/components';
import { useDocumentTitle } from '@/hooks';
import { SubscribersBaseIcon } from '@/components/icons';

export default function ParentsPage() {
  //
  // Effects
  //

  useDocumentTitle('Родители');

  //
  // Render
  //

  return (
    <>
      <PageTitle heading="Родители" svg={<SubscribersBaseIcon />} />

      <section className={styles.host}>
        <SubsParentsTable />
      </section>
    </>
  );
}
