import { getCssCustomProperty } from '@/utils/cssCustomProperties';

export default function TeacherIcon() {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <rect fill={getCssCustomProperty('--primary-color')} height="24" rx="4" width="24" />
      <path
        d="M17.7 16.1C17.4 15.7 16.9 15.3 16.4 15.1V8.7C16.4 7.5 15.9 6.3 15 5.4C14.1 4.5 13 4 11.7 4C10.5 4 9.3 4.5 8.4 5.4C7.7 6.3 7.2 7.4 7.2 8.7V15.1C6.7 15.4 6.3 15.7 5.9 16.1C5.3 16.8 5 17.7 5 18.7V19.8C5 20 5.2 20.2 5.4 20.2H18.2C18.4 20.2 18.6 20 18.6 19.8V18.7C18.6 17.8 18.3 16.9 17.7 16.1ZM12.8 17.3L13.8 15.1L14.2 15.2L14.5 16L12.8 17.3ZM11.8 14.1C12.2 14.1 12.5 14 12.9 13.8L13.1 14.6L11.8 17.5L10.5 14.6L10.7 13.8C11.1 14 11.5 14.1 11.8 14.1ZM9.2 8.6C9.6 8.6 10.1 8.5 10.5 8.4C10.9 8.3 11.3 8.1 11.6 7.9C11.8 8 11.9 8.1 12.1 8.2C12.7 8.6 13.5 8.8 14.4 8.8V10.7C14.4 11.4 14 12 13.6 12.4C13.1 12.9 12.4 13.3 11.8 13.3C11.3 13.3 10.6 13 10 12.4C9.7 12.1 9.2 11.5 9.2 10.8V8.6ZM9.2 16L9.5 15.2L9.9 15.1L10.9 17.3L9.2 16ZM15.7 14.8C15.6 14.8 15.4 14.7 15.3 14.7L13.8 14.3L13.6 13.4C13.8 13.3 13.9 13.1 14.1 13C14.8 12.4 15.1 11.6 15.1 10.8V8.5C15.1 8.3 14.9 8.1 14.7 8.1C13.8 8.1 13.1 7.9 12.5 7.6C11.9 7.3 11.5 6.8 11.4 6.3C11.3 6 11.1 5.9 10.9 6C10.8 6 10.7 6 10.7 6.1C10.6 6.2 10.6 6.3 10.7 6.4C10.7 6.7 10.9 7 11.1 7.3C10.8 7.5 10.6 7.6 10.3 7.6C9.9 7.8 9.4 7.8 8.9 7.8C8.7 7.8 8.5 8 8.5 8.2V10.8C8.5 11.6 8.9 12.4 9.5 13C9.7 13.2 9.8 13.3 10 13.4L9.8 14.3L8.3 14.7C8.2 14.7 8 14.8 7.9 14.8V8.7C7.9 7.7 8.3 6.7 9 6C9.7 5.3 10.7 4.9 11.7 4.9C12.7 4.9 13.7 5.3 14.4 6C15.1 6.7 15.5 7.7 15.5 8.7V14.8H15.7ZM8.6 15.4L8.4 16C8.3 16.2 8.4 16.3 8.5 16.4L11.5 18.7C11.5 18.7 11.5 18.7 11.6 18.7C11.7 18.7 11.8 18.7 11.9 18.7L15 16.3C15.1 16.2 15.2 16 15.1 15.9L14.9 15.3H15C15.8 15.5 16.5 15.9 17 16.5C17.5 17.1 17.7 17.8 17.7 18.6V19.4H5.7V18.6C5.7 17.8 6 17 6.4 16.5C7 16 7.7 15.6 8.6 15.4Z"
        fill="white"
      />
    </svg>
  );
}
