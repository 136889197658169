import { DateRange } from 'rsuite/esm/DateRangePicker';
import { saveAs } from 'file-saver';

import { ReportsApi } from '@/api/sources';
import { ClassesValue } from '@/constants/reports';

export default class ReportsService {
  public static async makeAttendanceReport(
    dateRange: DateRange,
    schoolId: string,
    classes: ClassesValue,
  ): Promise<void> {
    const { file, name } = await ReportsApi.makeAttendanceReport(dateRange, schoolId, classes);

    saveAs(file, name);
  }

  public static async makeAccountancyReport(
    dateRange: DateRange,
    schoolId: string,
    classes: ClassesValue,
  ): Promise<void> {
    const { file, name } = await ReportsApi.makeAccountancyReport(dateRange, schoolId, classes);

    saveAs(file, name);
  }

  public static async makeNutritionReport(
    dateRange: DateRange,
    schoolId: string,
    classes: ClassesValue,
  ): Promise<void> {
    const { file, name } = await ReportsApi.makeNutritionReport(dateRange, schoolId, classes);

    saveAs(file, name);
  }
}
