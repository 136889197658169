import { IikoApi } from '@/api/sources';
import { MarketingCampaign, WriteOffStore } from '@/models/entity';

export default class IikoService {
  public static async getWriteOffStores(): Promise<WriteOffStore[]> {
    const dtos = await IikoApi.fetchWriteOffStores();
    const writeoffStores = dtos.map(
      (dto) =>
        new WriteOffStore({
          id: dto.id.toString(),
          iikoId: dto.iiko_id,
          name: dto.name,
        }),
    );

    return writeoffStores;
  }

  public static async getMarketingCampaigns(): Promise<MarketingCampaign[]> {
    const dtos = await IikoApi.fetchMarketingCampaigns();
    const marketingCampaign = dtos.map(
      (dto) =>
        new MarketingCampaign({
          id: dto.id.toString(),
          discountValue: dto.discount_value,
          name: dto.name,
        }),
    );

    return marketingCampaign;
  }
}
