type Props = {
  className?: string
};

export default function SyncIcon(props: Props) {
  const { className } = props

  return (
    <svg
      className={className}
      fill="none"
      height="19"
      viewBox="0 0 15 19"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 4.31818V6.90909L11 3.45455L7.5 0V2.59091C3.6325 2.59091 0.5 5.68273 0.5 9.5C0.5 10.8559 0.9025 12.1168 1.585 13.1791L2.8625 11.9182C2.46875 11.2014 2.25 10.3723 2.25 9.5C2.25 6.64136 4.60375 4.31818 7.5 4.31818ZM13.415 5.82091L12.1375 7.08182C12.5225 7.80727 12.75 8.62773 12.75 9.5C12.75 12.3586 10.3962 14.6818 7.5 14.6818V12.0909L4 15.5455L7.5 19V16.4091C11.3675 16.4091 14.5 13.3173 14.5 9.5C14.5 8.14409 14.0975 6.88318 13.415 5.82091Z"
        fill="black"
      />
    </svg>
  );
}
