import { useState, ReactElement } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import styles from './CellAction.module.scss';

import { ActionIcon } from '@/components/icons';

interface Props {
  dropdownItems: ReactElement[] | string[];
}

const CellAction = (props: Props) => {
  const { dropdownItems } = props;

  const [actionsOpen, setActionsOpen] = useState(false);
  const toggle = () => setActionsOpen((prevState) => !prevState);

  return (
    <Dropdown
      className={styles.host}
      isOpen={actionsOpen}
      toggle={toggle}
    >
      <DropdownToggle
        className="btn-icon btn-icon-only btn btn-link dropdown-toggle w-100 he-100 cursor-pointer"
        tag="a"
      >
        <ActionIcon />
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu-xl rm-pointers"
        positionFixed
        right
      >
        {dropdownItems}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CellAction;
