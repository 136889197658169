import { ReactElement, ReactNode } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import cn from 'classnames';

import styles from './AppButtonGroup.module.scss';

type Props = {
  onClick?(): void;
  disabled?: boolean;
  children: ReactNode;
  leftSlotColor?: 'default' | 'primary' | 'primary-light' | 'success' | 'info' | 'warning' | 'danger' | 'link';
  rightSlotColor?: 'default' | 'primary' | 'primary-light' | 'success' | 'info' | 'warning' | 'danger' | 'link';
  className?: string;
  leftSlot: ReactElement;
};

export default function AppButtonGroup(props: Props) {
  const {
    leftSlot,
    onClick = () => {},
    children,
    leftSlotColor = 'primary',
    rightSlotColor = 'primary-light',
    className = '',
    disabled = false,
  } = props;

  return (
    <ButtonGroup
      className={cn(styles.host, className)}
      onClick={onClick}
    >
      <Button
        color={leftSlotColor}
        disabled={disabled}
        type="button"
      >
        {leftSlot}
      </Button>
      <Button
        color={rightSlotColor}
        disabled={disabled}
        type="button"
      >
        {children}
      </Button>
    </ButtonGroup>
  );
}
