import styles from './TeacherDeleteModal.module.scss';

import { AppButton, AppModal } from '@/components/app';

type Props = {
  open: boolean;
  onClose(): void;
  handleSubmit(): void;
  loading: boolean;
};

export default function TeacherDeleteModal(props: Props) {
  const { onClose, open, handleSubmit, loading } = props;

  return (
    <AppModal
      className={styles.modal}
      isOpen={open}
      onClose={onClose}
    >
      <section className={styles.host}>
        <h2>
          Вы уверены, что хотите <br /> удалить учителя?
        </h2>

        <section className={styles.actions}>
          <AppButton
            className={styles.actionBtn}
            disabled={loading}
            onClick={onClose}
            outline
          >
            Отмена
          </AppButton>
          <AppButton
            className={styles.actionBtn}
            disabled={loading}
            onClick={handleSubmit}
          >
            Удалить
          </AppButton>
        </section>
      </section>
    </AppModal>
  );
}
