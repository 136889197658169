/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import { httpClient } from '@/api/httpClient';
import { UserDto } from '@/models/dto';

const AuthApi = {
  async fetchCurrentUser(): Promise<UserDto> {
    const { data } = await httpClient.get<never, AxiosResponse<UserDto>>('/api/v01/user/');

    return data;
  },
};

export default AuthApi;
