import IFlavor from '../IFlavor';

export default class ShkolnoePitanie implements IFlavor {
  // Assets
  logoBig = '/apps/shkolnoePitanie/logoBig.svg';
  logoShort = '/apps/shkolnoePitanie/logoShort.svg';
  patternBg = '/apps/shkolnoePitanie/patternBg.svg';
  favicon = '/apps/shkolnoePitanie/favicon.ico';

  // Common
  primaryColor = '#FEC425';

  // Buttons
  buttonPrimaryBgColor = '#FEC425';
  buttonPrimaryTextColor = '#000000';
  buttonPrimaryHoverBgColor = '#fcb901';
  buttonPrimaryLightenBgColor = '#FFD86E';
  buttonLinkTextColor = '#FEC425';

  // Table
  tableInvalidRowBgColor = '#FFFCF3';

  // Tile tabs
  tileTabsInactiveBgColor = '#F9F6EE';
  tileTabsHoverBgColor = '#f8e4ac';
  tileTabsInactiveTextColor = '#000';

  // Sidebar
  sidebarActiveItemBgColor = '#F9F6EE';

  // Controls
  checkboxActiveBgImage = '/apps/shkolnoePitanie/checkboxActive.svg';

  // Calendar
  calendarSelectedCellBgColor = '#FEC425';
  calendarSelectedCellTextColor = '#000';
  calendarRangeColor = '#ffedbe';
}
