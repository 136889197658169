import styles from './SubsStudentsPage.module.scss';

import { SubStudentsTable } from './components';
import { PageTitle } from '@/layout/DashboardLayout/components';
import { useDocumentTitle } from '@/hooks';
import { SubscribersBaseIcon } from '@/components/icons';

export default function SubsStudentsPage() {
  //
  // Effects
  //

  useDocumentTitle('Ученики');

  //
  // Render
  //

  return (
    <>
      <PageTitle heading="Ученики" svg={<SubscribersBaseIcon />} />

      <section className={styles.host}>
        <SubStudentsTable />
      </section>
    </>
  );
}
