/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import { httpClient } from '@/api/httpClient';
import {
  ClassNutritionPlanDto,
  ImportStudentDto,
  KitchenRequestDto,
  OrderListItemDto,
  PaidNutritionDto,
  ParentListItemDto,
  SchoolChildDto,
  SchoolClassDto,
  SchoolDto,
  StudentDto,
  StudentListItemDto,
  StudentTransactionDto,
  SubTeacherListItemDto,
  TeacherDto,
  TeacherListItemDto,
  TransactionDto,
} from '@/models/dto';
import { deformatPhone } from '@/utils/formatters';
import { freeDateFormat } from '@/utils/date';
import { getFileNameByContentDisposition } from '@/utils/files';
import { ClassNutritionPlan, PaidNutrition } from '@/models/entity';

const AuthApi = {
  async fetchSchools(): Promise<SchoolDto[]> {
    const { data } = await httpClient.get<never, AxiosResponse<SchoolDto[]>>('/api/v01/schools/');

    return data;
  },

  async fetchSchoolClasses(schoolId: string): Promise<SchoolClassDto[]> {
    const { data } = await httpClient.get<never, AxiosResponse<SchoolClassDto[]>>(
      `/api/v01/school/${schoolId}/classes/`,
    );

    return data;
  },

  async fetchClassStudents(classId: string): Promise<SchoolChildDto> {
    const { data } = await httpClient.get<never, AxiosResponse<SchoolChildDto>>(
      `/api/v01/school-class/${classId}/child/`,
    );

    return data;
  },

  async fetchStudentById(studentId: string): Promise<StudentDto> {
    const { data } = await httpClient.get<never, AxiosResponse<StudentDto>>(`/api/v01/children/${studentId}/`);

    return data;
  },

  async fetchStudentOrders(
    studentId: string,
    query: {
      currentPage: number;
      itemsPerPage: number;
      orderType: string | string[] | undefined;
      dateRange: string | string[] | undefined;
    },
  ): Promise<{ data: OrderListItemDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.orderType && !Array.isArray(query.orderType)) urlSearchParams.set('kind', query.orderType);
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[0])
      urlSearchParams.set('date_start', freeDateFormat(new Date(query.dateRange[0]), 'yyyy-MM-dd') || '');
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[1])
      urlSearchParams.set('date_end', freeDateFormat(new Date(query.dateRange[1]), 'yyyy-MM-dd') || '');

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/iiko/parent/children/${studentId}/purchases/?${urlSearchParams.toString()}`,
    );

    return { data: data, count: data.length };
  },

  async fetchSchoolTeachers(schoolId: string): Promise<TeacherDto[]> {
    const { data } = await httpClient.get<never, AxiosResponse<TeacherDto[]>>(`/api/v01/school/${schoolId}/teacher/`);

    return data;
  },

  async createSchool(
    marketingCampaign: string,
    iikoFrontCount: number,
    mealDaily: '5' | '6' | undefined,
    name: string,
    phone: string,
    warehouse: string,
    nameEmployee: string,
    surnameEmployee: string,
    lastNameEmployee: string,
  ): Promise<SchoolDto> {
    const { data } = await httpClient.post<never, AxiosResponse<SchoolDto>>('/api/v01/schools/', {
      title: name,
      teacher_marketing_campaign: marketingCampaign,
      iiko_front_number: iikoFrontCount,
      week_dishes_plan: mealDaily,
      report_writeoff_store: warehouse,
      employee:
        !phone && !nameEmployee && !lastNameEmployee && !surnameEmployee
          ? undefined
          : {
            phone: phone,
            first_name: nameEmployee,
            middle_name: lastNameEmployee,
            last_name: surnameEmployee,
          },
    });

    return data;
  },

  async createClass(
    schoolId: string,
    number: string,
    liter: string,
    mealRequestTime: string,
    mealRequestDeadlineTime: string,
  ): Promise<SchoolClassDto> {
    const { data } = await httpClient.post<never, AxiosResponse<SchoolClassDto>>('/api/v01/school-class/', {
      number: parseInt(number, 10),
      school: parseInt(schoolId, 10),
      liter,
      time_submitting: mealRequestDeadlineTime,
      time_publication: mealRequestTime,
      children: [],
    });

    return data;
  },

  async updateClass(
    classId: string,
    schoolId: string,
    number: string,
    liter: string,
    mealRequestTime: string,
    mealRequestDeadlineTime: string,
  ): Promise<SchoolClassDto> {
    const { data } = await httpClient.patch<never, AxiosResponse<SchoolClassDto>>(`/api/v01/school-class/${classId}/`, {
      number,
      liter,
      school: parseInt(schoolId, 10),
      time_submitting: mealRequestDeadlineTime,
      time_publication: mealRequestTime,
    });

    return data;
  },

  async deleteClass(classId: string): Promise<void> {
    const { data } = await httpClient.delete<never, AxiosResponse<void>>(`/api/v01/school-class/${classId}/`);

    return data;
  },

  async parseClassFile(file: File): Promise<ImportStudentDto[]> {
    let formData = new FormData();
    formData.append('file', file);

    const { data } = await httpClient.post<never, AxiosResponse<ImportStudentDto[]>>(
      '/api/v01/school-class/parser/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  },

  async createStudent(
    schoolClassId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    parentPhone: string,
  ): Promise<void> {
    const { data } = await httpClient.post<never, AxiosResponse<void>>(
      `/api/v01/school-class/${schoolClassId}/child/`,
      [
        {
          first_name: firstName,
          last_name: lastName,
          middle_name: middleName,
          phone_parent: parentPhone,
        },
      ],
    );

    return data;
  },

  async createStudents(
    schoolClassId: string,
    students: { firstName: string; lastName: string; middleName: string; parentPhone: string }[],
  ): Promise<void> {
    const { data } = await httpClient.post<never, AxiosResponse<void>>(
      `/api/v01/school-class/${schoolClassId}/child/`,
      students.map((s) => ({
        first_name: s.firstName,
        last_name: s.lastName,
        middle_name: s.middleName,
        phone_parent: s.parentPhone,
      })),
    );

    return data;
  },

  async editStudent(
    studentId: string,
    schoolClassId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    parentPhone: string,
  ): Promise<void> {
    const { data } = await httpClient.patch<never, AxiosResponse<void>>(
      `/api/v01/school-class/${schoolClassId}/child/${studentId}/`,
      {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        phone_parent: parentPhone,
        id: parseInt(studentId, 10),
      },
    );

    return data;
  },

  async deleteStudent(studentId: string, schoolClassId: string): Promise<void> {
    const { data } = await httpClient.delete<never, AxiosResponse<void>>(
      `/api/v01/school-class/${schoolClassId}/child/${studentId}/`,
    );

    return data;
  },

  async fetchTransactions(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    operationType: string | string[] | undefined;
    dateRange: string | string[] | undefined;
    schoolsIds: string[];
  }): Promise<{ data: TransactionDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('initials', query.search);
    if (query.operationType && !Array.isArray(query.operationType)) urlSearchParams.set('kind', query.operationType);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[0])
      urlSearchParams.set('date_start', freeDateFormat(new Date(query.dateRange[0]), 'yyyy-MM-dd') || '');
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[1])
      urlSearchParams.set('date_end', freeDateFormat(new Date(query.dateRange[1]), 'yyyy-MM-dd') || '');
    if (query.schoolsIds) {
      query.schoolsIds.forEach((id) => {
        urlSearchParams.append('school', id);
      });
    }

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/iiko/transactions/?${urlSearchParams.toString()}`,
    );

    return { data: data.results, count: data.count };
  },

  async fetchSchoolOrders(
    schoolId: string,
    query: {
      search: string;
      currentPage: number;
      itemsPerPage: number;
      classId: string | string[] | undefined;
      orderType: string | string[] | undefined;
      dateRange: string | string[] | undefined;
    },
  ): Promise<OrderListItemDto[]> {
    const urlSearchParams = new URLSearchParams();

    if (query.classId && !Array.isArray(query.classId)) urlSearchParams.set('school_class', query.classId);
    if (query.search) urlSearchParams.set('initials', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.orderType && !Array.isArray(query.orderType)) urlSearchParams.set('kind', query.orderType);
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[0])
      urlSearchParams.set('date_start', freeDateFormat(new Date(query.dateRange[0]), 'yyyy-MM-dd') || '');
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[1])
      urlSearchParams.set('date_end', freeDateFormat(new Date(query.dateRange[1]), 'yyyy-MM-dd') || '');

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/iiko/school/${schoolId}/orders/?${urlSearchParams.toString()}`,
    );

    if (data.results) {
      return data.results;
    }

    return data;
  },

  async fetchAllOrders(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    orderType: string | string[] | undefined;
    dateRange: string | string[] | undefined;
    schoolsIds: string[];
  }): Promise<{ data: OrderListItemDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('initials', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.orderType && !Array.isArray(query.orderType)) urlSearchParams.set('kind', query.orderType);
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[0])
      urlSearchParams.set('date_start', freeDateFormat(new Date(query.dateRange[0]), 'yyyy-MM-dd') || '');
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[1])
      urlSearchParams.set('date_end', freeDateFormat(new Date(query.dateRange[1]), 'yyyy-MM-dd') || '');
    if (query.schoolsIds) {
      query.schoolsIds.forEach((id) => {
        urlSearchParams.append('school', id);
      });
    }

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/iiko/orders/?${urlSearchParams.toString()}`,
    );

    return { data: data.results, count: data.count };
  },

  async createTeacher(
    schoolId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    responsibleForClassIds: string[],
    phone: string,
  ): Promise<void> {
    const phonePurified = deformatPhone(phone);
    const { data } = await httpClient.post<never, AxiosResponse<void>>(`/api/v01/school/${schoolId}/teachers/`, {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      responsible: responsibleForClassIds,
      phone: phonePurified,
    });

    return data;
  },

  async editTeacher(
    teacherId: string,
    schoolId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    responsibleForClassIds: string[],
    phone: string,
  ): Promise<void> {
    const phonePurified = deformatPhone(phone);
    const { data } = await httpClient.put<never, AxiosResponse<void>>(
      `/api/v01/school/${schoolId}/teachers/${teacherId}`,
      {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        responsible: responsibleForClassIds,
        phone: phonePurified,
      },
    );

    return data;
  },

  async deleteTeacher(teacherId: string, schoolId: string): Promise<void> {
    const { data } = await httpClient.delete<never, AxiosResponse<void>>(
      `/api/v01/school/${schoolId}/teachers/${teacherId}`,
    );

    return data;
  },

  async fetchSubsParents(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: ParentListItemDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.schoolsIds) {
      query.schoolsIds.forEach((id) => {
        urlSearchParams.append('school', id);
      });
    }

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/school/active-users/parents/?${urlSearchParams.toString()}`,
    );

    return { data: data.results, count: data.count };
  },

  async fetchSubsStudents(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: StudentListItemDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.schoolsIds) {
      query.schoolsIds.forEach((id) => {
        urlSearchParams.append('school', id);
      });
    }

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/school/active-users/students/?${urlSearchParams.toString()}`,
    );

    return { data: data.results, count: data.count };
  },

  async fetchSubsTeachers(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: SubTeacherListItemDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.schoolsIds) {
      query.schoolsIds.forEach((id) => {
        urlSearchParams.append('school', id);
      });
    }

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/school/active-users/teachers/?${urlSearchParams.toString()}`,
    );

    return { data: data.results, count: data.count };
  },

  async fetchAllTeachers(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: TeacherListItemDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.schoolsIds) {
      query.schoolsIds.forEach((id) => {
        urlSearchParams.append('school', id);
      });
    }

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/teachers/?${urlSearchParams.toString()}`,
    );

    return { data: data.results, count: data.count };
  },

  async fetchClasses(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: SchoolClassDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.schoolsIds) {
      query.schoolsIds.forEach((id) => {
        urlSearchParams.append('school', id);
      });
    }

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/school-class/?${urlSearchParams.toString()}`,
    );

    return { data: data.results, count: data.count };
  },

  async fetchStudentTransactions(
    studentId: string,
    query: {
      currentPage: number;
      itemsPerPage: number;
      orderType: string | undefined | string[];
      dateRange: string | undefined | string[];
    },
  ): Promise<{ data: StudentTransactionDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.orderType && !Array.isArray(query.orderType)) urlSearchParams.set('kind', query.orderType);
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[0])
      urlSearchParams.set('date_start', freeDateFormat(new Date(query.dateRange[0]), 'yyyy-MM-dd') || '');
    if (query.dateRange && Array.isArray(query.dateRange) && query.dateRange[1])
      urlSearchParams.set('date_end', freeDateFormat(new Date(query.dateRange[1]), 'yyyy-MM-dd') || '');

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/iiko/parent/children/${studentId}/transactions/?${urlSearchParams.toString()}`,
    );

    return { data: data.transaction, count: data.transaction.length };
  },

  async getKitchenRequests(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    status: string | undefined;
    schoolsIds: string[];
  }): Promise<{ data: KitchenRequestDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.status) urlSearchParams.set('status', query.status);
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());
    if (query.schoolsIds) {
      query.schoolsIds.forEach((id) => {
        urlSearchParams.append('school', id);
      });
    }

    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/employee/application/?${urlSearchParams.toString()}`,
    );

    return { data: data.results, count: data.count };
  },

  async updateKitchenRequest(kitchenRequestId: string, studentsIds: string[]): Promise<void> {
    await httpClient.put<never, AxiosResponse<any>>(`/api/v01/employee/application/${kitchenRequestId}/`, {
      items: studentsIds,
    });
  },

  async makeKitchenRequest(schoolsIds: string[]): Promise<{ file: Blob; name: string }> {
    const urlSearchParams = new URLSearchParams();

    schoolsIds.forEach((id) => {
      urlSearchParams.append('school', id);
    });

    const { data, headers } = await httpClient.get<never, AxiosResponse<Blob>>(
      `/api/v01/employee/application/summary/?${urlSearchParams.toString()}`,
      { responseType: 'blob' },
    );

    return { file: data, name: getFileNameByContentDisposition(headers['content-disposition']) };
  },

  async getClassChildrenNutritionPlans(classId: string): Promise<ClassNutritionPlanDto[]> {
    const { data } = await httpClient.get<never, AxiosResponse<any>>(
      `/api/v01/school-class/${classId}/children/nutrition-subscription/`,
    );

    return data;
  },

  async updateClassNutritionPlan(classId: string, plans: ClassNutritionPlan[]): Promise<void> {
    const plansPayload = plans.map((plan) => ({
      child: plan.id,
      subscriptions: plan.nutritionSubscriptions,
    }));

    const { data } = await httpClient.put<never, AxiosResponse<any>>(
      `/api/v01/school-class/${classId}/children/nutrition-subscription/`,
      plansPayload,
    );

    return data;
  },

  async fetchPaidNutrition(): Promise<PaidNutritionDto[]> {
    const { data } = await httpClient.get<never, AxiosResponse<any>>('/api/v01/school/paid-nutrition/');

    return data;
  },

  async updatePaidNutrition(
    srcData: PaidNutrition,
    values: {
      breakfastClasses: number[];
      lunchClasses: number[];
      afternoonSnackClasses: number[];
      debtAmount: number;
    },
  ): Promise<void> {
    await httpClient.put<never, AxiosResponse<any>>(`/api/v01/school/paid-nutrition/${srcData.id}`, {
      debt_allowed: values.debtAmount,
      paid_breakfast_classes: values.breakfastClasses,
      paid_lunch_classes: values.lunchClasses,
      paid_afternoon_snack_classes: values.afternoonSnackClasses,
    });
  },

  async disablePaidNutrition(srcData: PaidNutrition): Promise<void> {
    await httpClient.post<never, AxiosResponse<any>>(`/api/v01/school/paid-nutrition/${srcData.id}/disable/`);
  },

  async enablePaidNutrition(srcData: PaidNutrition): Promise<void> {
    await httpClient.post<never, AxiosResponse<any>>(`/api/v01/school/paid-nutrition/${srcData.id}/enable/`);
  },
};

export default AuthApi;
