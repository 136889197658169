import { useEffect, useState } from 'react';

import styles from './AppInstallPage.module.scss';

import { getDeviceType } from '@/utils/getDeviceType';

export default function AppInstallPage() {
  const [haveNoApp, setHaveNoApp] = useState<boolean>(false);

  useEffect(() => {
    const { isIos, isAndroid } = getDeviceType(navigator.userAgent);
    const iOsAppUrl = process.env.REACT_APP_IOS_APP_URL;
    const androidAppUrl = process.env.REACT_APP_ANDROID_APP_URL;

    if (isIos && iOsAppUrl) window.location.href = iOsAppUrl;
    else if (isAndroid && androidAppUrl) window.location.href = androidAppUrl;
    else setHaveNoApp(true);
  }, []);

  return haveNoApp ? (
    <h2 className={styles.message}>Не найдено приложения для вашего устройства</h2>
  ) : (
    <h2 className={styles.message}>Переводим вас на страницу приложения...</h2>
  );
}
