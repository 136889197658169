import {
  format,
  intervalToDuration,
  isToday as isTodayFns,
  isValid,
  startOfDay as dateFnsStartOfDay,
  subSeconds,
} from 'date-fns';
import { ru } from 'date-fns/locale';

import { zeroPad } from './formatters';

const defaultLocale: Locale = ru;

export function formatDateToDateAndTime(dateString: string | Date) {
  const date = new Date(dateString)

  return {
    date: format(date, 'dd.MM.yyyy'),
    time: format(date, 'HH:mm'),
  }
}

export function hoursAndMinutes(date: Date | null | undefined): string | null {
  if (!date) return null;

  return format(date, 'HH:mm', { locale: defaultLocale });
}

export function dayAndMonthReadable(date: Date | null | undefined): string | null {
  if (!date) return null;

  return format(date, 'dd MMMM', { locale: defaultLocale });
}

export function dayMonthYear(date: Date | null | undefined): string {
  if (!date) return '';

  return format(date, 'dd.MM.yyyy', { locale: defaultLocale });
}

export function freeDateFormat(date: Date | null | undefined, template: string = ''): string | null {
  if (!date || !isValid(date)) return null;

  return format(date, template, { locale: defaultLocale });
}

export function timeLeft(date: Date | null): string | null {
  if (!date) return null;

  const { hours = 0, minutes = 0, seconds = 0 } = intervalToDuration({ start: new Date(), end: date });

  if (hours === 0 && minutes === 0 && seconds === 0) return '00:00';

  if (hours > 0) return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;

  return `${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
}

export const isToday = (date: Date | null): boolean => {
  if (!date) return false;

  return isTodayFns(date);
};

const oneDay = 1000 * 60 * 60 * 24;

export function getMidnight(day: Date) {
  const date = new Date(day);
  date.setMilliseconds(999);
  date.setSeconds(59);
  date.setMinutes(59);
  date.setHours(23);
  return date;
}

export function isTomorrow(date: Date | null) {
  if (!date) return false;

  const midnightTonight = getMidnight(new Date());
  const midnightTomorrow = new Date(midnightTonight.getTime() + oneDay);

  return date > midnightTonight && date < midnightTomorrow;
}

export function isDayAfterTomorrow(date: Date | null) {
  if (!date) return false;

  const midnightTomorrow = getMidnight(new Date(Date.now() + oneDay));
  const midnightDayAfterTomorrow = new Date(midnightTomorrow.getTime() + oneDay);

  return date > midnightTomorrow && date < midnightDayAfterTomorrow;
}

export const startOfDay = dateFnsStartOfDay;

export function getDateByTimeZoneOffset(date: Date, offset: number) {
  const clientTimezoneOffset = -new Date().getTimezoneOffset() * 60;
  const diff = clientTimezoneOffset - offset;

  return subSeconds(date, diff);
}
