import cn from 'classnames';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from 'reactstrap';

import { ArrowIcon } from '@/components/icons';

import styles from './AppDropdownCheckboxSelect.module.scss';

export type Option = {
  label: string;
  value: any;
};

type Props = {
  options: Option[];
  placeholder?: string;
  value: Option | null;
  onChange(value: Option | null): void;
  className?: string;
};

export default function AppDropdownCheckboxSelect(props: Props) {
  const { onChange, options, value, className, placeholder } = props;

  //
  // State
  //

  const [open, setOpen] = useState<boolean>(false);

  //
  // Render
  //

  const renderOptions = options.map((o, index) => (
    <FormGroup
      className={styles.option}
      key={o.value.toString() + index}
    >
      <Label check>
        <Input
          defaultChecked={o.value === value?.value}
          onClick={() => {
            setOpen(false);
            if (o.value === value?.value) onChange(null);
            else onChange(o);
          }}
          type="checkbox"
          value={o.value}
        />{' '}
        <span>{o.label}</span>
      </Label>
    </FormGroup>
  ));

  return (
    <>
      <Dropdown
        className={cn(styles.host, className)}
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        <DropdownToggle caret>
          <div className={cn(styles.trigger, { [styles.focus]: open })}>
            {value ? value.label : <span className={styles.placeholder}>{placeholder}</span>}
            <ArrowIcon
              className={styles.arrow}
              direction={!open ? 'bottom' : 'top'}
            />
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">{renderOptions}</DropdownMenu>
      </Dropdown>
    </>
  );
}
