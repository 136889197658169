import { ToastContainer } from 'react-toastify';

import './NotificationHub.scss';

export default function NotificationHub() {
  return (<ToastContainer
    limit={5}
    newestOnTop
    pauseOnFocusLoss={false}
    theme="colored"
  />);
}
