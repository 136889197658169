export default class FAQ {
  public id: string;
  public question: string;
  public answer: string;
  public createdAt: Date | null;
  public updatedAt: Date | null;

  public constructor(data: Partial<FAQ>) {
    this.id = data.id!;
    this.question = data.question ?? '';
    this.answer = data.answer ?? '';
    this.createdAt = data.createdAt ?? null;
    this.updatedAt = data.updatedAt ?? null;
  }
}
