import styles from './TeachersPage.module.scss';

import { TeachersTable } from './components';
import { PageTitle } from '@/layout/DashboardLayout/components';
import { useDocumentTitle } from '@/hooks';
import { TeacherIcon } from '@/components/icons';

export default function TeachersPage() {
  //
  // Effects
  //

  useDocumentTitle('Список учителей');

  //
  // Render
  //

  return (
    <>
      <PageTitle heading="Список учителей" svg={<TeacherIcon />} />

      <section className={styles.host}>
        <TeachersTable />
      </section>
    </>
  );
}
