export default {
  // Auth
  userDoesNotExists: 'UserDoesNotExist',
  codeDoesNotExist: 'CodedDoesNotExist',
  exceededInputCode: 'ExceededInputCode',
  exceededRequestCall: 'ExceededRequestCall',
  smscException: 'SmscException',
  validateCustomException: 'ValidateCustomException',
  invalidDataStructureException: 'InvalidDataStructureException',
  schemaError: 'SchemaError',
  parentExist: 'ParentExist',
  employeeAlreadyAssigned: 'EmployeeAlreadyAssigned',
};
