import { MealSubscription } from '@/constants/mealSubscription';

export default class KitchenRequestItem {
  public pk: string;
  public firstName: string;
  public lastName: string;
  public childAttend: boolean;
  public concessional: string;
  public paid: string;
  public subsidy: string;
  public photo: string | null;
  public nutritionPlansPositions: {
    kind: MealSubscription;
    denied: boolean;
  }[];

  public constructor(data: Partial<KitchenRequestItem>) {
    this.pk = data.pk!;
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.childAttend = data.childAttend || false;
    this.concessional = data.concessional || '';
    this.paid = data.paid || '';
    this.subsidy = data.subsidy || '';
    this.photo = data.photo || null;
    this.nutritionPlansPositions = data.nutritionPlansPositions || [];
  }

  public get FI(): string {
    return [this.lastName, this.firstName].filter(Boolean).join(' ');
  }
}
