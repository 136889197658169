export const formatNumeric = (value: string | number, fractionDigits = 0) => {
  return (+(+value).toFixed(fractionDigits)).toLocaleString('ru-RU');
};

export const formatCurrency = (value: string | number | null | undefined) => {
  if (value == null) return null;

  const rx = /(\d+)(\d{3})/;
  const spaces = String(value).replace(/^-?\d+/, (w) => {
    while (rx.test(w)) w = w.replace(rx, '$1 $2');
    return w;
  });

  return spaces + ' ₽';
};

export const formatPhone = (phone: string | null | undefined): string | null => {
  if (!phone) return null;
  if (phone.length !== 10) return phone;

  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3$4');
};

export const formatPhoneToCall = (phone: string | null | undefined): string => {
  if (!phone) return '';

  const purePhone = phone.replace(/[^A-Z0-9]/gi, '');

  if (purePhone.length < 11) return purePhone;
  if (purePhone.startsWith('8')) return purePhone.replace('8', '+7');
  if (purePhone.startsWith('7')) return purePhone.replace('7', '+7');

  return '+7' + purePhone;
};

export const deformatPhone = (phone: string | null): string => {
  if (!phone) return '';

  return phone.replace(/[^0-9a-zA-Z]+/g, '');
};

export function formatFloatComma(x: number | string) {
  return x.toString().replace('.', ',');
}

/**
 * Convert string from snake_case to camelCase.
 * @param str snake_case string.
 * @returns camelCase string.
 */
export function camelize(str: string): string {
  return str
    .split('_')
    .map((word, i) => (i === 0 ? word : word[0].toUpperCase() + word.slice(1)))
    .join('');
}

export function zeroPad(num: number, places: number) {
  const zero = places - num.toString().length + 1;

  return Array(+(zero > 0 && zero)).join('0') + num;
}
