import { useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import styles from './EditStudentModal.module.scss';

import { AppButton, AppModal } from '@/components/app';
import { StudentForm } from '../';
import { FormFields as StudentFormFields } from '../StudentForm';
import { Student } from '@/models/entity';
import { deformatPhone } from '@/utils/formatters';
import { SchoolsService } from '@/services';
import { UserIcon } from '@/components/icons';
import { notify } from '@/utils/notifications';
import { ServerValidationError } from '@/api/exceptions';
import wellKnownErrors from '@/api/wellKnownErrors';
import { IikoCardState } from '@/constants';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onActionSuccess(): void;
  student: Student | null;
};

export default function EditStudentModal(props: Props) {
  const { isOpen, onActionSuccess, onClose, student } = props;
  const { classId } = useParams();

  //
  // State
  //

  const [loading, setLoading] = useState<boolean>(false);

  //
  // Methods
  //

  const handleSubmit = async (values: StudentFormFields) => {
    if (!classId || Array.isArray(classId) || !student) return;

    const { firstName, lastName, middleName, parentPhone } = values;
    const phonePurified = deformatPhone(parentPhone);

    try {
      setLoading(true);
      await SchoolsService.editStudent(student.id, classId, firstName, lastName, middleName, phonePurified);
      notify('Ученик успешно обновлен', 'success', <UserIcon />);
      onClose();
      onActionSuccess();
    } catch (error) {
      if (error instanceof ServerValidationError) {
        if (error.code === wellKnownErrors.schemaError) notify(error.message, 'error', <UserIcon />);
        if (error.code === wellKnownErrors.parentExist) notify(error.message, 'error', <UserIcon />);
      }

      throw error;
    } finally {
      setLoading(false);
    }
  };

  //
  // Render
  //

  const renderActions = (values: StudentFormFields, dirty: boolean) => (
    <section className={styles.actions}>
      <AppButton
        className={styles.actionBtn}
        disabled={loading || !dirty}
        type="submit"
      >
        Сохранить
      </AppButton>
    </section>
  );

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <section className={styles.host}>
        <h2>Редактирование данных ученика</h2>
        {student && (
          <StudentForm
            actionsSection={renderActions}
            allowEditParentPhone={student.iikoCardState !== IikoCardState.created}
            className={cn('animate__animated animate__fadeIn', styles.form)}
            defaultValues={{
              firstName: student.firstName,
              lastName: student.lastName,
              middleName: student.middleName,
              parentPhone: student.parentPhone,
            }}
            onSubmit={handleSubmit}
          />
        )}
      </section>
    </AppModal>
  );
}
