import cn from 'classnames';
import { ReactElement, CSSProperties, useEffect } from 'react';
import Loader from 'react-loaders';
import { Table } from 'reactstrap';

import styles from './AppTable.module.scss';

import { Cell, Pagination, PaginationProps, CellType, CellValue } from './components';

type TableHeader = {
  title: string | ReactElement;
  className?: string;
  style?: CSSProperties;
};

type TableColumn<T> = {
  value(item: T, itemIndex: number): CellValue;
  className?: string | ((item: T, itemIndex: number) => string);
  type?: CellType;
  header: TableHeader;
};

interface Props<T> {
  columns: TableColumn<T>[];
  data: Array<T>;
  pagination: PaginationProps | null;
  className?: string;
  loading?: boolean;
}

export default function AppTable<T>(props: Props<T>): ReactElement {
  const { columns, data, loading = false, pagination: paginationProps, className = '' } = props;

  //
  // Methods
  //

  const collectCellsRow = (cells: TableColumn<T>[], item: T, itemIndex: number) => {
    return cells.map((cell, index) => (
      <Cell
        className={
          typeof cell.className === 'function' && cell.className ? cell.className(item, itemIndex) : cell.className
        }
        itemIndex={itemIndex}
        key={index}
        type={cell.type || 'custom'}
        value={cell.value(item, itemIndex)}
      />
    ));
  };

  //
  // Methods
  //

  const setTableHeight = () => {
    const theadNode = document.querySelector(`.${styles.table} thead`);
    const tableWrapperNode = document.querySelector<HTMLElement>(`.${styles.host} .table-responsive`);
    const paginationNode = document.querySelector<HTMLElement>(`.${styles.host} .pagination-bottom`);
    const theadTopOffset = theadNode?.getBoundingClientRect().y || 0;
    const paginationHeight = paginationNode?.getBoundingClientRect().height || 0;
    const marginBottom = 20;
    const tableHeight = window.innerHeight - theadTopOffset - paginationHeight - marginBottom;

    if (tableWrapperNode) {
      tableWrapperNode.style.maxHeight = `${tableHeight}px`;
    }
  };

  //
  // Effect
  //

  useEffect(() => {
    setTableHeight();
  }, [data]);

  useEffect(() => {
    window.addEventListener('resize', setTableHeight);
    return () => window.removeEventListener('resize', setTableHeight);
  });

  //
  // Render
  //

  const renderTableBody = () => {
    if (!data || data.length < 1) {
      return (
        <tr>
          <td className="one wide" colSpan={columns.length}>
            <h6 className={styles.noDataMessage}>Пока нет ни одной записи</h6>
          </td>
        </tr>
      );
    }

    const renderBodyRows = data.map((item, itemIndex) => {
      const row = collectCellsRow(columns, item, itemIndex);

      return <tr key={itemIndex}>{row}</tr>;
    });

    return renderBodyRows;
  };

  const renderHeaderRow = () => {
    const headerRow = columns.map((column, index) => (
      <th className={column.header.className} key={index} style={column.header.style}>
        {column.header.title}
      </th>
    ));

    return <tr>{headerRow}</tr>;
  };

  if (loading) {
    return (
      <div className="mt-5">
        <Loader active type="ball-clip-rotate" />
      </div>
    );
  }

  return (
    <div className={cn('ReactTable', styles.host, className)}>
      <Table bordered className={styles.table} responsive striped>
        <thead>{renderHeaderRow()}</thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
      {paginationProps && <Pagination {...paginationProps} />}
    </div>
  );
}
