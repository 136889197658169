export default function MessageCloudIcon() {
  return (
    <svg
      fill="none"
      height="28"
      viewBox="0 0 29 28"
      width="29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8232 25.1411L25.7894 20.72C26.9979 18.6273 27.6463 16.24 27.6463 13.8232C27.6463 6.18948 21.4569 0 13.8232 0C6.18948 0 0 6.18948 0 13.8232C0 21.4569 6.18948 27.6463 13.8232 27.6463C16.2401 27.6463 18.6274 26.9979 20.72 25.7894L25.1411 27.8232C25.4063 27.941 25.7011 28 25.9957 28C26.5261 28 27.0274 27.7936 27.4104 27.4106C28 26.7915 28.1768 25.9073 27.8232 25.1411L27.8232 25.1411ZM23.3726 21.1327L25.2589 25.2295L21.162 23.3432C20.7788 23.1664 20.3662 23.1958 20.0125 23.4316C18.1557 24.6104 16.0335 25.2295 13.8525 25.2295C7.54517 25.2295 2.41674 20.101 2.41674 13.7937C2.38734 7.51578 7.51585 2.38736 13.8231 2.38736C20.1303 2.38736 25.2589 7.51584 25.2589 13.8232C25.2589 16.0043 24.6398 18.1558 23.461 19.9832C23.2252 20.3368 23.1956 20.7494 23.3724 21.1326L23.3726 21.1327Z"
        fill="white"
      />
      <path
        d="M7.95803 11.7305C7.25061 11.7305 6.66113 12.3199 6.66113 13.0274V14.6188C6.66113 15.3263 7.25055 15.9157 7.95803 15.9157C8.66545 15.9157 9.25493 15.3263 9.25493 14.6188V13.0274C9.25493 12.3199 8.6655 11.7305 7.95803 11.7305Z"
        fill="white"
      />
      <path
        d="M13.8233 11.7305C13.1158 11.7305 12.5264 12.3199 12.5264 13.0274V14.6188C12.5264 15.3263 13.1158 15.9157 13.8233 15.9157C14.5307 15.9157 15.1202 15.3263 15.1202 14.6188V13.0274C15.1202 12.3199 14.5305 11.7305 13.8233 11.7305Z"
        fill="white"
      />
      <path
        d="M19.6885 11.7305C18.9811 11.7305 18.3916 12.3199 18.3916 13.0274V14.6188C18.3916 15.3263 18.981 15.9157 19.6885 15.9157C20.396 15.9157 20.9854 15.3263 20.9854 14.6188V13.0274C20.956 12.3199 20.396 11.7305 19.6885 11.7305Z"
        fill="white"
      />
    </svg>
  );
}
