import React from 'react';

type Props = {
  className?: string;
  direction: 'top' | 'bottom' | 'right' | 'left';
};

export default function ArrowIcon(props: Props) {
  const { direction, className } = props


  const getAngleDegree = () => {
    switch (direction) {
      case 'top':
        return 0

      case 'bottom':
        return 180

      case 'right':
        return 90

      case 'left':
        return 270

      default:
        return 0
    }
  }

  return (
    <svg
      className={className}
      fill="none"
      height="6"
      style={{ transform: `rotate(${getAngleDegree()}deg)` }}
      viewBox="0 0 10 6"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.5L5 1.5L1 5.5"
        stroke="black"
      />
    </svg>
  );
}
