import cn from 'classnames';
import { ChangeEventHandler, FocusEvent, ReactElement, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { v4 as uuid } from 'uuid';
import ReactCodeInput from 'react-code-input';
import Select, { components as ReactSelectComponents } from 'react-select';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { MultiSelect } from 'react-multi-select-component';
import NumberFormat from 'react-number-format';
import DateRangePicker, { DateRange, RangeType } from 'rsuite/esm/DateRangePicker';
import { Input } from 'reactstrap';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';

import styles from './AppInputField.module.scss';
import dropIcon from '@/assets/img/icons/dropIcon.svg';
import AppImageCropper from '../AppImageCropper/AppImageCropper';
import { ArrowIcon } from '@/components/icons';
import { Option } from '../AppDropdownCheckboxSelect/AppDropdownCheckboxSelect';

type SelectOption = {
  value: string | number | undefined;
  label: string;
};

type Props = {
  autofocus?: boolean;
  required?: boolean;
  className?: string;
  controlType?:
    | 'input'
    | 'password'
    | 'textarea'
    | 'phone'
    | 'code'
    | 'select'
    | 'file'
    | 'multi-select'
    | 'numeric'
    | 'image-cropper'
    | 'checkbox'
    | 'date-range'
    | 'mask';
  disabled?: boolean;
  error?: string | boolean;
  inputType?: 'text' | 'tel' | 'color' | 'email' | 'number' | 'range' | 'search' | 'url';
  label?: string;
  maxLength?: number;
  name?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (event: any) => void;
  placeholder?: string;
  rows?: number;
  value?: string | number | File | string[] | number[] | boolean | DateRange | null;
  codeFieldsCount?: number;
  options?: SelectOption[];
  noOptionsMessage?: string;
  fileAccept?: string;
  onFileAccept?: (files: File[], event: DropEvent) => void;
  onFileReject?: (fileRejections: FileRejection[], event: DropEvent) => void;
  renderFileValue?(): ReactElement;
  multiSelectItemRender?(checked: any, option: any, onClick: any, disabled: any): ReactElement;
  multiSelectValueRenderer?(value: Option[]): ReactElement | string;
  numericSuffix?: string;
  maxNumericValue?: number;
  cropperAspectRatio?: number;
  dateRangesPresets?: RangeType[];
  mask?: string;
  maskFormatChars?: Record<string, string>;
  dropzoneIcon?: ReactElement;
  customSelectControlStyles?: any;
  multiSelectDisableSearch?: boolean;
};

const controlId = () => uuid();

export default function AppInputField(props: Props) {
  const {
    autofocus = false,
    className = '',
    controlType = 'input',
    disabled,
    error,
    inputType = 'text',
    label,
    maxLength,
    name = '',
    onBlur = () => {},
    onChange = () => {},
    placeholder,
    rows = 5,
    value = '',
    codeFieldsCount = 4,
    required = false,
    options = [],
    noOptionsMessage = 'Нет доступных опций',
    fileAccept,
    onFileAccept = () => {},
    onFileReject = () => {},
    renderFileValue = () => <div />,
    multiSelectItemRender = undefined,
    numericSuffix = '',
    maxNumericValue = 99999,
    cropperAspectRatio = undefined,
    dateRangesPresets = undefined,
    mask,
    maskFormatChars,
    dropzoneIcon,
    customSelectControlStyles = {},
    multiSelectValueRenderer,
    multiSelectDisableSearch = false,
  } = props;

  //
  // State
  //

  const [id] = useState(controlId());
  const [phoneFieldRef, setPhoneFieldRef] = useState<HTMLInputElement | null>(null);
  const [passwordInputType, setPasswordInputType] = useState<'text' | 'password'>('password');

  //
  // Effects
  //

  useEffect(() => {
    if (controlType === 'phone' && autofocus && phoneFieldRef) {
      setTimeout(() => {
        phoneFieldRef.focus();
      });
    }
  }, [autofocus, controlType, phoneFieldRef]);

  //
  // Render
  //

  const renderControl = (): ReactElement | null => {
    switch (controlType) {
      case 'input':
        return value instanceof File || Array.isArray(value) || typeof value === 'boolean' || value === null ? null : (
          <input
            autoComplete="off"
            autoFocus={autofocus}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange as ChangeEventHandler<HTMLInputElement>}
            placeholder={placeholder}
            type={inputType}
            value={value}
          />
        );

      case 'password':
        return value instanceof File || Array.isArray(value) || typeof value === 'boolean' || value === null ? null : (
          <div className={styles.passwordInputWrapper}>
            {passwordInputType === 'text' ? (
              <svg
                fill="none"
                height="16"
                onClick={() => setPasswordInputType('password')}
                viewBox="0 0 22 16"
                width="22"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                fill="none"
                height="19"
                onClick={() => setPasswordInputType('text')}
                viewBox="0 0 22 19"
                width="22"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 4C13.76 4 16 6.24 16 9C16 9.65 15.87 10.26 15.64 10.83L18.56 13.75C20.07 12.49 21.26 10.86 21.99 9C20.26 4.61 15.99 1.5 10.99 1.5C9.59 1.5 8.25 1.75 7.01 2.2L9.17 4.36C9.74 4.13 10.35 4 11 4ZM1 1.27L3.28 3.55L3.74 4.01C2.08 5.3 0.78 7.02 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.8 16.08L18.73 19L20 17.73L2.27 0L1 1.27ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.78 8 9C8 10.66 9.34 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C8.24 14 6 11.76 6 9C6 8.21 6.2 7.47 6.53 6.8ZM10.84 6.02L13.99 9.17L14.01 9.01C14.01 7.35 12.67 6.01 11.01 6.01L10.84 6.02Z"
                  fill="black"
                />
              </svg>
            )}

            <input
              autoComplete="off"
              autoFocus={autofocus}
              disabled={disabled}
              id={id}
              maxLength={maxLength}
              name={name}
              onBlur={onBlur}
              onChange={onChange as ChangeEventHandler<HTMLInputElement>}
              placeholder={placeholder}
              type={passwordInputType}
              value={value}
            />
          </div>
        );

      case 'textarea':
        return value instanceof File || Array.isArray(value) || typeof value === 'boolean' || value === null ? null : (
          <textarea
            autoComplete="off"
            autoFocus={autofocus}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange as ChangeEventHandler<HTMLTextAreaElement>}
            placeholder={placeholder}
            rows={rows}
            value={value}
          />
        );

      case 'phone':
        return value instanceof File || Array.isArray(value) || typeof value === 'boolean' || value === null ? null : (
          <div className={styles.phoneField}>
            <div className="phoneFieldRegionSign">+7</div>
            <InputMask
              alwaysShowMask={false}
              autoFocus={autofocus}
              disabled={disabled}
              inputRef={(a) => setPhoneFieldRef(a)}
              mask={'(999) 999-9999'}
              maskChar={null}
              name={name}
              onChange={onChange}
              placeholder={placeholder ?? '+7 '}
              type="tel"
              value={value}
            />
          </div>
        );

      case 'mask':
        return value instanceof File || Array.isArray(value) || typeof value === 'boolean' || value === null ? null : (
          <InputMask
            alwaysShowMask={false}
            autoFocus={autofocus}
            disabled={disabled}
            formatChars={maskFormatChars}
            mask={mask || ''}
            maskChar={null}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
          />
        );

      case 'code':
        return (
          <ReactCodeInput
            className={styles.codeField}
            fields={codeFieldsCount}
            inputMode="tel"
            name={name}
            onChange={onChange}
            type="number"
          />
        );

      case 'select':
        return (
          <Select
            components={{
              Menu: (menuProps) => (
                <ReactSelectComponents.Menu
                  {...menuProps}
                  className="animate__animated animate__fadeIn animate__faster"
                />
              ),
            }}
            isDisabled={disabled}
            menuPosition="fixed"
            name={name}
            noOptionsMessage={() => noOptionsMessage}
            onBlur={onBlur}
            onChange={(selectValue) => onChange(selectValue)}
            options={options}
            placeholder={placeholder || ''}
            styles={{
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 3,
              }),
              valueContainer: (provided) => ({
                ...provided,
                cursor: 'text',
                height: 48,
                padding: 0,
                margin: 0,
              }),
              indicatorSeparator: () => ({}),
              control: (provided, { menuIsOpen, isDisabled }) => {
                let borderColor = '#e8e8e8';
                let bgColor = '#fff';

                if (menuIsOpen) borderColor = 'var(--primary-color) !important';
                if (error) borderColor = '#b22b2b';
                if (isDisabled) bgColor = '#FAFAFA';

                return {
                  ...provided,
                  height: 48,
                  transition: 'var(--base-transition)',
                  borderColor,
                  fontSize: '1.6rem',
                  fontWeight: 400,
                  color: '#000',
                  padding: '0 0 0 16px',
                  boxShadow: 'none',
                  background: bgColor,
                  '&:hover': {
                    borderColor: 'none',
                  },
                  '&:before, &:after': {
                    content: '""',
                    position: 'absolute',
                    top: '20px',
                    right: '16px',
                    width: '1px',
                    height: '7px',
                    borderRadius: '1px',
                    background: '#000',
                    transform: menuIsOpen ? 'rotate(-45deg)' : 'rotate(-135deg)',
                    transition: 'all .3s',
                    zIndex: '1',
                  },
                  '&:after': {
                    right: '20.5px',
                    transform: menuIsOpen ? 'rotate(45deg)' : 'rotate(135deg)',
                  },
                  ...customSelectControlStyles,
                };
              },
              input: (provided) => ({
                ...provided,
                height: 48,
                margin: 0,
                padding: 0,
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#818181',
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 10000,
                marginTop: 0,
              }),
              option: (provided, { isFocused, isSelected, isDisabled }) => {
                let bg = 'none';

                if (isFocused) bg = 'rgba(0, 0, 0, 0.05)';
                if (isSelected) bg = '#f7cb75a6';

                return {
                  ...provided,
                  cursor: 'pointer',
                  zIndex: 10000,
                  color: '#000',
                  background: bg,
                  transition: 'var(--base-transition)',
                  minHeight: 38,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: isSelected ? 600 : 400,
                  '&:hover,&:active': {
                    background: 'rgba(0, 0, 0, 0.05)',
                  },
                };
              },
              singleValue: (provided) => ({
                ...provided,
                paddingRight: 30,
              }),
              multiValue: (provided) => ({
                ...provided,
              }),
              menuList: (provided) => ({
                ...provided,
                padding: 0,
                boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
                maxHeight: 152,
              }),
              multiValueRemove: (provided) => ({
                ...provided,
                cursor: 'pointer',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                cursor: 'pointer',
              }),
              indicatorsContainer: () => ({
                display: 'none',
              }),
              noOptionsMessage: (provided) => ({
                ...provided,
                fontSize: '1.4rem',
                height: 114,
                padding: 10,
              }),
            }}
            value={options.find((o) => o.value === value)}
          />
        );

      case 'multi-select':
        return (
          <MultiSelect
            ArrowRenderer={({ expanded }) => <ArrowIcon direction={!expanded ? 'bottom' : 'top'} />}
            ItemRenderer={({ checked, option, onClick, disabled }: any) =>
              multiSelectItemRender ? (
                multiSelectItemRender(checked, option, onClick, disabled)
              ) : (
                <div className={`item-renderer ${disabled && 'disabled'}`}>
                  <Input checked={checked} disabled={disabled} onChange={onClick} tabIndex={-1} type="checkbox" />
                  <span>{option.label}</span>
                </div>
              )}
            disableSearch={multiSelectDisableSearch}
            disabled={disabled}
            hasSelectAll={false}
            labelledBy="Select"
            onChange={(v: any) => onChange(v.map((cv: any) => cv.value))}
            options={options}
            overrideStrings={{
              selectSomeItems: placeholder || ' ',
              noOptions: noOptionsMessage,
              search: 'Поиск...',
            }}
            value={options.filter((o) => {
              //@ts-ignore
              return value.find((v: any) => v === o.value);
            })}
            valueRenderer={(selected, _options) =>
              multiSelectValueRenderer ? multiSelectValueRenderer(selected) : selected.map((s) => s.label).join(', ')}
          />
        );

      case 'file':
        return (
          <Dropzone accept={fileAccept} multiple={false} onDropAccepted={onFileAccept} onDropRejected={onFileReject}>
            {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  className={cn(styles.dropZoneContent, 'dropzone-wrapper', {
                    [styles.dragAccepted]: isDragAccept,
                    [styles.dragRejected]: isDragReject,
                  })}
                >
                  {value ? (
                    renderFileValue()
                  ) : (
                    <>
                      <img alt="Иконка дропзоны" src={dropIcon} />
                      <span>{placeholder}</span>
                    </>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        );

      case 'numeric':
        return value instanceof File || Array.isArray(value) || typeof value === 'boolean' ? null : (
          <NumberFormat
            allowLeadingZeros
            disabled={disabled}
            displayType="input"
            isAllowed={({ floatValue }) => (floatValue ?? 0) <= maxNumericValue}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            suffix={numericSuffix}
            thousandSeparator=" "
            type="tel"
            value={value}
          />
        );

      case 'image-cropper':
        return value instanceof File || Array.isArray(value) ? null : (
          <AppImageCropper
            aspectRatio={cropperAspectRatio}
            dropzoneIcon={dropzoneIcon}
            fileAccept={fileAccept}
            onChange={onChange}
            onFileAccept={onFileAccept}
            onFileReject={onFileReject}
            placeholder={placeholder ?? 'Перетащите файл в это поле или нажмите для выбора'}
            value={typeof value !== 'string' ? null : value}
          />
        );

      case 'checkbox':
        return typeof value === 'boolean' ? (
          <label className={styles.checkbox}>
            {value && <span className={cn(styles.checkMark, 'animate__animated animate__fadeIn animate__faster')} />}
            <input checked={value} name={name} onChange={onChange} type="checkbox" />
            {label}
          </label>
        ) : null;

      case 'date-range':
        return typeof value === 'string' ||
          typeof value === 'boolean' ||
          typeof value === 'number' ||
          value instanceof File ||
          (Array.isArray(value) && value.some((v) => typeof v === 'string')) ||
          (Array.isArray(value) && value.some((v) => typeof v === 'number')) ? null : (
          <DateRangePicker
            className={styles.dateRange}
            format="dd.MM.yyyy"
            locale={{
              today: 'Сегодня',
              yesterday: 'Вчера',
              tomorrow: 'Завтра',
              last7Days: 'Последние 7 дней',
            }}
            onChange={(v) => onChange(v)}
            placeholder={placeholder}
            ranges={
              dateRangesPresets || [
                {
                  label: 'today',
                  value: [startOfDay(new Date()), endOfDay(new Date())],
                },
                {
                  label: 'Завтра',
                  value: [startOfDay(addDays(new Date(), +1)), endOfDay(addDays(new Date(), +1))],
                },
                {
                  label: 'last7Days',
                  value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
                },
              ]
            }
            value={value as DateRange}
          />
          );

      default:
        return null;
    }
  };

  return (
    <div className={cn(styles.host, className, { [styles.invalid]: error })}>
      {label && controlType !== 'checkbox' && (
        <label htmlFor={id}>
          {label} {required && <span className={styles.requiredStar}>*</span>}
        </label>
      )}
      {renderControl()}
    </div>
  );
}
