import { ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './CenteredLayout.module.scss';

import { NotificationHub } from '../components';
import { AppFlavor } from '@/flavors';

export default function CenteredLayout(): ReactElement {
  const { patternBg } = AppFlavor;

  return (
    <div
      className={styles.host}
      style={{
        backgroundImage: `url(${patternBg})`,
      }}
    >
      <Suspense fallback={''}>
        <NotificationHub />
        <Outlet />
      </Suspense>
    </div>
  );
}
