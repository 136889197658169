import cn from 'classnames';
import { ReactElement } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import styles from './AppModal.module.scss';

interface Props {
  isOpen?: boolean;
  closeOnEsc?: boolean;
  closeOnClick?: boolean;
  className?: string;
  rootClassName?: string;
  onClose(): void;
  children: ReactElement | ReactElement[];
  noBackdrop?: boolean;
  closeIcon?: boolean
}

export default function AppModal(props: Props): ReactElement {
  const {
    isOpen = false,
    onClose,
    children,
    closeOnEsc = true,
    closeOnClick = true,
    noBackdrop = false,
    className = '',
    rootClassName = '',
    closeIcon = true,
  } = props;

  const closeBtn = (
    <button
      className={`${styles.close} close`}
      onClick={onClose}
    >
      &times;
    </button>
  );

  return (
    <Modal
      backdrop={!noBackdrop}
      centered
      className={cn(styles.host, rootClassName)}
      contentClassName={cn(styles.content, className)}
      isOpen={isOpen}
      keyboard={closeOnEsc}
      toggle={closeOnClick ? onClose : undefined}
    >
      <ModalBody className={styles.body}>
        <ModalHeader
          className={styles.header}
          close={closeIcon ? closeBtn : <span />}
          toggle={onClose}
        />
        {children}
      </ModalBody>
    </Modal>
  );
}
