import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import styles from './SchoolsTable.module.scss';

import { AppTable } from '@/components/app';
import { formatPhone } from '@/utils/formatters';
import { SchoolIikoSettings } from '@/models/entity';
import { Paths } from '@/routes';

type Props = {
  handleEditSchoolClick(school: SchoolIikoSettings): void;
  handleDeleteSchoolClick(school: SchoolIikoSettings): void;
  data: SchoolIikoSettings[];
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  setCurrentPage(value: number): void;
  setItemsPerPage(value: number): void;
};

export default function SchoolsTable(props: Props) {
  const {
    handleDeleteSchoolClick,
    handleEditSchoolClick,
    data,
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    totalPages,
  } = props;

  //
  // Render
  //

  const renderActions = (school: SchoolIikoSettings) => {
    return [
      <DropdownItem key="edit" onClick={() => handleEditSchoolClick(school)}>
        <span className="dropdown-icon material-icons">create</span>
        Редактировать
      </DropdownItem>,
      <DropdownItem key="delete" onClick={() => handleDeleteSchoolClick(school)}>
        <span className="dropdown-icon material-icons">delete</span>
        Удалить
      </DropdownItem>,
    ];
  };

  return (
    <AppTable
      columns={[
        {
          value: (school) => (
            <div className={styles.linkCell} style={{ wordBreak: 'break-word', textAlign: 'left' }}>
              <Link to={`${Paths.classes}?schoolId=${school.id}`}>{school.name}</Link>
            </div>
          ),
          className: 'text-left',
          header: {
            title: 'Название школы',
            className: 'text-left',
          },
        },
        {
          value: (school) => <div style={{ whiteSpace: 'nowrap' }}>{formatPhone(school.phoneEmployee) || '—'}</div>,
          className: 'text-left',
          header: {
            title: 'Номер телефона\nсотрудника',
            style: {
              width: 150,
            },
            className: 'text-left',
          },
        },
        {
          value: (school) => (school.marketingCampaign ? `${school.marketingCampaign.discountValue}%` : ''),
          className: 'text-left',
          header: {
            title: 'Скидка учителя',
            style: {
              width: 90,
            },
            className: 'text-left',
          },
        },
        {
          value: (school) => <div style={{ whiteSpace: 'nowrap' }}>{school.mealDaily}-дневное</div>,
          className: 'text-left',
          header: {
            title: 'Питание',
            style: {
              width: 120,
            },
            className: 'text-left',
          },
        },
        {
          value: (school) => {
            if (!school.reportWriteoffStore) return '';

            return school.reportWriteoffStore.name;
          },
          className: 'text-left',
          header: {
            title: 'Склад\nсписания',
            style: {
              width: 120,
            },
            className: 'text-left',
          },
        },
        {
          value: (school) => school.classesCount,
          className: 'text-left',
          header: {
            title: 'Кол-во\nклассов',
            style: {
              width: 90,
            },
            className: 'text-left',
          },
        },
        {
          value: (school) => (
            <Link className={styles.linkCell} to={Paths.teachers}>
              {school.teachersCount}
            </Link>
          ),
          className: 'text-left',
          header: {
            title: 'Кол-во\nучителей',
            style: {
              width: 90,
            },
            className: 'text-left',
          },
        },
        {
          value: (school) => (!school.currentNutritionPlan ? '—' : school.currentNutritionPlan.name),
          className: 'text-left',
          header: {
            title: 'День\nпитания',
            style: {
              width: 90,
            },
            className: 'text-left',
          },
        },
        {
          value: (school) => renderActions(school),
          type: 'action',
          className: 'text-center',
          header: {
            title: 'Действия',
            style: {
              width: 100,
            },
            className: 'text-center',
          },
        },
      ]}
      data={data}
      pagination={{
        totalPages,
        currentPage,
        itemsPerPage,
        onChangePage: (newPage: number) => setCurrentPage(newPage),
        onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
      }}
    />
  );
}
