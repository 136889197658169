import { ComplexDishStatus } from '@/constants/complexDishes';

export default class ComplexDish {
  public id: string;
  public name: string;
  public createdAt: Date | null;
  public updatedAt: Date | null;
  public startAt: Date | null;
  public syncDate: Date | null;
  public daysCount: number;
  public status: ComplexDishStatus | null;
  public elements: {
    afternoonSnackTeen: string | null;
    afternoonSnackYoung: string | null;
    breakfastTeen: string | null;
    breakfastYoung: string | null;
    lunchTeen: string | null;
    lunchYoung: string | null;
  }[];

  public constructor(data: Partial<ComplexDish>) {
    this.id = data.id!;
    this.name = data.name ?? '';
    this.createdAt = data.createdAt ?? null;
    this.updatedAt = data.updatedAt ?? null;
    this.startAt = data.startAt ?? null;
    this.syncDate = data.syncDate ?? null;
    this.daysCount = data.daysCount ?? 0;
    this.status = data.status ?? null;
    this.elements = data.elements ?? [];
  }
}
