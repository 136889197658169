import { useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import styles from './AddClassModal.module.scss';
import classDataExample from '@/assets/img/classDataExample.png';
import xlsxFileIcon from '@/assets/img/icons/xlsxFileIcon.svg';

import { AppButton, AppInputField, AppModal } from '@/components/app';
import { notify } from '@/utils/notifications';
import { Yup } from '@/utils/yup';
import { ImportStudent } from '@/models/entity';
import { ClassUploadPreviewModal } from '../';
import { SchoolsService } from '@/services';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onActionSuccess(): void;
  classId: string;
};

type FormFields = {
  importFile?: File;
  importStudent: ImportStudent[];
};

export default function AddClassModal(props: Props) {
  const { isOpen, onClose, onActionSuccess, classId } = props;
  const { schoolId } = useParams();

  const validationSchema = Yup.object().shape({
    importFile: Yup.mixed(),
  });

  //
  // State
  //

  const [loading, setLoading] = useState<boolean>(false);
  const [previewModalOpened, setPreviewModalOpened] = useState<boolean>(false);
  const [tmpUploadFile, setTmpUploadFile] = useState<File | null>(null);

  //
  // Methods
  //

  const handleSubmitForm = async (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    if (!schoolId || Array.isArray(schoolId)) return;

    const { importStudent } = values;

    try {
      setLoading(true);
      await SchoolsService.createStudents(
        classId,
        importStudent.map((s) => ({
          firstName: s.firstName,
          lastName: s.lastName,
          middleName: s.middleName,
          parentPhone: s.parentPhoneClean,
        })),
      );
      onActionSuccess();
      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleImportFileAdd = (
    file: File,
    students: ImportStudent[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    setTmpUploadFile(null);
    setPreviewModalOpened(false);
    setFieldValue('importFile', file);
    setFieldValue('importStudent', students);
  };

  //
  // Render
  //

  return (
    <AppModal closeOnClick={false} isOpen={isOpen} onClose={onClose}>
      <section className={styles.host}>
        <h2>Добавление списка учеников</h2>

        <Formik
          initialValues={
            {
              importFile: undefined,
              importStudent: [],
            } as FormFields
          }
          onSubmit={(values, { setErrors }) => {
            handleSubmitForm(values, setErrors);
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            submitCount,
            setErrors,
            setFieldValue,
            dirty,
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <p className={styles.fileUploadMessage}>
                Для добавления учеников, загрузите файл в формате ".xlsx", заполненный данными в соответствующей форме:
              </p>

              <img alt="Пример данный excel" className={styles.previewImage} src={classDataExample} />

              <AppInputField
                className={styles.field}
                controlType="file"
                fileAccept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                label="Файл “.xlsx”"
                name="importFile"
                onBlur={handleBlur}
                onChange={() => {}}
                onFileAccept={(files) => {
                  setTmpUploadFile(files[0]);
                  setPreviewModalOpened(true);
                }}
                onFileReject={() => notify('Необходимо загрузить файл\nв формате ".xlsx"', 'error')}
                placeholder="Перетащите файл в это поле или нажмите для выбора"
                renderFileValue={() => (
                  <div className={styles.fileValue}>
                    <img alt="Иконка xlsx файла" src={xlsxFileIcon} />

                    <div>
                      <p>Загруженный файл:</p>
                      <h3>{values.importFile?.name}</h3>
                    </div>
                  </div>
                )}
                value={values.importFile}
              />

              <AppButton className={styles.actionBtn} disabled={loading || !dirty} type="submit">
                Сохранить
              </AppButton>
              <AppButton className={cn(styles.actionBtn, styles.closeBtn)} disabled={loading} onClick={onClose} outline>
                Назад
              </AppButton>

              <ClassUploadPreviewModal
                file={tmpUploadFile}
                isOpen={previewModalOpened}
                onClose={() => {
                  setPreviewModalOpened(false);
                  setTimeout(() => {
                    setTmpUploadFile(null);
                  }, 300);
                }}
                onContinue={(file, students) => handleImportFileAdd(file, students, setFieldValue)}
              />
            </form>
          )}
        </Formik>
      </section>
    </AppModal>
  );
}
