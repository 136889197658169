import { PaymentTypes, StatusTypes } from '@/constants/transactions';

export default class Transaction {
  public id: string;
  public className: string;
  public createdAt: Date | null;
  public studentName: string;
  public parentName: string;
  public amount: number;
  public balance: number;
  public paymentType: PaymentTypes | null;
  public status: StatusTypes | null;
  public schoolName: string;

  public constructor(data: Partial<Transaction>) {
    this.id = data.id!;
    this.createdAt = data.createdAt ?? null;
    this.className = data.className ?? '';
    this.studentName = data.studentName ?? '';
    this.parentName = data.parentName ?? '';
    this.amount = data.amount ?? 0;
    this.balance = data.balance ?? 0;
    this.paymentType = data.paymentType ?? null;
    this.status = data.status ?? null;
    this.schoolName = data.schoolName ?? '';
  }
}
