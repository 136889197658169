import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Paths } from '@/routes';
import { AuthService } from '@/services';
import { RootState } from '@/store';
import { AppLoader } from '@/components/app';
import { useDocumentTitle } from '@/hooks';

export default function IndexPage() {
  const navigate = useNavigate();

  //
  // Store
  //

  const { isSchoolsLoading, schools } = useSelector((state: RootState) => state.schools);
  const { isUserLoading } = useSelector((state: RootState) => state.profile);

  //
  // Effects
  //

  useDocumentTitle('Школьное питание');

  useEffect(() => {
    if (!AuthService.isAuthenticated) navigate(Paths.auth);
    if (isSchoolsLoading) return;
    if (schools.length > 0) navigate(Paths.allSchools);
    else navigate(Paths.newSchool);
  }, [isSchoolsLoading, navigate, schools.length]);

  //
  // Render
  //

  const loadingStageName = useMemo(() => {
    if (isUserLoading) return 'Загружаем данные пользователя';
    if (isSchoolsLoading) return 'Загружаем данные о школах';
    return '';
  }, [isSchoolsLoading, isUserLoading]);

  return <AppLoader active={!!loadingStageName} message={`${loadingStageName}...`} />;
}
