import { SettingsApi } from '@/api/sources';
import { FileDocumentKind } from '@/constants';
import {
  AccountType,
  ComplexDish,
  ComplexDishPlan,
  ComplexDishPlanInfo,
  FAQ,
  FileDocument,
  IikoProps,
  MealConception,
  Menu,
  PlantSettings,
  Promotion,
  PublicSettings,
  SchoolIikoSettings,
} from '@/models/entity';
import {
  mapDtoToFaq,
  mapDtoToMenu,
  mapDtoToPromotion,
  mapDtoToSchoolIikoSettings,
} from '@/models/mappers/settingsMappers';

export default class SettingsService {
  public static async getPublicSettings(): Promise<PublicSettings> {
    const dto = await SettingsApi.getPublicSettings();
    const settings = new PublicSettings({
      email: dto.public_email,
      phone: dto.public_phone,
    });

    return settings;
  }

  public static async getFaqDocuments(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ faqs: FAQ[]; count: number }> {
    const { data, count } = await SettingsApi.getFaqDocuments(query);
    const faqs = data.map(mapDtoToFaq);

    return { faqs, count };
  }

  public static async createFaqDocument(question: string, answer: string): Promise<FAQ> {
    const dto = await SettingsApi.createFaqDocument(question, answer);

    return mapDtoToFaq(dto);
  }

  public static async editFaqDocument(faqId: string, question: string, answer: string): Promise<FAQ> {
    const dto = await SettingsApi.editFaqDocument(faqId, question, answer);

    return mapDtoToFaq(dto);
  }

  public static async deleteFaqDocument(faqId: string): Promise<void> {
    await SettingsApi.deleteFaqDocument(faqId);
  }

  public static async getFileDocuments(): Promise<FileDocument[]> {
    const dtos = await SettingsApi.getFileDocuments();

    return dtos.map(
      (dto) =>
        new FileDocument({
          id: dto.id.toString(),
          kind: dto.kind,
          url: dto.url,
        }),
    );
  }

  public static async uploadFileDocument(kind: FileDocumentKind, file: File): Promise<void> {
    await SettingsApi.uploadFileDocument(kind, file);
  }

  public static async updateFileDocument(id: string, kind: FileDocumentKind, file: File): Promise<void> {
    await SettingsApi.updateFileDocument(id, kind, file);
  }

  public static async deleteFileDocument(id: string): Promise<void> {
    await SettingsApi.deleteFileDocument(id);
  }

  public static async getMenus(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ menus: Menu[]; count: number }> {
    const { data, count } = await SettingsApi.getMenus(query);
    const menus = data.map(mapDtoToMenu);

    return { menus, count };
  }

  public static async getSchoolsIikoSettings(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ schoolIikoSettings: SchoolIikoSettings[] }> {
    const { data } = await SettingsApi.getSchoolsIikoSettings(query);
    const schoolIikoSettings = data.map(mapDtoToSchoolIikoSettings);

    return { schoolIikoSettings };
  }

  public static async createMenu(name: string, schools: string[], file: File): Promise<Menu> {
    const dto = await SettingsApi.createMenu(name, schools, file);

    return mapDtoToMenu(dto);
  }

  public static async updateMenu(id: string, name: string, schools: string[], file: File): Promise<Menu> {
    const dto = await SettingsApi.updateMenu(id, name, schools, file);

    return mapDtoToMenu(dto);
  }

  public static async deleteMenu(id: string): Promise<void> {
    await SettingsApi.deleteMenu(id);
  }

  public static async updateIikoCredentials(
    iikoDeliveryLogin: string,
    iikoDeliveryPassword: string,
    iikoServerLogin: string,
    iikoServerPassword: string,
    iikoServerUrl: string,
    iikoTransportKey: string,
  ): Promise<void> {
    await SettingsApi.updateIikoCredentials(
      iikoDeliveryLogin,
      iikoDeliveryPassword,
      iikoServerLogin,
      iikoServerPassword,
      iikoServerUrl,
      iikoTransportKey,
    );
  }

  public static async getIikoProps(): Promise<IikoProps> {
    const dto = await SettingsApi.getIikoProps();

    return new IikoProps({
      iikoDeliveryEnabled: dto.iiko_card_integration_enabled,
      iikoServerEnabled: dto.iiko_server_integration_enabled,
      iikoTransportEnabled: dto.iiko_transport_integration_enabled,
      iikoDeliveryLogin: dto.iiko_card_login,
      syncDate: dto.iiko_card_synchronised_timestamp ? new Date(dto.iiko_card_synchronised_timestamp) : null,
      iikoServerLogin: dto.iiko_server_login,
      iikoServerUrl: dto.iiko_server_host,
      iikoTransportKey: dto.iiko_transport_key,
    });
  }

  public static async deleteSchool(id: string): Promise<void> {
    await SettingsApi.deleteSchool(id);
  }

  public static async editSchool(
    id: string,
    marketingCampaign: string,
    iikoFrontCount: number,
    mealDaily: '5' | '6' | undefined,
    name: string,
    phone: string,
    warehouse: string,
    nameEmployee: string,
    surnameEmployee: string,
    middleNameEmployee: string,
  ): Promise<void> {
    await SettingsApi.editSchool(
      id,
      marketingCampaign,
      iikoFrontCount,
      mealDaily,
      name,
      phone,
      warehouse,
      nameEmployee,
      surnameEmployee,
      middleNameEmployee,
    );
  }

  public static async syncIikoSettings(): Promise<void> {
    await SettingsApi.syncIikoSettings();
  }

  public static async getPromotions(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ data: Promotion[]; count: number }> {
    const { data, count } = await SettingsApi.getPromotions(query);
    const promotions = data.map(mapDtoToPromotion);

    return { data: promotions, count };
  }

  public static async createPromotion(name: string, preview: File, active: boolean): Promise<Promotion> {
    const dto = await SettingsApi.createPromotion(name, preview, active);
    const promotion = mapDtoToPromotion(dto);

    return promotion;
  }

  public static async updatePromotion(
    id: string,
    name: string,
    preview: File,
    active: boolean,
    storiesImageIds: string[],
  ): Promise<Promotion> {
    const dto = await SettingsApi.updatePromotion(id, name, preview, active, storiesImageIds);
    const promotion = mapDtoToPromotion(dto);

    return promotion;
  }

  public static async uploadPromotionStoriesImage(promotionId: string, image: File): Promise<string> {
    const id = await SettingsApi.uploadPromotionStoriesImage(promotionId, image);

    return id;
  }

  public static async deletePromotion(id: string): Promise<void> {
    await SettingsApi.deletePromotion(id);
  }

  public static async getComplexDishes(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ data: ComplexDish[]; count: number }> {
    const { count, data: dtos } = await SettingsApi.getComplexDishes(query);

    return {
      count,
      data: dtos.map(
        (dto) =>
          new ComplexDish({
            createdAt: new Date(dto.created_at),
            daysCount: dto.elements.length,
            id: dto.id.toString(),
            name: dto.title,
            startAt: dto.activation_date ? new Date(dto.activation_date) : null,
            status: dto.status,
            updatedAt: new Date(dto.updated_at),
          }),
      ),
    };
  }

  public static async createComplexDish(name: string, daysCount: number): Promise<ComplexDish> {
    const dto = await SettingsApi.createComplexDish(name, daysCount);

    return new ComplexDish({
      createdAt: new Date(dto.created_at),
      daysCount: dto.elements.length,
      id: dto.id.toString(),
      name: dto.title,
      startAt: dto.activation_date ? new Date(dto.activation_date) : null,
      status: dto.status,
      updatedAt: new Date(dto.updated_at),
    });
  }

  public static async deleteComplexDish(id: string): Promise<void> {
    await SettingsApi.deleteComplexDish(id);
  }

  public static async getComplexDishById(id: string): Promise<ComplexDish> {
    const dto = await SettingsApi.getComplexDishById(id);

    return new ComplexDish({
      createdAt: new Date(dto.created_at),
      daysCount: dto.elements.length,
      id: dto.id.toString(),
      name: dto.title,
      startAt: dto.activation_date ? new Date(dto.activation_date) : null,
      status: dto.status,
      updatedAt: new Date(dto.updated_at),
      elements: dto.elements.map((dtoElem) => ({
        afternoonSnackTeen: dtoElem.snack_adult_meal ? dtoElem.snack_adult_meal.id.toString() : null,
        afternoonSnackYoung: dtoElem.snack_child_meal ? dtoElem.snack_child_meal.id.toString() : null,
        breakfastTeen: dtoElem.breakfast_adult_meal ? dtoElem.breakfast_adult_meal.id.toString() : null,
        breakfastYoung: dtoElem.breakfast_child_meal ? dtoElem.breakfast_child_meal.id.toString() : null,
        lunchTeen: dtoElem.lunch_adult_meal ? dtoElem.lunch_adult_meal.id.toString() : null,
        lunchYoung: dtoElem.lunch_child_meal ? dtoElem.lunch_child_meal.id.toString() : null,
      })),
    });
  }

  public static async getMealConceptions(): Promise<MealConception[]> {
    const dtos = await SettingsApi.fetchMealConceptions();

    return dtos.map(
      (dto) =>
        new MealConception({
          id: dto.id.toString(),
          name: dto.title,
        }),
    );
  }

  public static async getAccountTypes(): Promise<AccountType[]> {
    const dtos = await SettingsApi.fetchAccountTypes();

    return dtos.map(
      (dto) =>
        new AccountType({
          id: dto.id.toString(),
          name: dto.title,
        }),
    );
  }

  public static async getPlantSettings(): Promise<PlantSettings> {
    const dto = await SettingsApi.fetchPlantSettings();

    return new PlantSettings({
      reportConception: dto.report_conception ? dto.report_conception.id.toString() : null,
      reportExpenseAccount: dto.report_expense_account ? dto.report_expense_account.id.toString() : null,
      reportRevenueAccount: dto.report_revenue_account ? dto.report_revenue_account.id.toString() : null,
    });
  }

  public static async updatePlantSettings(
    reportConception: string | undefined,
    reportExpenseAccount: string,
    reportRevenueAccount: string,
  ): Promise<PlantSettings> {
    const dto = await SettingsApi.updatePlantSettings(reportConception, reportExpenseAccount, reportRevenueAccount);

    return new PlantSettings({
      reportConception: dto.report_conception ? dto.report_conception.id.toString() : null,
      reportExpenseAccount: dto.report_expense_account ? dto.report_expense_account.id.toString() : null,
      reportRevenueAccount: dto.report_revenue_account ? dto.report_revenue_account.id.toString() : null,
    });
  }

  public static async getComplexDishPlans(): Promise<ComplexDishPlanInfo> {
    const dto = await SettingsApi.getComplexDishPlans();

    return new ComplexDishPlanInfo({
      dishes: dto.dishes.map(
        (dDto) =>
          new ComplexDishPlan({
            id: dDto.id.toString(),
            name: dDto.title,
          }),
      ),
      lastSynchronisation: dto.last_synchronisation ? new Date(dto.last_synchronisation) : null,
      synchronised: dto.synchronised,
    });
  }

  public static async saveComplexDish(
    complexDish: ComplexDish,
    startDate: string | null,
    elements: {
      afternoonSnackTeen: string | null;
      afternoonSnackYoung: string | null;
      breakfastTeen: string | null;
      breakfastYoung: string | null;
      lunchTeen: string | null;
      lunchYoung: string | null;
    }[],
    launched: boolean,
  ): Promise<void> {
    await SettingsApi.updateComplexDish(complexDish, startDate, elements, launched);
  }

  public static async syncComplexDishPlans(): Promise<ComplexDishPlanInfo> {
    const dto = await SettingsApi.syncComplexDishPlans();

    return new ComplexDishPlanInfo({
      dishes: dto.dishes.map(
        (dDto) =>
          new ComplexDishPlan({
            id: dDto.id.toString(),
            name: dDto.title,
          }),
      ),
      lastSynchronisation: dto.last_synchronisation ? new Date(dto.last_synchronisation) : null,
      synchronised: dto.synchronised,
    });
  }

  public static async deleteSchoolClass(id: string): Promise<void> {
    await SettingsApi.deleteSchoolClass(id);
  }
}
