import { useEffect, useState, useMemo, SyntheticEvent } from 'react'
import { useLocation } from 'react-router-dom'

import './styles/default.scss'

import { hashItems, mapPathsToHashes, findActiveItems } from './utils'
import Container from './components/Container'
import { NavItem } from './'

interface Props {
  onSelected?(): void;
  className?: string;
  classNameStateIcon?: string;
  items?: NavItem[];
  highlightNested?: boolean;
}

export default function AppMenu(props: Props) {
  const {
    items = [],
    highlightNested = true,
    className = 'light-menu',
    classNameStateIcon = 'fa fa-caret-left',
    onSelected,
  } = props

  const { pathname } = useLocation()

  //
  // State
  //

  const [activeItems, setActiveItems] = useState<string[]>([])
  // Wrapper Set in array because otherwise Set will not be working in the
  const [visibleSubmenus, setVisibleSubmenus] = useState<string[]>([])

  //
  // Computed
  //

  // Memoize hashes and parents to use when highlighting active items
  const hashedItems = useMemo(() => hashItems(items), [items])
  // Looks like we can depend on another memos, so...
  const pathsToHashes = useMemo(() => mapPathsToHashes(hashedItems), [hashedItems])

  //
  // Effects
  //

  useEffect(() => {
    const foundActiveItems = findActiveItems(
      pathsToHashes,
      pathname,
      highlightNested,
    )

    setActiveItems(foundActiveItems)

    if (foundActiveItems.length > 1) {
      // If last is active and there is more than one - set everything else as an visible submenus
      setVisibleSubmenus(foundActiveItems.slice(0, -1))
    }
  }, [highlightNested, pathsToHashes, pathname])

  // Call onSelected if activeItems changes
  useEffect(() => {
    if (onSelected && activeItems.length > 0) onSelected()
  }, [activeItems, onSelected])

  //
  // Methods
  //

  const hideSubmenu = (hash: string) => {
    setVisibleSubmenus(visibleSubmenus.filter(h => h !== hash))
  }

  const showSubmenu = (parentHashes: string[], hash: string) => {
    setVisibleSubmenus([...parentHashes, hash])
  }

  const onActivate = (e: SyntheticEvent, item: any, subMenuVisibility: boolean, hasSubItems?: boolean) => {
    if (hasSubItems) {
      e.preventDefault()

      if (subMenuVisibility) {
        hideSubmenu(item.hash)
      } else {
        showSubmenu(item.parentHashes, item.hash)
      }

      return false
    }

    if (item.external) {
      return false
    }

    // Reset visible submenus on item select

    const uniqParentHashes = Array.from(new Set<string>(item.parentHashes))

    setVisibleSubmenus(uniqParentHashes)
    setActiveItems([...item.parentHashes, item.hash])

    return true
  }

  //
  // Render
  //

  return (
    <div className={className}>
      <Container
        activeItems={activeItems}
        classNameStateIcon={classNameStateIcon}
        hideSubmenu={hideSubmenu}
        items={hashedItems}
        onActivate={onActivate}
        showSubmenu={showSubmenu}
        visibleSubmenus={visibleSubmenus}
      />
    </div>
  )
}
