import { lazy } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { CenteredLayout, DashboardLayout } from '@/layout';
import { IndexPage } from '@/pages';
import { Paths } from './';
import PrivateGuard from './PrivateGuard';

const AuthPage = lazy(() => import('@/pages/AuthPage'));
const NewSchoolPage = lazy(() => import('@/pages/NewSchoolPage'));
const TeachersPage = lazy(() => import('@/pages/TeachersPage'));
const ClassStudentsPage = lazy(() => import('@/pages/ClassStudentsPage'));
const TransactionsPage = lazy(() => import('@/pages/TransactionsPage'));
const OrdersPage = lazy(() => import('@/pages/OrdersPage'));
const SubsParentsPage = lazy(() => import('@/pages/SubsParentsPage'));
const SubsStudentsPage = lazy(() => import('@/pages/SubsStudentsPage'));
const SubsTeachersPage = lazy(() => import('@/pages/SubsTeachersPage'));
const MainSettingsPage = lazy(() => import('@/pages/settings/MainSettingsPage'));
const ComplexDishesSettingsPage = lazy(() => import('@/pages/settings/ComplexDishesSettingsPage'));
const PromotionsSettingsPage = lazy(() => import('@/pages/settings/PromotionsSettingsPage'));
const MenuSettingsPage = lazy(() => import('@/pages/settings/MenuSettingsPage'));
const FAQSettingsPage = lazy(() => import('@/pages/settings/FAQSettingsPage'));
const DocumentsDetailsSettingsPage = lazy(() => import('@/pages/settings/DocumentsDetailsSettingsPage'));
const TrainingSchedulePage = lazy(() => import('@/pages/settings/TrainingSchedulePage'));
const PolicySettingsPage = lazy(() => import('@/pages/settings/PolicySettingsPage'));
const AppInstallPage = lazy(() => import('@/pages/AppInstallPage'));
const ReportsPage = lazy(() => import('@/pages/ReportsPage'));
const AllSchoolsPage = lazy(() => import('@/pages/AllSchoolsPage'));
const ClassesPage = lazy(() => import('@/pages/ClassesPage'));
const StudentPurchasesPage = lazy(() => import('@/pages/students/StudentPurchasesPage'));
const StudentTransactionsPage = lazy(() => import('@/pages/students/StudentTransactionsPage'));
const ComplexDishUpdatePage = lazy(() => import('@/pages/ComplexDishUpdatePage'));
const RequestsPage = lazy(() => import('@/pages/RequestsPage'));
const PaidNutritionPage = lazy(() => import('@/pages/PaidNutritionPage'));

function AppRouter() {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<CenteredLayout />}>
        <Route element={<AuthPage />} path={Paths.auth} />
      </Route>

      <Route element={<DashboardLayout />}>
        <Route element={<IndexPage />} index />
        <Route
          element={
            <PrivateGuard>
              <AllSchoolsPage />
            </PrivateGuard>
          }
          path={Paths.allSchools}
        />
        <Route
          element={
            <PrivateGuard>
              <ClassesPage />
            </PrivateGuard>
          }
          path={Paths.classes}
        />
        <Route
          element={
            <PrivateGuard>
              <ClassStudentsPage />
            </PrivateGuard>
          }
          path={Paths.classStudents()}
        />
        <Route
          element={
            <PrivateGuard>
              <NewSchoolPage />
            </PrivateGuard>
          }
          path={Paths.newSchool}
        />
        <Route
          element={
            <PrivateGuard>
              <TeachersPage />
            </PrivateGuard>
          }
          path={Paths.teachers}
        />
        <Route
          element={
            <PrivateGuard>
              <TransactionsPage />
            </PrivateGuard>
          }
          path={Paths.transactions}
        />
        <Route
          element={
            <PrivateGuard>
              <OrdersPage />
            </PrivateGuard>
          }
          path={Paths.orders}
        />
        <Route
          element={
            <PrivateGuard>
              <SubsParentsPage />
            </PrivateGuard>
          }
          path={Paths.subsParents}
        />
        <Route
          element={
            <PrivateGuard>
              <SubsStudentsPage />
            </PrivateGuard>
          }
          path={Paths.subsStudents}
        />
        <Route
          element={
            <PrivateGuard>
              <SubsTeachersPage />
            </PrivateGuard>
          }
          path={Paths.subsTeachers}
        />
        <Route
          element={
            <PrivateGuard>
              <MainSettingsPage />
            </PrivateGuard>
          }
          path={Paths.mainSettings}
        />
        <Route
          element={
            <PrivateGuard>
              <PromotionsSettingsPage />
            </PrivateGuard>
          }
          path={Paths.promotionsSettings}
        />
        <Route
          element={
            <PrivateGuard>
              <MenuSettingsPage />
            </PrivateGuard>
          }
          path={Paths.menuSettings}
        />
        <Route
          element={
            <PrivateGuard>
              <FAQSettingsPage />
            </PrivateGuard>
          }
          path={Paths.faqSettings}
        />
        <Route
          element={
            <PrivateGuard>
              <PolicySettingsPage />
            </PrivateGuard>
          }
          path={Paths.privacySettings}
        />
        <Route
          element={
            <PrivateGuard>
              <ReportsPage />
            </PrivateGuard>
          }
          path={Paths.reports}
        />
        <Route
          element={
            <PrivateGuard>
              <StudentPurchasesPage />
            </PrivateGuard>
          }
          path={Paths.studentPurchases()}
        />
        <Route
          element={
            <PrivateGuard>
              <StudentTransactionsPage />
            </PrivateGuard>
          }
          path={Paths.studentTransactions()}
        />
        <Route
          element={
            <PrivateGuard>
              <ComplexDishesSettingsPage />
            </PrivateGuard>
          }
          path={Paths.complexDishesSettings}
        />
        <Route
          element={
            <PrivateGuard>
              <ComplexDishUpdatePage />
            </PrivateGuard>
          }
          path={Paths.complexDishUpdate()}
        />
        <Route
          element={
            <PrivateGuard>
              <DocumentsDetailsSettingsPage />
            </PrivateGuard>
          }
          path={Paths.documentsDetails}
        />
        <Route
          element={
            <PrivateGuard>
              <TrainingSchedulePage />
            </PrivateGuard>
          }
          path={Paths.trainingSchedule}
        />
        <Route
          element={
            <PrivateGuard>
              <RequestsPage />
            </PrivateGuard>
          }
          path={Paths.requests}
        />
        <Route
          element={
            <PrivateGuard>
              <PaidNutritionPage />
            </PrivateGuard>
          }
          path={Paths.paidNutrition}
        />
      </Route>

      <Route element={<AppInstallPage />} path={Paths.appInstall} />

      <Route element={<Navigate to={Paths.home} />} path="*" />
    </Routes>
  );
}

export default AppRouter;
