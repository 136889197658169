import cn from 'classnames';
import { ReactElement } from 'react';

import styles from './AppCircularSpinner.module.scss';

import { getCssCustomProperty } from '@/utils/cssCustomProperties';


interface Props {
  radius?: number | string;
  color?: string;
  className?: string;
  stroke?: number | string;
  visible?: boolean;
}

export default function AppCircularSpinner(props: Props): ReactElement | null {
  const { visible = true, color = getCssCustomProperty('--primary-color'), stroke = 2, radius = 20, className = '' } = props;

  if (!visible) return null;

  return (
    <div
      className={cn(styles.host, className)}
      style={{
        width: radius,
        height: radius,
        borderColor: color,
        borderWidth: stroke,
      }}
    />
  );
}
