import styles from './TransactionsPage.module.scss';

import { TransactionsTable } from './components';
import { PageTitle } from '@/layout/DashboardLayout/components';
import { TransactionsIcon } from '@/components/icons';
import { useDocumentTitle } from '@/hooks';

export default function TransactionsPage() {
  //
  // Effects
  //

  useDocumentTitle('Транзакции');

  //
  // Render
  //

  return (
    <>
      <PageTitle heading="Транзакции" svg={<TransactionsIcon />} />

      <section className={styles.host}>
        <TransactionsTable />
      </section>
    </>
  );
}
