import { FAQDto, MenuDto, PromotionDto, SchoolIikoSettingsDto, WarehouseDto } from '../dto';
import {
  FAQ,
  MarketingCampaign,
  Menu,
  NutritionPlan,
  Promotion,
  SchoolIikoSettings,
  Warehouse,
  WriteOffStore,
} from '../entity';
import { mapDtoToSchool } from './schoolMappers';

export const mapDtoToFaq = (dto: FAQDto): FAQ => {
  return new FAQ({
    question: dto.name,
    answer: dto.content,
    id: dto.id.toString(),
    createdAt: dto.created_at ? new Date(dto.created_at) : null,
    updatedAt: dto.updated_at ? new Date(dto.updated_at) : null,
  });
};

export const mapDtoToMenu = (dto: MenuDto): Menu => {
  return new Menu({
    id: dto.id.toString(),
    createdAt: dto.created_at ? new Date(dto.created_at) : null,
    updatedAt: dto.updated_at ? new Date(dto.updated_at) : null,
    name: dto.title,
    schools: dto.schools.map(mapDtoToSchool),
    url: dto.url,
  });
};

export const mapDtoToWarehouse = (dto: WarehouseDto): Warehouse => {
  return new Warehouse({
    id: dto.id.toString(),
    iikoId: dto.iiko_id,
    name: dto.name,
  });
};

export const mapDtoToSchoolIikoSettings = (dto: SchoolIikoSettingsDto): SchoolIikoSettings => {
  return new SchoolIikoSettings({
    id: dto.id.toString(),
    discount: parseFloat(dto.teacher_discount),
    mealDaily: dto.week_dishes_plan,
    name: dto.title,
    phoneEmployee: dto.employee?.phone,
    middleNameEmployee: dto.employee?.middle_name || '',
    nameEmployee: dto.employee?.first_name || '',
    surnameEmployee: dto.employee?.last_name || '',
    plugins: dto.plugins,
    iikoFrontCount: dto.plugins.length,
    writeoffStores: dto.writeoff_stores ? dto.writeoff_stores.map(mapDtoToWarehouse) : [],
    reportWriteoffStore: dto.report_writeoff_store
      ? new WriteOffStore({
        id: dto.report_writeoff_store.id.toString(),
        iikoId: dto.report_writeoff_store.iiko_id,
        name: dto.report_writeoff_store.name,
      })
      : null,
    marketingCampaign: dto.teacher_marketing_campaign
      ? new MarketingCampaign({
        discountValue: dto.teacher_marketing_campaign.discount_value,
        id: dto.teacher_marketing_campaign.id.toString(),
        name: dto.teacher_marketing_campaign.name,
      })
      : null,
    classesCount: dto.classes_count,
    teachersCount: dto.teachers_count,
    currentNutritionPlan: dto.current_nutrition_plan
      ? new NutritionPlan({
        id: dto.current_nutrition_plan.id.toString(),
        name: dto.current_nutrition_plan.title,
      })
      : null,
  });
};

export const mapDtoToPromotion = (dto: PromotionDto): Promotion => {
  return new Promotion({
    createdAt: new Date(dto.created_at),
    updatedAt: new Date(dto.updated_at),
    isActive: dto.is_published,
    miniatureImage: dto.preload,
    storiesImages: dto.image_stores.map((is) => is.image),
    name: dto.title,
    id: dto.id.toString(),
  });
};
