import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import InputMask, { BeforeMaskedStateChangeStates } from 'react-input-mask';

import styles from './AppDateInput.module.scss';

type Props = {
  onChange(value: string): void;
  value: string;
  format?: string;
  divider?: string;
  maskChar?: string;
  placeholder?: string;
  className?: string
};

const DAY_RANGE = [0, 1, 2];
const MONTH_RANGE = [3, 4, 5];
const YEAR_RANGE = [6, 7, 8, 9, 10];
const FIELD_FORMAT_CHARS = {
  e: '[0-3]',
  d: '[0-9]',
  n: '[0-1]',
  m: '[0-9]',
  z: '[1-2]',
  y: '[0-9]',
};

export default function AppDateInput(props: Props) {
  const {
    onChange,
    value,
    format = 'DD.MM.YYYY',
    divider = '.',
    maskChar = '_',
    placeholder = 'ДД.ММ.ГГГГ',
    className,
  } = props;

  const inputRef = useRef<any>(null);

  const [completed, setCompleted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const mask = useMemo(() => format.replace('DD', 'ed').replace('MM', 'nm').replace('YYYY', 'zyyy'), [format]);

  //
  // Effects
  //

  // Set valid state when the filed has completed.
  useEffect(() => {
    const inputChecked = value.split(maskChar).join('').length === 10;

    setIsValid(false);

    if (inputChecked) {
      setIsValid(true);
    }

    return () => {
      setIsValid(false);
    };
  }, [value, maskChar]);

  function beforeMaskedValueChange(states: BeforeMaskedStateChangeStates) {
    const {
      currentState: { selection, value },
      previousState,
    } = states;

    const splitFormat = format.split(divider);
    const dayFormatIndex = splitFormat.indexOf('DD');
    const monthFormatIndex = splitFormat.indexOf('MM');
    const yearFormatIndex = splitFormat.indexOf('YYYY');

    const splitValue = value.split(divider);
    const day = splitValue[dayFormatIndex];
    const month = splitValue[monthFormatIndex];
    const year = splitValue[yearFormatIndex];

    const currentYear = new Date().getFullYear();

    if (!value) {
      return {
        value: '',
        selection: {
          start: 0,
          end: 0,
        },
      };
    }

    if (+day > 31 || +day === 0) {
      return previousState;
    }

    if (+year > currentYear || +year < currentYear - 120) {
      return previousState;
    }

    if (+month > 12 || +month === 0) {
      return previousState;
    }

    return {
      selection,
      value,
    };
  }

  const handleInputClick = (event: any) => {
    const inputElement = inputRef.current as any;

    if (!inputElement) return;

    const dividerAndDots = `/[.${maskChar}]/g`;
    const text = event.target.value.replace(dividerAndDots, '');
    const { start, end } = inputElement.getSelection();

    const isDaySelected = DAY_RANGE.includes(start) || DAY_RANGE.includes(end);
    const isMonthSelected = MONTH_RANGE.includes(start) || MONTH_RANGE.includes(end);
    const isYearSelected = YEAR_RANGE.includes(start) || YEAR_RANGE.includes(end);

    if (!text) {
      inputElement.setSelection(0, 0);
    } else if (isDaySelected) {
      inputElement.setSelection(DAY_RANGE[0], DAY_RANGE.slice(-1).pop());
    } else if (isMonthSelected) {
      inputElement.setSelection(MONTH_RANGE[0], MONTH_RANGE.slice(-1).pop());
    } else if (isYearSelected) {
      inputElement.setSelection(YEAR_RANGE[0], YEAR_RANGE.slice(-1).pop());
    }
  };

  /**
   * Field change handler.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @returns {void}
   */
  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const inputCompleted = text.split(maskChar).join('').length === 10;

    // Fire event up.
    onChange(text);

    if (inputCompleted) {
      if (!completed) {
        event.target.blur();
      }

      setCompleted(true);
    }
  };

  return (
    <div className={cn(className, styles.host)}>
      <InputMask
        alwaysShowMask={false}
        beforeMaskedStateChange={beforeMaskedValueChange}
        formatChars={FIELD_FORMAT_CHARS}
        mask={mask}
        maskChar={maskChar}
        onChange={handleChangeDate}
        onClick={handleInputClick}
        placeholder={placeholder}
        ref={inputRef}
        type="tel"
        value={value}
      />
    </div>
  );
}
