import { SyntheticEvent } from 'react';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import style from './Item.module.scss';

import { isActiveItem, arrayLast } from '../utils';
import Container from './Container';
import ItemLink from './ItemLink';
import { HashedNavItem } from '../';
import { isUrl } from '@/utils/verifiers';

interface Props extends HashedNavItem {
  activeItems: string[];
  onActivate(e: SyntheticEvent, item: any, subMenuVisibility: boolean, hasSubItems?: boolean): boolean;
  visibleSubmenus: string[];
  classNameStateIcon?: string;
  hideSubmenu(hash: string): void;
  showSubmenu(parentHashes: string[], hash: string): void;
}

export default function Item(props: Props) {
  const {
    icon,
    title,
    customTitle,
    items,
    activeItems,
    hash,
    parentHashes,
    external,
    to,
    onActivate,
    visibleSubmenus,
    classNameStateIcon,
    hideSubmenu,
    showSubmenu,
    invisible,
    disabled,
  } = props;

  /*
    If image link was not change, but image by the link was change.
    This must rerender component
  */
  const imageKey = uniqueId();
  const hasSubItems = items && items.length > 0;
  const hasVisibleSubItems = items && items.filter((i) => !i.invisible).length > 0;

  let active = isActiveItem(activeItems, hash);
  const subMenuVisibility = [...parentHashes, hash].every((h) => visibleSubmenus.includes(h));

  let hasActiveChild = false;

  // Item with submenu can't be "active", only submenu can be visible
  // And it should be checked not via "active", but via "visibleSubmenus"
  if (hasSubItems && active) {
    if (arrayLast(activeItems) !== hash) {
      active = false;
      hasActiveChild = true;
    }
  }

  const handleClickLink = (e: SyntheticEvent) => {
    onActivate(e, props, subMenuVisibility, hasSubItems);
  };

  //
  // Render
  //

  const renderIcon = () => {
    if (!icon) return null;

    if (typeof icon === 'string') {
      if (isUrl(icon)) {
        return (
          <div className={`light-menu-icon ${style.iconImg}`}>
            <img alt="" key={imageKey} src={icon} />
          </div>
        );
      }

      return <i className={classnames('light-menu-icon', icon)} />;
    }

    return icon;
  };

  return (
    <li
      className={classnames('light-menu-item', style.itemWrapper, {
        [style.itemActive]: active,
        [style.hasActiveChild]: hasActiveChild,
        [style.invisible]: invisible,
        [style.disabled]: disabled,
      })}
    >
      <ItemLink
        active={active}
        className={classnames({ [style.withoutSubItems]: !hasSubItems }, style.itemTitle, 'light-menu-link')}
        external={external}
        hasActiveChild={hasActiveChild}
        hasSubItems={hasSubItems}
        onClick={handleClickLink}
        to={to}
      >
        <>
          <div className={style.titleAndIcon}>
            <>
              {renderIcon()}
              {customTitle ? customTitle() : title}
            </>
          </div>
          {hasVisibleSubItems && (
            <i
              className={classnames(
                'light-menu-state-icon',
                style.chevron,
                subMenuVisibility ? [classNameStateIcon, 'rotate-minus-90'] : classNameStateIcon,
              )}
            />
          )}
        </>
      </ItemLink>
      {hasSubItems && (
        <Container
          activeItems={activeItems}
          classNameStateIcon={classNameStateIcon}
          hideSubmenu={hideSubmenu}
          items={items}
          onActivate={onActivate}
          showSubmenu={showSubmenu}
          visible={subMenuVisibility}
          visibleSubmenus={visibleSubmenus}
        />
      )}
    </li>
  );
}
