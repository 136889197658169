import IFlavor from './IFlavor';
import { AppIds } from '@/constants';
import { DevelopFlavor, ShkolnoePitanie, TwoDayFlavor } from './apps';

class AppFlavor implements IFlavor {
  private flavorModule: IFlavor | null;

  constructor() {
    const appId = process.env.REACT_APP_APPLICATION_ID;

    console.log({ appId });

    switch (appId) {
      case AppIds.develop:
        this.flavorModule = new DevelopFlavor();
        break;

      case AppIds.shkolnoePitanie:
        this.flavorModule = new ShkolnoePitanie();
        break;

      case AppIds.twoDay:
        this.flavorModule = new TwoDayFlavor();
        break;

      default:
        this.flavorModule = new DevelopFlavor();
    }
  }

  public get primaryColor(): string {
    return this.flavorModule?.primaryColor ?? '';
  }

  public get buttonPrimaryBgColor(): string {
    return this.flavorModule?.buttonPrimaryBgColor ?? '';
  }

  public get buttonPrimaryHoverBgColor(): string {
    return this.flavorModule?.buttonPrimaryHoverBgColor ?? '';
  }

  public get buttonPrimaryLightenBgColor(): string {
    return this.flavorModule?.buttonPrimaryLightenBgColor ?? '';
  }

  public get tableInvalidRowBgColor(): string {
    return this.flavorModule?.tableInvalidRowBgColor ?? '';
  }

  public get buttonLinkTextColor(): string {
    return this.flavorModule?.buttonLinkTextColor ?? '';
  }

  public get tileTabsInactiveBgColor(): string {
    return this.flavorModule?.tileTabsInactiveBgColor ?? '';
  }

  public get tileTabsHoverBgColor(): string {
    return this.flavorModule?.tileTabsHoverBgColor ?? '';
  }

  public get sidebarActiveItemBgColor(): string {
    return this.flavorModule?.sidebarActiveItemBgColor ?? '';
  }

  public get checkboxActiveBgImage(): string {
    return this.flavorModule?.checkboxActiveBgImage ?? '';
  }

  public get logoBig(): string {
    return this.flavorModule?.logoBig ?? '';
  }

  public get logoShort(): string {
    return this.flavorModule?.logoShort ?? '';
  }

  public get patternBg(): string {
    return this.flavorModule?.patternBg ?? '';
  }

  public get favicon(): string {
    return this.flavorModule?.favicon ?? '';
  }

  public get buttonPrimaryTextColor(): string {
    return this.flavorModule?.buttonPrimaryTextColor ?? '';
  }

  public get tileTabsInactiveTextColor(): string {
    return this.flavorModule?.tileTabsInactiveTextColor ?? '';
  }

  public get calendarSelectedCellBgColor(): string {
    return this.flavorModule?.calendarSelectedCellBgColor ?? '#000';
  }

  public get calendarSelectedCellTextColor(): string {
    return this.flavorModule?.calendarSelectedCellTextColor ?? '#fff';
  }

  public get calendarRangeColor(): string {
    return this.flavorModule?.calendarRangeColor ?? '#dcdcdc';
  }
}

export default new AppFlavor();
