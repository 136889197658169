enum OperationTypes {
  'income' = 'REFILL',
  'expanse' = 'DEBIT',
}

enum PaymentTypes {
  'paidMeal' = 'PAID_NUTRITION',
  'mainAccount' = 'PRIMARY',
  'iikoBiz' = 'IIKO_BIZ',
}

enum StatusTypes {
  'success' = 'success',
  'error' = 'error',
}

const OperationTypesConfig = {
  [OperationTypes.income]: {
    value: OperationTypes.income,
    name: 'Пополнение',
  },
  [OperationTypes.expanse]: {
    value: OperationTypes.expanse,
    name: 'Списание',
  },
};

const PaymentTypesConfig = {
  [PaymentTypes.paidMeal]: {
    value: PaymentTypes.paidMeal,
    name: 'Комплексное питание',
    bgColor: '#54A651',
    color: '#fff',
  },
  [PaymentTypes.mainAccount]: {
    value: PaymentTypes.mainAccount,
    name: 'Основной счет',
    bgColor: '#8B09B8',
    color: '#fff',
  },
  [PaymentTypes.iikoBiz]: {
    value: PaymentTypes.iikoBiz,
    name: 'iikoBiz',
    bgColor: '#ef5350',
    color: '#fff',
  },
};

const StatusTypesConfig = {
  [StatusTypes.success]: {
    value: StatusTypes.success,
    name: 'Успешно',
    bgColor: '#54A651',
    color: '#fff',
  },
  [StatusTypes.error]: {
    value: StatusTypes.error,
    name: 'Ошибка',
    bgColor: '#B22B2B',
    color: '#fff',
  },
};

export { OperationTypes, OperationTypesConfig, PaymentTypes, PaymentTypesConfig, StatusTypes, StatusTypesConfig };
