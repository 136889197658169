import { ReactElement } from 'react';
import { cssTransition, toast, ToastPosition } from 'react-toastify';

const Fade = cssTransition({
  enter: 'animate__animated animate__fadeIn',
  exit: 'animate__animated animate__fadeOut',
});

export const notify = (
  message: string,
  type: 'info' | 'success' | 'warning' | 'error',
  icon?: ReactElement,
  timeout: number = 6000,
  position: ToastPosition = 'top-right',
) => {
  toast[type](message, {
    position,
    autoClose: timeout,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    closeButton: false,
    transition: Fade,
    icon: () => icon || <span />,
  });
};
