import { Genders, IikoCardState } from '@/constants';
import SchoolClass from './SchoolClass';

export default class Teacher {
  public id: number;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public phone: string;
  public gender?: Genders;
  public iikoCardState?: IikoCardState;
  public class?: SchoolClass;

  public constructor(data: Partial<Teacher>) {
    this.id = data.id!;
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.middleName = data.middleName ?? '';
    this.phone = data.phone ?? '';
    this.gender = data.gender ?? undefined;
    this.iikoCardState = data.iikoCardState ?? undefined;
    this.class = data.class ?? undefined;
  }

  public get FIO(): string {
    return [this.lastName, this.firstName, this.middleName].filter(Boolean).join(' ');
  }

  public get FIOShort(): string {
    const firstNameShort = this.firstName[0] ? this.firstName[0] + '.' : undefined;
    const middleNameShort = this.middleName[0] ? this.middleName[0] + '.' : undefined;

    return [this.lastName, firstNameShort, middleNameShort].filter(Boolean).join(' ');
  }
}
