export default class IikoProps {
  public syncDate: Date | null;
  public iikoDeliveryLogin: string;
  public iikoTransportKey: string;
  public iikoServerUrl: string;
  public iikoServerLogin: string;
  public iikoDeliveryEnabled: boolean;
  public iikoTransportEnabled: boolean;
  public iikoServerEnabled: boolean;

  public constructor(data: Partial<IikoProps>) {
    this.syncDate = data.syncDate ?? null;
    this.iikoDeliveryLogin = data.iikoDeliveryLogin ?? '';
    this.iikoTransportKey = data.iikoTransportKey ?? '';
    this.iikoServerUrl = data.iikoServerUrl ?? '';
    this.iikoServerLogin = data.iikoServerLogin ?? '';
    this.iikoDeliveryEnabled = data.iikoDeliveryEnabled ?? false;
    this.iikoTransportEnabled = data.iikoTransportEnabled ?? false;
    this.iikoServerEnabled = data.iikoServerEnabled ?? false;
  }
}
