enum IikoCardState {
  notCreated = 'notCreated',
  created = 'created',
}

type ConfigValueType = {
  name: string;
  backgroundColor: string;
  color: string;
};

const config: Record<IikoCardState, ConfigValueType> = {
  [IikoCardState.notCreated]: {
    name: 'Не создан',
    backgroundColor: '#C73953',
    color: '#fff',
  },
  [IikoCardState.created]: {
    name: 'Создан',
    backgroundColor: '#66C183',
    color: '#fff',
  },
};

export { config, IikoCardState };
