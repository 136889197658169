import cn from 'classnames';
import { Formik, FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import { Badge, Tooltip } from 'reactstrap';
import Loader from 'react-loaders';

import styles from './IikoAPIConnection.module.scss';

import { IikoIcon, QuestionMarkIcon, SettingsIcon, SyncIcon } from '@/components/icons';
import { AppButton, AppButtonGroup, AppInputField, AppModal } from '@/components/app';
import { Yup } from '@/utils/yup';
import { SettingsService } from '@/services';
import { notify } from '@/utils/notifications';
import { ServerValidationError } from '@/api/exceptions';
import { IikoProps } from '@/models/entity';
import { freeDateFormat } from '@/utils/date';

type IikoServerFields = {
  iikoDeliveryLogin: string;
  iikoDeliveryPassword: string;
  iikoTransportKey: string;
  iikoServerUrl: string;
  iikoServerLogin: string;
  iikoServerPassword: string;
};

export default function IikoAPIConnection() {
  const validationSchema = Yup.object().shape({
    iikoDeliveryLogin: Yup.string().required(' '),
    iikoDeliveryPassword: Yup.string().required(' '),
    iikoTransportKey: Yup.string().required(' '),
    iikoServerUrl: Yup.string().required(' '),
    iikoServerLogin: Yup.string().required(' '),
    iikoServerPassword: Yup.string().required(' '),
  });

  //
  // State
  //

  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [iikoServerModalOpened, setIikoServerModalOpened] = useState<boolean>(false);
  const [iikoProps, setIikoProps] = useState<IikoProps | null>(null);
  const [iikoServerUrlFieldTooltipOpen, setIikoServerUrlFieldTooltipOpen] = useState<boolean>(false);

  //
  // Methods
  //

  const handleSaveIikoServerSettings = async (
    values: IikoServerFields,
    setErrors: (errors: FormikErrors<IikoServerFields>) => void,
  ) => {
    try {
      setFormLoading(true);
      const {
        iikoDeliveryLogin,
        iikoDeliveryPassword,
        iikoServerLogin,
        iikoServerPassword,
        iikoServerUrl,
        iikoTransportKey,
      } = values;
      await SettingsService.updateIikoCredentials(
        iikoDeliveryLogin,
        iikoDeliveryPassword,
        iikoServerLogin,
        iikoServerPassword,
        iikoServerUrl,
        iikoTransportKey,
      );
      setIikoServerModalOpened(false);
      loadIikoProps();
      notify('Настройки сервера сохранены', 'success');
    } catch (error) {
      if (error instanceof ServerValidationError) {
        if (error.fields.iiko_server_host) {
          notify(error.fields.iiko_server_host[0], 'error', <IikoIcon />);
          setErrors({ iikoServerUrl: ' ' });
        } else if (error.fields.iiko_card) {
          notify(error.fields.iiko_card as any, 'error', <IikoIcon />);
          setErrors({ iikoDeliveryLogin: ' ', iikoDeliveryPassword: ' ' });
        } else if (error.fields.iiko_server) {
          notify(error.fields.iiko_server as any, 'error', <IikoIcon />);
          setErrors({ iikoServerLogin: ' ', iikoServerPassword: ' ', iikoServerUrl: ' ' });
        } else {
          notify(error.message, 'error', <IikoIcon />);
        }
      } else {
        throw error;
      }
    } finally {
      setFormLoading(false);
    }
  };

  const loadIikoProps = async () => {
    try {
      const iikoPropsData = await SettingsService.getIikoProps();
      setIikoProps(iikoPropsData);
    } catch (error) {
      if (navigator.onLine) {
        notify('Не удалось загрузить данные iiko', 'error');
      }

      throw error;
    }
  };

  const syncIikoSettings = async () => {
    try {
      setSyncLoading(true);
      await SettingsService.syncIikoSettings();
      await loadIikoProps();
      notify('Синхронизация прошла успешно', 'success', <SyncIcon className={styles.syncNotificationIcon} />);
    } catch (error) {
      if (navigator.onLine) {
        notify('Не удалось синхронизировать', 'error');
      }

      throw error;
    } finally {
      setSyncLoading(false);
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    loadIikoProps();
  }, []);

  //
  // Render
  //

  return (
    <section className={styles.host}>
      <div className={styles.connections}>
        <h2>Подключение API и плагинов</h2>
        {iikoProps?.syncDate && <p>Данные на {freeDateFormat(iikoProps.syncDate, 'HH:mm dd.MM.yyyy')}</p>}

        <div className={styles.iikoApiList}>
          <div>
            <h2>АПИ iikoDelivery</h2>

            <div className={styles.connectionStatus}>
              <h3>Статус подключения:</h3>

              {iikoProps?.iikoDeliveryEnabled == null ? null : (
                <Badge
                  className={styles.connectionBadge}
                  style={{
                    backgroundColor: iikoProps?.iikoDeliveryEnabled ? '#54A651' : '#B22B2B',
                    color: '#fff',
                  }}
                >
                  {iikoProps?.iikoDeliveryEnabled ? 'Подключен' : 'Не подключен'}
                </Badge>
              )}
            </div>
          </div>

          <div>
            <h2>АПИ iikoTransport</h2>

            <div className={styles.connectionStatus}>
              <h3>Статус подключения:</h3>

              {iikoProps?.iikoTransportEnabled == null ? null : (
                <Badge
                  className={styles.connectionBadge}
                  style={{
                    backgroundColor: iikoProps?.iikoTransportEnabled ? '#54A651' : '#B22B2B',
                    color: '#fff',
                  }}
                >
                  {iikoProps?.iikoTransportEnabled ? 'Подключен' : 'Не подключен'}
                </Badge>
              )}
            </div>
          </div>

          <div>
            <h2>АПИ iikoServer</h2>

            <div className={styles.connectionStatus}>
              <h3>Статус подключения:</h3>

              {iikoProps?.iikoServerEnabled == null ? null : (
                <Badge
                  className={styles.connectionBadge}
                  style={{
                    backgroundColor: iikoProps?.iikoServerEnabled ? '#54A651' : '#B22B2B',
                    color: '#fff',
                  }}
                >
                  {iikoProps?.iikoServerEnabled ? 'Подключен' : 'Не подключен'}
                </Badge>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <AppButtonGroup
          className={cn(styles.syncBtn, styles.actionBtn)}
          disabled={syncLoading}
          leftSlot={<SyncIcon />}
          onClick={syncIikoSettings}
        >
          Переподключить
        </AppButtonGroup>
        <AppButtonGroup
          className={cn(styles.serverSettingsBtn, styles.actionBtn)}
          disabled={syncLoading}
          leftSlot={<SettingsIcon />}
          onClick={() => setIikoServerModalOpened(true)}
        >
          Настройки сервера
        </AppButtonGroup>
      </div>

      <AppModal isOpen={iikoServerModalOpened} onClose={() => setIikoServerModalOpened(false)}>
        <section className={styles.iikoServerModal}>
          <h2>Настройки сервера</h2>

          <Formik
            initialValues={
              {
                iikoDeliveryLogin: iikoProps?.iikoDeliveryLogin || '',
                iikoDeliveryPassword: '',
                iikoTransportKey: iikoProps?.iikoTransportKey || '',
                iikoServerUrl: iikoProps?.iikoServerUrl || '',
                iikoServerLogin: iikoProps?.iikoServerLogin || '',
                iikoServerPassword: '',
              } as IikoServerFields
            }
            onSubmit={(values, { setErrors }) => {
              handleSaveIikoServerSettings(values, setErrors);
            }}
            validationSchema={validationSchema}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit, submitCount, isValid, dirty }) => {
              return (
                <form className={styles.form} onSubmit={handleSubmit}>
                  <div className={styles.fieldSet}>
                    <AppInputField
                      className={styles.field}
                      controlType="input"
                      error={submitCount > 0 && errors.iikoDeliveryLogin}
                      label="Логин iikoDelivery"
                      name="iikoDeliveryLogin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder=""
                      required
                      value={values.iikoDeliveryLogin}
                    />

                    <AppInputField
                      className={styles.field}
                      controlType="password"
                      error={submitCount > 0 && errors.iikoDeliveryPassword}
                      label="Пароль iikoDelivery"
                      name="iikoDeliveryPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder=""
                      required
                      value={values.iikoDeliveryPassword}
                    />

                    <AppInputField
                      className={styles.field}
                      controlType="input"
                      error={submitCount > 0 && errors.iikoTransportKey}
                      label="Ключ iikoTransport"
                      name="iikoTransportKey"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder=""
                      required
                      value={values.iikoTransportKey}
                    />

                    <div className={styles.iikoServerUrlField}>
                      <div className={styles.iikoServerUrlFieldTooltip}>
                        <QuestionMarkIcon id="iikoServerUrlFieldTooltip" />
                        <Tooltip
                          className="tooltip-light"
                          innerClassName={styles.iikoServerUrlFieldTooltipInner}
                          isOpen={iikoServerUrlFieldTooltipOpen}
                          placement={'top'}
                          target="iikoServerUrlFieldTooltip"
                          toggle={() => setIikoServerUrlFieldTooltipOpen(!iikoServerUrlFieldTooltipOpen)}
                        >
                          Адрес страницы iikoServer должен иметь вид: https://*****/resto
                        </Tooltip>
                      </div>

                      <AppInputField
                        className={styles.field}
                        controlType="input"
                        error={submitCount > 0 && errors.iikoServerUrl}
                        label="Адрес страницы iikoServer"
                        name="iikoServerUrl"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder=""
                        required
                        value={values.iikoServerUrl}
                      />
                    </div>

                    <AppInputField
                      className={styles.field}
                      controlType="input"
                      error={submitCount > 0 && errors.iikoServerLogin}
                      label="Логин iikoServer"
                      name="iikoServerLogin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder=""
                      required
                      value={values.iikoServerLogin}
                    />

                    <AppInputField
                      className={styles.field}
                      controlType="password"
                      error={submitCount > 0 && errors.iikoServerPassword}
                      label="Пароль iikoServer"
                      name="iikoServerPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder=""
                      required
                      value={values.iikoServerPassword}
                    />
                  </div>

                  <AppButton className={styles.actionBtn} disabled={formLoading || !isValid || !dirty} type="submit">
                    Сохранить
                  </AppButton>
                </form>
              );
            }}
          </Formik>
        </section>
      </AppModal>
      {syncLoading && (
        <div className={cn(styles.syncLoadingOverlay, 'animate__animated animate__fadeIn')}>
          <Loader active type="ball-clip-rotate" />
          <p>
            Синхронизируем данные с iiko. <br /> Подождите, пожалуйста, синхронизация займет не больше минуты.
          </p>
        </div>
      )}
    </section>
  );
}
