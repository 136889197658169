import { ReactElement, SyntheticEvent } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

interface Props {
  active: boolean;
  className?: string;
  onClick(e: SyntheticEvent): void;
  to?: string;
  children: ReactElement | ReactElement[];
  external?: boolean;
  hasSubItems?: boolean;
  hasActiveChild?: boolean
}

export default function ItemLink(props: Props) {
  const {
    hasActiveChild,
    hasSubItems,
    className,
    external,
    to = '#',
    onClick,
    active,
    children,
  } = props

  const href = hasSubItems ? null : to

  return (
    hasSubItems || external
      ? (
        <a
          className={
            classnames(
              className,
              active && 'active',
              hasActiveChild && 'has-active-child',
            )
          }
          href={href || '#'}
          onClick={onClick}
          rel="noreferrer"
          target={external ? '_blank' : undefined}
        >
          {children}
        </a>
      )
      : (
        <Link
          className={
            classnames(
              className,
              active && 'active',
            )
          }
          to={to}
        >
          {children}
        </Link>
      )
  )
}
