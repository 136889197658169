export default class PlantSettings {
  public reportConception: string | null;
  public reportRevenueAccount: string | null;
  public reportExpenseAccount: string | null;

  public constructor(data: Partial<PlantSettings>) {
    this.reportConception = data.reportConception ?? null;
    this.reportRevenueAccount = data.reportRevenueAccount ?? null;
    this.reportExpenseAccount = data.reportExpenseAccount ?? null;
  }
}
