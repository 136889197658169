import { Genders } from '@/constants';
import School from './School';
import SchoolClass from './SchoolClass';

export default class TeacherListItem {
  public id: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public phone: string;
  public gender?: Genders;
  public assignedClasses: SchoolClass[] ;
  public iikoWalletExist: boolean;
  public school: School | null;

  public constructor(data: Partial<TeacherListItem>) {
    this.id = data.id!;
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.middleName = data.middleName ?? '';
    this.phone = data.phone ?? '';
    this.gender = data.gender ?? undefined;
    this.assignedClasses = data.assignedClasses ?? [];
    this.iikoWalletExist = data.iikoWalletExist ?? false;
    this.school = data.school ?? null;
  }

  public get FIO(): string {
    return [this.lastName, this.firstName, this.middleName].filter(Boolean).join(' ');
  }
}
