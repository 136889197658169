import React from 'react';

type Props = {
  className?: string;
  id?: string;
};

export default function QuestionMarkIcon(props: Props) {
  const { className, id } = props;

  return (
    <svg
      className={className}
      fill="none"
      height="16"
      id={id}
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7 12.8H9.3V11.2H7.7V12.8ZM8.5 0C4.084 0 0.5 3.584 0.5 8C0.5 12.416 4.084 16 8.5 16C12.916 16 16.5 12.416 16.5 8C16.5 3.584 12.916 0 8.5 0ZM8.5 14.4C4.972 14.4 2.1 11.528 2.1 8C2.1 4.472 4.972 1.6 8.5 1.6C12.028 1.6 14.9 4.472 14.9 8C14.9 11.528 12.028 14.4 8.5 14.4ZM8.5 3.2C6.732 3.2 5.3 4.632 5.3 6.4H6.9C6.9 5.52 7.62 4.8 8.5 4.8C9.38 4.8 10.1 5.52 10.1 6.4C10.1 8 7.7 7.8 7.7 10.4H9.3C9.3 8.6 11.7 8.4 11.7 6.4C11.7 4.632 10.268 3.2 8.5 3.2Z"
        fill="black"
      />
    </svg>
  );
}
