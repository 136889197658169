import { SyntheticEvent } from 'react'
import classnames from 'classnames'

import Item from './Item'
import { HashedNavItem } from '../'

interface Props {
  classNameStateIcon?: string;
  hideSubmenu(hash: string): void;
  showSubmenu(parentHashes: string[], hash: string): void;
  visibleSubmenus: string[];
  activeItems: string[];
  items: HashedNavItem[];
  visible?: boolean;
  onActivate(e: SyntheticEvent, item: any, subMenuVisibility: boolean, hasSubItems?: boolean): boolean;
}

export default function Container(props: Props){
  const {
    visible = false,
    items,
    activeItems,
    hideSubmenu,
    showSubmenu,
    visibleSubmenus,
    classNameStateIcon,
    onActivate,
  } = props

  return (
    <ul
      className={classnames(
        'light-menu-container',
        visible && 'visible',
      )}
    >
      {items.map((item) => (
        <Item
          key={item.hash}
          {...item}
          activeItems={activeItems}
          classNameStateIcon={classNameStateIcon}
          hideSubmenu={hideSubmenu}
          onActivate={onActivate}
          parentHashes={item.parentHashes}
          showSubmenu={showSubmenu}
          visibleSubmenus={visibleSubmenus}
        />
      ))}
    </ul>
  )
}