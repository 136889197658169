import { Genders } from '@/constants';
import ParentListItemStudent from './ParentListItemStudent';
import School from './School';

export default class ParentListItem {
  public id: string;
  public firstName: string;
  public lastName: string;
  public phone: string | null;
  public middleName: string;
  public birthdate: Date | null;
  public lastLogin: Date | null;
  public dateJoined: Date | null;
  public isActive: boolean;
  public gender: Genders;
  public children: ParentListItemStudent[]
  public school: School | null

  public constructor(data: Partial<ParentListItem>) {
    this.id = data.id!;
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.middleName = data.middleName ?? '';
    this.birthdate = data.birthdate ?? null;
    this.lastLogin = data.lastLogin ?? null;
    this.dateJoined = data.dateJoined ?? null;
    this.isActive = data.isActive ?? false;
    this.gender = data.gender ?? Genders.notDefined;
    this.phone = data.phone ?? null;
    this.children = data.children ?? [];
    this.school = data.school ?? null;
  }

  public get FIO(): string {
    return [this.lastName, this.firstName, this.middleName].filter(Boolean).join(' ');
  }

  public get FIOShort(): string {
    const firstNameShort = this.firstName[0] ? this.firstName[0] + '.' : undefined;
    const middleNameShort = this.middleName[0] ? this.middleName[0] + '.' : undefined;

    return [this.lastName, firstNameShort, middleNameShort].filter(Boolean).join(' ');
  }
}
