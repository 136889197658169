enum ClassesValue {
  '1-4' = '1-4',
  '5-11' = '5-11',
}

enum FieldSetKeys {
  'accounting' = 'accounting',
  'visits' = 'visits',
  'beneficiaries' = 'beneficiaries',
}

export { ClassesValue, FieldSetKeys };
