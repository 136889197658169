import { MouseEvent, ReactNode } from 'react';
import { Button } from 'reactstrap';
import cn from 'classnames';

import styles from './AppButton.module.scss';

type Props = {
  onClick?(e: MouseEvent): void;
  disabled?: boolean;
  outline?: boolean;
  children: ReactNode;
  color?: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'link';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
};

export default function AppButton(props: Props) {
  const {
    onClick = () => {},
    children,
    color = 'primary',
    type = 'button',
    className = '',
    disabled = false,
    outline = false,
  } = props;

  return (
    <Button
      className={cn(styles.host, className)}
      color={color}
      disabled={disabled}
      onClick={onClick}
      outline={outline}
      type={type}
    >
      {children}
    </Button>
  );
}
