import School from './School';
import Teacher from './Teacher';

export default class SchoolClass {
  public id: string;
  public liter: string;
  public number: number | null;
  public createdAt: Date | null;
  public mealRequestDate: string | null;
  public mealRequestDeadlineDate: string | null;
  public studentsCount: number;
  public responsible: Teacher | null;
  public school: School | null

  public constructor(data: Partial<SchoolClass>) {
    this.id = data.id!;
    this.createdAt = data.createdAt ?? null;
    this.studentsCount = data.studentsCount ?? 0;
    this.liter = data.liter ?? '';
    this.number = data.number ?? null;
    this.responsible = data.responsible ?? null;
    this.mealRequestDate = data.mealRequestDate ?? null;
    this.mealRequestDeadlineDate = data.mealRequestDeadlineDate ?? null;
    this.school = data.school ?? null;
  }

  public get name(): string {
    return [this.number, this.liter].filter(Boolean).join(' ').toLocaleUpperCase();
  }
}
