import styles from './AppUnsavedChangesPrompt.module.scss';

import AppModal from '../AppModal';
import AppButton from '../AppButton';
import usePrompt from '@/hooks/usePrompt';

type Props = {
  when: boolean;
  title?: string;
  message?: string;
  confirmCallback?: () => void;
  cancelCallback?: () => void;
  active?: boolean;
};

export default function AppUnsavedChangesPrompt(props: Props) {
  const { when, title, message, confirmCallback, active, cancelCallback } = props;

  //
  // Effects
  //

  const { isActive, onCancel, onConfirm } = usePrompt(when);

  //
  // Render
  //

  return (
    <AppModal closeIcon={false} isOpen={isActive || active} onClose={() => onCancel()}>
      <section className={styles.host}>
        <h2>{title || 'Изменения не сохранены'}</h2>
        <p>{message || 'Вы уверены, что хотите выйти без сохранения изменений?'}</p>

        <div className={styles.actions}>
          <AppButton
            className={styles.actionBtn}
            onClick={() => {
              onCancel();
              cancelCallback && cancelCallback();
            }}
            outline
          >
            Отмена
          </AppButton>
          <AppButton
            className={styles.actionBtn}
            onClick={() => {
              onConfirm();
              confirmCallback && confirmCallback();
            }}
          >
            Да
          </AppButton>
        </div>
      </section>
    </AppModal>
  );
}
