/* eslint-disable camelcase */
import { AppFlavor } from '@/flavors';
import cn from 'classnames';

import styles from './AppLoader.module.scss';

interface Props {
  active: boolean;
  message?: string;
}

export default function AppLoader(props: Props) {
  const { logoShort } = AppFlavor;
  const { active = false, message } = props;

  return (
    <div className={cn(styles.host, 'animate__animated', { animate__fadeIn: active, animate__fadeOut: !active })}>
      <div
        className={cn(styles.content, 'animate__animated', {
          animate__fadeIn: active,
          animate__fadeOut: !active,
        })}
      >
        <img alt="Логотип" src={logoShort} />
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
}
