import { RefreshIcon } from '@/components/icons';

import style from './AppRefreshButton.module.scss';

interface Props {
  onClick(): void;
  disabled?: boolean;
  className?: string;
}

export default function AppRefreshButton(props: Props) {
  const { onClick, disabled = false, className = '' } = props;

  return (
    <button
      className={`btn ${style.host} ${className}`}
      disabled={disabled}
      onClick={onClick}
      title="Обновить"
      type="button"
    >
     <RefreshIcon />
    </button>
  );
}
