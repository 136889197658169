import School from './School';

export default class Menu {
  public id: string;
  public name: string;
  public createdAt: Date | null;
  public updatedAt: Date | null;
  public url: string | null;
  public schools: School[];

  public constructor(data: Partial<Menu>) {
    this.id = data.id!;
    this.name = data.name ?? '';
    this.createdAt = data.createdAt ?? null;
    this.updatedAt = data.updatedAt ?? null;
    this.url = data.url ?? null;
    this.schools = data.schools ?? [];
  }
}
