import { useSelector } from 'react-redux';
import { Suspense, useEffect } from 'react';
import cx from 'classnames';
import { Outlet } from 'react-router-dom';

import styles from './DashboardLayout.module.scss';

import { RootState, useAppDispatch } from '@/store';
import { Header, ModalsHub, Sidebar } from './components';
import { NotificationHub } from '../components';
import { setIsUserLoading, setUser } from '@/store/profileSlice';
import ProfileService from '@/services/ProfileService';
import { setIsSchoolsLoading, setSchools } from '@/store/schoolsSlice';
import { IikoService, SchoolsService } from '@/services';
import { setMarketingCampaigns, setWriteoffStores } from '@/store/iikoSlice';

export default function DashboardLayout() {
  //
  // Store
  //

  const dispatch = useAppDispatch();
  const { sidebarClosed } = useSelector((state: RootState) => state.layout);

  //
  // Methods
  //

  const loadUser = async () => {
    try {
      dispatch(setIsUserLoading(true));
      const me = await ProfileService.getMe();
      dispatch(setUser(me));
    } catch (error) {
      throw error;
    } finally {
      dispatch(setIsUserLoading(false));
    }
  };

  const loadSchools = async () => {
    try {
      dispatch(setIsSchoolsLoading(true));
      const schools = await SchoolsService.getAllSchools();
      dispatch(setSchools(schools));
    } catch (error) {
      throw error;
    } finally {
      dispatch(setIsSchoolsLoading(false));
    }
  };

  const loadWriteoffStores = async () => {
    const writeoffStores = await IikoService.getWriteOffStores();
    dispatch(setWriteoffStores(writeoffStores));
  };

  const loadMarketingCampaigns = async () => {
    const marketingCampaigns = await IikoService.getMarketingCampaigns();
    dispatch(setMarketingCampaigns(marketingCampaigns));
  };

  //
  // Effects
  //

  useEffect(() => {
    loadUser();
    loadSchools();
    loadWriteoffStores();
    loadMarketingCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Render
  //

  return (
    <div
      className={cx('app-container fixed-header fixed-sidebar body-tabs-shadow-btn', {
        'closed-sidebar': sidebarClosed,
      })}
    >
      <div className={styles.host}>
        <Header />
        <div className="app-main">
          <Sidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Suspense fallback={''}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
      <ModalsHub />
      <NotificationHub />
    </div>
  );
}
