import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Badge, DropdownItem } from 'reactstrap';
import { Formik, FormikErrors } from 'formik';

import styles from './RequestsPage.module.scss';

import { PageTitle } from '@/layout/DashboardLayout/components';
import { AppButton, AppButtonGroup, AppInputField, AppLoader, AppModal, AppSearch, AppTable } from '@/components/app';
import { RootState } from '@/store';
import AppDropdownCheckboxSelect from '@/components/app/AppDropdownCheckboxSelect';
import { useTableUrlParams } from '@/hooks';
import { RequestStatus, config as RequestStatusConfig } from '@/constants/kitchenRequests';
import { KitchenRequest } from '@/models/entity';
import { SchoolsService } from '@/services';
import { formatDateToDateAndTime, freeDateFormat } from '@/utils/date';
import { formatPhone } from '@/utils/formatters';
import { Yup } from '@/utils/yup';
import { EditRequestModal } from './components';
import { notify } from '@/utils/notifications';
import { ServerValidationError } from '@/api/exceptions';
import { ListIcon } from '@/components/icons';

type FormValues = {
  schools: string[];
};

const STATUS_FILTER = 'status';

export default function RequestsPage() {
  const {
    search: urlSearch,
    currentPage: urlCurrentPage,
    itemsPerPage: urlItemsPerPage,
    filters,
  } = useTableUrlParams();

  //
  // State
  //

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [schoolsFilter, setSchoolsFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<RequestStatus | undefined>(filters[STATUS_FILTER]);
  const [searchFilter, setSearchFilter] = useState<string>(urlSearch || '');
  const [kitchenRequests, setKitchenRequests] = useState<KitchenRequest[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState<number>(urlItemsPerPage);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [createRequestModalOpened, setCreateRequestModalOpened] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [requestEditModal, setRequestEditModal] = useState<{
    open: boolean;
    kitchenRequest: KitchenRequest | null;
    loading: boolean;
  }>({
    open: false,
    kitchenRequest: null,
    loading: false,
  });

  //
  // Computed
  //

  const statuses = useMemo(() => Object.values(RequestStatusConfig), []);
  const validationSchema = Yup.object().shape({
    schools: Yup.array().min(1).required(' '),
  });

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Methods
  //

  const loadRequests = useCallback(async () => {
    try {
      setTableLoading(true);
      const { count, data } = await SchoolsService.getKitchenRequests({
        currentPage,
        itemsPerPage,
        schoolsIds: schoolsFilter,
        search: searchFilter,
        status: statusFilter,
      });

      setKitchenRequests(data);
      setTotalPages(Math.ceil(count / itemsPerPage));
    } catch (error) {
      throw error;
    } finally {
      setTableLoading(false);
    }
  }, [currentPage, itemsPerPage, schoolsFilter, searchFilter, statusFilter]);

  const handleCreateRequest = async (values: FormValues, setErrors: (errors: FormikErrors<FormValues>) => void) => {
    try {
      setFormLoading(true);
      await SchoolsService.makeKitchenRequest(values.schools);
      setCreateRequestModalOpened(false);
    } catch (error) {
      throw error;
    } finally {
      setFormLoading(false);
    }
  };

  const handleUpdateRequest = async (studentsIds: string[]) => {
    if (!requestEditModal.kitchenRequest) return;

    try {
      setRequestEditModal({ kitchenRequest: requestEditModal.kitchenRequest, loading: true, open: true });
      await SchoolsService.updateKitchenRequest(requestEditModal.kitchenRequest.id, studentsIds);
      notify('Заявка успешно подана', 'success');
      setRequestEditModal({ kitchenRequest: null, loading: false, open: false });
      loadRequests();
    } catch (error) {
      setRequestEditModal({ kitchenRequest: requestEditModal.kitchenRequest, loading: false, open: true });

      if (error instanceof ServerValidationError) {
        notify(error.message, 'error');
      }

      throw error;
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    loadRequests();
  }, [loadRequests]);

  //
  // Render
  //

  const renderActions = (request: KitchenRequest) => {
    return [
      <DropdownItem
        key="edit"
        onClick={() => setRequestEditModal({ open: true, kitchenRequest: request, loading: false })}
      >
        <span className="dropdown-icon material-icons">create</span>
        Редактировать
      </DropdownItem>,
    ];
  };

  return (
    <>
      <PageTitle heading="Заявки" svg={<ListIcon />} />

      <section className={styles.host}>
        <section className={styles.topActions}>
          <div className={styles.filters}>
            <AppButtonGroup
              className={cn(styles.addRequestBtn, 'animate__animated animate__fadeIn')}
              leftSlot={<FontAwesomeIcon fixedWidth icon={faPlus} />}
              onClick={() =>
                schoolsFilter.length === 1
                  ? SchoolsService.makeKitchenRequest([schoolsFilter[0]])
                  : setCreateRequestModalOpened(true)}
            >
              Сформировать заявку на кухню
            </AppButtonGroup>

            <AppInputField
              className={styles.schoolsFilter}
              controlType="multi-select"
              onChange={(value) => setSchoolsFilter(value)}
              options={schools.map((s) => ({
                label: s.name,
                value: s.id,
              }))}
              placeholder="Выберите школу"
              value={schoolsFilter}
            />

            <AppDropdownCheckboxSelect
              onChange={(option) =>
                !option
                  ? setStatusFilter(undefined)
                  : setStatusFilter(statuses.find((s) => s.value === option.value)?.value)}
              options={statuses.map((otc) => ({ label: otc.name, value: otc.value }))}
              placeholder="Выберите статус"
              value={
                statusFilter
                  ? {
                    label: statuses.find((o) => o.value === statusFilter)?.name ?? '',
                    value: statusFilter,
                  }
                  : null
              }
            />
          </div>

          <AppSearch
            onChange={(value) => {
              setSearchFilter(value);
            }}
            onClickRefreshButton={loadRequests}
            value={searchFilter}
          />
        </section>

        <AppLoader active={tableLoading} />
        <AppTable
          columns={[
            {
              value: (request) => request.school?.name,
              className: 'text-left',
              header: {
                title: 'Название школы',
                className: 'text-left',
                style: {
                  width: 178,
                },
              },
            },
            {
              value: (request) => {
                if (!request.date) return '';

                return (
                  <>
                    {freeDateFormat(request.date, 'dd MMMM')}
                    <br />({freeDateFormat(request.date, 'EEEE')})
                  </>
                );
              },
              className: 'text-left',
              header: {
                title: 'На какой\nдень',
                className: 'text-left',
                style: {
                  width: 120,
                },
              },
            },
            {
              value: (request) => request.number,
              className: 'text-left',
              header: {
                title: 'Номер\nзаявки',
                className: 'text-left',
                style: {
                  width: 70,
                },
              },
            },
            {
              value: (request) => {
                if (!request.status) return null;

                const configValue = RequestStatusConfig[request.status];

                return (
                  <Badge
                    style={{
                      backgroundColor: configValue.backgroundColor,
                      color: configValue.color,
                    }}
                  >
                    {configValue.name}
                  </Badge>
                );
              },
              className: 'text-left',
              header: {
                title: 'Статус',
                className: 'text-left',
                style: {
                  width: 100,
                },
              },
            },
            {
              value: (request) => request.schoolClass?.name,
              className: 'text-left',
              header: {
                title: 'Класс',
                className: 'text-left',
                style: {
                  width: 50,
                },
              },
            },
            {
              value: (request) => {
                return (
                  <>
                    {request.responsible?.FIO}
                    <br />
                    {formatPhone(request.responsible?.phone)}
                  </>
                );
              },
              className: 'text-left',
              header: {
                title: 'Ответственный по питанию',
                className: 'text-left',
              },
            },
            {
              value: (request) => request.breakfastYoungCount,
              className: 'text-left',
              header: {
                title: (
                  <div className={styles.counterHeaderCell}>
                    Завтрак <span>6-10</span>
                  </div>
                ),
                className: 'text-left',
                style: {
                  width: 65,
                },
              },
            },
            {
              value: (request) => request.lunchYoungCount,
              className: 'text-left',
              header: {
                title: (
                  <div className={styles.counterHeaderCell}>
                    Обед <span>6-10</span>
                  </div>
                ),
                className: 'text-left',
                style: {
                  width: 65,
                },
              },
            },
            {
              value: (request) => request.afternoonSnackYoungCount,
              className: 'text-left',
              header: {
                title: (
                  <div className={styles.counterHeaderCell}>
                    Полдник <span>6-10</span>
                  </div>
                ),
                className: 'text-left',
                style: {
                  width: 65,
                },
              },
            },
            {
              value: (request) => request.breakfastTeenCount,
              className: 'text-left',
              header: {
                title: (
                  <div className={styles.counterHeaderCell}>
                    Завтрак <span>11-18</span>
                  </div>
                ),
                className: 'text-left',
                style: {
                  width: 65,
                },
              },
            },
            {
              value: (request) => request.lunchTeenCount,
              className: 'text-left',
              header: {
                title: (
                  <div className={styles.counterHeaderCell}>
                    Обед <span>11-18</span>
                  </div>
                ),
                className: 'text-left',
                style: {
                  width: 65,
                },
              },
            },
            {
              value: (request) => request.afternoonSnackTeenCount,
              className: 'text-left',
              header: {
                title: (
                  <div className={styles.counterHeaderCell}>
                    Полдник <span>11-18</span>
                  </div>
                ),
                className: 'text-left',
                style: {
                  width: 65,
                },
              },
            },
            {
              value: (request) => {
                if (!request.updateAt) return '';

                const { date } = formatDateToDateAndTime(request.updateAt);

                return date;
              },
              className: 'text-left',
              header: {
                title: 'Дата\nпосл. изм.',
                className: 'text-left',
                style: {
                  width: 85,
                },
              },
            },
            {
              value: (schoolClass) => renderActions(schoolClass),
              type: 'action',
              header: {
                title: 'Действия',
                style: {
                  width: 80,
                },
              },
            },
          ]}
          data={kitchenRequests}
          pagination={{
            totalPages,
            currentPage,
            itemsPerPage,
            onChangePage: (newPage: number) => setCurrentPage(newPage),
            onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
          }}
        />
      </section>

      <AppModal isOpen={createRequestModalOpened} onClose={() => setCreateRequestModalOpened(false)}>
        <section className={styles.modal}>
          <h2>Сформировать заявку на кухню</h2>
          <p>Выберите школы, заявки которых вы хотите отправить на кухню</p>

          <Formik
            initialValues={
              {
                schools: schoolsFilter.length === 1 ? schoolsFilter : [],
              } as FormValues
            }
            onSubmit={(values, { setErrors }) => {
              handleCreateRequest(values, setErrors);
            }}
            validationSchema={validationSchema}
          >
            {({ values, errors, handleBlur, handleSubmit, submitCount, setFieldValue, isValid }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <AppInputField
                  controlType="multi-select"
                  disabled={formLoading}
                  error={submitCount > 0 && !!errors.schools}
                  label="Школы"
                  name="schools"
                  onBlur={handleBlur}
                  onChange={(value) => setFieldValue('schools', value)}
                  options={schools.map((s) => ({ label: s.name, value: s.id }))}
                  required
                  value={values.schools}
                />

                <AppButton className={styles.submitBtn} disabled={formLoading || !isValid} type="submit">
                  Сформировать
                </AppButton>
              </form>
            )}
          </Formik>
        </section>
      </AppModal>

      <EditRequestModal
        kitchenRequest={requestEditModal.kitchenRequest}
        loading={requestEditModal.loading}
        onClose={() => setRequestEditModal({ open: false, kitchenRequest: null, loading: false })}
        onSubmit={(studentsIds) => handleUpdateRequest(studentsIds)}
        open={requestEditModal.open}
      />
    </>
  );
}
