export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_SIDEBAR_CLOSED = 'THEME_OPTIONS/SET_SIDEBAR_CLOSED';

export const setEnableFixedSidebar = (enableFixedSidebar: boolean) => ({
  type: SET_ENABLE_FIXED_SIDEBAR,
  enableFixedSidebar,
});

export const setSidebarClosed = (sidebarClosed: boolean) => ({
  type: SET_SIDEBAR_CLOSED,
  sidebarClosed,
});

export const setEnableMobileMenu = (enableMobileMenu: boolean) => ({
  type: SET_ENABLE_MOBILE_MENU,
  enableMobileMenu,
});

export const setEnableMobileMenuSmall = (enableMobileMenuSmall: boolean) => ({
  type: SET_ENABLE_MOBILE_MENU_SMALL,
  enableMobileMenuSmall,
});

export default function layoutSlice(
  state = {
    sidebarClosed: false,
    enableMobileMenu: false,
    enableMobileMenuSmall: '',
  },
  action: any,
) {
  switch (action.type) {
    case SET_ENABLE_FIXED_SIDEBAR:
      return {
        ...state,
        enableFixedSidebar: action.enableFixedSidebar,
      };

    case SET_ENABLE_MOBILE_MENU:
      return {
        ...state,
        enableMobileMenu: action.enableMobileMenu,
      };

    case SET_ENABLE_MOBILE_MENU_SMALL:
      return {
        ...state,
        enableMobileMenuSmall: action.enableMobileMenuSmall,
      };

    case SET_SIDEBAR_CLOSED:
      return {
        ...state,
        sidebarClosed: action.sidebarClosed,
      };

    default:
      return state;
  }
}
