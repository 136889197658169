type Props = {
  onClick?(): void;
  className?: string;
};

export default function ActionIcon(props: Props) {
  const { onClick = () => {}, className = '' } = props;

  return (
    <svg
      className={className}
      fill="none"
      height="20"
      onClick={onClick}
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="9.99997"
        r="8.75082"
        stroke="#545CD8"
        strokeWidth="2"
      />
      <path
        d="M5.49979 11.5001C4.67472 11.5001 3.99966 10.8251 3.99966 10C3.99966 9.17493 4.67472 8.49987 5.49979 8.49987C6.32486 8.49987 6.99992 9.17493 6.99992 10C6.99992 10.8251 6.32486 11.5001 5.49979 11.5001ZM14.5005 11.5001C13.6755 11.5001 13.0004 10.8251 13.0004 10C13.0004 9.17493 13.6755 8.49987 14.5005 8.49987C15.3256 8.49987 16.0007 9.17493 16.0007 10C16.0007 10.8251 15.3256 11.5001 14.5005 11.5001ZM10.0002 11.5001C9.1751 11.5001 8.50004 10.8251 8.50004 10C8.50004 9.17493 9.1751 8.49987 10.0002 8.49987C10.8252 8.49987 11.5003 9.17493 11.5003 10C11.5003 10.8251 10.8252 11.5001 10.0002 11.5001Z"
        fill="#545CD8"
      />
    </svg>
  );
}
