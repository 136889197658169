import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { Badge, Spinner, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { isBefore } from 'date-fns';

import styles from './ComplexDishUpdatePage.module.scss';

import { PageTitle } from '@/layout/DashboardLayout/components';
import { ComplexDish, ComplexDishPlan } from '@/models/entity';
import { SettingsService } from '@/services';
import {
  AppButton,
  AppButtonGroup,
  AppDateInput,
  AppInputField,
  AppLoader,
  AppModal,
  AppUnsavedChangesPrompt,
} from '@/components/app';
import { freeDateFormat, getMidnight } from '@/utils/date';
import {
  ComplexDishKindByAgeRanges,
  complexDishKindByAgeRangesConfig,
  ComplexDishStatus,
} from '@/constants/complexDishes';
import { CogsIcon, QuestionMarkIcon, SyncIcon } from '@/components/icons';
import { notify } from '@/utils/notifications';
import { ServerValidationError } from '@/api/exceptions';

export default function ComplexDishUpdatePage() {
  const { dishId } = useParams();

  //
  // State
  //

  const [loading, setLoading] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [complexDish, setComplexDish] = useState<ComplexDish | null>(null);
  const [questionMarkTooltipOpened, setQuestionMarkTooltipOpened] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [availableComplexPlans, setAvailableComplexPlans] = useState<ComplexDishPlan[]>([]);
  const [perDayComplexPlans, setPerDayComplexPlans] = useState<
    {
      afternoonSnackTeen: string | null;
      afternoonSnackYoung: string | null;
      breakfastTeen: string | null;
      breakfastYoung: string | null;
      lunchTeen: string | null;
      lunchYoung: string | null;
    }[]
  >([]);
  const [saveAsDraftModalOpened, setSaveAsDraftModalOpened] = useState<boolean>(false);
  const [launchModalOpened, setLaunchModalOpened] = useState<boolean>(false);
  const [fieldCellFocused, setFieldCellFocused] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [startDateInvalid, setStartDateInvalid] = useState<boolean>(false);
  const [currentActiveComplexDish, setCurrentActiveComplexDish] = useState<ComplexDish | null>(null);
  const [dishesPlansSynchronized, setDishesPlansSynchronized] = useState(false);
  const [dishesPlansLastUpdateDate, setDishesPlansLastUpdateDate] = useState<Date | null>(null);

  //
  // Computed
  //

  const availableComplexPlansOptions = [
    { label: '', value: undefined },
    ...availableComplexPlans.map((acp) => ({ label: acp.name, value: acp.id })),
  ];

  //
  // Methods
  //

  const loadDish = async () => {
    if (!dishId || Array.isArray(dishId)) return;

    const data = await SettingsService.getComplexDishById(dishId);
    setComplexDish(data);
    setPerDayComplexPlans(data.elements);

    if (data.startAt) {
      setStartDate(freeDateFormat(data.startAt, 'dd.MM.yyyy') || '');
    }
  };

  const loadActiveDish = async () => {
    const { data: dishes } = await SettingsService.getComplexDishes({
      currentPage: 1,
      itemsPerPage: 3000,
      search: '',
    });
    const activeDish = dishes.find((d) => d.status === ComplexDishStatus.active);

    if (activeDish) {
      setCurrentActiveComplexDish(activeDish);
    }
  };

  const loadAvailableComplexPlans = async () => {
    const { dishes: data, lastSynchronisation, synchronised } = await SettingsService.getComplexDishPlans();
    setAvailableComplexPlans(data);
    setDishesPlansSynchronized(synchronised);
    setDishesPlansLastUpdateDate(lastSynchronisation);
  };

  const checkDataValidity = (allowEmpty: boolean = false): boolean => {
    if (allowEmpty && !startDate) {
      return true;
    }

    if (!startDate) {
      notify('Укажите дату начала для запуска цикла', 'error');
      setStartDateInvalid(true);
      return false;
    }

    const dateSplit = startDate.split('.');
    const dateString = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
    const timestamp = Date.parse(dateString);

    if (isNaN(timestamp)) {
      notify('Дата имеет неверное значение', 'error');
      setStartDateInvalid(true);
      return false;
    }

    const tomorrowDate = getMidnight(new Date());

    if (isBefore(new Date(timestamp), tomorrowDate)) {
      notify('Минимальная дата начала - следующий день', 'error');
      setStartDateInvalid(true);
      return false;
    }

    return true;
  };

  const handleSync = async () => {
    try {
      setSyncLoading(true);
      const { dishes: data, lastSynchronisation, synchronised } = await SettingsService.syncComplexDishPlans();
      setAvailableComplexPlans(data);
      setDishesPlansSynchronized(synchronised);
      setDishesPlansLastUpdateDate(lastSynchronisation);
    } catch (error) {
      throw error;
    } finally {
      setSyncLoading(false);
    }
  };

  const handleSave = async (launched: boolean) => {
    if (!complexDish) return;

    try {
      setSaveLoading(true);
      setStartDateInvalid(false);

      let date = null;

      if (startDate) {
        const dateSplit = startDate.split('.');
        date = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
      }

      await SettingsService.saveComplexDish(complexDish, date, perDayComplexPlans, launched);
      setHasUnsavedChanges(false);
      notify('Сохранено успешно', 'success');
      setSaveAsDraftModalOpened(false);
      setLaunchModalOpened(false);
      loadDish();
      loadActiveDish();
    } catch (error) {
      if (error instanceof ServerValidationError) {
        if (error.fields.activation_date && error.fields.activation_date[0]) {
          notify(error.fields.activation_date[0], 'error');
          setStartDateInvalid(true);
        } else {
          notify(error.message, 'error');
        }
      }

      throw error;
    } finally {
      setSaveLoading(false);
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    setLoading(true);
    Promise.all([loadDish(), loadAvailableComplexPlans(), loadActiveDish()]).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  // Render
  //

  const renderStatusBadge = () => {
    if (loading) return null;

    let name = '';
    let bgColor = '';

    if (syncLoading) {
      name = 'Обновляется';
      bgColor = '#545CD8';
    } else if (dishesPlansSynchronized) {
      name = 'Обновлено';
      bgColor = '#54A651';
    } else {
      name = 'Не подключено';
      bgColor = '#B22B2B';
    }

    return (
      <div className={styles.statusBadgeWrapper}>
        <Badge
          className={styles.statusBadge}
          style={{
            backgroundColor: bgColor,
            color: '#fff',
          }}
        >
          {syncLoading && <Spinner />}
          {name}
        </Badge>

        <QuestionMarkIcon id="questionMark" />
        <Tooltip
          className={cn('tooltip-light', styles.tooltip)}
          isOpen={questionMarkTooltipOpened}
          placement="right"
          target="questionMark"
          toggle={() => setQuestionMarkTooltipOpened(!questionMarkTooltipOpened)}
        >
          Синхронизация блюд необходима, чтобы <br /> в таблице создания цикла отображались <br /> актуальные
          комплексные блюда
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      <PageTitle heading="Создание цикла комплексных блюд" svg={<CogsIcon />} />

      <AppLoader active={loading} />

      <section className={styles.syncSection}>
        <p>
          Дата и время обновления комплексных блюд:{' '}
          {dishesPlansLastUpdateDate && freeDateFormat(dishesPlansLastUpdateDate, 'dd MMMM yyyy, HH:mm')}
        </p>

        <div className={styles.statusRow}>
          <p>Статус:</p> {renderStatusBadge()}
        </div>

        <AppButtonGroup
          className={styles.syncBtn}
          disabled={syncLoading}
          leftSlot={<SyncIcon />}
          onClick={() => {
            handleSync();
          }}
        >
          Синхронизировать блюда
        </AppButtonGroup>
      </section>

      <section className={styles.cycleSection}>
        <h2>{complexDish?.name}</h2>

        <div className={styles.dateInputField}>
          <label>Дата начала</label>
          <AppDateInput
            className={cn(styles.dateInput, { [styles.dateInputInvalid]: startDateInvalid })}
            onChange={(value) => {
              setStartDate(value);
              setHasUnsavedChanges(true);
              setStartDateInvalid(false);
            }}
            placeholder="ДД.ММ.ГГГГ"
            value={startDate}
          />
        </div>
      </section>

      <div className={styles.tableOuter}>
        <table className={styles.table}>
          <tr>
            <th className={cn(styles.headingCell, styles.headingRowCell)}></th>
            {Array.from(Array(complexDish ? complexDish.daysCount : 0)).map((_, index) => (
              <th className={cn(styles.headingCell, styles.valueCell)} key={index}>
                День {index + 1}
              </th>
            ))}
          </tr>

          {Object.keys(complexDishKindByAgeRangesConfig).map((key) => {
            const cfgKey = key as ComplexDishKindByAgeRanges;

            return (
              <tr key={key}>
                <td className={cn(styles.headingCell, styles.headingRowCell)}>
                  {complexDishKindByAgeRangesConfig[cfgKey].name}
                </td>
                {Array.from(Array(complexDish ? complexDish.daysCount : 0)).map((_, index) => (
                  <td className={cn(styles.valueCell, styles.filedCell)} key={`${index}-${cfgKey}`}>
                    {perDayComplexPlans[index][cfgKey] && !fieldCellFocused && (
                      <UncontrolledTooltip
                        className={cn('tooltip-light', styles.tooltip)}
                        placement="bottom"
                        target={`cellTooltip-${index}-${cfgKey}`}
                      >
                        <span>
                          {availableComplexPlans.find((acp) => acp.id === perDayComplexPlans[index][cfgKey])?.name}
                        </span>
                      </UncontrolledTooltip>
                    )}

                    <div
                      id={`cellTooltip-${index}-${cfgKey}`}
                      onBlur={() => setFieldCellFocused(false)}
                      onFocus={() => setFieldCellFocused(true)}
                    >
                      <AppInputField
                        className={styles.selectField}
                        controlType="select"
                        customSelectControlStyles={{ border: 'none' }}
                        disabled={saveLoading}
                        onChange={({ value }) => {
                          const perDayComplexPlansTmp = perDayComplexPlans;
                          perDayComplexPlansTmp[index][cfgKey] = value;
                          setPerDayComplexPlans([...perDayComplexPlansTmp]);
                          setFieldCellFocused(false);
                          document.querySelector<HTMLElement>(`#${`cellTooltip-${index}-${cfgKey}`}`)?.blur();
                          setHasUnsavedChanges(true);
                        }}
                        options={availableComplexPlansOptions}
                        value={perDayComplexPlans[index][cfgKey]}
                      />
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </table>
      </div>

      <section className={styles.actions}>
        <AppButton
          disabled={complexDish?.status === ComplexDishStatus.draft ? false : !hasUnsavedChanges}
          onClick={() => {
            const isValid = checkDataValidity();
            if (isValid) setLaunchModalOpened(true);
          }}
        >
          Запустить цикл
        </AppButton>
        <AppButton
          disabled={!hasUnsavedChanges}
          onClick={() => {
            const isValid = checkDataValidity(true);
            if (!isValid) return;

            if (currentActiveComplexDish) {
              setSaveAsDraftModalOpened(true);
            } else {
              handleSave(false);
            }
          }}
        >
          Сохранить как черновик
        </AppButton>
      </section>

      <AppModal isOpen={saveAsDraftModalOpened} onClose={() => setSaveAsDraftModalOpened(false)}>
        <section className={styles.modal}>
          <h2>Обратите внимание</h2>

          <p>
            При запуске этого цикла, он заменит {currentActiveComplexDish?.name} <br /> {currentActiveComplexDish?.name}{' '}
            перестанет быть активным и перейдет в статус Черновик
          </p>

          <section className={styles.modalActions}>
            <AppButton onClick={() => setSaveAsDraftModalOpened(false)} outline>
              Вернуться
            </AppButton>
            <AppButton
              disabled={saveLoading}
              onClick={() => {
                handleSave(false);
              }}
            >
              Сохранить
            </AppButton>
          </section>
        </section>
      </AppModal>

      <AppModal isOpen={launchModalOpened} onClose={() => setLaunchModalOpened(false)}>
        <section className={styles.modal}>
          <h2>Обратите внимание</h2>

          <p>
            Вы уверены, что хотите запустить цикл? <br />
            Цикл начнет действовать с {startDate}
          </p>

          <section className={styles.modalActions}>
            <AppButton onClick={() => setLaunchModalOpened(false)} outline>
              Вернуться
            </AppButton>
            <AppButton
              disabled={saveLoading}
              onClick={() => {
                handleSave(true);
              }}
            >
              Запустить
            </AppButton>
          </section>
        </section>
      </AppModal>

      <AppUnsavedChangesPrompt when={hasUnsavedChanges} />
    </>
  );
}
