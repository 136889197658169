import { useEffect, useMemo } from 'react';
import { CustomInput } from 'reactstrap';
import { animateScroll } from 'react-scroll';
import { DebounceInput } from 'react-debounce-input';

import { PaginationConstants } from '@/constants';
import { PaginationProps as Props } from './';

export default function Pagination(props: Props) {
  const {
    currentPage,
    itemsPerPage,
    totalPages,
    onChangePage,
    onChangeLimit,
    scrollTopOnChange = true,
    itemsPerPageList,
    itemsPerPageListVisible = true,
  } = props;

  //
  // Computed
  //

  const limitOptions = useMemo(
    () =>
      itemsPerPageList ||
      (PaginationConstants.itemsPerPage.includes(itemsPerPage)
        ? PaginationConstants.itemsPerPage
        : [...PaginationConstants.itemsPerPage, itemsPerPage].sort((a, b) => a - b)),
    [itemsPerPage, itemsPerPageList],
  );

  //
  // Effects
  //

  useEffect(() => {
    if (!scrollTopOnChange) return;
    animateScroll.scrollToTop();
  }, [currentPage, itemsPerPage, scrollTopOnChange, totalPages]);

  //
  // Render
  //

  const renderPreviousPageArrow = (
    <div
      className="-previous"
      onClick={() => onChangePage(currentPage - 1)}
    >
      <button
        className="-btn"
        disabled={currentPage <= 1}
        type="button"
      >
        Предыдущая
      </button>
    </div>
  );

  const renderNextPageArrow = (
    <div
      className="-next"
      onClick={() => onChangePage(currentPage + 1)}
    >
      <button
        className="-btn"
        disabled={currentPage >= totalPages}
        type="button"
      >
        Следующая
      </button>
    </div>
  );

  const pageLimitOptions = limitOptions.map((item) => (
    <option
      key={item}
      value={item}
    >
      {item} строк
    </option>
  ));

  const itemsPerPageControl = (
    <CustomInput
      id="limitOption"
      onChange={(event) => {
        onChangePage(1);
        onChangeLimit(parseInt(event.currentTarget.value));
      }}
      type="select"
      value={itemsPerPage}
    >
      {pageLimitOptions}
    </CustomInput>
  );

  return (
    <div className="pagination-bottom">
      <div className="-pagination">
        {renderPreviousPageArrow}
        <div className="-center">
          <span className="-pageInfo">
            Страница
            <div className="-pageJump">
              <DebounceInput
                debounceTimeout={1000}
                max={totalPages}
                min="1"
                onChange={(event) => onChangePage(parseInt(event.target.value, 10))}
                type="number"
                value={currentPage}
              />
            </div>
            из <span className="-totalPages">{totalPages}</span>
          </span>
          {itemsPerPageListVisible && <span className="select-wrap -pageSizeOptions">{itemsPerPageControl}</span>}
        </div>
        {renderNextPageArrow}
      </div>
    </div>
  );
}
