export default class Promotion {
  public id: string;
  public name: string;
  public createdAt: Date | null;
  public updatedAt: Date | null;
  public storiesImages: string[];
  public miniatureImage: string | null;
  public isActive: boolean;

  public constructor(data: Partial<Promotion>) {
    this.id = data.id!;
    this.name = data.name ?? '';
    this.createdAt = data.createdAt ?? null;
    this.updatedAt = data.updatedAt ?? null;
    this.storiesImages = data.storiesImages ?? [];
    this.miniatureImage = data.miniatureImage ?? null;
    this.isActive = data.isActive ?? false;
  }
}
