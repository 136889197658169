export default function UserIcon() {
  return (
    <svg
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.41957 6.34315C7.41957 2.84572 10.265 0 13.7624 0C17.2599 0 20.1056 2.84572 20.1056 6.34315C20.1056 9.84059 17.2599 12.686 13.7624 12.686C10.265 12.686 7.41957 9.84059 7.41957 6.34315ZM13.7624 14.3134C6.01198 14.3134 0 18.9247 0 22.8914C0 27.3811 6.61429 28 13.7624 28C20.9103 28 27.5249 27.3811 27.5249 22.8914C27.5249 18.9241 21.513 14.3134 13.7624 14.3134Z"
        fill="white"
      />
    </svg>
  );
}
