import { useNavigate } from 'react-router-dom';
import { ReactElement, useEffect } from 'react';

import { Paths } from '.';
import { AuthService } from '@/services';
import { Roles } from '@/constants';

interface Props {
  children: ReactElement;
  accessRoles?: Roles[];
}

const PrivateGuard = ({ children, accessRoles = [Roles.employee, Roles.senior_employee]}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: make `accessRoles` comparison + access denied message

    if (!AuthService.isAuthenticated) {
      navigate(Paths.home);
    }
  }, [navigate]);

  return children;
};

export default PrivateGuard;
