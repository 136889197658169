import { useEffect } from 'react';
import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

export type Filter = {
  filterName: string;
  value: string[] | string | undefined;
};

interface TableUrlParams {
  perPage?: number;
  currentPage?: number;
  search?: string;
  filters?: Filter[];
}

export default function useChangeTableUrlParams(params: TableUrlParams) {
  const { currentPage, perPage, search, filters } = params;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  function collectFiltersParams(fFilters?: Filter[]) {
    const fParams: Record<string, string[] | string | undefined> = {};

    fFilters?.forEach((filter) => {
      fParams[filter.filterName] = filter.value;
    });

    return fParams;
  }

  useEffect(() => {
    const generalParams = pickBy({ page: currentPage, perPage: perPage, search: search }, (value) => value);
    const filterParams = collectFiltersParams(filters);
    const urlParams = merge(generalParams, filterParams);
    const urlParamsString = qs.stringify(urlParams);

    navigate(
      {
        pathname: pathname,
        search: urlParamsString,
      },
      { replace: true },
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, search, JSON.stringify(filters), pathname]);
}
