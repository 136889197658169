import { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge } from 'reactstrap';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import styles from './SubsParentsTable.module.scss';

import { AppInputField, AppLoader, AppSearch, AppTable } from '@/components/app';
import { ParentListItem } from '@/models/entity';
import { SchoolsService } from '@/services';
import { formatDateToDateAndTime } from '@/utils/date';
import { formatPhone } from '@/utils/formatters';
import { useChangeTableUrlParams, useTableUrlParams } from '@/hooks';
import { Filter } from '@/hooks/useChangeTableUrlParams';
import { getGenderShortName } from '@/constants/genders';
import { RootState } from '@/store';

export default function SubsParentsTable() {
  const { search: urlSearch, currentPage: urlCurrentPage, itemsPerPage: urlItemsPerPage } = useTableUrlParams();

  //
  // State
  //

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [parents, setParents] = useState<ParentListItem[]>([]);
  const [schoolsFilter, setSchoolsFilter] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(urlItemsPerPage);
  const [searchFilter, setSearchFilter] = useState<string>(urlSearch || '');

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Computed
  //

  const queryFilters = useMemo(() => [].filter(Boolean) as Filter[], []);

  //
  // Methods
  //

  const loadParents = useCallback(async () => {
    const { data: parentsData, total } = await SchoolsService.getSubsParents({
      search: searchFilter,
      currentPage,
      itemsPerPage,
      schoolsIds: schoolsFilter,
    });
    setParents(parentsData);
    setTotalPages(Math.ceil(total / itemsPerPage));
  }, [currentPage, itemsPerPage, schoolsFilter, searchFilter]);

  const loadAllData = useCallback(() => {
    setTableLoading(true);
    Promise.all([loadParents()]).finally(() => {
      setTableLoading(false);
    });
  }, [loadParents]);

  //
  // Effects
  //

  useEffect(() => {
    loadAllData();
  }, [loadAllData]);

  useChangeTableUrlParams({
    currentPage,
    perPage: itemsPerPage,
    search: searchFilter,
    filters: queryFilters,
  });

  //
  // Render
  //

  const renderClassFilter = (
    <section className={styles.host}>
      <section className={styles.topActions}>
        <section className={styles.filters}>
          <AppInputField
            className={cn(styles.filter, styles.schoolsFilter)}
            controlType="multi-select"
            onChange={(value) => setSchoolsFilter(value)}
            options={schools.map((s) => ({ label: s.name, value: s.id }))}
            placeholder="Выберите школу"
            value={schoolsFilter}
          />
        </section>
        <AppSearch
          onChange={(value) => {
            setSearchFilter(value);
          }}
          onClickRefreshButton={loadAllData}
          value={searchFilter}
        />
      </section>

      <AppTable
        className={styles.table}
        columns={[
          {
            value: (parent) => (
              <div className={styles.multiRow}>
                {parent.children.map((child) => (
                  <span className={cn(styles.childCellValue, styles.schoolCell)} key={child.id}>
                    {child.schoolClass?.school?.name}
                  </span>
                ))}
              </div>
            ),
            header: {
              title: 'Название школы',
              style: {
                width: 237,
              },
              className: 'text-left',
            },
          },
          {
            value: (parent) => {
              if (!parent.dateJoined) return '—';

              const { date } = formatDateToDateAndTime(parent.dateJoined);

              return date;
            },
            className: 'text-left',
            header: {
              title: 'Дата\nрегистрации',
              style: {
                width: 100,
              },
              className: 'text-left',
            },
          },
          {
            value: (parent) => {
              if (!parent.lastLogin) return '—';

              const { date } = formatDateToDateAndTime(parent.lastLogin);

              return date;
            },
            className: 'text-left',
            header: {
              title: 'Последняя\nдата входа',
              style: {
                width: 100,
              },
              className: 'text-left',
            },
          },
          {
            value: (parent) => parent.FIO,
            className: 'text-left',
            header: {
              title: 'ФИО',
              className: 'text-left',
            },
          },
          {
            value: (parent) => (parent.phone ? formatPhone(parent.phone) : '—'),
            className: 'text-left',
            header: {
              title: 'Контактный\nтелефон',
              style: {
                width: 160,
              },
              className: 'text-left',
            },
          },
          {
            value: (parent) => getGenderShortName(parent.gender),
            className: 'text-left',
            header: {
              title: 'Пол',
              style: {
                width: 50,
              },
              className: 'text-left',
            },
          },
          {
            value: (parent) => {
              if (!parent.birthdate) return '—';

              const { date } = formatDateToDateAndTime(parent.birthdate);

              return date;
            },
            className: 'text-left',
            header: {
              title: 'Дата\nрождения',
              style: {
                width: 100,
              },
              className: 'text-left',
            },
          },
          {
            value: (parent) => (
              <div className={styles.multiRow}>
                {parent.children.map((child) => (
                  <span className={styles.childCellValue} key={child.id}>
                    {child.FIO}
                  </span>
                ))}
              </div>
            ),

            className: 'text-left',
            header: {
              title: 'ФИО Ученика',
              className: 'text-left',
            },
          },
          {
            value: (parent) => (
              <div className={styles.multiRow}>
                {parent.children.map((child) => (
                  <span className={styles.childCellValue} key={child.id}>
                    {child.schoolClass?.name}
                  </span>
                ))}
              </div>
            ),
            className: 'text-left',
            header: {
              title: 'Класс',
              className: 'text-left',
              style: {
                width: 50,
              },
            },
          },
          {
            value: (parent) => {
              if (!parent.isActive)
                return (
                  <Badge
                    style={{
                      backgroundColor: '#B22B2B',
                      color: '#fff',
                    }}
                  >
                    Заблокирован
                  </Badge>
                );

              return (
                <Badge
                  style={{
                    backgroundColor: '#54A651',
                    color: '#fff',
                  }}
                >
                  Активный
                </Badge>
              );
            },
            className: 'text-left',
            header: {
              title: 'Статус',
              style: {
                width: 110,
              },
              className: 'text-left',
            },
          },
        ]}
        data={parents}
        loading={false}
        pagination={{
          totalPages,
          currentPage,
          itemsPerPage,
          onChangePage: (newPage: number) => setCurrentPage(newPage),
          onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
        }}
      />
    </section>
  );

  return (
    <section className={styles.host}>
      <AppLoader active={tableLoading} />
      {renderClassFilter}
    </section>
  );
}
