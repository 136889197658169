import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SchoolIcon, SchoolOutlineIcon } from '@/components/icons';
import { PageTitle } from '@/layout/DashboardLayout/components';
import { RootState, useAppDispatch } from '@/store';
import { setAddSchoolModalOpened } from '@/store/modalsSlice';
import { notify } from '@/utils/notifications';
import { SchoolsTable } from '@/components/shared';
import { useDocumentTitle } from '@/hooks';

export default function NewSchoolPage() {

  //
  // Store
  //

  const dispatch = useAppDispatch()
  const { user } = useSelector((state: RootState) => state.profile);

  //
  // Effects
  //

  useDocumentTitle('Новая школа')

  useEffect(() => {
    if(!user) return

    if (user.isSeniorEmployee) {
      dispatch(setAddSchoolModalOpened(true))
    } else {
      notify('Пожалуйста, сначала\nдобавьте школу', 'error', <SchoolOutlineIcon />)
    }
  }, [dispatch, user])


  //
  // Render
  //

  return (
    <>
      <PageTitle
        heading="Список классов новой школы"
        svg={<SchoolIcon />}
      />

      <SchoolsTable
        currentPage={1}
        data={[]}
        handleDeleteSchoolClick={() => {}}
        handleEditSchoolClick={() => {}}
        itemsPerPage={1}
        setCurrentPage={() => {}}
        setItemsPerPage={() => {}}
        totalPages={1}
      />
    </>
  );
}
