import { IikoCardState } from '@/constants';
import { SchoolClassDto, SchoolDto, StudentDto, TeacherDto } from '../dto';
import { School, SchoolClass, Student, Teacher } from '../entity';

export const mapDtoToSchool = (dto: SchoolDto): School => {
  return new School({
    id: dto.id.toString(),
    name: dto.title,
  });
};

export const mapDtoToTeacher = (dto: TeacherDto): Teacher => {
  return new Teacher({
    id: dto.id,
    firstName: dto.first_name,
    lastName: dto.last_name,
    middleName: dto.middle_name,
  });
};

export const mapDtoToSchoolClass = (dto: SchoolClassDto): SchoolClass => {
  let mealRequestDeadlineDate = null;
  let mealRequestDate = null;

  if (dto.time_submitting) {
    const tmpSplit = dto.time_submitting.split(':');
    mealRequestDeadlineDate = [tmpSplit[0], tmpSplit[1]].join(':');
  }

  if (dto.time_publication) {
    const tmpSplit = dto.time_publication.split(':');
    mealRequestDate = [tmpSplit[0], tmpSplit[1]].join(':');
  }

  return new SchoolClass({
    id: dto.id.toString(),
    liter: dto.liter,
    number: dto.number,
    responsible: dto.responsible ? mapDtoToTeacher(dto.responsible) : null,
    studentsCount: dto.count_children,
    createdAt: dto.created_at ? new Date(dto.created_at) : null,
    school: dto.school ? new School({ id: dto.school.id.toString(), name: dto.school.title }) : null,
    mealRequestDeadlineDate,
    mealRequestDate,
  });
};

export const mapDtoToStudent = (dto: StudentDto): Student => {
  return new Student({
    balance: dto.balance,
    birthdate: dto.birthdate ? new Date(dto.birthdate) : null,
    firstName: dto.first_name,
    gender: dto.gender,
    id: dto.id.toString(),
    lastName: dto.last_name,
    middleName: dto.middle_name,
    parentFIO: dto.fio_parent,
    parentPhone: dto.phone_parent,
    photoUrl: dto.main_photo,
    iikoCardState: dto.card_exist ? IikoCardState.created : IikoCardState.notCreated,
    nutritionSubscriptions: dto.nutrition_subscriptions,
  });
};
