import { AppFlavor } from '@/flavors';

export default `
:root {
  --primary-color: ${AppFlavor.primaryColor};

  --btn-primary-bg-color: ${AppFlavor.buttonPrimaryBgColor};
  --btn-primary-text-color: ${AppFlavor.buttonPrimaryTextColor};
  --btn-primary-hover-bg-color: ${AppFlavor.buttonPrimaryHoverBgColor};
  --btn-primary-lighten-bg-color: ${AppFlavor.buttonPrimaryLightenBgColor};

  --btn-link-text-color: ${AppFlavor.buttonLinkTextColor};

  --table-invalid-row-bg-color: ${AppFlavor.tableInvalidRowBgColor};

  --tile-tabs-inactive-bg-color: ${AppFlavor.tileTabsInactiveBgColor};
  --tile-tabs-inactive-text-color: ${AppFlavor.tileTabsInactiveTextColor};
  --tile-tabs-hover-bg-color: ${AppFlavor.tileTabsHoverBgColor};

  --sidebar-active-item-bg-color: ${AppFlavor.sidebarActiveItemBgColor};

  --checkbox-active-bg-image-url: url(${AppFlavor.checkboxActiveBgImage});

  --calendar-selected-cell-bg-color: ${AppFlavor.calendarSelectedCellBgColor};
  --calendar-selected-cell-text-color: ${AppFlavor.calendarSelectedCellTextColor};
  --calendar-range-color: ${AppFlavor.calendarRangeColor};
}
`;
