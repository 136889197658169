import { History, Transition } from 'history';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type ExtendNavigator = Navigator & Pick<History, 'block'>;

export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export default function usePrompt(when = true) {
  const [isActive, setIsActive] = useState(false);
  const [tx, setTx] = useState<Transition | null>(null);

  const onCancel = () => {
    setIsActive(false);
    setTx(null);
  };
  const onConfirm = () => {
    tx && tx.retry();
    setTx(null);
  };

  const blocker = useCallback((tx: Transition) => {
    setIsActive(true);
    setTx(tx);
  }, []);

  useBlocker(blocker, when);

  return { isActive, onCancel, onConfirm };
}
