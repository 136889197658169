import { OrderTypes } from '@/constants/orders';
import School from './School';

export default class Order {
  public id: string;
  public className: string;
  public createdAt: Date | null;
  public studentName: string;
  public amount: number;
  public type: OrderTypes | null;
  public number: string | null;
  public items: {
    amount: number;
    product: {
      iiko_id: string;
      name: string;
      description: string;
    };
  }[];
  public total: number;
  public school: School | null;

  public constructor(data: Partial<Order>) {
    this.id = data.id!;
    this.createdAt = data.createdAt ?? null;
    this.className = data.className ?? '';
    this.studentName = data.studentName ?? '';
    this.amount = data.amount ?? 0;
    this.type = data.type ?? null;
    this.number = data.number ?? null;
    this.items = data.items ?? [];
    this.total = data.total ?? 0;
    this.school = data.school ?? null;
  }
}
