export default function RefreshIcon() {
  return (
    <svg
      fill="none"
      height="19"
      viewBox="0 0 16 19"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4543 4.6983L7.22074 8.29723V1.09936L13.4543 4.6983Z"
        fill="white"
      />
      <path
        d="M14.5714 10.9141C14.5714 14.5592 11.6165 17.5141 7.97143 17.5141C4.32635 17.5141 1.37143 14.5592 1.37143 10.9141C1.37143 7.26901 4.32635 4.31409 7.97143 4.31409"
        stroke="white"
      />
    </svg>
  );
}
