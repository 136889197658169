/* eslint-disable camelcase */
enum Roles {
  parent = 'parent',
  child = 'child',
  teacher = 'teacher',
  employee = 'employee',
  senior_employee = 'senior_employee',
}

export const config: Record<Roles, { name: string; value: Roles }> = {
  parent: {
    name: 'Родитель',
    value: Roles.parent,
  },
  child: {
    name: 'Ребенок',
    value: Roles.child,
  },
  teacher: {
    name: 'Учитель',
    value: Roles.teacher,
  },
  employee: {
    name: 'Сотрудник',
    value: Roles.employee,
  },
  senior_employee: {
    name: 'Старший сотрудник',
    value: Roles.senior_employee,
  },
};

export const getRoleName = (roleValue: Roles): string => {
  return config[roleValue].name;
};

export default Roles;
