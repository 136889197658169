import { Genders } from '@/constants';
import { IikoCardState } from '@/constants/iikoCardState';
import { MealSubscription } from '@/constants/mealSubscription';

export default class Student {
  public id: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public birthdate: Date | null;
  public photoUrl: string | null;
  public parentFIO: string;
  public parentPhone: string;
  public parentPhoneValid: boolean;
  public balance: number;
  public iikoCardState: IikoCardState;
  public gender: Genders;
  public nutritionSubscriptions: string[];

  public constructor(data: Partial<Student>) {
    this.id = data.id!;
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.middleName = data.middleName ?? '';
    this.birthdate = data.birthdate ?? null;
    this.photoUrl = data.photoUrl ?? null;
    this.parentFIO = data.parentFIO ?? '';
    this.parentPhone = data.parentPhone ?? '';
    this.parentPhoneValid = data.parentPhoneValid ?? true;
    this.balance = data.balance ?? 0;
    this.iikoCardState = data.iikoCardState ?? IikoCardState.notCreated;
    this.gender = data.gender ?? Genders.notDefined;
    this.nutritionSubscriptions = data.nutritionSubscriptions ?? [];
  }

  public get FIO(): string {
    return [this.lastName, this.firstName, this.middleName].filter(Boolean).join(' ');
  }

  public get FI(): string {
    return [this.lastName, this.firstName].filter(Boolean).join(' ');
  }

  public get breakfastMealEnabled(): boolean {
    return this.nutritionSubscriptions.includes(MealSubscription.breakfast);
  }

  public get lunchMealEnabled(): boolean {
    return this.nutritionSubscriptions.includes(MealSubscription.lunch);
  }

  public get afternoonSnackMealEnabled(): boolean {
    return this.nutritionSubscriptions.includes(MealSubscription.afternoonSnack);
  }
}
