import { School } from '@/models/entity';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
  isSchoolsLoading: boolean;
  schools: School[];
};

const initialState: SliceState = {
  isSchoolsLoading: true,
  schools: [],
};

const schools = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    setIsSchoolsLoading: (state, action: PayloadAction<boolean>) => {
      state.isSchoolsLoading = action.payload;
    },
    setSchools: (state, action: PayloadAction<School[]>) => {
      state.schools = action.payload;
    },
    pushSchool: (state, action: PayloadAction<School>) => {
      state.schools = [...state.schools, action.payload];
    },
  },
});

export const { reducer: schoolsReducer } = schools;
export const { setIsSchoolsLoading, setSchools, pushSchool } = schools.actions;
