/* eslint-disable camelcase */
import { httpClient } from '@/api/httpClient';

const AuthApi = {
  async sendCode(phone: string): Promise<void> {
    return await httpClient.post('/api/v01/sms-request/', { phone, 'role': 'employee' });
  },

  async authComplete(phone: string, code: string): Promise<{ token: string; }> {
    const {
      data: { token },
    } = await httpClient.post('/api/v01/token-auth-create/', { phone, code, 'role': 'employee' });

    return { token: token };
  },
};

export default AuthApi;
