import { useEffect, useMemo, useState } from 'react';
import { Badge, DropdownItem } from 'reactstrap';
import { Formik, FormikErrors } from 'formik';
import cn from 'classnames';

import styles from './PaidNutritionPage.module.scss';

import { PaidNutritionIcon } from '@/components/icons';
import { PageTitle } from '@/layout/DashboardLayout/components';
import {
  AppButton,
  AppInputField,
  AppLoader,
  AppModal,
  AppSearch,
  AppTable,
  AppUnsavedChangesPrompt,
} from '@/components/app';
import AppDropdownCheckboxSelect, {
  Option,
} from '@/components/app/AppDropdownCheckboxSelect/AppDropdownCheckboxSelect';
import { SchoolsService } from '@/services';
import { PaidNutrition } from '@/models/entity';
import { formatCurrency } from '@/utils/formatters';
import { notify } from '@/utils/notifications';
import { SchoolsApi } from '@/api/sources';
import { ServerValidationError } from '@/api/exceptions';

enum Statuses {
  enabled = 'enabled',
  disabled = 'disabled',
}
const statuses: Option[] = [
  { label: 'Включено', value: Statuses.enabled },
  { label: 'Выключено', value: Statuses.disabled },
];

type FormValues = {
  school: string;
  breakfastClasses: number[];
  lunchClasses: number[];
  afternoonSnackClasses: number[];
  debtAllowed: boolean;
  debtAmount: string;
};

export default function PaidNutritionPage() {
  //
  // State
  //

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string | undefined>(undefined);
  const [tableLoading, setTableLoading] = useState(false);
  const [paidNutrition, setPaidNutrition] = useState<PaidNutrition[]>([]);
  const [paidNutritionEditModal, setPaidNutritionEditModal] = useState<{
    open: boolean;
    data: PaidNutrition | null;
    loading: boolean;
  }>({
    open: false,
    data: null,
    loading: false,
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasUnsavedChangesPopupOpened, setHasUnsavedChangesPopupOpened] = useState(false);

  //
  // Computed
  //

  const paidNutritionToRender = useMemo(
    () =>
      paidNutrition
        .filter((data) => {
          if (statusFilter === Statuses.enabled) return data.paidNutritionEnabled;
          if (statusFilter === Statuses.disabled) return !data.paidNutritionEnabled;

          return data;
        })
        .filter((data) =>
          !searchFilter ? data : data.schoolTitle.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()),
        ),
    [paidNutrition, searchFilter, statusFilter],
  );

  //
  // Methods
  //

  const loadData = async () => {
    try {
      setTableLoading(true);
      const data = await SchoolsService.getPaidNutrition();
      setPaidNutrition(data);
    } catch (error) {
      throw error;
    } finally {
      setTableLoading(false);
    }
  };

  const handleUpdatePaidNutrition = async (
    values: FormValues,
    srcData: PaidNutrition,
    setErrors: (errors: FormikErrors<FormValues>) => void,
  ) => {
    try {
      setPaidNutritionEditModal({ data: paidNutritionEditModal.data, open: true, loading: true });
      await SchoolsService.updatePaidNutrition(srcData, {
        ...values,
        debtAmount: values.debtAmount ? parseFloat(values.debtAmount.replace(/ /g, '').replace('₽', '')) : 0,
      });
      setPaidNutritionEditModal({ data: null, open: false, loading: false });
      loadData();
      notify(`Комплексное питание для школы ${values.school} обновлено`, 'success');
      setHasUnsavedChanges(false);
    } catch (error) {
      setPaidNutritionEditModal({ data: paidNutritionEditModal.data, open: true, loading: false });

      if (error instanceof ServerValidationError) {
        notify(error.message, 'error');
      } else {
        notify(`Не удалось обновить комплексное питание для школы ${values.school}`, 'error');
      }

      throw error;
    }
  };

  const handleTogglePaidNutritionStatus = async (data: PaidNutrition) => {
    try {
      data.paidNutritionEnabled
        ? await SchoolsApi.disablePaidNutrition(data)
        : await SchoolsApi.enablePaidNutrition(data);
      data.paidNutritionEnabled
        ? notify(`Комплексное питание для школы ${data.schoolTitle} выключено`, 'success')
        : notify(`Комплексное питание для школы ${data.schoolTitle} включено`, 'success');
      loadData();
    } catch (error) {
      if (error instanceof ServerValidationError) {
        notify(error.message, 'error');
      } else {
        notify(`Не удалось обновить комплексное питание для школы ${data.schoolTitle}`, 'error');
      }

      throw error;
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    loadData();
  }, []);

  //
  // Render
  //

  const renderActions = (data: PaidNutrition) => {
    return [
      <DropdownItem key="edit" onClick={() => setPaidNutritionEditModal({ open: true, data, loading: false })}>
        <span className="dropdown-icon material-icons">create</span>
        Редактировать
      </DropdownItem>,
      <DropdownItem key="toggle" onClick={() => handleTogglePaidNutritionStatus(data)}>
        <span className="dropdown-icon material-icons">close</span>
        {data.paidNutritionEnabled ? 'Выключить комплексное питание' : 'Включить комплексное питание'}
      </DropdownItem>,
    ];
  };

  return (
    <>
      <PageTitle heading="Комплексное питание" svg={<PaidNutritionIcon />} />
      <AppLoader active={tableLoading} />

      <section className={styles.host}>
        <section className={styles.topActions}>
          <div className={styles.filters}>
            <AppDropdownCheckboxSelect
              onChange={(option) =>
                !option
                  ? setStatusFilter(undefined)
                  : setStatusFilter(statuses.find((s) => s.value === option.value)?.value)}
              options={statuses.map((status) => ({ label: status.label, value: status.value }))}
              placeholder="Статус"
              value={
                statusFilter
                  ? {
                    label: statuses.find((o) => o.value === statusFilter)?.label ?? '',
                    value: statusFilter,
                  }
                  : null
              }
            />
          </div>

          <AppSearch
            onChange={(value) => setSearchFilter(value)}
            onClickRefreshButton={loadData}
            value={searchFilter}
          />
        </section>

        <AppTable
          columns={[
            {
              value: (data) => data.schoolTitle,
              className: 'text-left',
              header: {
                title: 'Название школы',
                className: 'text-left',
                style: { width: 309 },
              },
            },
            {
              value: (data) =>
                data.debtAllowed > 0 ? formatCurrency(-data.debtAllowed) : formatCurrency(data.debtAllowed),
              header: {
                title: 'Сумма долга',
                style: { width: 150 },
              },
            },
            {
              value: (data) => (
                <p className={styles.nutritionClassCell}>
                  {data.paidBreakfastClasses.length > 0
                    ? `${data.paidBreakfastClasses.join(', ')} классы \n ${
                      data.paidBreakfastPrice ? formatCurrency(data.paidBreakfastPrice) : <></>
                    }`
                    : ''}
                </p>
              ),
              header: {
                title: 'Завтрак',
                style: { width: 150 },
              },
            },
            {
              value: (data) => (
                <p className={styles.nutritionClassCell}>
                  {data.paidLunchClasses.length > 0
                    ? `${data.paidLunchClasses.join(', ')} классы \n ${
                      data.paidLunchPrice ? formatCurrency(data.paidLunchPrice) : <></>
                    }`
                    : ''}
                </p>
              ),
              header: {
                title: 'Обед',
                style: { width: 150 },
              },
            },
            {
              value: (data) => (
                <p className={styles.nutritionClassCell}>
                  {data.paidAfternoonSnackClasses.length > 0
                    ? `${data.paidAfternoonSnackClasses.join(', ')} классы \n  ${
                      data.paidAfternoonSnackPrice ? formatCurrency(data.paidAfternoonSnackPrice) : <></>
                    }`
                    : ''}
                </p>
              ),
              header: {
                title: 'Полдник',
                style: { width: 150 },
              },
            },
            {
              value: (data) =>
                data.paidNutritionEnabled ? (
                  <Badge style={{ backgroundColor: '#54A651', color: '#FFFFFF' }}>Включено</Badge>
                ) : (
                  <Badge style={{ backgroundColor: '#B22B2B', color: '#FFFFFF' }}>Выключено</Badge>
                ),
              header: {
                title: 'Статус',
                style: { width: 150 },
              },
            },
            {
              value: (schoolClass) => renderActions(schoolClass),
              type: 'action',
              header: {
                title: 'Действия',
                style: { width: 80 },
              },
            },
          ]}
          data={paidNutritionToRender}
          pagination={{
            totalPages: 1,
            currentPage: 1,
            itemsPerPage: paidNutritionToRender.length,
            onChangePage: () => {},
            onChangeLimit: () => {},
          }}
        />
      </section>

      <AppModal
        isOpen={paidNutritionEditModal.open}
        onClose={() => {
          if (hasUnsavedChanges) {
            setHasUnsavedChangesPopupOpened(true);
            return;
          }

          setPaidNutritionEditModal({ data: null, loading: false, open: false });
        }}
      >
        <section className={styles.modal}>
          <h2>Комплексное питание</h2>

          <Formik
            initialValues={
              {
                school: paidNutritionEditModal.data?.schoolTitle || '',
                breakfastClasses: paidNutritionEditModal.data?.paidBreakfastClasses || [],
                afternoonSnackClasses: paidNutritionEditModal.data?.paidAfternoonSnackClasses || [],
                debtAllowed: paidNutritionEditModal.data?.debtAllowed
                  ? paidNutritionEditModal.data?.debtAllowed > 0
                  : false,
                debtAmount: paidNutritionEditModal.data?.debtAllowed
                  ? formatCurrency(paidNutritionEditModal.data.debtAllowed)
                  : undefined,
                lunchClasses: paidNutritionEditModal.data?.paidLunchClasses || [],
              } as FormValues
            }
            onSubmit={(values, { setErrors }) => {
              if (paidNutritionEditModal.data) {
                handleUpdatePaidNutrition(values, paidNutritionEditModal.data, setErrors);
              }
            }}
          >
            {({ values, dirty, handleBlur, handleSubmit, handleChange, submitCount, setFieldValue, isValid }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <AppInputField
                  className={styles.field}
                  controlType="input"
                  disabled
                  label="Школа"
                  name="school"
                  onChange={() => {}}
                  value={values.school}
                />
                <AppInputField
                  className={styles.field}
                  controlType="multi-select"
                  disabled={paidNutritionEditModal.loading}
                  label="Укажите классы для подачи завтраков в выбранной школе"
                  multiSelectDisableSearch
                  multiSelectValueRenderer={(value) => value.map((s) => s.value).join(', ')}
                  name="breakfastClasses"
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('breakfastClasses', value);
                    setHasUnsavedChanges(true);
                  }}
                  options={[...Array(11)].map((s, index) => ({ label: `${index + 1} классы`, value: index + 1 }))}
                  value={values.breakfastClasses}
                />
                <AppInputField
                  className={styles.field}
                  controlType="multi-select"
                  disabled={paidNutritionEditModal.loading}
                  label="Укажите классы для подачи обедов в выбранной школе"
                  multiSelectDisableSearch
                  multiSelectValueRenderer={(value) => value.map((s) => s.value).join(', ')}
                  name="lunchClasses"
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('lunchClasses', value);
                    setHasUnsavedChanges(true);
                  }}
                  options={[...Array(11)].map((s, index) => ({ label: `${index + 1} классы`, value: index + 1 }))}
                  value={values.lunchClasses}
                />
                <AppInputField
                  className={styles.field}
                  controlType="multi-select"
                  disabled={paidNutritionEditModal.loading}
                  label="Укажите классы для подачи полдников в выбранной школе"
                  multiSelectDisableSearch
                  multiSelectValueRenderer={(value) => value.map((s) => s.value).join(', ')}
                  name="afternoonSnackClasses"
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('afternoonSnackClasses', value);
                    setHasUnsavedChanges(true);
                  }}
                  options={[...Array(11)].map((s, index) => ({ label: `${index + 1} классы`, value: index + 1 }))}
                  value={values.afternoonSnackClasses}
                />

                <div className={styles.fieldSet}>
                  <AppInputField
                    className={cn(styles.field, styles.checkboxField)}
                    controlType="checkbox"
                    disabled={paidNutritionEditModal.loading}
                    label="Можно уходить в долг"
                    name="debtAllowed"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setHasUnsavedChanges(true);
                    }}
                    placeholder=""
                    value={values.debtAllowed}
                  />
                  <AppInputField
                    className={styles.field}
                    controlType="numeric"
                    disabled={!values.debtAllowed}
                    label="Сумма долга"
                    maxNumericValue={9999999}
                    name="debtAmount"
                    numericSuffix=" ₽"
                    onChange={(e) => {
                      handleChange(e);
                      setHasUnsavedChanges(true);
                    }}
                    placeholder="0 ₽"
                    value={values.debtAmount}
                  />
                </div>

                <AppButton
                  className={styles.submitBtn}
                  disabled={paidNutritionEditModal.loading || !dirty || !isValid}
                  type="submit"
                >
                  Сохранить
                </AppButton>

                <AppUnsavedChangesPrompt
                  active={hasUnsavedChangesPopupOpened}
                  cancelCallback={() => setHasUnsavedChangesPopupOpened(false)}
                  confirmCallback={() => {
                    setHasUnsavedChanges(false);
                    setHasUnsavedChangesPopupOpened(false);
                    setPaidNutritionEditModal({ data: null, loading: false, open: false });
                  }}
                  when={dirty || hasUnsavedChanges}
                />
              </form>
            )}
          </Formik>
        </section>
      </AppModal>
    </>
  );
}
