import { useEffect, useMemo, useState } from 'react';
import { FormikErrors } from 'formik';
import { useSelector } from 'react-redux';

import styles from './AllSchoolsPage.module.scss';

import { PageTitle } from '@/layout/DashboardLayout/components';
import { AppInputField, AppLoader } from '@/components/app';
import { useChangeTableUrlParams, useDocumentTitle, useTableUrlParams } from '@/hooks';
import { SchoolIikoSettings } from '@/models/entity';
import { SettingsService } from '@/services';
import { deformatPhone } from '@/utils/formatters';
import { SchoolDeleteModal, SchoolEditModal } from '../settings/MainSettingsPage/components';
import { FormFields as EditFormFields } from '@/components/shared/SchoolForm/SchoolForm';
import { notify } from '@/utils/notifications';
import { ServerValidationError } from '@/api/exceptions';
import { RootState } from '@/store';
import { SchoolsTable } from '@/components/shared';
import wellKnownErrors from '@/api/wellKnownErrors';
import { SchoolIcon, WifiIcon } from '@/components/icons';

export default function AllSchoolsPage() {
  const { currentPage: urlCurrentPage, itemsPerPage: urlItemsPerPage } = useTableUrlParams();

  //
  // State
  //

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(urlItemsPerPage);
  const [schoolsFilter, setSchoolsFilter] = useState<string[]>([]);
  const [schoolsTableData, setSchoolsTableData] = useState<SchoolIikoSettings[]>([]);
  const [schoolDeleteModal, setSchoolDeleteModal] = useState<{
    opened: boolean;
    school: SchoolIikoSettings | null;
    loading: boolean;
  }>({
    loading: false,
    opened: false,
    school: null,
  });
  const [schoolEditModal, setSchoolEditModal] = useState<{
    opened: boolean;
    school: SchoolIikoSettings | null;
    loading: boolean;
  }>({
    loading: false,
    opened: false,
    school: null,
  });

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Computed
  //

  const filteredSchools =
    schoolsFilter.length === 0 ? schoolsTableData : schoolsTableData.filter((std) => schoolsFilter.includes(std.id));
  const paginatedSchools = useMemo(() => {
    const currentPageDivider = currentPage <= 0 ? 0 : currentPage - 1;
    const startIndex = currentPageDivider * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return filteredSchools.slice(startIndex, endIndex);
  }, [currentPage, filteredSchools, itemsPerPage]);
  const totalPages = useMemo(() => Math.ceil(filteredSchools.length / itemsPerPage), [itemsPerPage, filteredSchools]);

  //
  // Methods
  //

  const loadAllData = async () => {
    setTableLoading(true);

    try {
      const { schoolIikoSettings } = await SettingsService.getSchoolsIikoSettings({
        currentPage,
        itemsPerPage,
        search: '',
      });
      setSchoolsTableData(schoolIikoSettings);
    } catch (error) {
      throw error;
    } finally {
      setTableLoading(false);
    }
  };

  const handleSubmitDeleteSchool = async (school: SchoolIikoSettings | null) => {
    if (!school) return;

    try {
      setSchoolDeleteModal({
        loading: true,
        opened: true,
        school: schoolDeleteModal.school,
      });
      await SettingsService.deleteSchool(school.id);
      notify(`Школа ${school.name} успешно удалена`, 'success');
      setSchoolDeleteModal({
        loading: false,
        opened: false,
        school: schoolDeleteModal.school,
      });
      loadAllData();
      setTimeout(() => {
        setSchoolDeleteModal({
          loading: false,
          opened: false,
          school: null,
        });
      }, 600);
    } catch (error) {
      notify('Не удалось удалить школу', 'error');
      setSchoolDeleteModal({
        loading: false,
        opened: true,
        school: schoolDeleteModal.school,
      });
    }
  };

  const handleSubmitEditSchool = async (
    values: EditFormFields,
    setErrors: (errors: FormikErrors<EditFormFields>) => void,
  ) => {
    if (!schoolEditModal.school) return;

    const {
      marketingCampaign,
      iikoFrontCount,
      mealDaily,
      name,
      phone,
      warehouse,
      middleNameEmployee,
      nameEmployee,
      surnameEmployee,
    } = values;
    const phoneNoFormat = deformatPhone(phone);

    if (!marketingCampaign || !warehouse) return;

    try {
      setSchoolEditModal({
        loading: true,
        opened: true,
        school: schoolEditModal.school,
      });
      await SettingsService.editSchool(
        schoolEditModal.school.id,
        marketingCampaign,
        iikoFrontCount || 0,
        mealDaily,
        name,
        phoneNoFormat,
        warehouse,
        nameEmployee,
        surnameEmployee,
        middleNameEmployee,
      );
      notify(`Школа ${schoolEditModal.school.name} успешно обновлена`, 'success');
      setSchoolEditModal({
        loading: false,
        opened: false,
        school: schoolEditModal.school,
      });
      loadAllData();
      setTimeout(() => {
        setSchoolEditModal({
          loading: false,
          opened: false,
          school: null,
        });
      }, 600);
    } catch (error) {
      setSchoolEditModal({
        loading: false,
        opened: true,
        school: schoolEditModal.school,
      });
      if (error instanceof ServerValidationError) {
        if (error.code === wellKnownErrors.employeeAlreadyAssigned) {
          notify(error.message, 'error');
          setErrors({ phone: ' ' });
        } else if (error.fields?.employee) {
          notify('Проверьте правильность данных сотрудника', 'error');
        } else {
          notify(error.message, 'error', <WifiIcon />);
        }
      }
      throw error;
    }
  };

  //
  // Effects
  //

  useEffect(() => {
    loadAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useChangeTableUrlParams({
    currentPage,
    perPage: itemsPerPage,
  });

  useDocumentTitle('Школы');

  //
  // Render
  //

  return (
    <>
      <PageTitle heading="Школы" svg={<SchoolIcon />} />

      <section className={styles.host}>
        <section className={styles.actions}>
          <section className={styles.filters}>
            <AppInputField
              className={styles.schoolsFilter}
              controlType="multi-select"
              onChange={(value) => setSchoolsFilter(value)}
              options={schools.map((s) => ({
                label: s.name,
                value: s.id,
              }))}
              placeholder="Выберите школу"
              value={schoolsFilter}
            />
          </section>
        </section>

        <AppLoader active={tableLoading} />
        <SchoolsTable
          currentPage={currentPage}
          data={paginatedSchools}
          handleDeleteSchoolClick={(school) => setSchoolDeleteModal({ loading: false, opened: true, school })}
          handleEditSchoolClick={(school) => setSchoolEditModal({ loading: false, opened: true, school })}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setItemsPerPage}
          totalPages={totalPages}
        />
      </section>

      <SchoolDeleteModal
        handleSubmit={handleSubmitDeleteSchool}
        isOpen={schoolDeleteModal.opened}
        loading={schoolDeleteModal.loading}
        onClose={() => {
          setSchoolDeleteModal({ loading: false, opened: false, school: schoolDeleteModal.school });
          setTimeout(() => {
            setSchoolDeleteModal({ loading: false, opened: false, school: null });
          }, 600);
        }}
        school={schoolDeleteModal.school}
      />
      <SchoolEditModal
        handleSubmit={handleSubmitEditSchool}
        isOpen={schoolEditModal.opened}
        loading={schoolEditModal.loading}
        onClose={() => {
          setSchoolEditModal({ loading: false, opened: false, school: schoolEditModal.school });
          setTimeout(() => {
            setSchoolEditModal({ loading: false, opened: false, school: null });
          }, 600);
        }}
        school={schoolEditModal.school}
      />
    </>
  );
}
