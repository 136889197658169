import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketingCampaign, WriteOffStore } from '@/models/entity';

type SliceState = {
  writeoffStores: WriteOffStore[];
  marketingCampaigns: MarketingCampaign[];
};

const initialState: SliceState = {
  writeoffStores: [],
  marketingCampaigns: [],
};

const iiko = createSlice({
  name: 'iiko',
  initialState,
  reducers: {
    setWriteoffStores: (state: SliceState, action: PayloadAction<WriteOffStore[]>) => {
      state.writeoffStores = action.payload;
    },
    setMarketingCampaigns: (state: SliceState, action: PayloadAction<MarketingCampaign[]>) => {
      state.marketingCampaigns = action.payload;
    },
  },
});

export const { reducer: iikoReducer } = iiko;
export const { setMarketingCampaigns, setWriteoffStores } = iiko.actions;
