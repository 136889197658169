import { useLocation } from 'react-router';
import qs from 'query-string';

import { PaginationConstants } from '../../constants';
import { TableUrlParams, ReturnedFilters } from './'

type ReturnedParams = {
  currentPage: number,
  itemsPerPage: number,
  search?: string,
  filters: ReturnedFilters,
}

export default function useTableUrlParams(): ReturnedParams {
  const { search: urlParams } = useLocation()
  const { page, perPage, search, ...rest }: Partial<TableUrlParams> = qs.parse(urlParams)

  return {
    currentPage: page ? parseInt(page) : 1,
    itemsPerPage: perPage ? parseInt(perPage) : PaginationConstants.defaultItemsPerPage,
    search: search,
    filters: rest,
  }
}
