import { DateRange } from 'rsuite/esm/DateRangePicker';

import styles from './ReportsPage.module.scss';

import { PageTitle } from '@/layout/DashboardLayout/components';
import { ReportFieldSet } from './components';
import ReportsService from '@/services/ReportsService';
import { ClassesValue, FieldSetKeys } from '@/constants/reports';
import { useDocumentTitle } from '@/hooks';
import { ReportsIcon } from '@/components/icons';

export default function ReportsPage() {
  //
  // Methods
  //

  const makeReport = async (
    fieldSetKey: FieldSetKeys,
    dateRange: DateRange,
    schoolId: string,
    classes: ClassesValue,
  ) => {
    switch (fieldSetKey) {
      case FieldSetKeys.accounting:
        await ReportsService.makeAccountancyReport(dateRange, schoolId, classes);
        break;

      case FieldSetKeys.visits:
        await ReportsService.makeAttendanceReport(dateRange, schoolId, classes);
        break;

      case FieldSetKeys.beneficiaries:
        await ReportsService.makeNutritionReport(dateRange, schoolId, classes);
        break;

      default:
        break;
    }
  };

  //
  // Effects
  //

  useDocumentTitle('Отчеты');

  //
  // Render
  //

  return (
    <section className={styles.host}>
      <PageTitle heading="Отчеты" svg={<ReportsIcon />} />

      <ul>
        <li>
          <ReportFieldSet
            fieldSetKey={FieldSetKeys.accounting}
            handleConfigureReport={makeReport}
            title="Табель в бухгалтерию"
          />
        </li>
        <li>
          <ReportFieldSet
            fieldSetKey={FieldSetKeys.visits}
            handleConfigureReport={makeReport}
            title="Табель посещаемости"
          />
        </li>
        <li>
          <ReportFieldSet
            fieldSetKey={FieldSetKeys.beneficiaries}
            handleConfigureReport={makeReport}
            title="Табель фактического питания льготников"
          />
        </li>
      </ul>
    </section>
  );
}
