import { RequestStatus } from '@/constants/kitchenRequests';
import KitchenRequestItem from './KitchenRequestItem';
import School from './School';
import SchoolClass from './SchoolClass';
import Teacher from './Teacher';

export default class KitchenRequest {
  public id: string;
  public school: School | null;
  public date: Date | null;
  public deadlineDateTime: Date | null;
  public number: string;
  public status: RequestStatus | null;
  public schoolClass: SchoolClass | null;
  public responsible: Teacher | null;
  public breakfastYoungCount: number;
  public breakfastTeenCount: number;
  public lunchYoungCount: number;
  public lunchTeenCount: number;
  public afternoonSnackYoungCount: number;
  public afternoonSnackTeenCount: number;
  public updateAt: Date | null;
  public items: KitchenRequestItem[];

  public constructor(data: Partial<KitchenRequest>) {
    this.id = data.id!;
    this.school = data.school ?? null;
    this.date = data.date ?? null;
    this.deadlineDateTime = data.deadlineDateTime ?? null;
    this.number = data.number ?? '';
    this.status = data.status ?? null;
    this.schoolClass = data.schoolClass ?? null;
    this.responsible = data.responsible ?? null;
    this.breakfastYoungCount = data.breakfastYoungCount ?? 0;
    this.breakfastTeenCount = data.breakfastTeenCount ?? 0;
    this.lunchYoungCount = data.lunchYoungCount ?? 0;
    this.lunchTeenCount = data.lunchTeenCount ?? 0;
    this.afternoonSnackYoungCount = data.afternoonSnackYoungCount ?? 0;
    this.afternoonSnackTeenCount = data.afternoonSnackTeenCount ?? 0;
    this.updateAt = data.updateAt ?? null;
    this.items = data.items ?? [];
  }
}
