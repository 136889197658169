import { ReactElement } from 'react';
import { Formik, FormikErrors } from 'formik';
import cn from 'classnames';

import styles from './StudentForm.module.scss';

import { AppInputField } from '@/components/app';
import { Yup } from '@/utils/yup';
import { deformatPhone } from '@/utils/formatters';
import { notify } from '@/utils/notifications';
import { UserIcon } from '@/components/icons';
import { FormFields } from '.';

type Props = {
  onSubmit(values: FormFields): void;
  defaultValues?: FormFields;
  className?: string;
  actionsSection(values: FormFields, dirty: boolean): ReactElement;
  allowEditParentPhone?: boolean;
};

export default function StudentForm(props: Props) {
  const { actionsSection, onSubmit, className = '', defaultValues, allowEditParentPhone = true } = props;

  const validationSchema = Yup.object().shape({
    lastName: Yup.string().required(' '),
    firstName: Yup.string().required(' '),
    middleName: Yup.string(),
    parentPhone: Yup.string().required(' '),
  });

  //
  // Methods
  //

  const isTextFieldLengthValid = (field: string) => {
    return field.length >= 2;
  };

  const handleSubmitForm = async (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    const { parentPhone, firstName, lastName, middleName } = values;
    const phoneNoFormat = deformatPhone(parentPhone);

    if (phoneNoFormat.length > 0 && !phoneNoFormat.startsWith('9')) {
      setErrors({ parentPhone: ' ' });
      notify('Проверьте корректность введенного номера телефона', 'error', <UserIcon />);
      return;
    }

    if (!isTextFieldLengthValid(lastName)) {
      setErrors({ lastName: ' ' });
      notify('Фамилия должна содержать минимум 2 символа', 'error', <UserIcon />);
      return;
    }

    if (!isTextFieldLengthValid(firstName)) {
      setErrors({ firstName: ' ' });
      notify('Имя должно содержать минимум 2 символа', 'error', <UserIcon />);
      return;
    }

    if (middleName && !isTextFieldLengthValid(middleName)) {
      setErrors({ middleName: ' ' });
      notify('Отчество должна содержать минимум 2 символа', 'error', <UserIcon />);
      return;
    }

    onSubmit(values);
  };

  //
  // Render
  //

  return (
    <Formik
      initialValues={
        defaultValues ||
        ({
          lastName: '',
          firstName: '',
          middleName: '',
          parentPhone: '',
        } as FormFields)
      }
      onSubmit={(values, { setErrors }) => {
        handleSubmitForm(values, setErrors);
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, submitCount, setErrors, setFieldValue, dirty }) => (
        <form className={cn(styles.form, className)} onSubmit={handleSubmit}>
          <AppInputField
            className={styles.field}
            controlType="input"
            error={submitCount > 0 && errors.lastName}
            label="Фамилия"
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder=""
            required
            value={values.lastName}
          />

          <AppInputField
            className={styles.field}
            controlType="input"
            error={submitCount > 0 && errors.firstName}
            label="Имя"
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder=""
            required
            value={values.firstName}
          />

          <AppInputField
            className={styles.field}
            controlType="input"
            error={submitCount > 0 && errors.middleName}
            label="Отчество"
            name="middleName"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder=""
            value={values.middleName}
          />

          <AppInputField
            className={styles.field}
            controlType="phone"
            disabled={!allowEditParentPhone}
            error={submitCount > 0 && errors.parentPhone}
            label="Номер телефона родителя"
            name="parentPhone"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder=""
            required
            value={values.parentPhone}
          />

          <div>{actionsSection(values, dirty)}</div>
        </form>
      )}
    </Formik>
  );
}
