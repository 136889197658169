export default class ClassNutritionPlan {
  public id: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public phoneParent: string;
  public nutritionSubscriptions: any[];

  public constructor(data: Partial<ClassNutritionPlan>) {
    this.id = data.id!;
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.middleName = data.middleName ?? '';
    this.phoneParent = data.phoneParent ?? '';
    this.nutritionSubscriptions = data.nutritionSubscriptions ?? [];
  }

  public get FIO(): string {
    return [this.lastName, this.firstName, this.middleName].filter(Boolean).join(' ');
  }
}
