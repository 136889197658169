import { FormikErrors } from 'formik';

import styles from './SchoolEditModal.module.scss';

import { AppModal } from '@/components/app';
import { SchoolIikoSettings } from '@/models/entity';
import { SchoolForm } from '@/components/shared';
import { FormFields } from '@/components/shared/SchoolForm/SchoolForm';

type Props = {
  isOpen: boolean;
  onClose(): void;
  school: SchoolIikoSettings | null;
  loading: boolean;
  handleSubmit(values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void): void;
};

export default function SchoolEditModal(props: Props) {
  const { handleSubmit, isOpen, loading, onClose, school } = props;

  //
  // Render
  //

  return (
    <AppModal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <section className={styles.host}>
        <h2>Редактирование данных школы</h2>

        <SchoolForm handleSubmit={handleSubmit} loading={loading} school={school} />
      </section>
    </AppModal>
  );
}
