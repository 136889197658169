import { useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Badge, DropdownItem } from 'reactstrap';

import styles from './ClassStudentsPage.module.scss';
import defaultUserAvatar from '@/assets/img/defaultUserAvatar.svg';

import { AppButton, AppButtonGroup, AppLoader, AppModal, AppSearch, AppTable } from '@/components/app';
import { ClassNutritionPlan, SchoolClass, Student } from '@/models/entity';
import { SchoolsService } from '@/services';
import { AddStudentModal, DeleteStudentModal, EditStudentModal } from './components';
import { PageTitle } from '@/layout/DashboardLayout/components';
import { useDocumentTitle, useTableUrlParams } from '@/hooks';
import { RootState } from '@/store';
import { dayMonthYear } from '@/utils/date';
import { getGenderShortName } from '@/constants/genders';
import { formatCurrency, formatPhone } from '@/utils/formatters';
import { config as IikoCardStateConfig } from '@/constants/iikoCardState';
import { AddClassModal } from '../SchoolClassesPage/components';
import { SchoolIcon } from '@/components/icons';
import { getCssCustomProperty } from '@/utils/cssCustomProperties';
import { MealSubscription } from '@/constants/mealSubscription';

export default function ClassStudentsPage() {
  const { schoolId, classId } = useParams();
  const { search: urlSearch, currentPage: urlCurrentPage, itemsPerPage: urlItemsPerPage } = useTableUrlParams();

  //
  // State
  //

  const [initalLoading, setInitalLoading] = useState<boolean>(true);
  const [students, setStudents] = useState<Student[]>([]);
  const [studentsMealPlans, setStudentsMealPlans] = useState<ClassNutritionPlan[]>([]);
  const [schoolClass, setSchoolClass] = useState<SchoolClass | null>(null);
  const [search, setSearch] = useState<string>(urlSearch || '');
  const [currentPage, setCurrentPage] = useState<number>(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState<number>(urlItemsPerPage);
  const [matchMealTypesOpened, setMatchMealTypesOpened] = useState<boolean>(false);
  const [mealTypeLoading, setMealTypeLoading] = useState<boolean>(false);

  const [addStudentsModalOpened, setAddStudentsModalOpened] = useState<boolean>(false);
  const [addSingleStudentModalOpened, setAddSingleStudentModalOpened] = useState<boolean>(false);
  const [addClassModalOpened, setAddClassModalOpened] = useState<boolean>(false);
  const [editStudentModalOpened, setEditStudentModalOpened] = useState<boolean>(false);
  const [editStudentModalData, setEditStudentModalData] = useState<Student | null>(null);
  const [deleteStudentModalOpened, setDeleteStudentModalOpened] = useState<boolean>(false);
  const [deleteStudentModalData, setDeleteStudentModalData] = useState<Student | null>(null);

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Computed
  //

  const currentSchool = schools.find((s) => s.id === schoolId);
  const filteredStudents = useMemo(
    () => students.filter((st) => st.FIO.toLowerCase().includes(search.toLowerCase())),
    [search, students],
  );
  const paginatedStudents = useMemo(() => {
    const currentPageDivider = currentPage <= 0 ? 0 : currentPage - 1;
    const startIndex = currentPageDivider * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return filteredStudents.slice(startIndex, endIndex);
  }, [currentPage, filteredStudents, itemsPerPage]);
  const totalPages = useMemo(() => Math.ceil(filteredStudents.length / itemsPerPage), [itemsPerPage, filteredStudents]);

  //
  // Methods
  //

  const loadStudents = useCallback(async () => {
    if (!classId || Array.isArray(classId)) return;

    const { schoolClass: schoolClassData, students: studentsData } = await SchoolsService.getClassStudents(classId);
    setStudents(studentsData);
    setSchoolClass(schoolClassData);
  }, [classId]);

  const loadChildrenSubscriptions = useCallback(async () => {
    if (!classId || Array.isArray(classId)) return;

    const plans = await SchoolsService.getClassChildrenNutritionPlans(classId);
    setStudentsMealPlans(plans);
  }, [classId]);

  const loadInitialData = useCallback(async () => {
    try {
      setInitalLoading(true);
      await loadStudents();
      await loadChildrenSubscriptions();
    } catch (error) {
      throw error;
    } finally {
      setInitalLoading(false);
    }
  }, [loadChildrenSubscriptions, loadStudents]);

  const handleDeleteStudentClick = (student: Student) => {
    setDeleteStudentModalData(student);
    setDeleteStudentModalOpened(true);
  };

  const handleEditStudentClick = (student: Student) => {
    setEditStudentModalData(student);
    setEditStudentModalOpened(true);
  };

  const handleChangeMealTypeActive = (studentPlan: ClassNutritionPlan, mealType: MealSubscription) => {
    let newStudents: ClassNutritionPlan[] = [];

    newStudents = studentsMealPlans.map((s) => {
      if (s.id === studentPlan.id) {
        if (mealType === MealSubscription.breakfast) {
          let nutritionSubscriptions = s.nutritionSubscriptions;

          if (nutritionSubscriptions.includes(MealSubscription.breakfast)) {
            nutritionSubscriptions = nutritionSubscriptions.filter((ns) => ns !== MealSubscription.breakfast);
          } else {
            nutritionSubscriptions.push(MealSubscription.breakfast);
          }

          return new ClassNutritionPlan({ ...s, nutritionSubscriptions });
        } else if (mealType === MealSubscription.lunch) {
          let nutritionSubscriptions = s.nutritionSubscriptions;

          if (nutritionSubscriptions.includes(MealSubscription.lunch)) {
            nutritionSubscriptions = nutritionSubscriptions.filter((ns) => ns !== MealSubscription.lunch);
          } else {
            nutritionSubscriptions.push(MealSubscription.lunch);
          }

          return new ClassNutritionPlan({ ...s, nutritionSubscriptions });
        } else if (mealType === MealSubscription.afternoonSnack) {
          let nutritionSubscriptions = s.nutritionSubscriptions;

          if (nutritionSubscriptions.includes(MealSubscription.afternoonSnack)) {
            nutritionSubscriptions = nutritionSubscriptions.filter((ns) => ns !== MealSubscription.afternoonSnack);
          } else {
            nutritionSubscriptions.push(MealSubscription.afternoonSnack);
          }

          return new ClassNutritionPlan({ ...s, nutritionSubscriptions });
        } else {
          return s;
        }
      } else {
        return s;
      }
    });

    setStudentsMealPlans(newStudents);
  };

  const handleUpdateNutritionPlans = async () => {
    if (!classId || Array.isArray(classId)) return;

    try {
      setMealTypeLoading(true);
      await SchoolsService.updateClassNutritionPlan(classId, studentsMealPlans);
      setMatchMealTypesOpened(false);
      loadInitialData();
    } catch (error) {
      throw error;
    } finally {
      setMealTypeLoading(false);
    }
  };

  //
  // Effects
  //

  useDocumentTitle(`Список учеников ${schoolClass ? `${schoolClass.name} класса` : ''} ${currentSchool?.name || ''}`);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  //
  // Render
  //

  const renderActions = (student: Student) => {
    return [
      <DropdownItem key="edit" onClick={() => handleEditStudentClick(student)}>
        <span className="dropdown-icon material-icons">create</span>
        Редактировать ученика
      </DropdownItem>,
      <DropdownItem key="delete" onClick={() => handleDeleteStudentClick(student)}>
        <span className="dropdown-icon material-icons">delete</span>
        Удалить ученика
      </DropdownItem>,
    ];
  };

  const renderCheckboxActive = (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <rect
        height="18.5"
        rx="2.25"
        stroke={getCssCustomProperty('--primary-color')}
        stroke-width="1.5"
        width="18.5"
        x="0.75"
        y="0.75"
      />
      <path
        d="M7.73333 12.9252L5.00404 10.196L4.93333 10.1252L4.86262 10.196L3.92929 11.1293L3.85858 11.2L3.92929 11.2707L7.66262 15.004L7.73333 15.0748L7.80404 15.004L15.804 7.00404L15.8748 6.93333L15.804 6.86262L14.8707 5.92929L14.8 5.85858L14.7293 5.92929L7.73333 12.9252Z"
        fill={getCssCustomProperty('--primary-color')}
        stroke={getCssCustomProperty('--primary-color')}
        stroke-width="0.2"
      />
    </svg>
  );

  const renderCheckboxInacive = (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <rect height="18.5" rx="2.25" stroke="#B3B3B3" stroke-width="1.5" width="18.5" x="0.75" y="0.75" />
    </svg>
  );

  const renderTable = (
    <section className="animate__animated animate__fadeIn">
      <div className={cn(styles.actions, 'd-flex justify-content-between align-items-center')}>
        <div className={styles.actionsRow}>
          <AppButtonGroup
            className={styles.addStudentsBtn}
            leftSlot={<FontAwesomeIcon fixedWidth icon={faPlus} />}
            onClick={() => setAddStudentsModalOpened(true)}
          >
            Добавить учеников
          </AppButtonGroup>

          <AppButton onClick={() => setMatchMealTypesOpened(true)}>Указать типы питания</AppButton>
        </div>

        <AppSearch
          onChange={(value) => {
            setCurrentPage(1);
            setSearch(value);
          }}
          onClickRefreshButton={loadInitialData}
          value={search}
        />
      </div>

      <AppTable
        className={styles.table}
        columns={[
          {
            value: (student) => student.FIO,
            className: 'text-left',
            header: {
              title: 'ФИО',
              style: {
                width: 300,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => dayMonthYear(student.birthdate),
            className: 'text-left',
            header: {
              title: 'Дата рождения',
              style: {
                width: 140,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => (
              <img
                alt={`Фото ${student.FIO}`}
                className={styles.studentPhoto}
                src={student.photoUrl || defaultUserAvatar}
              />
            ),
            className: cn('text-left', styles.photoCell),
            header: {
              title: 'Фото',
              style: {
                width: 60,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => getGenderShortName(student.gender),
            className: 'text-left',
            header: {
              title: 'Пол',
              style: {
                width: 60,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => student.parentFIO,
            className: 'text-left',
            header: {
              title: 'ФИО родителя',
              style: {
                width: 300,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => formatPhone(student.parentPhone),
            className: (student) => cn('text-left', { [styles.invalidCell]: !student.parentPhoneValid }),
            header: {
              title: 'Телефон родителя',
              className: 'text-left',
              style: {
                width: 180,
              },
            },
          },
          {
            value: (student) => (
              <span className={cn({ [styles.negativeBalance]: student.balance < 0 })}>
                {formatCurrency(student.balance)}
              </span>
            ),
            className: 'text-left',
            header: {
              title: 'Баланс',
              style: {
                width: 100,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => {
              const configValue = IikoCardStateConfig[student.iikoCardState];

              return (
                <Badge
                  style={{
                    backgroundColor: configValue.backgroundColor,
                    color: configValue.color,
                  }}
                >
                  {configValue.name}
                </Badge>
              );
            },
            className: 'text-left',
            header: {
              title: 'iikoCard',
              style: {
                width: 100,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => (student.breakfastMealEnabled ? renderCheckboxActive : renderCheckboxInacive),
            className: 'text-left',
            header: {
              title: 'Завтрак',
              style: {
                width: 70,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => (student.lunchMealEnabled ? renderCheckboxActive : renderCheckboxInacive),
            className: 'text-left',
            header: {
              title: 'Обед',
              style: {
                width: 70,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => (student.afternoonSnackMealEnabled ? renderCheckboxActive : renderCheckboxInacive),
            className: 'text-left',
            header: {
              title: 'Полдник',
              style: {
                width: 70,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => renderActions(student),
            type: 'action',
            className: 'text-center',
            header: {
              title: 'Действия',
              style: {
                width: 120,
              },
            },
          },
        ]}
        data={paginatedStudents}
        loading={false}
        pagination={{
          totalPages,
          currentPage,
          itemsPerPage,
          onChangePage: (newPage: number) => setCurrentPage(newPage),
          onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
        }}
      />
    </section>
  );

  return (
    <>
      <PageTitle
        heading={`Список учеников ${schoolClass ? `${schoolClass.name} класса` : ''} ${currentSchool?.name || ''}`}
        svg={<SchoolIcon />}
      />
      <AppLoader active={initalLoading} />
      {initalLoading && students.length === 0 ? null : renderTable}
      <AddStudentModal
        isOpen={addSingleStudentModalOpened}
        onActionSuccess={() => {
          setAddSingleStudentModalOpened(false);
          loadInitialData();
        }}
        onClose={() => {
          setAddSingleStudentModalOpened(false);
          setAddStudentsModalOpened(true);
        }}
      />
      <EditStudentModal
        isOpen={editStudentModalOpened}
        onActionSuccess={loadInitialData}
        onClose={() => {
          setEditStudentModalOpened(false);
          setTimeout(() => {
            setEditStudentModalData(null);
          }, 300);
        }}
        student={editStudentModalData}
      />
      <DeleteStudentModal
        isOpen={deleteStudentModalOpened}
        onActionSuccess={loadInitialData}
        onClose={() => {
          setDeleteStudentModalOpened(false);
          setTimeout(() => {
            setDeleteStudentModalData(null);
          }, 300);
        }}
        student={deleteStudentModalData}
      />

      {classId && (
        <AddClassModal
          classId={classId}
          isOpen={addClassModalOpened}
          onActionSuccess={loadInitialData}
          onClose={() => {
            setAddClassModalOpened(false);
          }}
        />
      )}

      <AppModal isOpen={addStudentsModalOpened} onClose={() => setAddStudentsModalOpened(false)}>
        <section className={styles.addStudentsModal}>
          <h2>Добавление учеников</h2>
          <p>Вы можете добавить одного ученика или загрузить таблицу со списком учеников</p>
          <div className={styles.modalActions}>
            <AppButton
              onClick={() => {
                setAddStudentsModalOpened(false);
                setAddClassModalOpened(true);
              }}
            >
              Загрузить список учеников
            </AppButton>
            <AppButton
              onClick={() => {
                setAddStudentsModalOpened(false);
                setAddSingleStudentModalOpened(true);
              }}
            >
              Добавить одного ученика
            </AppButton>
          </div>
        </section>
      </AppModal>

      <AppModal
        closeOnClick={false}
        closeOnEsc={false}
        isOpen={matchMealTypesOpened}
        onClose={() => setMatchMealTypesOpened(false)}
        rootClassName={styles.matchMealTypeModalHost}
      >
        <section className={styles.matchMealTypeModal}>
          <h2>Типы питания</h2>
          <p>Укажите типы питания каждого ученика и нажмите на кнопку "Сохранить"</p>

          <AppTable
            className={styles.studentsMealTable}
            columns={[
              {
                value: (student) => student.FIO,
                className: 'text-left',
                header: {
                  title: 'ФИО',
                  style: {
                    width: 500,
                  },
                  className: 'text-left',
                },
              },
              {
                value: (student) => (
                  <div
                    className={styles.mealTypeCheckbox}
                    onClick={() => handleChangeMealTypeActive(student, MealSubscription.breakfast)}
                  >
                    {student.nutritionSubscriptions.includes(MealSubscription.breakfast)
                      ? renderCheckboxActive
                      : renderCheckboxInacive}
                  </div>
                ),
                className: 'text-left',
                header: {
                  title: 'Завтрак',
                  style: {
                    width: 70,
                  },
                  className: 'text-left',
                },
              },
              {
                value: (student) => (
                  <div
                    className={styles.mealTypeCheckbox}
                    onClick={() => handleChangeMealTypeActive(student, MealSubscription.lunch)}
                  >
                    {student.nutritionSubscriptions.includes(MealSubscription.lunch)
                      ? renderCheckboxActive
                      : renderCheckboxInacive}
                  </div>
                ),
                className: 'text-left',
                header: {
                  title: 'Обед',
                  style: {
                    width: 70,
                  },
                  className: 'text-left',
                },
              },
              {
                value: (student) => (
                  <div
                    className={styles.mealTypeCheckbox}
                    onClick={() => handleChangeMealTypeActive(student, MealSubscription.afternoonSnack)}
                  >
                    {student.nutritionSubscriptions.includes(MealSubscription.afternoonSnack)
                      ? renderCheckboxActive
                      : renderCheckboxInacive}
                  </div>
                ),
                className: 'text-left',
                header: {
                  title: 'Полдник',
                  style: {
                    width: 70,
                  },
                  className: 'text-left',
                },
              },
            ]}
            data={studentsMealPlans}
            pagination={null}
          />

          <div className={styles.matchMealTypeModalActions}>
            <AppButton disabled={mealTypeLoading} onClick={handleUpdateNutritionPlans}>
              Сохранить
            </AppButton>
          </div>
        </section>
      </AppModal>
    </>
  );
}
