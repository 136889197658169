import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import layoutSlice from './layoutSlice';
import { profileReducer } from './profileSlice';
import { modalsReducer } from './modalsSlice';
import { schoolsReducer } from './schoolsSlice';
import { iikoReducer } from './iikoSlice';

export const store = configureStore({
  reducer: {
    layout: layoutSlice,
    profile: profileReducer,
    modals: modalsReducer,
    schools: schoolsReducer,
    iiko: iikoReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
