import styles from './SchoolDeleteModal.module.scss';

import { SchoolIikoSettings } from '@/models/entity';
import { AppButton, AppModal } from '@/components/app';

type Props = {
  isOpen: boolean;
  onClose(): void;
  school: SchoolIikoSettings | null;
  loading: boolean;
  handleSubmit(school: SchoolIikoSettings | null): void;
};

export default function SchoolDeleteModal(props: Props) {
  const { isOpen, loading, onClose, school, handleSubmit } = props;

  return (
    <AppModal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
    >
      <section className={styles.host}>
        <h2>Вы уверены, что хотите удалить данную школу?</h2>

        <section className={styles.actions}>
          <AppButton
            className={styles.actionBtn}
            disabled={loading}
            onClick={onClose}
            outline
          >
            Отмена
          </AppButton>
          <AppButton
            className={styles.actionBtn}
            disabled={loading}
            onClick={() => handleSubmit(school)}
          >
            Да
          </AppButton>
        </section>
      </section>
    </AppModal>
  );
}
