import axios from 'axios';

import { requestInterceptor, responseInterceptorError, responseInterceptorSuccess } from './interceptors';

export const API_URL = process.env.REACT_APP_API_URL;

export const httpClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 60 * 1000,
  // follow up to 10 HTTP 3xx redirects
  maxRedirects: 10,
});

/**
 * Request interceptors.
 */
httpClient.interceptors.request.use(requestInterceptor);

/**
 * Response interceptors.
 */
httpClient.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError);
