export default class SubTeacherListItemAssignedClass {
  public id: string;
  public number: number | null;
  public liter: string;

  public constructor(data: Partial<SubTeacherListItemAssignedClass>) {
    this.id = data.id!;
    this.number = data.number ?? null;
    this.liter = data.liter ?? '';
  }
}
