import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
  addSchoolModalOpened: boolean;
};

const initialState: SliceState = {
  addSchoolModalOpened: false,
};

const modals = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setAddSchoolModalOpened: (state, action: PayloadAction<boolean>) => {
      state.addSchoolModalOpened = action.payload;
    },
  },
});

export const { reducer: modalsReducer } = modals;
export const { setAddSchoolModalOpened } = modals.actions;
