import { store } from '@/store';
import { setAuthToken } from '@/store/profileSlice';
import { parseJwt } from '@/utils/parsers';

const TOKEN_STORAGE_KEY = '@usr_t';

/**
 * Service for manage user tokens.
 */
const TokenStorageService = {
  set(authToken: string): void {
    localStorage.setItem(TOKEN_STORAGE_KEY, authToken);
    store.dispatch(setAuthToken(authToken));
  },

  getAuthToken(): string | null {
    let tokenFromStore = null;

    try {
      tokenFromStore = store.getState().profile.authToken;
    } catch (error) {
      tokenFromStore = null;
    }

    const tokenFromLS = localStorage.getItem(TOKEN_STORAGE_KEY) || null;

    return tokenFromStore ?? tokenFromLS;
  },

  remove(): void {
    store.dispatch(setAuthToken(null));
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  },

  isExpired(token: string): boolean {
    const { exp } = parseJwt(token);

    return Date.now() >= exp * 1000;
  },
};

export default TokenStorageService;
