import { FormikErrors } from 'formik';

import styles from './TeacherExistsModal.module.scss';

import { AppButton, AppModal } from '@/components/app';
import { SchoolClass } from '@/models/entity';
import { FormFields } from '../TeacherModal';

type Props = {
  open: boolean;
  onClose(): void;
  handleSubmit(): void;
  data: {
    classes: SchoolClass[];
    formValues: FormFields | null;
    setErrors: ((errors: FormikErrors<FormFields>) => void) | null;
  };
};

export default function TeacherExistsModal(props: Props) {
  const { data, handleSubmit, onClose, open } = props;

  const renderAlarmMessages = data.classes.map((c) => (
    <p key={c.id}>
      При добавлении этого учителя, в {c.name} классе сменится ответственный по питанию. <br />
      Ответственный по питанию {c.responsible?.FIOShort} перестанет быть ответственным по питанию {c.name} класса.
    </p>
  ));

  return (
    <AppModal
      className={styles.modal}
      isOpen={open}
      onClose={onClose}
    >
      <section className={styles.host}>
        <h2>Обратите внимание</h2>

        {renderAlarmMessages}

        <section className={styles.actions}>
          <AppButton
            className={styles.actionBtn}
            onClick={onClose}
            outline
          >
            Вернуться
          </AppButton>
          <AppButton
            className={styles.actionBtn}
            onClick={() => {
              handleSubmit();
              onClose();
            }}
          >
            Продолжить
          </AppButton>
        </section>
      </section>
    </AppModal>
  );
}
