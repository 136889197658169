import { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, DropdownItem } from 'reactstrap';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './SubStudentsTable.module.scss';
import defaultUserAvatar from '@/assets/img/defaultUserAvatar.svg';

import { AppInputField, AppLoader, AppSearch, AppTable } from '@/components/app';
import { StudentListItem } from '@/models/entity';
import { SchoolsService } from '@/services';
import { formatDateToDateAndTime } from '@/utils/date';
import { formatPhone } from '@/utils/formatters';
import { useChangeTableUrlParams, useTableUrlParams } from '@/hooks';
import { Filter } from '@/hooks/useChangeTableUrlParams';
import { getGenderShortName } from '@/constants/genders';
import { OrderTypesConfig } from '@/constants/orders';
import { RootState } from '@/store';
import { Paths } from '@/routes';

export default function SubStudentsTable() {
  const { search: urlSearch, currentPage: urlCurrentPage, itemsPerPage: urlItemsPerPage } = useTableUrlParams();
  const navigate = useNavigate();

  //
  // State
  //

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<StudentListItem[]>([]);
  const [schoolsFilter, setSchoolsFilter] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(urlItemsPerPage);
  const [searchFilter, setSearchFilter] = useState<string>(urlSearch || '');

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Computed
  //

  const queryFilters = useMemo(() => [].filter(Boolean) as Filter[], []);

  //
  // Methods
  //

  const loadStudents = useCallback(async () => {
    const { data: studentsData, count } = await SchoolsService.getSubsStudents({
      search: searchFilter,
      currentPage,
      itemsPerPage,
      schoolsIds: schoolsFilter,
    });
    setStudents(studentsData);
    setTotalPages(Math.ceil(count / itemsPerPage));
  }, [currentPage, itemsPerPage, schoolsFilter, searchFilter]);

  const loadAllData = useCallback(() => {
    setTableLoading(true);
    Promise.all([loadStudents()]).finally(() => {
      setTableLoading(false);
    });
  }, [loadStudents]);

  //
  // Effects
  //

  useEffect(() => {
    loadAllData();
  }, [loadAllData]);

  useChangeTableUrlParams({
    currentPage,
    perPage: itemsPerPage,
    search: searchFilter,
    filters: queryFilters,
  });

  //
  // Render
  //

  const renderActions = (student: StudentListItem) => {
    return [
      <DropdownItem key="edit" onClick={() => navigate(Paths.studentTransactions(student.id))}>
        <span className="dropdown-icon material-icons">list</span>
        Подробнее
      </DropdownItem>,
    ];
  };

  const renderClassFilter = (
    <section className={styles.host}>
      <section className={styles.topActions}>
        <section className={styles.filters}>
          <AppInputField
            className={cn(styles.filter, styles.schoolsFilter)}
            controlType="multi-select"
            onChange={(value) => setSchoolsFilter(value)}
            options={schools.map((s) => ({ label: s.name, value: s.id }))}
            placeholder="Выберите школу"
            value={schoolsFilter}
          />
        </section>

        <AppSearch
          onChange={(value) => {
            setSearchFilter(value);
          }}
          onClickRefreshButton={loadAllData}
          value={searchFilter}
        />
      </section>

      <AppTable
        className={styles.table}
        columns={[
          {
            value: (student) => (
              <div className={cn('text-left', styles.schoolCell)}>{student.schoolClass?.school?.name}</div>
            ),
            header: {
              title: 'Название школы',
              style: {
                width: 261,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => {
              if (!student.dateJoined) return '—';

              const { date } = formatDateToDateAndTime(student.dateJoined);

              return date;
            },
            className: 'text-left',
            header: {
              title: 'Дата\nрегистрации',
              style: {
                width: 105,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => {
              if (!student.lastLogin) return '—';

              const { date } = formatDateToDateAndTime(student.lastLogin);

              return date;
            },
            className: 'text-left',
            header: {
              title: 'Последняя\nдата входа',
              style: {
                width: 105,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => (
              <img
                alt={`Фото ${student.FIO}`}
                className={styles.studentPhoto}
                src={student.photo || defaultUserAvatar}
              />
            ),
            className: 'text-left',
            header: {
              title: 'Фото',
              style: {
                width: 50,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => student.FIO,
            className: 'text-left',
            header: {
              title: 'ФИО',
              className: 'text-left',
            },
          },
          {
            value: (student) => {
              if (!student.birthdate) return '—';

              const { date } = formatDateToDateAndTime(student.birthdate);

              return date;
            },
            className: 'text-left',
            header: {
              title: 'Дата\nрождения',
              style: {
                width: 95,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) => getGenderShortName(student.gender),
            className: 'text-left',
            header: {
              title: 'Пол',
              style: {
                width: 50,
              },
              className: 'text-left',
            },
          },
          {
            value: (student) =>
              student.parent ? (
                <div>
                  {student.parent?.FIO} <br />
                  <span className={styles.parentPhone}>{formatPhone(student.parent.phone)}</span>
                </div>
              ) : (
                '—'
              ),
            className: 'text-left',
            header: {
              title: 'ФИО Родителя',
              className: 'text-left',
            },
          },
          {
            value: (student) =>
              student.schoolClass ? `${student.schoolClass.number} ${student.schoolClass.liter}` : '—',
            className: 'text-left',
            header: {
              title: 'Класс',
              style: {
                width: 50,
              },
              className: 'text-left',
            },
          },
          // {
          //   value: (student) => {
          //     if (!student.mealType) return '—';

          //     const mealTypeConfigValue = OrderTypesConfig[student.mealType];

          //     if (!mealTypeConfigValue) return '—';

          //     return (
          //       <Badge
          //         style={{
          //           backgroundColor: mealTypeConfigValue.bgColor,
          //           color: mealTypeConfigValue.color,
          //         }}
          //       >
          //         {mealTypeConfigValue.name}
          //       </Badge>
          //     );
          //   },
          //   className: 'text-left',
          //   header: {
          //     title: 'Тип',
          //     style: {
          //       width: 140,
          //     },
          //     className: 'text-left',
          //   },
          // },
          {
            value: (student) => renderActions(student),
            type: 'action',
            className: 'text-center',
            header: {
              title: 'Действия',
              style: {
                width: 90,
              },
            },
          },
        ]}
        data={students}
        loading={false}
        pagination={{
          totalPages,
          currentPage,
          itemsPerPage,
          onChangePage: (newPage: number) => setCurrentPage(newPage),
          onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
        }}
      />
    </section>
  );

  return (
    <section className={styles.host}>
      <AppLoader active={tableLoading} />
      {renderClassFilter}
    </section>
  );
}
