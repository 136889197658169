/* eslint-disable camelcase */
import { saveAs } from 'file-saver';

import { SchoolsApi } from '@/api/sources';
import { OrderTypes } from '@/constants/orders';
import { StatusTypes } from '@/constants/transactions';
import {
  ClassNutritionPlan,
  ImportStudent,
  KitchenRequest,
  KitchenRequestItem,
  Order,
  PaidNutrition,
  ParentListItem,
  ParentListItemStudent,
  School,
  SchoolClass,
  Student,
  StudentListItem,
  StudentListItemParent,
  StudentTransaction,
  SubTeacherListItem,
  SubTeacherListItemAssignedClass,
  Teacher,
  TeacherListItem,
  Transaction,
} from '@/models/entity';
import { mapDtoToSchool, mapDtoToSchoolClass, mapDtoToStudent, mapDtoToTeacher } from '@/models/mappers/schoolMappers';

export default class SchoolsService {
  public static async getAllSchools(): Promise<School[]> {
    const dto = await SchoolsApi.fetchSchools();
    const schools = dto.map(mapDtoToSchool);

    return schools;
  }

  public static async createSchool(
    marketingCampaign: string,
    iikoFrontCount: number,
    mealDaily: '5' | '6' | undefined,
    name: string,
    phone: string,
    warehouse: string,
    nameEmployee: string,
    surnameEmployee: string,
    middleNameEmployee: string,
  ): Promise<School> {
    const dto = await SchoolsApi.createSchool(
      marketingCampaign,
      iikoFrontCount,
      mealDaily,
      name,
      phone,
      warehouse,
      nameEmployee,
      surnameEmployee,
      middleNameEmployee,
    );
    const school = mapDtoToSchool(dto);

    return school;
  }

  public static async getSchoolClasses(schoolId: string): Promise<SchoolClass[]> {
    const dto = await SchoolsApi.fetchSchoolClasses(schoolId);
    const classes = dto.map(mapDtoToSchoolClass);

    return classes;
  }

  public static async getClassStudents(classId: string): Promise<{ students: Student[]; schoolClass: SchoolClass }> {
    const dto = await SchoolsApi.fetchClassStudents(classId);
    const students = dto.children.map(mapDtoToStudent);
    const schoolClass = mapDtoToSchoolClass({
      count_children: dto.count_children,
      id: dto.id,
      liter: dto.liter,
      number: dto.number,
      responsible: dto.responsible,
      created_at: dto.created_at,
      school: null,
      time_publication: dto.time_publication,
      time_submitting: dto.time_submitting,
    });

    return {
      students,
      schoolClass,
    };
  }

  public static async getStudentById(studentId: string): Promise<Student> {
    const dto = await SchoolsApi.fetchStudentById(studentId);
    const student = mapDtoToStudent(dto);

    return student;
  }

  public static async createNewClass(
    schoolId: string,
    number: string,
    liter: string,
    mealRequestTime: string,
    mealRequestDeadlineTime: string,
  ): Promise<SchoolClass> {
    const dto = await SchoolsApi.createClass(schoolId, number, liter, mealRequestTime, mealRequestDeadlineTime);
    const schoolClass = mapDtoToSchoolClass(dto);

    return schoolClass;
  }

  public static async editSchoolClass(
    classId: string,
    schoolId: string,
    number: string,
    liter: string,
    mealRequestTime: string,
    mealRequestDeadlineTime: string,
  ): Promise<SchoolClass> {
    const dto = await SchoolsApi.updateClass(
      classId,
      schoolId,
      number,
      liter,
      mealRequestTime,
      mealRequestDeadlineTime,
    );
    const schoolClass = mapDtoToSchoolClass(dto);

    return schoolClass;
  }

  public static async getSchoolTeachers(schoolId: string): Promise<Teacher[]> {
    const dto = await SchoolsApi.fetchSchoolTeachers(schoolId);
    const teachers = dto.map(mapDtoToTeacher);

    return teachers;
  }

  public static async deleteSchoolClass(classId: string): Promise<void> {
    await SchoolsApi.deleteClass(classId);
  }

  public static async parseSchoolClassFile(file: File): Promise<ImportStudent[]> {
    const dto = await SchoolsApi.parseClassFile(file);
    const importStudents: ImportStudent[] = dto.map((iSDto, index) => ({
      firstName: iSDto.first_name,
      lastName: iSDto.last_name,
      middleName: iSDto.middle_mame,
      id: index,
      error: iSDto.error,
      parentPhone: iSDto.phone_parent,
      parentPhoneClean: iSDto.phone_clean,
    }));

    return importStudents;
  }

  public static async createStudent(
    schoolClassId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    parentPhone: string,
  ): Promise<void> {
    await SchoolsApi.createStudent(schoolClassId, firstName, lastName, middleName, parentPhone);
  }

  public static async createStudents(
    schoolClassId: string,
    students: { firstName: string; lastName: string; middleName: string; parentPhone: string }[],
  ): Promise<void> {
    await SchoolsApi.createStudents(schoolClassId, students);
  }

  public static async editStudent(
    studentId: string,
    schoolClassId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    parentPhone: string,
  ): Promise<void> {
    await SchoolsApi.editStudent(studentId, schoolClassId, firstName, lastName, middleName, parentPhone);
  }

  public static async deleteStudent(studentId: string, schoolClassId: string): Promise<void> {
    await SchoolsApi.deleteStudent(studentId, schoolClassId);
  }

  public static async getTransactions(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    operationType: string | string[] | undefined;
    dateRange: string | string[] | undefined;
    schoolsIds: string[];
  }): Promise<{ data: Transaction[]; count: number }> {
    const { data: dtos, count } = await SchoolsApi.fetchTransactions(query);
    const transactions = dtos.map((dto) => {
      return new Transaction({
        id: dto.iiko_processed + dto.child_fio,
        amount: parseFloat(dto.amount),
        className: dto.class_title,
        studentName: dto.child_fio,
        parentName: dto.parent_fio,
        createdAt: new Date(dto.iiko_processed),
        balance: parseFloat(dto.balance),
        status: dto.iiko_synchronized ? StatusTypes.success : StatusTypes.error,
        paymentType: dto.payment_type,
        schoolName: dto.school_title,
      });
    });

    return { data: transactions, count };
  }

  public static async getOrders(
    schoolId: string,
    query: {
      search: string;
      currentPage: number;
      itemsPerPage: number;
      classId: string | string[] | undefined;
      orderType: string | string[] | undefined;
      dateRange: string | string[] | undefined;
    },
  ): Promise<Order[]> {
    const dtos = await SchoolsApi.fetchSchoolOrders(schoolId, query);
    const orders = dtos.map((dto) => {
      return new Order({
        id: dto.id.toString(),
        amount: +dto.total,
        createdAt: new Date(dto.created_at),
        className: dto.child_school_class_title,
        studentName: dto.child_full_name,
        type: dto.kind,
        number: dto.checkout_order_number,
        items: dto.items,
        total: parseFloat(dto.total),
        school: dto.child_school
          ? new School({
            id: dto.child_school.id.toString(),
            name: dto.child_school.title,
          })
          : null,
      });
    });

    return orders;
  }

  public static async getAllOrders(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    orderType: string | string[] | undefined;
    dateRange: string | string[] | undefined;
    schoolsIds: string[];
  }): Promise<{ data: Order[]; count: number }> {
    const { data: dtos, count } = await SchoolsApi.fetchAllOrders(query);
    const orders = dtos.map((dto) => {
      return new Order({
        id: dto.id.toString(),
        amount: +dto.total,
        createdAt: new Date(dto.created_at),
        className: dto.child_school_class_title,
        studentName: dto.child_full_name,
        type: dto.kind,
        number: dto.checkout_order_number,
        items: dto.items,
        total: parseFloat(dto.total),
        school: dto.child_school
          ? new School({
            id: dto.child_school.id.toString(),
            name: dto.child_school.title,
          })
          : null,
      });
    });

    return { data: orders, count };
  }

  public static async getStudentOrders(
    studentId: string,
    query: {
      currentPage: number;
      itemsPerPage: number;
      orderType: string | string[] | undefined;
      dateRange: string | string[] | undefined;
    },
  ): Promise<{ data: Order[]; count: number }> {
    const { data: dtos, count } = await SchoolsApi.fetchStudentOrders(studentId, query);
    const orders = dtos.map(
      (dto) =>
        new Order({
          id: dto.id.toString(),
          amount: +dto.total,
          createdAt: new Date(dto.created_at),
          className: dto.child_school_class_title,
          studentName: dto.child_full_name,
          type: dto.kind,
          number: dto.checkout_order_number,
          items: dto.items,
          total: parseFloat(dto.total),
          school: dto.child_school
            ? new School({
              id: dto.child_school.id.toString(),
              name: dto.child_school.title,
            })
            : null,
        }),
    );

    return { count, data: orders };
  }

  public static async getAllTeachers(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: TeacherListItem[]; count: number }> {
    const { data: dtos, count } = await SchoolsApi.fetchAllTeachers(query);
    const teachers = dtos.map(
      (dto) =>
        new TeacherListItem({
          id: dto.id.toString(),
          firstName: dto.first_name,
          lastName: dto.last_name,
          middleName: dto.middle_name,
          gender: dto.gender,
          phone: dto.phone,
          assignedClasses: dto.assigned_classes.map(mapDtoToSchoolClass),
          iikoWalletExist: dto.iiko_wallet_exist,
          school: dto.school
            ? new School({
              id: dto.school.id.toString(),
              name: dto.school.title,
            })
            : null,
        }),
    );

    return { data: teachers, count };
  }

  public static async createTeacher(
    schoolId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    responsibleForClassIds: string[],
    phone: string,
  ): Promise<void> {
    await SchoolsApi.createTeacher(schoolId, firstName, lastName, middleName, responsibleForClassIds, phone);
  }

  public static async editTeacher(
    teacherId: string,
    schoolId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    responsibleForClassIds: string[],
    phone: string,
  ): Promise<void> {
    await SchoolsApi.editTeacher(teacherId, schoolId, firstName, lastName, middleName, responsibleForClassIds, phone);
  }

  public static async deleteTeacher(teacherId: string, schoolId: string): Promise<void> {
    await SchoolsApi.deleteTeacher(teacherId, schoolId);
  }

  public static async getSubsParents(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: ParentListItem[]; total: number }> {
    const { count, data: dtos } = await SchoolsApi.fetchSubsParents(query);
    const parents = dtos.map((dto) => {
      return new ParentListItem({
        id: dto.id.toString(),
        birthdate: dto.birthdate ? new Date(dto.birthdate) : null,
        dateJoined: dto.date_joined ? new Date(dto.date_joined) : null,
        lastLogin: dto.last_login ? new Date(dto.last_login) : null,
        firstName: dto.first_name,
        gender: dto.gender,
        isActive: dto.is_active,
        lastName: dto.last_name,
        middleName: dto.middle_name,
        phone: dto.phone,
        children: dto.children.map(
          (cDto) =>
            new ParentListItemStudent({
              firstName: cDto.first_name,
              id: cDto.id.toString(),
              lastName: cDto.last_name,
              middleName: cDto.middle_name,
              schoolClass: cDto.school_class
                ? new SchoolClass({
                  id: cDto.school_class.id.toString(),
                  liter: cDto.school_class.liter,
                  number: cDto.school_class.number,
                  school: cDto.school_class.school
                    ? new School({
                      id: cDto.school_class.school.id.toString(),
                      name: cDto.school_class.school.title,
                    })
                    : null,
                })
                : null,
            }),
        ),
      });
    });

    return { data: parents, total: count };
  }

  public static async getSubsStudents(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: StudentListItem[]; count: number }> {
    const { data: dtos, count } = await SchoolsApi.fetchSubsStudents(query);
    const parents = dtos.map((dto) => {
      return new StudentListItem({
        id: dto.id.toString(),
        birthdate: dto.birthdate ? new Date(dto.birthdate) : null,
        dateJoined: dto.date_joined ? new Date(dto.date_joined) : null,
        lastLogin: dto.last_login ? new Date(dto.last_login) : null,
        firstName: dto.first_name,
        gender: dto.gender,
        isActive: dto.is_active,
        lastName: dto.last_name,
        photo: dto.photo,
        middleName: dto.middle_name,
        phone: dto.phone,
        parent: dto.parent
          ? new StudentListItemParent({
            birthdate: dto.parent.birthdate ? new Date(dto.parent.birthdate) : null,
            firstName: dto.parent.first_name,
            gender: dto.parent.gender,
            id: dto.parent.id.toString(),
            lastName: dto.parent.last_name,
            middleName: dto.parent.middle_name,
            phone: dto.parent.phone,
          })
          : null,
        schoolClass: dto.school_class
          ? {
            id: dto.school_class.id,
            liter: dto.school_class.liter,
            number: dto.school_class.number,
            school: dto.school_class.school
              ? new School({
                id: dto.school_class.school.id.toString(),
                name: dto.school_class.school.title,
              })
              : null,
          }
          : null,
        mealType: dto.nutrition_plan ? (dto.nutrition_plan.toLocaleLowerCase() as OrderTypes) : null,
      });
    });

    return { data: parents, count };
  }

  public static async getSubsTeachers(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: SubTeacherListItem[]; count: number }> {
    const { data: dtos, count } = await SchoolsApi.fetchSubsTeachers(query);
    const parents = dtos.map((dto) => {
      return new SubTeacherListItem({
        id: dto.id.toString(),
        birthdate: dto.birthdate ? new Date(dto.birthdate) : null,
        dateJoined: dto.date_joined ? new Date(dto.date_joined) : null,
        lastLogin: dto.last_login ? new Date(dto.last_login) : null,
        firstName: dto.first_name,
        gender: dto.gender,
        isActive: dto.is_active,
        lastName: dto.last_name,
        middleName: dto.middle_name,
        phone: dto.phone,
        assignedClasses: dto.assigned_classes.map(
          (dtoAC) =>
            new SubTeacherListItemAssignedClass({
              id: dtoAC.id.toString(),
              liter: dtoAC.liter,
              number: dtoAC.number,
            }),
        ),
        school: dto.school
          ? new School({
            id: dto.school.id.toString(),
            name: dto.school.title,
          })
          : null,
      });
    });

    return { data: parents, count };
  }

  public static async getSchoolClassesList(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    schoolsIds: string[];
  }): Promise<{ data: SchoolClass[]; count: number }> {
    const { count, data } = await SchoolsApi.fetchClasses(query);
    const classes = data.map(mapDtoToSchoolClass);

    return { count, data: classes };
  }

  public static async getStudentTransactions(
    studentId: string,
    query: {
      currentPage: number;
      itemsPerPage: number;
      orderType: string | undefined | string[];
      dateRange: string | undefined | string[];
    },
  ): Promise<{ data: StudentTransaction[]; count: number }> {
    const { data: dtos, count } = await SchoolsApi.fetchStudentTransactions(studentId, query);
    const transactions = dtos.map(
      (dto) =>
        new StudentTransaction({
          amount: dto.amount,
          balance: dto.balance,
          date: dto.created_at ? new Date(dto.created_at) : null,
        }),
    );

    return { count, data: transactions };
  }

  public static async getKitchenRequests(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
    status: string | undefined;
    schoolsIds: string[];
  }): Promise<{ data: KitchenRequest[]; count: number }> {
    const { count, data } = await SchoolsApi.getKitchenRequests(query);
    const requests = data.map((dto) => {
      let deadlineDateTime = dto.date_application ? new Date(dto.date_application) : null;

      if (dto.date_application && dto.school_class) {
        const deadlineStr = `${dto.date_application} ${dto.school_class.time_submitting}`;
        deadlineDateTime = new Date(deadlineStr);
      }

      return new KitchenRequest({
        afternoonSnackTeenCount: dto.snack_adult,
        afternoonSnackYoungCount: dto.snack_child,
        breakfastTeenCount: dto.breakfast_adult,
        breakfastYoungCount: dto.breakfast_child,
        date: dto.date_application ? new Date(dto.date_application) : null,
        deadlineDateTime,
        id: dto.id.toString(),
        lunchTeenCount: dto.lunch_adult,
        lunchYoungCount: dto.lunch_child,
        number: dto.id.toString(),
        responsible: dto.responsible ? mapDtoToTeacher(dto.responsible) : null,
        school: dto.school_class?.school ? mapDtoToSchool(dto.school_class.school) : null,
        schoolClass: dto.school_class ? mapDtoToSchoolClass(dto.school_class) : null,
        status: dto.status,
        updateAt: new Date(dto.updated_at),
        items: dto.items.map(
          (itemDto) =>
            new KitchenRequestItem({
              childAttend: itemDto.child_attend,
              concessional: itemDto.concessional,
              firstName: itemDto.first_name,
              lastName: itemDto.last_name,
              nutritionPlansPositions: itemDto.nutrition_plans_positions.map((nppDto) => ({
                kind: nppDto.kind,
                denied: nppDto.denied,
              })),
              paid: itemDto.paid,
              photo: itemDto.photo_url,
              pk: itemDto.pk.toString(),
              subsidy: itemDto.subsidy,
            }),
        ),
      });
    });

    return { count, data: requests };
  }

  public static async makeKitchenRequest(schoolsIds: string[]): Promise<void> {
    const { file, name } = await SchoolsApi.makeKitchenRequest(schoolsIds);

    saveAs(file, name);
  }

  public static async updateKitchenRequest(kitchenRequestId: string, studentsIds: string[]): Promise<void> {
    await SchoolsApi.updateKitchenRequest(kitchenRequestId, studentsIds);
  }

  public static async getClassChildrenNutritionPlans(classId: string): Promise<ClassNutritionPlan[]> {
    const dtos = await SchoolsApi.getClassChildrenNutritionPlans(classId);
    const plans = dtos.map(
      (dto) =>
        new ClassNutritionPlan({
          firstName: dto.first_name,
          id: dto.id.toString(),
          lastName: dto.last_name,
          middleName: dto.middle_name,
          nutritionSubscriptions: dto.nutrition_subscriptions,
          phoneParent: dto.phone_parent,
        }),
    );

    return plans;
  }

  public static async updateClassNutritionPlan(classId: string, plans: ClassNutritionPlan[]): Promise<void> {
    await SchoolsApi.updateClassNutritionPlan(classId, plans);
  }

  public static async getPaidNutrition(): Promise<PaidNutrition[]> {
    const dtos = await SchoolsApi.fetchPaidNutrition();

    return dtos.map(
      (dto) =>
        new PaidNutrition({
          debtAllowed: dto.debt_allowed,
          id: dto.id.toString(),
          paidNutritionEnabled: dto.paid_nutrition_enabled,
          schoolTitle: dto.title,
          totalDebt: dto.total_debt,
          paidAfternoonSnackClasses: dto.paid_afternoon_snack_classes,
          paidBreakfastClasses: dto.paid_breakfast_classes,
          paidLunchClasses: dto.paid_lunch_classes,
          paidAfternoonSnackPrice: dto.paid_afternoon_snack_price,
          paidBreakfastPrice: dto.paid_breakfast_price,
          paidLunchPrice: dto.paid_lunch_price,
        }),
    );
  }

  public static async updatePaidNutrition(
    srcData: PaidNutrition,
    values: {
      breakfastClasses: number[];
      lunchClasses: number[];
      afternoonSnackClasses: number[];
      debtAmount: number;
    },
  ): Promise<void> {
    await SchoolsApi.updatePaidNutrition(srcData, values);
  }
}
