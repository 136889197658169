export { default as AppRouter } from './AppRouter';

export const Paths = {
  home: '/',
  auth: '/auth',
  newSchool: '/schools/new',
  allSchools: '/schools/all',
  classes: '/classes',
  schoolClasses: (schoolId = ':schoolId') => `/schools/${schoolId}/classes`,
  teachers: '/teachers',
  classStudents: (schoolId = ':schoolId', classId = ':classId') => `/schools/${schoolId}/classes/${classId}`,
  transactions: '/transactions',
  orders: '/orders',
  subsParents: '/subs-parents',
  subsStudents: '/subs-students',
  subsTeachers: '/subs-teachers',
  mainSettings: '/settings/main',
  promotionsSettings: '/settings/promotions',
  menuSettings: '/settings/menu',
  faqSettings: '/settings/faq',
  privacySettings: '/settings/privacy',
  complexDishesSettings: '/settings/complex-dishes',
  complexDishUpdate: (dishId = ':dishId') => `/settings/complex-dishes/${dishId}`,
  appInstall: '/install',
  reports: '/reports',
  studentTransactions: (studentId = ':studentId') => `/students/${studentId}/transactions`,
  studentPurchases: (studentId = ':studentId') => `/students/${studentId}/purchases`,
  documentsDetails: '/settings/documents-details',
  trainingSchedule: '/settings/training-schedule',
  requests: '/requests',
  paidNutrition: '/paid-nutrition',
};
