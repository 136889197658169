import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormikErrors } from 'formik';

import styles from './AddSchoolModal.module.scss';

import { AppModal } from '@/components/app';
import { RootState, useAppDispatch } from '@/store';
import { setAddSchoolModalOpened } from '@/store/modalsSlice';
import { deformatPhone } from '@/utils/formatters';
import { notify } from '@/utils/notifications';
import { SchoolOutlineIcon, UserIcon, WifiIcon } from '@/components/icons';
import { SchoolsService } from '@/services';
import { pushSchool } from '@/store/schoolsSlice';
import { Paths } from '@/routes';
import { ServerValidationError } from '@/api/exceptions';
import SchoolForm, { FormFields } from '@/components/shared/SchoolForm/SchoolForm';
import wellKnownErrors from '@/api/wellKnownErrors';

export default function AddSchoolModal() {
  const navigate = useNavigate();

  //
  // State
  //

  const [isLoading, setIsLoading] = useState<boolean>(false);

  //
  // Store
  //

  const dispatch = useAppDispatch();
  const { addSchoolModalOpened } = useSelector((state: RootState) => state.modals);
  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Methods
  //

  const handleSubmitForm = async (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    const {
      marketingCampaign,
      iikoFrontCount,
      mealDaily,
      name,
      phone,
      warehouse,
      middleNameEmployee,
      nameEmployee,
      surnameEmployee,
    } = values;
    const phoneNoFormat = deformatPhone(phone);

    if (phoneNoFormat.length > 0 && !phoneNoFormat.startsWith('9')) {
      setErrors({ phone: ' ' });
      notify('Проверьте корректность введенного номера телефона', 'error', <UserIcon />);
      return;
    }

    if (!marketingCampaign || !warehouse) return;

    try {
      setIsLoading(true);
      const school = await SchoolsService.createSchool(
        marketingCampaign,
        iikoFrontCount || 0,
        mealDaily,
        name,
        phoneNoFormat,
        warehouse,
        nameEmployee,
        surnameEmployee,
        middleNameEmployee,
      );
      dispatch(pushSchool(school));
      handleCloseModal(true);
      notify('Новая школа успешно добавлена', 'success', <SchoolOutlineIcon />);
      navigate(Paths.schoolClasses(school.id));
    } catch (error) {
      if (error instanceof ServerValidationError) {
        if (error.code === wellKnownErrors.employeeAlreadyAssigned) {
          notify(error.message, 'error');
          setErrors({ phone: ' ' });
        } else if (error.fields?.employee) {
          notify('Проверьте правильность данных сотрудника', 'error');
        } else {
          notify(error.message, 'error', <WifiIcon />);
        }
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = (silent?: boolean) => {
    dispatch(setAddSchoolModalOpened(false));

    if (!silent && schools.length === 0) {
      notify('Пожалуйста, сначала\nдобавьте школу', 'error', <SchoolOutlineIcon />);
    }
  };

  //
  // Render
  //

  return (
    <AppModal className={styles.modal} isOpen={addSchoolModalOpened} onClose={() => handleCloseModal()}>
      <section className={styles.host}>
        <h2>Добавление {schools.length === 0 ? 'первой' : ''} школы</h2>

        <p>
          Для добавления школы, пожалуйста,
          <br />
          заполните поля:
        </p>

        <SchoolForm handleSubmit={handleSubmitForm} loading={isLoading} school={null} />
      </section>
    </AppModal>
  );
}
