enum Genders {
  male = 'male',
  female = 'female',
  notDefined = 'not_defined',
}

export const config = {
  [Genders.male]: {
    name: 'Мужской',
    shortName: 'М',
    value: Genders.male,
  },
  [Genders.female]: {
    name: 'Женский',
    shortName: 'Ж',
    value: Genders.female,
  },
  [Genders.notDefined]: {
    name: 'Не указан',
    shortName: '-',
    value: Genders.notDefined,
  },
};

export const getGenderName = (genderValue: Genders): string => {
  return config[genderValue].name;
};

export const getGenderShortName = (genderValue: Genders): string => {
  return config[genderValue].shortName;
};

export default Genders;
