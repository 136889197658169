import SchoolClass from './SchoolClass';

export default class ParentListItemStudent {
  public id: string;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public schoolClass: SchoolClass | null;

  public constructor(data: Partial<ParentListItemStudent>) {
    this.id = data.id!;
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.middleName = data.middleName ?? '';
    this.schoolClass = data.schoolClass ?? null;
  }

  public get FIO(): string {
    return [this.lastName, this.firstName, this.middleName].filter(Boolean).join(' ');
  }

  public get FIOShort(): string {
    const firstNameShort = this.firstName[0] ? this.firstName[0] + '.' : undefined;
    const middleNameShort = this.middleName[0] ? this.middleName[0] + '.' : undefined;

    return [this.lastName, firstNameShort, middleNameShort].filter(Boolean).join(' ');
  }
}
