import { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge } from 'reactstrap';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { subWeeks } from 'date-fns';

import styles from './TransactionsTable.module.scss';

import { AppDropdownDateRangePicker, AppInputField, AppLoader, AppSearch, AppTable } from '@/components/app';
import { OperationTypes, OperationTypesConfig, PaymentTypesConfig, StatusTypesConfig } from '@/constants/transactions';
import { Transaction } from '@/models/entity';
import AppDropdownCheckboxSelect from '@/components/app/AppDropdownCheckboxSelect';
import { SchoolsService } from '@/services';
import { formatDateToDateAndTime } from '@/utils/date';
import { formatCurrency } from '@/utils/formatters';
import { useChangeTableUrlParams, useTableUrlParams } from '@/hooks';
import { Filter } from '@/hooks/useChangeTableUrlParams';
import { RootState } from '@/store';

const OPERATION_TYPE_FILTER = 'operationType';
const DATE_RANGE_FILTER = 'dateRange';

export default function TransactionsTable() {
  const {
    search: urlSearch,
    currentPage: urlCurrentPage,
    itemsPerPage: urlItemsPerPage,
    filters,
  } = useTableUrlParams();
  const dateRangeDefaultValue =
    filters[DATE_RANGE_FILTER] && Array.isArray(filters[DATE_RANGE_FILTER])
      ? (filters[DATE_RANGE_FILTER].map((d: string) => new Date(d)) as [Date, Date])
      : ([subWeeks(new Date(), 2), new Date()] as [Date, Date]);

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // State
  //

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [schoolTransactions, setSchoolTransactions] = useState<Transaction[]>([]);
  const [operationTypeFilter, setOperationTypeFilter] = useState<OperationTypes | undefined>(
    filters[OPERATION_TYPE_FILTER],
  );
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | null>(dateRangeDefaultValue);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(urlCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(urlItemsPerPage);
  const [searchFilter, setSearchFilter] = useState<string>(urlSearch || '');
  const [schoolsFilter, setSchoolsFilter] = useState<string[]>([]);

  //
  // Computed
  //

  const operationTypes = useMemo(() => Object.values(OperationTypesConfig), []);
  const queryFilters = useMemo(
    () =>
      [
        operationTypeFilter
          ? {
            filterName: OPERATION_TYPE_FILTER,
            value: operationTypeFilter,
          }
          : undefined,
        dateRangeFilter
          ? {
            filterName: DATE_RANGE_FILTER,
            value: dateRangeFilter,
          }
          : undefined,
      ].filter(Boolean) as Filter[],
    [dateRangeFilter, operationTypeFilter],
  );

  //
  // Methods
  //

  const loadSchoolTransactions = useCallback(async () => {
    const { data: transactionsData, count } = await SchoolsService.getTransactions({
      search: searchFilter,
      currentPage,
      itemsPerPage,
      operationType: queryFilters.find((f) => f.filterName === OPERATION_TYPE_FILTER)?.value,
      dateRange: queryFilters.find((f) => f.filterName === DATE_RANGE_FILTER)?.value,
      schoolsIds: schoolsFilter,
    });
    setSchoolTransactions(transactionsData);
    setTotalPages(Math.ceil(count / itemsPerPage));
  }, [currentPage, itemsPerPage, queryFilters, schoolsFilter, searchFilter]);

  const loadAllData = useCallback(() => {
    setTableLoading(true);
    Promise.all([loadSchoolTransactions()]).finally(() => {
      setTableLoading(false);
    });
  }, [loadSchoolTransactions]);

  //
  // Effects
  //

  useEffect(() => {
    loadAllData();
  }, [loadAllData]);

  useChangeTableUrlParams({
    currentPage,
    perPage: itemsPerPage,
    search: searchFilter,
    filters: queryFilters,
  });

  //
  // Render
  //

  const renderClassFilter = (
    <section className={styles.host}>
      <section className={styles.topActions}>
        <section className={styles.filters}>
          <AppInputField
            className={cn(styles.filter, styles.schoolsFilter)}
            controlType="multi-select"
            onChange={(value) => setSchoolsFilter(value)}
            options={schools.map((s) => ({ label: s.name, value: s.id }))}
            placeholder="Выберите школу"
            value={schoolsFilter}
          />
          <AppDropdownCheckboxSelect
            className={styles.filter}
            onChange={(option) =>
              !option
                ? setOperationTypeFilter(undefined)
                : setOperationTypeFilter(operationTypes.find((sc) => sc.value === option.value)?.value)}
            options={operationTypes.map((otc) => ({ label: otc.name, value: otc.value }))}
            placeholder="Выберите тип операции"
            value={
              operationTypeFilter
                ? {
                  label: operationTypes.find((o) => o.value === operationTypeFilter)?.name ?? '',
                  value: operationTypeFilter,
                }
                : null
            }
          />
          <AppDropdownDateRangePicker
            className={styles.filter}
            onChange={(value) => setDateRangeFilter(value)}
            placeholder="ДД.ММ.ГГГГ ~ ДД.ММ.ГГГГ"
            value={dateRangeFilter}
          />
        </section>

        <AppSearch
          onChange={(value) => {
            setSearchFilter(value);
          }}
          onClickRefreshButton={loadAllData}
          value={searchFilter}
        />
      </section>

      <AppTable
        className={styles.table}
        columns={[
          {
            value: (transaction) => <div className={cn('text-left', styles.schoolCell)}>{transaction.schoolName}</div>,
            header: {
              title: 'Название школы',
              style: {
                width: 299,
              },
              className: 'text-left',
            },
          },
          {
            value: (transaction) => {
              if (!transaction.createdAt) return '—';

              const { date, time } = formatDateToDateAndTime(transaction.createdAt);

              return (
                <>
                  {date}
                  <br />
                  {time}
                </>
              );
            },
            className: 'text-left',
            header: {
              title: 'Дата и время',
              style: {
                width: 150,
              },
              className: 'text-left',
            },
          },
          {
            value: (transaction) => transaction.className,
            className: 'text-left',
            header: {
              title: 'Класс',
              className: 'text-left',
              style: {
                width: 80,
              },
            },
          },
          {
            value: (transaction) => transaction.studentName,
            className: 'text-left',
            header: {
              title: 'ФИО ученика',
              style: {
                width: 320,
              },
              className: 'text-left',
            },
          },
          {
            value: (transaction) => transaction.parentName,
            className: 'text-left',
            header: {
              title: 'ФИО родителя',
              style: {
                width: 320,
              },
              className: 'text-left',
            },
          },
          {
            value: (transaction) => formatCurrency(transaction.amount),
            className: (item: Transaction) => cn('text-left', { [styles.amountNegative]: item.amount < 0 }),
            header: {
              title: 'Сумма',
              style: {
                width: 100,
              },
              className: 'text-left',
            },
          },
          {
            value: (transaction) => formatCurrency(transaction.balance),
            className: (item: Transaction) => cn('text-left', { [styles.amountNegative]: item.balance < 0 }),
            header: {
              title: 'Баланс',
              style: {
                width: 100,
              },
              className: 'text-left',
            },
          },
          {
            value: (transaction) => {
              if (!transaction.paymentType) return '—';

              const configValue = PaymentTypesConfig[transaction.paymentType];

              return (
                <Badge
                  style={{
                    backgroundColor: configValue.bgColor,
                    color: configValue.color,
                  }}
                >
                  {configValue.name}
                </Badge>
              );
            },
            className: 'text-left',
            header: {
              title: 'Тип',
              style: {
                width: 130,
              },
              className: 'text-left',
            },
          },
          {
            value: (transaction) => {
              if (!transaction.status) return '—';

              const configValue = StatusTypesConfig[transaction.status];

              return (
                <Badge
                  style={{
                    backgroundColor: configValue.bgColor,
                    color: configValue.color,
                  }}
                >
                  {configValue.name}
                </Badge>
              );
            },
            className: 'text-left',
            header: {
              title: 'Статус',
              className: 'text-left',
              style: {
                width: 100,
              },
            },
          },
        ]}
        data={schoolTransactions}
        loading={false}
        pagination={{
          totalPages,
          currentPage,
          itemsPerPage,
          onChangePage: (newPage: number) => setCurrentPage(newPage),
          onChangeLimit: (newLimit: number) => setItemsPerPage(newLimit),
        }}
      />
    </section>
  );

  return (
    <section className={styles.host}>
      <AppLoader active={tableLoading} />
      {renderClassFilter}
    </section>
  );
}
