export const getFileFromUrl = async (url: string, type: string, name?: string) => {
  return await fetch(url, {
    redirect: 'follow',
  })
    .then((res) => res.blob())
    .then(
      (blob: Blob) =>
        new File([blob], name || url.substring(url.lastIndexOf('/') + 1), {
          type: blob.type || type,
        }),
    );
};

export const getBlobFromUrl = async (url: string) => {
  return await fetch(url)
    .then((res) => res.blob())
    .then((blob: Blob) => blob);
};

export const getFileNameByContentDisposition = (contentDisposition: string) => {
  const regex = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/;
  const matches = regex.exec(contentDisposition);
  let filename = '';

  if (matches != null && matches[3]) {
    filename = matches[3].replace(/['"]/g, '');
  }

  return decodeURI(filename);
};
