import { AxiosResponse } from 'axios';

export type ValidationErrorFieldOption = {
  [key: string]: string;
};

export type ValidationErrorField = {
  message: string;
  key: string;
};

/**
 * HTTP 400 - Returns information about incorrect fields.
 * Map: field => array of related error messages.
 */
export class ServerValidationError extends Error {
  public message: string;
  public code: string;
  public fields: Record<string, string[]>;

  constructor({ message, code, fields }: { message: string; code: string; fields: Record<string, string[]> }) {
    super(message);
    this.name = 'ServerValidationError';
    this.code = code;
    this.message = message;
    this.fields = fields;
  }
}

/**
 * HTTP 503 - Typically, when application is updated, restarted, etc.
 */
export class ServerUnavailable extends Error {
  constructor() {
    super('Server is temporary unavailable, try in a few minutes');
    this.name = 'ServerUnavailable';
  }
}

/**
 * HTTP 500 - Most probably, unhandled error in server code.
 */
export class InternalServerError extends Error {
  constructor(response: AxiosResponse) {
    const message = response.data?.message ? response.data.message : response.statusText;
    super(message);
    this.name = 'InternalServerError';
  }
}

/**
 * Server code was executing too long, and Nginx returned error without waiting for result.
 */
export class GatewayTimeout extends Error {
  constructor() {
    super('Server operation timed out');
    this.name = 'GatewayTimeout';
  }
}

export class NotFoundError extends Error {
  constructor() {
    super('Resource not found');
    this.name = 'NotFoundError';
  }
}

/**
 * For all other cases, when server replies with error.
 */
export class ServerError extends InternalServerError {
  public data: any;

  constructor(response: AxiosResponse) {
    super(response);
    this.name = 'ServerError';
    this.data = response.data;
  }
}

/**
 * HTTP Server reply was not received.
 * Probably no network connection or CORS error, AdBlock, etc.
 */
export class NetworkError extends Error {
  constructor(message: string) {
    super(message || 'Check your network connection');
    this.name = 'NetworkError';
  }
}
