import { getCssCustomProperty } from '@/utils/cssCustomProperties';

export default function ReportsIcon() {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <rect fill={getCssCustomProperty('--primary-color')} height="24" rx="4" width="24" />
      <path
        d="M7.49998 17.5998H16.1C16.4 17.5998 16.5 17.2998 16.5 17.1998C16.5 16.8998 16.2 16.7998 16.1 16.7998H7.49998C7.19998 16.7998 7.09998 17.0998 7.09998 17.1998C7.09998 17.4998 7.39998 17.5998 7.49998 17.5998Z"
        fill="white"
      />
      <path
        d="M17.2 4.3C17 4.1 16.7 4 16.3 4H7.2C6.8 4 6.5 4.3 6.3 4.4C6.1 4.6 6 5 6 5.3V18.9C6 19.2 6.1 19.6 6.3 19.8C6.5 20 6.8 20.1 7.2 20.1H16.3C16.6 20.1 17 20 17.2 19.8C17.4 19.6 17.5 19.3 17.5 18.9V5.2C17.5 4.9 17.4 4.5 17.2 4.3ZM6.7 5.3C6.7 5.2 6.7 5.1 6.8 5C6.8 5 6.8 4.9 6.9 4.9C7 4.8 7.1 4.8 7.2 4.8H16.3C16.5 4.8 16.5 4.8 16.6 4.9C16.7 5 16.8 5.1 16.8 5.2V18.9C16.8 19 16.7 19.1 16.6 19.2C16.5 19.3 16.4 19.4 16.3 19.4H7.2C7.1 19.4 7 19.3 6.9 19.2C6.8 19.1 6.7 19 6.7 18.9V5.3Z"
        fill="white"
      />
      <path
        d="M7.49998 14.9001H16.1C16.4 14.9001 16.5 14.6001 16.5 14.5001C16.5 14.2001 16.2 14.1001 16.1 14.1001H7.49998C7.19998 14.1001 7.09998 14.4001 7.09998 14.5001C7.09998 14.8001 7.39998 14.9001 7.49998 14.9001Z"
        fill="white"
      />
      <path
        d="M9.60002 12.8999C10.2 13.3999 10.9 13.5999 11.7 13.5999C11.9 13.5999 12.2 13.5999 12.4 13.4999C13.3 13.2999 14.2 12.7999 14.7 11.9999C14.9 11.7999 15 11.6999 15.1 11.3999C15.4 10.6999 15.5 9.8999 15.4 9.0999C15.2 8.1999 14.7 7.2999 13.9 6.6999C13.2 6.0999 12.1 5.8999 11.2 6.0999C10.3 6.2999 9.70002 6.6999 9.30002 7.0999C9.20002 7.1999 9.10002 7.2999 9.00002 7.2999C8.90002 7.3999 8.80002 7.4999 8.70002 7.5999C8.10002 8.5999 7.90002 9.4999 8.10002 10.3999C8.30002 11.3999 8.80002 12.2999 9.60002 12.8999ZM10 7.3999C10.4 7.0999 10.8 6.9999 11.3 6.8999H11.4V8.8999L10 7.3999ZM12.1 9.4999V6.7999C12.6 6.8999 13.1 6.9999 13.4 7.3999C14.1 7.8999 14.6 8.5999 14.7 9.2999C14.8 9.6999 14.8 10.2999 14.6 10.6999L12.1 9.4999ZM9.30002 8.0999C9.40002 7.9999 9.40002 7.9999 9.50002 7.8999L11.6 10.0999L14.3 11.4999C14.3 11.4999 14.3 11.4999 14.2 11.5999C13.7 12.2999 13 12.7999 12.3 12.8999C11.4 12.9999 10.7 12.8999 10.1 12.3999C9.30002 11.7999 8.90002 11.0999 8.80002 10.3999C8.70002 9.4999 8.90002 8.6999 9.30002 8.0999Z"
        fill="white"
      />
      <path
        d="M7.49998 16.3H16.1C16.4 16.3 16.5 16 16.5 15.9C16.5 15.6 16.2 15.5 16.1 15.5H7.49998C7.19998 15.5 7.09998 15.8 7.09998 15.9C7.09998 16.2 7.39998 16.3 7.49998 16.3Z"
        fill="white"
      />
    </svg>
  );
}
