import { getCssCustomProperty } from '@/utils/cssCustomProperties';

export default function TransactionsIcon() {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <rect fill={getCssCustomProperty('--primary-color')} height="24" rx="4" width="24" />
      <path
        d="M16.1115 12.8314C15.8455 12.8315 15.5829 12.7703 15.3443 12.6525C14.9526 12.4625 14.6505 12.1272 14.502 11.718H10.3424C10.1894 11.718 10.0426 11.6572 9.93445 11.5489C9.82629 11.4408 9.7655 11.2941 9.7655 11.1411C9.7655 10.2231 10.1302 9.34267 10.7793 8.69354C11.4285 8.04442 12.3089 7.67972 13.2269 7.67972H14.3807C14.3933 7.25156 14.5642 6.84334 14.8604 6.53389C15.1566 6.22458 15.5569 6.03595 15.9841 6.00463C16.4112 5.97334 16.8347 6.10147 17.1729 6.36429L19.3304 8.0431C19.7529 8.37095 20 8.87561 20 9.41042C20 9.94509 19.7529 10.4497 19.3304 10.7776L17.1729 12.4564C16.8688 12.6928 16.4964 12.8243 16.1114 12.8314L16.1115 12.8314ZM11.0117 10.5642H14.9577C15.1107 10.5642 15.2575 10.625 15.3657 10.7332C15.4738 10.8414 15.5346 10.988 15.5346 11.1411C15.5332 11.2405 15.5601 11.3384 15.6123 11.4231C15.6643 11.508 15.7395 11.5761 15.8289 11.6199C15.9311 11.6678 16.0447 11.6861 16.1569 11.6728C16.269 11.6597 16.3751 11.6153 16.4635 11.545L18.6268 9.86616C18.7676 9.75683 18.85 9.58865 18.85 9.41044C18.85 9.23209 18.7676 9.06391 18.6268 8.95459L16.4635 7.27591C16.3491 7.18783 16.2061 7.14585 16.0623 7.15822C15.9186 7.17045 15.7847 7.23625 15.687 7.34236C15.5894 7.44847 15.535 7.58741 15.5346 7.73165V8.25666C15.5346 8.40964 15.4738 8.55631 15.3657 8.66448C15.2575 8.77278 15.1107 8.83356 14.9577 8.83356H13.227C12.7148 8.83266 12.2167 9.00225 11.8114 9.31555C11.4062 9.62885 11.1167 10.0682 10.9887 10.5643L11.0117 10.5642Z"
        fill="white"
      />
      <path
        d="M7.88491 18.6008C7.49987 18.5992 7.1263 18.4693 6.82345 18.2316L4.66587 16.5528C4.24556 16.2248 4 15.7214 4 15.1884C4 14.6553 4.24557 14.1521 4.66587 13.8241L6.82345 12.1453C7.08073 11.9385 7.39171 11.8097 7.71982 11.7739C8.04793 11.7381 8.37953 11.7969 8.67529 11.9433C9.06999 12.1322 9.37311 12.4707 9.5176 12.8836H13.6541H13.654C13.807 12.8836 13.9538 12.9444 14.062 13.0526C14.1701 13.1609 14.2309 13.3075 14.2309 13.4605C14.2309 14.3785 13.8662 15.259 13.2171 15.9081C12.568 16.5572 11.6875 16.9219 10.7695 16.9219H9.61571C9.60579 17.2365 9.51037 17.5423 9.33949 17.8066C9.16874 18.0708 8.92924 18.2836 8.64655 18.4219C8.41013 18.5401 8.14937 18.6012 7.88498 18.6007L7.88491 18.6008ZM7.88491 12.9181C7.74583 12.9192 7.6114 12.9681 7.50412 13.0566L5.37539 14.7354C5.23684 14.8448 5.15597 15.0116 5.15597 15.1883C5.15597 15.3648 5.23684 15.5317 5.37539 15.6412L7.53297 17.32V17.3198C7.6468 17.4075 7.78909 17.4495 7.93228 17.4378C8.07534 17.4259 8.20888 17.3613 8.30687 17.2562C8.40474 17.1513 8.45998 17.0135 8.46178 16.8699V16.3449C8.46178 16.1919 8.52256 16.0452 8.63073 15.9369C8.73903 15.8288 8.8857 15.768 9.03868 15.768H10.7694C11.2807 15.7676 11.7774 15.5975 12.1815 15.2842C12.5856 14.971 12.8742 14.5324 13.002 14.0373H9.03858C8.8856 14.0373 8.73893 13.9765 8.63063 13.8683C8.52247 13.7602 8.46168 13.6134 8.46168 13.4604C8.46606 13.3603 8.44031 13.2613 8.3879 13.1759C8.33549 13.0907 8.25874 13.0229 8.16744 12.9816C8.08889 12.9413 8.00197 12.9195 7.91363 12.9181L7.88491 12.9181Z"
        fill="white"
      />
    </svg>
  );
}
