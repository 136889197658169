import { Genders } from '@/constants';

export default class StudentListItemParent {
  public id: string;
  public firstName: string;
  public lastName: string;
  public phone: string | null;
  public middleName: string;
  public birthdate: Date | null;
  public gender: Genders;

  public constructor(data: Partial<StudentListItemParent>) {
    this.id = data.id!;
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.middleName = data.middleName ?? '';
    this.birthdate = data.birthdate ?? null;
    this.gender = data.gender ?? Genders.notDefined;
    this.phone = data.phone ?? null;
  }

  public get FIO(): string {
    return [this.lastName, this.firstName, this.middleName].filter(Boolean).join(' ');
  }

  public get FIOShort(): string {
    const firstNameShort = this.firstName[0] ? this.firstName[0] + '.' : undefined;
    const middleNameShort = this.middleName[0] ? this.middleName[0] + '.' : undefined;

    return [this.lastName, firstNameShort, middleNameShort].filter(Boolean).join(' ');
  }
}
