import * as SentryLib from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import pkg from '../../package.json';

const SENTRY_DSN = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SENTRY_DSN : '';

export const Sentry = SentryLib;
export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: pkg.version,
  });
};
