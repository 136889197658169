import ComplexDishPlan from './ComplexDishPlan';

export default class ComplexDishPlanInfo {
  public lastSynchronisation: Date | null;
  public synchronised: boolean;
  public dishes: ComplexDishPlan[];

  public constructor(data: Partial<ComplexDishPlanInfo>) {
    this.lastSynchronisation = data.lastSynchronisation ?? null;
    this.synchronised = data.synchronised ?? false;
    this.dishes = data.dishes ?? [];
  }
}
