import { Roles } from '@/constants';

export default class User {
  public id: number;
  public firstName: string;
  public lastName: string;
  public roles: Roles[];

  public constructor(data: Partial<User>) {
    this.id = data.id!;
    this.firstName = data.firstName ?? '';
    this.lastName = data.lastName ?? '';
    this.roles = data.roles ?? [];
  }

  public get isEmployee(): boolean {
    return this.roles.includes(Roles.employee);
  }

  public get isSeniorEmployee(): boolean {
    return this.roles.includes(Roles.senior_employee);
  }
}
