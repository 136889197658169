import { Formik, FormikErrors } from 'formik';
import { useSelector } from 'react-redux';

import styles from './SchoolForm.module.scss';

import { SchoolIikoSettings } from '@/models/entity';
import { deformatPhone } from '@/utils/formatters';
import { Yup } from '@/utils/yup';
import { AppButton, AppInputField } from '@/components/app';
import { RootState } from '@/store';

export type FormFields = {
  name: string;
  phone: string;
  marketingCampaign: string | null;
  mealDaily: '5' | '6' | undefined;
  iikoFrontCount: number | undefined;
  warehouse: string | null;
  nameEmployee: string;
  surnameEmployee: string;
  middleNameEmployee: string;
};

type Props = {
  school: SchoolIikoSettings | null;
  handleSubmit(values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void): void;
  loading: boolean;
};

export default function SchoolForm(props: Props) {
  const { school, handleSubmit, loading } = props;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(' '),
    surnameEmployee: Yup.string(),
    nameEmployee: Yup.string(),
    middleNameEmployee: Yup.string(),
    phone: Yup.string()
      .transform((value: string) => deformatPhone(value))
      .min(10),
    marketingCampaign: Yup.string().required(' '),
    mealDaily: Yup.number().required(' '),
    iikoFrontCount: Yup.number().required(' '),
    warehouse: Yup.string().required(' '),
  });

  //
  // Store
  //

  const { marketingCampaigns, writeoffStores } = useSelector((state: RootState) => state.iiko);

  //
  // Render
  //

  return (
    <Formik
      initialValues={{
        name: school?.name ?? '',
        phone: school?.phoneEmployee ?? '',
        marketingCampaign: school?.marketingCampaign?.id ?? null,
        mealDaily: school?.mealDaily ?? undefined,
        iikoFrontCount: school?.iikoFrontCount ?? undefined,
        warehouse: school?.reportWriteoffStore?.id ?? null,
        nameEmployee: school?.nameEmployee ?? '',
        surnameEmployee: school?.surnameEmployee ?? '',
        middleNameEmployee: school?.middleNameEmployee ?? '',
      }}
      onSubmit={(values, { setErrors }) => {
        handleSubmit(values, setErrors);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit: submit,
        submitCount,
        setFieldValue,
        isValid,
        dirty,
      }) => {
        const phoneRequired =
          values.nameEmployee.length > 0 || values.surnameEmployee.length > 0 || values.middleNameEmployee.length > 0;
        const isPhoneValidRequired = phoneRequired ? values.phone.length > 0 : true;

        return (
          <form className={styles.host} onSubmit={submit}>
            <AppInputField
              className={styles.field}
              controlType="input"
              disabled={loading}
              error={submitCount > 0 && errors.name}
              label="Название школы"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder=""
              required
              value={values.name}
            />

            <AppInputField
              className={styles.field}
              controlType="select"
              disabled={loading}
              error={submitCount > 0 && errors.mealDaily}
              label="Маркетинговая программа для учителя"
              name="marketingCampaign"
              onBlur={handleBlur}
              onChange={({ value }) => setFieldValue('marketingCampaign', value)}
              options={marketingCampaigns.map((mc) => ({ label: `${mc.name} (${mc.discountValue}%)`, value: mc.id }))}
              placeholder=""
              required
              value={values.marketingCampaign}
            />

            <AppInputField
              className={styles.field}
              controlType="select"
              disabled={loading}
              error={submitCount > 0 && errors.mealDaily}
              label="Питание"
              name="mealDaily"
              onBlur={handleBlur}
              onChange={({ value }) => setFieldValue('mealDaily', value)}
              options={[
                { label: '5-дневное', value: '5' },
                { label: '6-дневное', value: '6' },
              ]}
              placeholder=""
              required
              value={values.mealDaily}
            />

            <div className={styles.fieldSet}>
              <AppInputField
                className={styles.field}
                controlType="select"
                disabled={loading}
                error={submitCount > 0 && errors.iikoFrontCount}
                label="Количество iikoFront"
                name="iikoFrontCount"
                onBlur={handleBlur}
                onChange={({ value }) => setFieldValue('iikoFrontCount', value)}
                options={[
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                ]}
                placeholder=""
                required
                value={values.iikoFrontCount}
              />
              <AppInputField
                className={styles.field}
                controlType="select"
                disabled={loading}
                error={submitCount > 0 && errors.warehouse}
                label="Склад списания"
                maxNumericValue={100}
                name="warehouse"
                onBlur={handleBlur}
                onChange={({ value }) => setFieldValue('warehouse', value)}
                options={writeoffStores.map((w) => ({ label: w.name, value: w.id }))}
                placeholder=""
                required
                value={values.warehouse}
              />
            </div>

            <h2>Данные сотрудника школы</h2>

            <p>Заполните данные о сотруднике, которому Вы хотите дать доступ для работы с заявками в школе</p>

            <AppInputField
              className={styles.field}
              controlType="phone"
              disabled={loading}
              error={submitCount > 0 && errors.phone}
              label="Номер телефона"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder=""
              required={phoneRequired}
              value={values.phone}
            />

            <AppInputField
              className={styles.field}
              controlType="input"
              disabled={loading}
              error={submitCount > 0 && errors.surnameEmployee}
              label="Фамилия"
              name="surnameEmployee"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder=""
              value={values.surnameEmployee}
            />

            <div className={styles.fieldSet}>
              <AppInputField
                className={styles.field}
                controlType="input"
                disabled={loading}
                error={submitCount > 0 && errors.nameEmployee}
                label="Имя"
                name="nameEmployee"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder=""
                value={values.nameEmployee}
              />

              <AppInputField
                className={styles.field}
                controlType="input"
                disabled={loading}
                error={submitCount > 0 && errors.middleNameEmployee}
                label="Отчество"
                name="middleNameEmployee"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder=""
                value={values.middleNameEmployee}
              />
            </div>

            <section className={styles.actions}>
              <AppButton
                className={styles.actionBtn}
                disabled={loading || !dirty || !isValid || !isPhoneValidRequired}
                type="submit"
              >
                Сохранить
              </AppButton>
            </section>
          </form>
        );
      }}
    </Formik>
  );
}
