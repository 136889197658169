export default class MarketingCampaign {
  public id: string;
  public discountValue: number;
  public name: string;

  public constructor(data: Partial<MarketingCampaign>) {
    this.id = data.id!;
    this.discountValue = data.discountValue ?? 0;
    this.name = data.name ?? '';
  }
}
