import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { startOfDay, endOfDay, subDays } from 'date-fns';

import styles from './ReportFieldSet.module.scss';

import { AppButton, AppInputField } from '@/components/app';
import { RootState } from '@/store';
import { ClassesValue, FieldSetKeys } from '@/constants/reports';

type Props = {
  fieldSetKey: FieldSetKeys;
  handleConfigureReport(fieldSetKey: FieldSetKeys, dateRange: DateRange, schoolId: string, classes: ClassesValue): void;
  title: string;
};

const CLASSES_OPTIONS = [
  { label: '1-4', value: ClassesValue['1-4'] },
  { label: '5-11', value: ClassesValue['5-11'] },
];

export default function ReportFieldSet(props: Props) {
  const { fieldSetKey, handleConfigureReport, title } = props;

  //
  // State
  //

  const [dateRage, setDateRage] = useState<DateRange | null>(null);
  const [schoolId, setSchoolId] = useState<string | null>(null);
  const [classes, setClasses] = useState<ClassesValue | null>(null);

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Computed
  //

  const schoolsOptions = useMemo(() => schools.map((s) => ({ label: s.name, value: s.id })), [schools]);

  //
  // Render
  //

  return (
    <section className={styles.host}>
      <h2>{title}</h2>

      <div className={styles.reportFields}>
        <AppInputField
          className={cn(styles.dateRangeField, styles.field)}
          controlType="date-range"
          dateRangesPresets={
            [
              {
                label: 'today',
                value: [startOfDay(new Date()), endOfDay(new Date())],
              },
              {
                label: 'Вчера',
                value: [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))],
              },
              {
                label: 'last7Days',
                value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
              },
            ]
          }
          label="Календарный период"
          onChange={(value: DateRange) => setDateRage(value)}
          placeholder="ДД.ММ.ГГГГ — ДД.ММ.ГГГГ"
          value={dateRage}
        />

        <AppInputField
          className={cn(styles.schoolField, styles.field)}
          controlType="select"
          label="Школа"
          onChange={({ value }) => setSchoolId(value)}
          options={schoolsOptions}
          placeholder=""
          value={schoolId}
        />

        <AppInputField
          className={cn(styles.classField, styles.field)}
          controlType="select"
          label="Классы"
          onChange={({ value }) => setClasses(value)}
          options={CLASSES_OPTIONS}
          placeholder=""
          value={classes}
        />
      </div>

      <div className={styles.actions}>
        <AppButton
          disabled={!dateRage || !schoolId || !classes}
          onClick={() =>
            !dateRage || !schoolId || !classes ? null : handleConfigureReport(fieldSetKey, dateRage, schoolId, classes)}
        >
          Сформировать
        </AppButton>
      </div>
    </section>
  );
}
