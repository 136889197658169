import IFlavor from '../IFlavor';

export default class TwoDayFlavor implements IFlavor {
  // Assets
  logoBig = '/apps/2day/logoBig.svg';
  logoShort = '/apps/2day/logoShort.svg';
  patternBg = '/apps/2day/patternBg.svg';
  favicon = '/apps/2day/favicon.ico';

  // Common
  primaryColor = '#566E3A';

  // Buttons
  buttonPrimaryBgColor = '#566E3A';
  buttonPrimaryTextColor = '#fff';
  buttonPrimaryHoverBgColor = '#74944F';
  buttonPrimaryLightenBgColor = '#6D8A4B';
  buttonLinkTextColor = '#566E3A';

  // Table
  tableInvalidRowBgColor = '#F3FFF6';

  // Tile tabs
  tileTabsInactiveBgColor = '#F3FFF6';
  tileTabsHoverBgColor = '#74964E';
  tileTabsInactiveTextColor = '#000';

  // Sidebar
  sidebarActiveItemBgColor = '#EEF9F0';

  // Controls
  checkboxActiveBgImage = '/apps/2day/checkboxActive.svg';

  // Calendar
  calendarSelectedCellBgColor = '#566E3A';
  calendarSelectedCellTextColor = '#fff';
  calendarRangeColor = '#B8CC9A';
}
