import { useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './AuthPage.module.scss';

import { AppButton, AppInputField } from '@/components/app';
import { MessageCloudIcon, UserIcon, WifiIcon } from '@/components/icons';
import { Yup } from '@/utils/yup';
import { ServerValidationError } from '@/api/exceptions';
import { deformatPhone, formatPhone } from '@/utils/formatters';
import { notify } from '@/utils/notifications';
import { useCountdown, useDocumentTitle } from '@/hooks';
import { AuthService, SchoolsService, SettingsService } from '@/services';
import wellKnownErrors from '@/api/wellKnownErrors';
import { Paths } from '@/routes';
import { PublicSettings } from '@/models/entity';
import { useAppDispatch } from '@/store';
import { setSchools } from '@/store/schoolsSlice';
import { AppFlavor } from '@/flavors';

type FormFields = {
  phone: string;
  code: string;
};

export default function AuthPage() {
  const { logoBig } = AppFlavor;
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(' ')
      .transform((value: string) => deformatPhone(value))
      .min(10),
    code: Yup.string(),
  });
  const [countdown, { start: launchCountdown }] = useCountdown(60 * 1000);

  //
  // State
  //

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCodeStage, setIsCodeStage] = useState<boolean>(false);
  const [settings, setSettings] = useState<PublicSettings | null>(null);

  //
  // Store
  //

  const dispatch = useAppDispatch();

  //
  // Methods
  //

  const handleSendCode = async (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    const phone = deformatPhone(values.phone);

    try {
      setIsLoading(true);
      setErrors({});
      await AuthService.sendCode(phone);
      setIsCodeStage(true);
    } catch (error) {
      if (!(error instanceof ServerValidationError)) {
        notify('Проверьте подключение\nк интернету', 'error', <WifiIcon />);
        throw error;
      }
      if (error.code === wellKnownErrors.userDoesNotExists)
        notify('Данный номер не зарегистрирован, обратитесь в тех.поддержку: +7 (902) 222-2222', 'error', <UserIcon />);
      if (error.code === wellKnownErrors.exceededRequestCall)
        notify('Превышено количество запрошенных звонков-сбросов, попробуйте позже', 'error', <UserIcon />);
      if (error.code === wellKnownErrors.smscException)
        notify(
          'Не удалось запросить код. Попробуйте позже или обратитесь в тех.поддержку: +7 (902) 222-2222',
          'error',
          <WifiIcon />,
        );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuthComplete = async (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    const phone = deformatPhone(values.phone);

    try {
      setIsLoading(true);
      setErrors({});
      await AuthService.authComplete(phone, values.code);
      const schools = await SchoolsService.getAllSchools();
      dispatch(setSchools(schools));
      navigate(Paths.home);
    } catch (error) {
      if (!(error instanceof ServerValidationError)) {
        notify('Проверьте подключение\nк интернету', 'error', <WifiIcon />);
        throw error;
      }
      if (error.code === wellKnownErrors.codeDoesNotExist)
        notify('Неверный СМС-код', 'error', <MessageCloudIcon />);
      if (error.code === wellKnownErrors.exceededInputCode)
        notify('Превышено количество неверно введенных кодов.\nПопробуйте позже', 'error', <UserIcon />);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitRequest = (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    if (isCodeStage) {
      handleAuthComplete(values, setErrors);
    } else {
      handleSendCode(values, setErrors);
    }
  };

  const handleSubmitForm = (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    const { phone } = values;
    const phoneNoFormat = deformatPhone(phone);

    if (!phoneNoFormat.startsWith('9')) {
      setErrors({ phone: ' ' });
      notify('Проверьте корректность введенного номера телефона', 'error', <UserIcon />);
      return;
    }

    handleSubmitRequest(values, setErrors);
  };

  const loadSettings = async () => {
    try {
      const settingsData = await SettingsService.getPublicSettings();
      setSettings(settingsData);
    } catch (error) {
      if (error instanceof ServerValidationError) notify(error.message, 'error', <WifiIcon />);
      throw error;
    }
  };

  //
  // Effects
  //

  useDocumentTitle('Школьное питание');

  useEffect(() => {
    if (isCodeStage) launchCountdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCodeStage]);

  useEffect(() => {
    loadSettings();
  }, []);

  //
  // Render
  //

  const renderFooterMessage = (values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void) => {
    if (isCodeStage && countdown > 0) {
      return (
        <p className={styles.footerMessage}>
          СМС код можно запросить повторно
          <br />
          через <span>{countdown / 1000}</span> секунд
        </p>
      );
    } else if (isCodeStage && countdown <= 0) {
      return (
        <AppButton
          className={styles.sendCodeAgainBtn}
          color="link"
          onClick={() => {
            launchCountdown();
            handleSendCode(values, setErrors);
          }}
        >
          Отправить СМС-код повторно
        </AppButton>
      );
    } else if (settings && settings.email && settings.phone) {
      return (
        <p className={styles.footerMessage}>
          По всем вопросам пишите на почту:{' '}
          <a href={`mailto:${settings.email}`} rel="noreferrer" target="_blank">
            {settings.email}
          </a>{' '}
          <br />
          или по телефону:{' '}
          <a href={`tel:+7${settings.phone}`} rel="noreferrer" target="_blank">
            {formatPhone(settings.phone)}
          </a>
        </p>
      );
    }
  };

  return (
    <section className={cn(styles.host, 'animate__animated animate__fadeIn')}>
      <img alt="Логотип" src={logoBig} />

      <h2>Авторизация</h2>

      {isCodeStage ? (
        <p className={styles.authMessage}>
            Мы отправили вам СМС-код на номер телефона:
        </p>
      ) : (
        <p className={styles.authMessage}>
          Для подтверждения мы отправим
          <br />
          СМС-код  на ваш номер
        </p>
      )}

      <Formik
        initialValues={{ phone: '', code: '' }}
        onSubmit={(values, { setErrors }) => {
          handleSubmitForm(values, setErrors);
        }}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, submitCount, setErrors, setFieldValue }) => {
          const submitDisabled = isCodeStage ? values.code.length !== 4 : deformatPhone(values.phone).length !== 10;

          return (
            <>
              <form className={styles.form} onSubmit={handleSubmit}>
                <AppInputField
                  className={styles.field}
                  controlType="phone"
                  disabled={isCodeStage}
                  error={submitCount > 0 && errors.phone}
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder=""
                  value={values.phone}
                />

                {isCodeStage && (
                  <section className="animate__animated animate__fadeIn">
                    <AppButton className={styles.changeNumberBtn} color="link" onClick={() => setIsCodeStage(false)}>
                      Изменить номер
                    </AppButton>
                    <AppInputField
                      className={cn(styles.field, styles.codeField)}
                      controlType="code"
                      error={submitCount > 0 && errors.code}
                      name="code"
                      onBlur={handleBlur}
                      onChange={(value) => setFieldValue('code', value)}
                      placeholder=""
                      value={values.code}
                    />
                  </section>
                )}

                <AppButton className={styles.submitBtn} disabled={isLoading || submitDisabled} type="submit">
                  {isCodeStage ? 'Подтвердить' : 'Продолжить'}
                </AppButton>
              </form>
              {renderFooterMessage(values, setErrors)}
            </>
          );
        }}
      </Formik>
    </section>
  );
}
