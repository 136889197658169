import hash from 'hash-sum';

import { NavItem, HashedNavItem } from '../';

type MapOfPaths = {
  [urlPath: string]: string[];
};

export const omit = (item: NavItem, blacklist: string[]) => {
  return Object.fromEntries(Object.entries(item).filter(([key]) => !blacklist.includes(key)));
};

export const hashItem = (item: NavItem) => {
  // Hash should not be changed if submenu items changes, so ignore `items`
  // Also ignore `hash` field, but don't ignore `parentHashes`
  return hash(omit(item, ['items', 'hash', 'icon']));
};

export const hashItems = (items: NavItem[], parentHashes: string[] = []): HashedNavItem[] => {
  return items.map((item) => {
    const { items: childrenItems, ...otherItemFields } = item;

    const hashedItem: HashedNavItem = { hash: '', parentHashes: [], ...otherItemFields };

    hashedItem.parentHashes = [...parentHashes];
    hashedItem.hash = hashItem(item);

    if (Array.isArray(childrenItems)) {
      hashedItem.items = hashItems(childrenItems, [...hashedItem.parentHashes, hashedItem.hash]);
    }

    return hashedItem;
  });
};

export const mapPathsToHashes = (items: HashedNavItem[]) => {
  const mapOfPaths: MapOfPaths = {};

  for (const item of items) {
    if (Array.isArray(item.items)) {
      Object.assign(mapOfPaths, mapPathsToHashes(item.items));
    }

    const { to } = item;

    if (!to) continue;

    mapOfPaths[to] = [...item.parentHashes, item.hash];
  }

  return mapOfPaths;
};

export const findActiveItems = (pathsToHashes: MapOfPaths, pathname: string, highlightNested: boolean) => {
  const sortedKeys = Object.keys(pathsToHashes).sort((a, b) => b.length - a.length);

  let bestMatchRank = Number.MAX_VALUE;
  let bestMatchHashes: string[] = [];

  for (const key of sortedKeys) {
    // If keys is longer than selected path - ignore it
    if (key.length > pathname.length) continue;

    if (!highlightNested) {
      // Search for exact match if we don't highlight parents on nested routes
      if (key === pathname) {
        bestMatchHashes = pathsToHashes[key];
        break;
      }
    } else {
      // If path does not start with the key - we are not interested
      if (!pathname.startsWith(key)) continue;

      // The we need rank to be as log as possible (the lower is rank - the longer is key)
      const rank = pathname.length - key.length;

      if (rank < bestMatchRank) {
        bestMatchRank = rank;
        bestMatchHashes = pathsToHashes[key];
      }
    }
  }

  return bestMatchHashes;
};

export const isActiveItem = (activeItems: string[], targetHash: string) => {
  return activeItems.includes(targetHash);
};

export const arrayLast = (array: string[]) => {
  return array[array.length - 1];
};
