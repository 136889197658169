import { getCssCustomProperty } from '@/utils/cssCustomProperties';

export default function ClassesIcon() {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <rect fill={getCssCustomProperty('--primary-color')} height="24" rx="4" width="24" />
      <path
        d="M6.66669 11.9333H17.3334C17.4806 11.9333 17.6 11.8139 17.6 11.6667V5.26667C17.6 5.11941 17.4806 5 17.3334 5H6.66669C6.51943 5 6.40002 5.11941 6.40002 5.26667V11.6667C6.40002 11.8139 6.51943 11.9333 6.66669 11.9333ZM6.93336 11.4V10.8667H17.0667V11.4H6.93336ZM10.1334 10.3333H8.80002V9.8H10.1334V10.3333ZM17.0667 5.53333V10.3333H10.6667V9.53333C10.6667 9.38607 10.5473 9.26667 10.4 9.26667H8.53336C8.3861 9.26667 8.26669 9.38607 8.26669 9.53333V10.3333H6.93336V5.53333H17.0667Z"
        fill="white"
      />
      <path
        d="M11.2 13H9.33333V12.7333C9.33333 12.5861 9.21393 12.4667 9.06667 12.4667H6.4C6.25274 12.4667 6.13333 12.5861 6.13333 12.7333V13H4.26667C4.11941 13 4 13.1194 4 13.2667V14.3333C4 14.4806 4.11941 14.6 4.26667 14.6H4.53333V19.4H5.06667V14.6H6.66667V15.4H6.13333C5.98607 15.4 5.86667 15.5194 5.86667 15.6667V16.7333C5.86667 16.8806 5.98607 17 6.13333 17H6.4V19.4H6.93333V17H8.53333V19.4H9.06667V17H9.33333C9.48059 17 9.6 16.8806 9.6 16.7333V15.6667C9.6 15.5194 9.48059 15.4 9.33333 15.4H8.8V14.6H10.4V19.4H10.9333V14.6H11.2C11.3473 14.6 11.4667 14.4806 11.4667 14.3333V13.2667C11.4667 13.1194 11.3473 13 11.2 13ZM4.53333 13.5333H6.13333V14.0667H4.53333V13.5333ZM9.06667 16.4667H6.4V15.9333H9.06667V16.4667ZM8.26667 15.4H7.2V14.6H8.26667V15.4ZM8.8 14.0667H6.66667V13H8.8V14.0667ZM10.9333 14.0667H9.33333V13.5333H10.9333V14.0667Z"
        fill="white"
      />
      <path
        d="M19.7333 13H17.8667V12.7333C17.8667 12.5861 17.7473 12.4667 17.6 12.4667H14.9333C14.7861 12.4667 14.6667 12.5861 14.6667 12.7333V13H12.8C12.6527 13 12.5333 13.1194 12.5333 13.2667V14.3333C12.5333 14.4806 12.6527 14.6 12.8 14.6H13.0667V19.4H13.6V14.6H15.2V15.4H14.6667C14.5194 15.4 14.4 15.5194 14.4 15.6667V16.7333C14.4 16.8806 14.5194 17 14.6667 17H14.9333V19.4H15.4667V17H17.0667V19.4H17.6V17H17.8667C18.0139 17 18.1333 16.8806 18.1333 16.7333V15.6667C18.1333 15.5194 18.0139 15.4 17.8667 15.4H17.3333V14.6H18.9333V19.4H19.4667V14.6H19.7333C19.8806 14.6 20 14.4806 20 14.3333V13.2667C20 13.1194 19.8806 13 19.7333 13ZM13.0667 13.5333H14.6667V14.0667H13.0667V13.5333ZM17.6 16.4667H14.9333V15.9333H17.6V16.4667ZM16.8 15.4H15.7333V14.6H16.8V15.4ZM17.3333 14.0667H15.2V13H17.3333V14.0667ZM19.4667 14.0667H17.8667V13.5333H19.4667V14.0667Z"
        fill="white"
      />
    </svg>
  );
}
