/* eslint-disable camelcase */
import { httpClient } from '@/api/httpClient';
import { FileDocumentKind } from '@/constants';
import {
  AccountTypeDto,
  ComplexDishDto,
  ComplexDishPlanDto,
  ComplexDishPlanInfoDto,
  FAQDto,
  FileDocumentDto,
  IIkoPropsDto,
  MealConceptionDto,
  MenuDto,
  PlantSettingsDto,
  PromotionDto,
  PublicSettingsDto,
  SchoolIikoSettingsDto,
} from '@/models/dto';
import { ComplexDish, ComplexDishPlanInfo, Warehouse } from '@/models/entity';

const SettingsApi = {
  async getPublicSettings(): Promise<PublicSettingsDto> {
    const { data } = await httpClient.get('/api/v01/settings/public/');

    return data;
  },

  async getFaqDocuments(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ data: FAQDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();
    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());

    const { data } = await httpClient.get(`/api/v01/documents/faqs?${urlSearchParams.toString()}`);

    return { data: data.results, count: data.count };
  },

  async createFaqDocument(question: string, answer: string): Promise<FAQDto> {
    const { data } = await httpClient.post('/api/v01/documents/faqs/', {
      name: question,
      content: answer,
    });

    return data;
  },

  async editFaqDocument(faqId: string, question: string, answer: string): Promise<FAQDto> {
    const { data } = await httpClient.put(`/api/v01/documents/faqs/${faqId}/`, {
      name: question,
      content: answer,
    });

    return data;
  },

  async deleteFaqDocument(faqId: string): Promise<void> {
    await httpClient.delete(`/api/v01/documents/faqs/${faqId}/`);
  },

  async getFileDocuments(): Promise<FileDocumentDto[]> {
    const { data } = await httpClient.get('/api/v01/documents/files/');

    return data;
  },

  async uploadFileDocument(kind: FileDocumentKind, file: File): Promise<void> {
    let formData = new FormData();
    formData.append('kind', kind);
    formData.append('file', file);

    const { data } = await httpClient.post('/api/v01/documents/files/', formData);

    return data;
  },

  async updateFileDocument(id: string, kind: FileDocumentKind, file: File): Promise<void> {
    let formData = new FormData();
    formData.append('kind', kind);
    formData.append('file', file);

    const { data } = await httpClient.put(`/api/v01/documents/files/${id}/`, formData);

    return data;
  },

  async deleteFileDocument(id: string): Promise<void> {
    const { data } = await httpClient.delete(`/api/v01/documents/files/${id}/`);

    return data;
  },

  async getMenus(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ data: MenuDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();
    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());

    const { data } = await httpClient.get(`/api/v01/school/menus/?${urlSearchParams.toString()}`);

    return { data: data.results, count: data.count };
  },

  async createMenu(name: string, schools: string[], file: File): Promise<MenuDto> {
    let formData = new FormData();
    formData.append('title', name);
    formData.append('file', file);

    schools.forEach((sId) => {
      formData.append('schools', sId);
    });

    const { data } = await httpClient.post('/api/v01/school/menus/', formData);

    return data;
  },

  async updateMenu(id: string, name: string, schools: string[], file: File): Promise<MenuDto> {
    let formData = new FormData();
    formData.append('title', name);
    formData.append('file', file);

    schools.forEach((sId) => {
      formData.append('schools', sId);
    });

    const { data } = await httpClient.put(`/api/v01/school/menu/${id}/`, formData);

    return data;
  },

  async deleteMenu(id: string): Promise<void> {
    await httpClient.delete(`/api/v01/school/menu/${id}/`);
  },

  async updateIikoCredentials(
    iikoDeliveryLogin: string,
    iikoDeliveryPassword: string,
    iikoServerLogin: string,
    iikoServerPassword: string,
    iikoServerUrl: string,
    iikoTransportKey: string,
  ): Promise<void> {
    await httpClient.put('/api/v01/settings/iiko-card/credentials/', {
      iiko_card_login: iikoDeliveryLogin,
      iiko_card_password: iikoDeliveryPassword,
      iiko_transport_key: iikoTransportKey,
      iiko_server_host: iikoServerUrl,
      iiko_server_login: iikoServerLogin,
      iiko_server_password: iikoServerPassword,
    });
  },

  async getSchoolsIikoSettings(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ data: SchoolIikoSettingsDto[] }> {
    const urlSearchParams = new URLSearchParams();
    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());

    const { data } = await httpClient.get(`/api/v01/schools/?${urlSearchParams.toString()}`);

    return { data };
  },

  async getIikoProps(): Promise<IIkoPropsDto> {
    const { data } = await httpClient.get('/api/v01/settings/private/');

    return data;
  },

  async deleteSchool(id: string): Promise<void> {
    await httpClient.delete(`/api/v01/schools/${id}`);
  },

  async editSchool(
    id: string,
    marketingCampaign: string,
    iikoFrontCount: number,
    mealDaily: '5' | '6' | undefined,
    name: string,
    phone: string,
    warehouse: string,
    nameEmployee: string,
    surnameEmployee: string,
    lastNameEmployee: string,
  ): Promise<void> {
    await httpClient.patch(`/api/v01/schools/${id}/`, {
      title: name,
      teacher_marketing_campaign: marketingCampaign,
      iiko_front_number: iikoFrontCount,
      week_dishes_plan: mealDaily,
      report_writeoff_store: warehouse,
      employee:
        !phone && !nameEmployee && !lastNameEmployee && !surnameEmployee
          ? undefined
          : {
            phone: phone,
            first_name: nameEmployee,
            middle_name: lastNameEmployee,
            last_name: surnameEmployee,
          },
    });
  },

  async syncIikoSettings(): Promise<void> {
    await httpClient.post('/api/v01/settings/iiko/synchronise/');
  },

  async getPromotions(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ data: PromotionDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();
    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());

    const { data } = await httpClient.get(`/api/v01/employee-promotions/?${urlSearchParams.toString()}`);

    return { data: data.results, count: data.count };
  },

  async createPromotion(name: string, preview: File, active: boolean): Promise<PromotionDto> {
    let formData = new FormData();
    formData.append('title', name);
    formData.append('preload', preview);
    formData.append('is_published', active.toString());

    const { data } = await httpClient.post('/api/v01/employee-promotions/', formData);

    return data;
  },

  async updatePromotion(
    id: string,
    name: string,
    preview: File,
    active: boolean,
    storiesImageIds: string[],
  ): Promise<PromotionDto> {
    let formData = new FormData();
    formData.append('title', name);
    formData.append('preload', preview);
    formData.append('is_published', active.toString());

    storiesImageIds.forEach((sImageId) => {
      formData.append('image_stores', sImageId);
    });

    const { data } = await httpClient.put(`/api/v01/employee-promotions/${id}/`, formData);

    return data;
  },

  async uploadPromotionStoriesImage(promotionId: string, image: File): Promise<string> {
    let formData = new FormData();
    formData.append('promotion', promotionId);
    formData.append('image', image);

    const { data } = await httpClient.post('/api/v01/employee-promotions/gallery/', formData);

    return data.id;
  },

  async deletePromotion(id: string): Promise<void> {
    await httpClient.delete(`/api/v01/employee-promotions/${id}/`);
  },

  async fetchMealConceptions(): Promise<MealConceptionDto[]> {
    const { data } = await httpClient.get('/api/v01/iiko/conceptions/');

    return data;
  },

  async fetchAccountTypes(): Promise<AccountTypeDto[]> {
    const { data } = await httpClient.get('/api/v01/iiko/account_types/');

    return data;
  },

  async fetchPlantSettings(): Promise<PlantSettingsDto> {
    const { data } = await httpClient.get('/api/v01/plant/settings/');

    return data;
  },

  async updatePlantSettings(
    reportConception: string | undefined,
    reportExpenseAccount: string,
    reportRevenueAccount: string,
  ): Promise<PlantSettingsDto> {
    const { data } = await httpClient.put('/api/v01/plant/settings/', {
      report_conception: reportConception,
      report_revenue_account: reportRevenueAccount,
      report_expense_account: reportExpenseAccount,
    });

    return data;
  },

  async createComplexDish(name: string, daysCount: number): Promise<ComplexDishDto> {
    const elements = Array.from(Array(+daysCount)).map((_) => ({
      breakfast_child_meal: null,
      breakfast_adult_meal: null,
      lunch_child_meal: null,
      lunch_adult_meal: null,
      snack_child_meal: null,
      snack_adult_meal: null,
    }));

    const { data } = await httpClient.post('/api/v01/nutrition-cycles/', {
      title: name,
      elements,
    });

    return data;
  },

  async getComplexDishes(query: {
    search: string;
    currentPage: number;
    itemsPerPage: number;
  }): Promise<{ data: ComplexDishDto[]; count: number }> {
    const urlSearchParams = new URLSearchParams();

    if (query.search) urlSearchParams.set('search', query.search);
    if (query.itemsPerPage) urlSearchParams.set('limit', query.itemsPerPage.toString());
    if (query.currentPage && query.itemsPerPage)
      urlSearchParams.set('offset', ((query.currentPage - 1) * query.itemsPerPage).toString());

    const { data } = await httpClient.get(`/api/v01/nutrition-cycles/?${urlSearchParams.toString()}`);

    return { data: data.results, count: data.count };
  },

  async deleteComplexDish(id: string): Promise<void> {
    const { data } = await httpClient.delete(`/api/v01/nutrition-cycles/${id}`);

    return data;
  },

  async getComplexDishById(id: string): Promise<ComplexDishDto> {
    const { data } = await httpClient.get(`/api/v01/nutrition-cycles/${id}`);

    return data;
  },

  async getComplexDishPlans(): Promise<ComplexDishPlanInfoDto> {
    const { data } = await httpClient.get('/api/v01/iiko/complex_dishes/');

    return data;
  },

  async updateComplexDish(
    complexDish: ComplexDish,
    startDate: string | null,
    elements: {
      afternoonSnackTeen: string | null;
      afternoonSnackYoung: string | null;
      breakfastTeen: string | null;
      breakfastYoung: string | null;
      lunchTeen: string | null;
      lunchYoung: string | null;
    }[],
    launched: boolean,
  ): Promise<void> {
    await httpClient.put(`/api/v01/nutrition-cycles/${complexDish.id}/`, {
      title: complexDish.name,
      activation_date: startDate || null,
      elements: elements.map((elem) => ({
        breakfast_child_meal: elem.breakfastYoung ? +elem.breakfastYoung : null,
        breakfast_adult_meal: elem.breakfastTeen ? +elem.breakfastTeen : null,
        lunch_child_meal: elem.lunchYoung ? +elem.lunchYoung : null,
        lunch_adult_meal: elem.lunchTeen ? +elem.lunchTeen : null,
        snack_child_meal: elem.afternoonSnackYoung ? +elem.afternoonSnackYoung : null,
        snack_adult_meal: elem.afternoonSnackTeen ? +elem.afternoonSnackTeen : null,
      })),
      launched,
    });
  },

  async syncComplexDishPlans(): Promise<ComplexDishPlanInfoDto> {
    const { data } = await httpClient.get('/api/v01/iiko/complex_dishes/synchronise/');

    return data;
  },

  async deleteSchoolClass(id: string): Promise<void> {
    await httpClient.delete(`/api/v01/school-class/${id}/`);
  },
};

export default SettingsApi;
