import cn from 'classnames';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import styles from './Header.module.scss';

import { RootState, useAppDispatch } from '@/store';
import { setSidebarClosed } from '@/store/layoutSlice';
import { CloseIcon, LogoIcon } from '@/components/icons';
import { AuthService } from '@/services';
import { Paths } from '@/routes';
import { AppButton, AppButtonGroup } from '@/components/app';
import { setAddSchoolModalOpened } from '@/store/modalsSlice';
import { AppFlavor } from '@/flavors';

export default function Header() {
  const { logoShort } = AppFlavor;
  const navigate = useNavigate();

  //
  // Refs
  //

  const logoRef = useRef(null);
  const contentRef = useRef(null);

  //
  // Store
  //

  const dispatch = useAppDispatch();
  const { sidebarClosed, enableMobileMenuSmall } = useSelector((state: RootState) => state.layout);
  const { user } = useSelector((state: RootState) => state.profile);

  //
  // Methods
  //

  const handleToggleEnableClosedSidebar = () => {
    dispatch(setSidebarClosed(!sidebarClosed));
  };

  const handleLogout = () => {
    AuthService.logout();
    navigate(Paths.auth);
  };

  //
  // Render
  //

  return (
    <>
      <TransitionGroup appear className={cn('app-header')} enter={false} exit={false}>
        <CSSTransition key={1} nodeRef={logoRef} timeout={500}>
          <div className={cn('app-header__logo', styles.logo)} ref={logoRef}>
            <div className="logo-src">
              <h1>
                Школьное <span>питание</span>
              </h1>
            </div>
            <div className="header__pane ml-auto">
              <div className={styles.toggleTrigger} onClick={handleToggleEnableClosedSidebar}>
                {sidebarClosed ? (
                  <img alt="Logo" className={cn('animated fadeIn', styles.logoShort)} src={logoShort} />
                ) : (
                  <CloseIcon className="animated fadeIn" />
                )}
              </div>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition key={2} nodeRef={contentRef} timeout={500}>
          <div className={cn('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })} ref={contentRef}>
            <div className="app-header-right">
              <div className="header-dots">
                {user?.isSeniorEmployee && (
                  <AppButtonGroup
                    className={cn(styles.addSchoolBtn, 'animate__animated animate__fadeIn')}
                    leftSlot={<FontAwesomeIcon fixedWidth icon={faPlus} />}
                    onClick={() => dispatch(setAddSchoolModalOpened(true))}
                  >
                    Добавить школу
                  </AppButtonGroup>
                )}

                <AppButton className={styles.logoutBtn} color="primary" onClick={handleLogout}>
                  Выйти
                </AppButton>
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
}
