import { Provider } from 'react-redux';
import { useEffect } from 'react';

import { AppRouter } from '@/routes';
import { store } from '@/store';
import { AppFlavor, FlavorCSSVariables } from './flavors';

function App() {
  const { favicon } = AppFlavor;

  useEffect(() => {
    var link = document.querySelector<HTMLLinkElement>('link[rel~=\'icon\']');
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style>{FlavorCSSVariables}</style>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </>
  );
}

export default App;
