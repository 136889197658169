import { useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './DeleteStudentModal.module.scss';

import { AppButton, AppModal } from '@/components/app';
import { Student } from '@/models/entity';
import { SchoolsService } from '@/services';
import { UserIcon } from '@/components/icons';
import { notify } from '@/utils/notifications';
import { ServerValidationError } from '@/api/exceptions';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onActionSuccess(): void;
  student: Student | null;
};

export default function DeleteStudentModal(props: Props) {
  const { isOpen, onActionSuccess, onClose, student } = props;
  const { classId } = useParams();

  //
  // State
  //

  const [loading, setLoading] = useState<boolean>(false);

  //
  // Methods
  //

  const handleSubmit = async () => {
    if (!classId || Array.isArray(classId) || !student) return;

    try {
      setLoading(true);
      await SchoolsService.deleteStudent(student.id, classId);
      notify('Ученик успешно удален', 'success', <UserIcon />);
      onClose();
      onActionSuccess();
    } catch (error) {
      if (error instanceof ServerValidationError) {
        notify('Не удалось удалить ученика', 'error', <UserIcon />);
      }

      throw error;
    } finally {
      setLoading(false);
    }
  };

  //
  // Render
  //

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <section className={styles.host}>
        <h2>
          Вы уверены, что хотите
          <br />
          удалить ученика?
        </h2>

        <section className={styles.actions}>
          <AppButton
            className={styles.actionBtn}
            disabled={loading}
            onClick={onClose}
            outline
          >
            Отмена
          </AppButton>
          <AppButton
            className={styles.actionBtn}
            disabled={loading}
            onClick={handleSubmit}
          >
            Удалить
          </AppButton>
        </section>
      </section>
    </AppModal>
  );
}
