import { Formik, FormikErrors } from 'formik';
import { useSelector } from 'react-redux';

import styles from './ClassForm.module.scss';

import { Yup } from '@/utils/yup';
import { AppButton, AppInputField } from '@/components/app';
import { RootState } from '@/store';

export type FormFields = {
  school: string | null;
  number: string;
  liter: string;
  mealRequestTime: string;
  mealRequestDeadlineTime: string;
};

type Props = {
  title: string;
  subtitle?: string;
  onCancel(): void;
  handleSubmit(values: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void): void;
  loading: boolean;
  defaultValues?: FormFields;
};

export default function ClassForm(props: Props) {
  const { handleSubmit, loading, onCancel, title, defaultValues, subtitle } = props;

  const validationSchema = Yup.object().shape({
    school: Yup.string().required(' '),
    number: Yup.string().required(' '),
    liter: Yup.string().required(' '),
    mealRequestTime: Yup.string().required(' '),
    mealRequestDeadlineTime: Yup.string().required(' '),
  });

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Computed
  //

  const schoolsOptions = schools.map((school) => ({
    value: school.id,
    label: school.name,
  }));

  //
  // Render
  //

  return (
    <Formik
      initialValues={{
        school: defaultValues?.school ?? null,
        number: defaultValues?.number ?? '',
        liter: defaultValues?.liter ?? '',
        mealRequestTime: defaultValues?.mealRequestTime ?? '',
        mealRequestDeadlineTime: defaultValues?.mealRequestDeadlineTime ?? '',
      }}
      onSubmit={(values, { setErrors }) => {
        handleSubmit(values, setErrors);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit: submit,
        submitCount,
        setErrors,
        setFieldValue,
        dirty,
      }) => (
        <form className={styles.host} onSubmit={submit}>
          <h2>{title}</h2>
          <p>{subtitle}</p>

          <AppInputField
            className={styles.field}
            controlType="select"
            error={submitCount > 0 && errors.school}
            label="Школа"
            name="school"
            onChange={({ value }) => setFieldValue('school', value)}
            options={schoolsOptions}
            required
            value={values.school}
          />

          <div className={styles.fieldSet}>
            <AppInputField
              className={styles.field}
              controlType="input"
              error={submitCount > 0 && errors.number}
              inputType="number"
              label="Номер класса"
              name="number"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.number}
            />
            <AppInputField
              className={styles.field}
              controlType="input"
              error={submitCount > 0 && errors.liter}
              label="Буква класса"
              name="liter"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.liter}
            />
          </div>

          <h2>Настройки заявки на питание</h2>

          <div className={styles.fieldSet}>
            <AppInputField
              className={styles.field}
              controlType="mask"
              error={submitCount > 0 && errors.mealRequestTime}
              label="Время создания заявки"
              mask='ab:cd'
              maskFormatChars={{
                a: '[0-2]',
                b: '[0-9]',
                c: '[0-5]',
                d: '[0-9]',
              }}
              name="mealRequestTime"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="ЧЧ:ММ"
              required
              value={values.mealRequestTime}
            />
            <AppInputField
              className={styles.field}
              controlType="mask"
              error={submitCount > 0 && errors.mealRequestDeadlineTime}
              label="Дедлайн подачи заявки"
              mask='ab:cd'
              maskFormatChars={{
                a: '[0-2]',
                b: '[0-9]',
                c: '[0-5]',
                d: '[0-9]',
              }}
              name="mealRequestDeadlineTime"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="ЧЧ:ММ"
              required
              value={values.mealRequestDeadlineTime}
            />
          </div>

          <section className={styles.actions}>
            <AppButton className={styles.actionBtn} disabled={loading || !dirty} type="submit">
              Сохранить
            </AppButton>

            <AppButton className={styles.actionBtn} disabled={loading} onClick={() => onCancel()} outline type="button">
              Отмена
            </AppButton>
          </section>
        </form>
      )}
    </Formik>
  );
}
