import { FileDocumentKind } from '@/constants';

export default class FileDocument {
  public id: string;
  public kind: FileDocumentKind | null;
  public url: string | null;

  public constructor(data: Partial<FileDocument>) {
    this.id = data.id!;
    this.kind = data.kind ?? null;
    this.url = data.url ?? null;
  }
}
