/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { DateRange } from 'rsuite/esm/DateRangePicker';

import { httpClient } from '@/api/httpClient';
import { freeDateFormat } from '@/utils/date';
import { ClassesValue } from '@/constants/reports';
import { getFileNameByContentDisposition } from '@/utils/files';

const ReportsApi = {
  async makeAttendanceReport(
    dateRange: DateRange,
    schoolId: string,
    classes: ClassesValue,
  ): Promise<{ file: Blob; name: string }> {
    let classesValues: number[] = [];

    switch (classes) {
      case ClassesValue['1-4']:
        classesValues = [1, 2, 3, 4];
        break;

      case ClassesValue['5-11']:
        classesValues = [5, 6, 7, 8, 9, 10, 11];
        break;

      default:
        break;
    }

    const urlSearchParams = new URLSearchParams();

    urlSearchParams.set('date_start', freeDateFormat(dateRange[0], 'yyyy-MM-dd') || '');
    urlSearchParams.set('date_end', freeDateFormat(dateRange[1], 'yyyy-MM-dd') || '');
    classesValues.forEach((classNumber) => {
      urlSearchParams.append('classes_numbers', classNumber.toString());
    });

    const { data, headers } = await httpClient.get<never, AxiosResponse<Blob>>(
      `/api/v01/school/${schoolId}/reports/attendance/?${urlSearchParams.toString()}`,
      { responseType: 'blob' },
    );

    return { file: data, name: getFileNameByContentDisposition(headers['content-disposition']) };
  },

  async makeAccountancyReport(
    dateRange: DateRange,
    schoolId: string,
    classes: ClassesValue,
  ): Promise<{ file: Blob; name: string }> {
    let classesValues: number[] = [];

    switch (classes) {
      case ClassesValue['1-4']:
        classesValues = [1, 2, 3, 4];
        break;

      case ClassesValue['5-11']:
        classesValues = [5, 6, 7, 8, 9, 10, 11];
        break;

      default:
        break;
    }

    const urlSearchParams = new URLSearchParams();

    urlSearchParams.set('date_start', freeDateFormat(dateRange[0], 'yyyy-MM-dd') || '');
    urlSearchParams.set('date_end', freeDateFormat(dateRange[1], 'yyyy-MM-dd') || '');
    classesValues.forEach((classNumber) => {
      urlSearchParams.append('classes_numbers', classNumber.toString());
    });

    const { data, headers } = await httpClient.get<never, AxiosResponse<Blob>>(
      `/api/v01/school/${schoolId}/reports/accountancy/?${urlSearchParams.toString()}`,
      { responseType: 'blob' },
    );

    return { file: data, name: getFileNameByContentDisposition(headers['content-disposition']) };
  },

  async makeNutritionReport(
    dateRange: DateRange,
    schoolId: string,
    classes: ClassesValue,
  ): Promise<{ file: Blob; name: string }> {
    let classesValues: number[] = [];

    switch (classes) {
      case ClassesValue['1-4']:
        classesValues = [1, 2, 3, 4];
        break;

      case ClassesValue['5-11']:
        classesValues = [5, 6, 7, 8, 9, 10, 11];
        break;

      default:
        break;
    }

    const urlSearchParams = new URLSearchParams();

    urlSearchParams.set('date_start', freeDateFormat(dateRange[0], 'yyyy-MM-dd') || '');
    urlSearchParams.set('date_end', freeDateFormat(dateRange[1], 'yyyy-MM-dd') || '');
    classesValues.forEach((classNumber) => {
      urlSearchParams.append('classes_numbers', classNumber.toString());
    });

    const { data, headers } = await httpClient.get<never, AxiosResponse<Blob>>(
      `/api/v01/school/${schoolId}/reports/nutrition/?${urlSearchParams.toString()}`,
      { responseType: 'blob' },
    );

    return { file: data, name: getFileNameByContentDisposition(headers['content-disposition']) };
  },
};

export default ReportsApi;
