import MarketingCampaign from './MarketingCampaign';
import NutritionPlan from './NutritionPlan';
import Warehouse from './Warehouse';
import WriteOffStore from './WriteOffStore';

export default class SchoolIikoSettings {
  public id: string;
  public syncDate: Date | null;
  public name: string;
  public phoneEmployee: string | null;
  public nameEmployee: string;
  public surnameEmployee: string;
  public middleNameEmployee: string;
  public discount: number;
  public mealDaily: '5' | '6' | null;
  public iikoFrontCount: number;
  public plugins: {
    is_active: boolean;
    last_login: string;
    auth_token: string;
    version: string;
    date_joined: string;
  }[];
  public iikoPluginTokens: string[];
  public iikoPluginTokenActive: boolean;
  public iikoPluginTokenConnectDate: Date | null;
  public iikoPluginTokenLastUsageDate: Date | null;
  public iikoPluginVersion: string | null;
  public writeoffStores: Warehouse[];
  public reportWriteoffStore: WriteOffStore | null;
  public marketingCampaign: MarketingCampaign | null;
  public classesCount: number;
  public teachersCount: number;
  public currentNutritionPlan: NutritionPlan | null;

  public constructor(data: Partial<SchoolIikoSettings>) {
    this.id = data.id!;
    this.syncDate = data.syncDate ?? null;
    this.name = data.name ?? '';
    this.nameEmployee = data.nameEmployee ?? '';
    this.surnameEmployee = data.surnameEmployee ?? '';
    this.middleNameEmployee = data.middleNameEmployee ?? '';
    this.phoneEmployee = data.phoneEmployee ?? null;
    this.discount = data.discount ?? 0;
    this.iikoFrontCount = data.iikoFrontCount ?? 0;
    this.mealDaily = data.mealDaily ?? null;
    this.iikoPluginTokens = data.iikoPluginTokens ?? [];
    this.iikoPluginTokenActive = data.iikoPluginTokenActive ?? false;
    this.iikoPluginTokenConnectDate = data.iikoPluginTokenConnectDate ?? null;
    this.iikoPluginTokenLastUsageDate = data.iikoPluginTokenLastUsageDate ?? null;
    this.iikoPluginVersion = data.iikoPluginVersion ?? null;
    this.plugins = data.plugins ?? [];
    this.writeoffStores = data.writeoffStores ?? [];
    this.reportWriteoffStore = data.reportWriteoffStore ?? null;
    this.marketingCampaign = data.marketingCampaign ?? null;
    this.classesCount = data.classesCount ?? 0;
    this.teachersCount = data.teachersCount ?? 0;
    this.currentNutritionPlan = data.currentNutritionPlan ?? null;
  }
}
