export default class StudentTransaction {
  public id: string;
  public date: Date | null;
  public amount: number | null;
  public balance: number | null;
  public kind: 'main' | 'paid' | null;

  public constructor(data: Partial<StudentTransaction>) {
    this.id = data.id!;
    this.date = data.date ?? null;
    this.amount = data.amount ?? null;
    this.balance = data.balance ?? null;
    this.kind = data.kind ?? null;
  }
}
