import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { FormikErrors, useFormik } from 'formik';
import { useSelector } from 'react-redux';

import styles from './TeacherModal.module.scss';

import { SchoolClass, TeacherListItem } from '@/models/entity';
import { AppInputField, AppModal } from '@/components/app';
import { Yup } from '@/utils/yup';
import { deformatPhone } from '@/utils/formatters';
import { FormFields } from '.';
import { RootState } from '@/store';
import { SchoolsService } from '@/services';

type Props = {
  open: boolean;
  onClose(): void;
  handleSubmit(formData: FormFields, setErrors: (errors: FormikErrors<FormFields>) => void): void;
  actionsSlot(values: FormFields, dirty: boolean, isValid: boolean): ReactElement;
  data: TeacherListItem | null;
  header: string;
  loading: boolean;
};

export default function TeacherModal(props: Props) {
  const { data, onClose, open, actionsSlot, header, handleSubmit, loading } = props;

  const validationSchema = Yup.object().shape({
    surname: Yup.string().required(' '),
    name: Yup.string().required(' '),
    responsible: Yup.array().required(' ').min(1, ' '),
    middleName: Yup.string(),
    phone: Yup.string()
      .transform((value: string) => deformatPhone(value))
      .min(10)
      .required(' '),
    school: Yup.string().required(' '),
  });

  const formik = useFormik({
    initialValues: {
      name: data ? data.firstName : '',
      phone: data ? data.phone : '',
      surname: data ? data.lastName : '',
      middleName: data ? data.middleName : '',
      responsible: data?.assignedClasses ? data.assignedClasses.map((ac) => ac.id) : [],
      school: data?.school?.id ?? null,
    },
    onSubmit: (values, { setErrors }) => {
      handleSubmit(values, setErrors);
    },
    validationSchema,
  });

  //
  // State
  //

  const [classes, setClasses] = useState<SchoolClass[]>([]);
  const [classesLoading, setClassesLoading] = useState<boolean>(false);

  //
  // Store
  //

  const { schools } = useSelector((state: RootState) => state.schools);

  //
  // Computed
  //

  const classOptions = classes.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  const schoolsOptions = schools.map((school) => ({
    value: school.id,
    label: school.name,
  }));

  //
  // Methods
  //

  const loadSchoolClasses = useCallback(async () => {
    const schoolId = formik.values.school;

    if (!schoolId) return;

    try {
      setClassesLoading(true);
      const schoolClasses = await SchoolsService.getSchoolClasses(schoolId);
      setClasses(schoolClasses);
    } catch (error) {
      throw error;
    } finally {
      setClassesLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.school]);

  //
  // Effects
  //

  useEffect(() => {
    loadSchoolClasses();
  }, [loadSchoolClasses]);

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!data) return;

    formik.resetForm({
      values: {
        name: data ? data.firstName : '',
        phone: data ? data.phone : '',
        surname: data ? data.lastName : '',
        middleName: data ? data.middleName : '',
        responsible: data?.assignedClasses ? data.assignedClasses.map((ac) => ac.id) : [],
        school: data?.school?.id ?? null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //
  // Render
  //

  return (
    <AppModal className={styles.modal} isOpen={open} onClose={onClose}>
      <section className={styles.host}>
        <h2>{header}</h2>
        <p>
          Для добавления учителя, пожалуйста
          <br />
          заполните поля:
        </p>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <AppInputField
            className={styles.field}
            controlType="input"
            disabled={loading}
            error={formik.submitCount > 0 && formik.errors.surname}
            label="Фамилия"
            name="surname"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder=""
            required
            value={formik.values.surname}
          />

          <AppInputField
            className={styles.field}
            controlType="input"
            disabled={loading}
            error={formik.submitCount > 0 && formik.errors.name}
            label="Имя"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder=""
            required
            value={formik.values.name}
          />

          <AppInputField
            className={styles.field}
            controlType="input"
            disabled={loading}
            error={formik.submitCount > 0 && formik.errors.middleName}
            label="Отчество"
            name="middleName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder=""
            value={formik.values.middleName}
          />

          <AppInputField
            className={styles.field}
            controlType="phone"
            disabled={loading}
            error={formik.submitCount > 0 && formik.errors.phone}
            label="Номер телефона"
            name="phone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder=""
            required
            value={formik.values.phone}
          />

          <AppInputField
            className={styles.field}
            controlType="select"
            error={formik.submitCount > 0 && formik.errors.school}
            label="Школа"
            name="school"
            onChange={({ value }) => {
              formik.setFieldValue('school', value);
              formik.setFieldValue('responsible', []);
            }}
            options={schoolsOptions}
            required
            value={formik.values.school}
          />

          <AppInputField
            className={styles.field}
            controlType="multi-select"
            disabled={loading || classesLoading}
            error={formik.submitCount > 0 && !!formik.errors.responsible}
            label="Ответственный по питанию"
            multiSelectItemRender={(checked, option, onClick, disabled) => {
              const schoolClass = classes.find((c) => c.id === option.value);

              return (
                <div className={`item-renderer ${disabled && 'disabled'}`}>
                  <Label check className={styles.responsibleLabelWrapper}>
                    <Input checked={checked} disabled={disabled} onChange={onClick} tabIndex={-1} type="checkbox" />{' '}
                    <span className={styles.responsibleLabel}>
                      {option.label}{' '}
                      {schoolClass?.responsible ? (
                        <span className={styles.responsible}>{schoolClass.responsible.FIOShort}</span>
                      ) : (
                        <span className={styles.responsible}>—</span>
                      )}
                    </span>
                  </Label>
                </div>
              );
            }}
            name="responsible"
            noOptionsMessage={!formik.values.school ? 'Сначала выберите школу' : 'Опций нет'}
            onBlur={formik.handleBlur}
            onChange={(value) => formik.setFieldValue('responsible', value)}
            options={[
              ...classOptions
                .filter((c) => formik.values.responsible.includes(c.value))
                .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' })),
              ...classOptions
                .filter((c) => !formik.values.responsible.includes(c.value))
                .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' })),
            ]}
            placeholder="Выберите классы из списка"
            required
            value={formik.values.responsible}
          />

          {actionsSlot(formik.values, formik.dirty, formik.isValid)}
        </form>
      </section>
    </AppModal>
  );
}
